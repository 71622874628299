import { createKey, get } from "../../utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const partnerReferrals = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
    serviceStatus: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SERVICE_STATUS(state, payload) {
      state.serviceStatus.value = payload;
    },
    SET_SORT,
    SET_STATUS,
  },
  actions: {
    async get({ commit }, id) {
      commit("SET_SERVICE_STATUS", Status.LOADING);
      const res = await get(createKey("partner-referrals", { id }), request.makeParse, [
        "getPartnerReferral",
        { id },
      ]);
      commit("SET_ITEM", res);
      commit("SET_SERVICE_STATUS", Status.LOADED);
      return res;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const res = await get(createKey("partner-referrals-list", params), request.makeParse, [
        "getPartnerReferrals",
        params,
      ]);
      commit("SET_LIST", res.list);
      commit("SET_PAGINATION", res.pagination);
      commit("SET_STATUS", Status.LOADED);
      return res;
    },
  },
};

export default partnerReferrals;
