import { createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_LIST,
  SET_LIST_STATUS,
  SET_PAGINATION,
  SET_SORT,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_LIST_KEY = "ssl-packages";

const CACHE_EXPIRY = 60 * 60 * 1000; // 1 hour

const ssl = {
  namespaced: true,
  state: {
    chunk: null,
    list: [],
    listStatus: new Status(),
    pagination: null,
    sort: null,
  },
  mutations: {
    SET_CHUNK,
    SET_LIST,
    SET_LIST_STATUS,
    SET_PAGINATION,
    SET_SORT,
  },
  actions: {
    async getList({ commit }, params) {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        const response = await get(
          createKey(CACHE_LIST_KEY, params),
          request.makeParse,
          ["getSslPackages", params],
          CACHE_EXPIRY
        );
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return Promise.resolve(response);
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    getList: (state) => state.list,
    getListStatus: (state) => state.listStatus,
  },
};

export default ssl;
