import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "containers";
const CACHE_PLURAL_TYPES_KEY = "container-types";
const CACHE_PLURAL_GROUPS_KEY = "container-groups";

const store = {
  namespaced: true,
  state: {
    typesList: [],
    typesStatus: new Status(),
    groupsList: [],
    groupsStatus: new Status(),
    list: [],
    listStatus: new Status(),
    siteId: null,
  },
  mutations: {
    SET_TYPES_LIST(state, payload) {
      state.typesList = payload;
    },
    SET_TYPES_LIST_STATUS(state, payload) {
      state.typesStatus.value = payload;
    },
    SET_GROUPS_LIST(state, payload) {
      state.groupsList = payload;
    },
    SET_GROUPS_LIST_STATUS(state, payload) {
      state.groupsStatus.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_LIST_STATUS(state, payload) {
      state.listStatus.value = payload;
    },
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async add({ commit, state }, { id, data }) {
      const lastStatus = state.listStatus;
      try {
        commit("SET_LIST_STATUS", state.listStatus.isLoaded ? Status.UPDATING : Status.LOADING);
        return request.makeParse("addSiteContainer", { id }, data);
      } catch (e) {
        commit("SET_LIST_STATUS", lastStatus);
      }
    },
    async getList({ commit, state }, id) {
      commit(
        "SET_LIST_STATUS",
        (state.listStatus.isLoaded || state.listStatus.isUpdating) && id === state.siteId
          ? Status.UPDATING
          : Status.LOADING
      );
      commit("SET_SITE_ID", id);
      try {
        const response = await get(createKey(CACHE_PLURAL_KEY, { id }), request.makeParse, [
          "getSiteContainers",
          { id },
        ]);
        commit("SET_LIST", response);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    async getGroupList({ commit }) {
      commit("SET_GROUPS_LIST_STATUS", Status.LOADING);
      try {
        const response = await get(createKey(CACHE_PLURAL_GROUPS_KEY), request.makeParse, [
          "getSiteContainerGroups",
        ]);
        commit("SET_GROUPS_LIST", response);
        commit("SET_GROUPS_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_GROUPS_LIST_STATUS", Status.ERROR);
      }
    },
    async getTypeList({ commit }) {
      commit("SET_TYPES_LIST_STATUS", Status.LOADING);
      try {
        const response = await get(createKey(CACHE_PLURAL_TYPES_KEY), request.makeParse, [
          "getSiteContainerTypes",
        ]);
        commit("SET_TYPES_LIST", response);
        commit("SET_TYPES_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_TYPES_LIST_STATUS", Status.ERROR);
      }
    },
    update({ dispatch, state }, siteId) {
      clear(createKey(CACHE_PLURAL_KEY, { id: siteId }));
      if (state.listStatus.isInitialized && state.siteId === siteId) {
        dispatch("getList", siteId);
      }
    },
    refreshList({ dispatch, state }) {
      clearListCache(createKey(CACHE_PLURAL_KEY, { id: state.siteId }));
      if (state.listStatus.isInitialized) {
        dispatch("getList", state.siteId);
      }
    },
    async delete({ commit, state }, { id, data }) {
      const lastStatus = state.listStatus;
      try {
        commit("SET_LIST_STATUS", state.listStatus.isLoaded ? Status.UPDATING : Status.LOADING);
        return request.makeParse("removeSiteContainer", { id }, data);
      } catch (e) {
        commit("SET_LIST_STATUS", lastStatus);
      }
    },
    resize(context, { id, data }) {
      return request.makeParse("resizeSiteContainer", { id }, data);
    },
  },
  getters: {
    getList: (state) => state.list,
    getListStatus: (state) => state.listStatus,
    getElasticSearch: (state) => state.list.find((item) => item.group.name === "elasticsearch"),
    getRabbitMQ: (state) => state.list.find((item) => item.group.name === "rabbitmq"),
    getSolr: (state) => state.list.find((item) => item.group.name === "solr"),

    // types
    getElasticSearchTypes: (state) =>
      state.typesList.filter((item) => item.type === "elasticsearch"),
    getRabbitMQTypes: (state) => state.typesList.filter((item) => item.type === "rabbitmq"),
    getSolrTypes: (state) => state.typesList.filter((item) => item.type === "solr"),
    getTypesStatus: (state) => state.typesStatus,
    getVarnishTypes: (state) => state.typesList.filter((item) => item.type === "varnish"),

    // groups
    getElasticSearchGroups: (state) =>
      state.groupsList.filter((item) => item.type === "elasticsearch"),
    getElasticSearchGroupsStatus: (state) => state.groupsStatus,

    getRabbitmq: (state) => state.list.find((item) => item.group.name === "rabbitmq"),
    getPhp: (state) => state.list.find((item) => item.group.name === "php"),
    getVarnish: (state) => state.list.find((item) => item.group.type === "varnish"),
  },
};

export default store;
