import { createKey, get } from "@/utils/networkCache";
import { SET_LIST, SET_LIST_STATUS } from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_LIST_KEY = "cloud-server-packages";

const CACHE_EXPIRY = 60 * 60 * 1000; // 1 hour

const module = {
  namespaced: true,
  state: {
    list: [],
    listStatus: new Status(),
  },
  mutations: {
    SET_LIST,
    SET_LIST_STATUS,
  },
  actions: {
    async getList({ commit }) {
      commit("SET_LIST_STATUS", Status.LOADING);
      try {
        const response = await get(
          createKey(CACHE_LIST_KEY),
          request.makeParse,
          ["getCloudServerPackages"],
          CACHE_EXPIRY
        );
        commit("SET_LIST", response.list);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    getList: (state) => state.list,
    getListStatus: (state) => state.listStatus,
  },
};

export default module;
