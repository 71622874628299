import Cookies from "js-cookie";

const defaultTheme = {
  stylesheet: "default",
};

const dark = {
  stylesheet: "dark",
};

const getPrefersColorScheme = () =>
  window.matchMedia("(prefers-color-scheme: light)").matches ? "default" : "dark";

const OPTIONS = [
  { value: "system-settings", name: "Default" },
  { value: "dark", name: "Dark" },
  { value: "default", name: "Light" },
];

const theme = {
  namespaced: true,
  state: {
    current: Cookies.get("nxTheme") || "system-settings",
    list: {
      default: defaultTheme,
      dark,
    },
    systemTheme: getPrefersColorScheme(),
  },
  mutations: {
    SET: (state, payload) => (state.current = payload),
    UPDATE_SYSTEM_THEME: (state, payload) => (state.systemTheme = payload),
  },
  actions: {
    set: ({ commit }, { theme, cache }) => {
      if (cache === true || typeof cache === "undefined") {
        Cookies.set("nxTheme", theme, { expires: 365 * 100 });
      }
      commit("SET", theme);
    },
    updateSystemTheme: ({ commit }, theme) => commit("UPDATE_SYSTEM_THEME", theme),
  },
  getters: {
    get: (state) => ({
      ...state.list[state.current === "system-settings" ? state.systemTheme : state.current],
    }),
    getCurrent: (state) => state.current,
    getOptions: () => OPTIONS,
    getStylesheet: (state, getters) => getters["get"]?.stylesheet,
    isDark: (state, getters) => getters["getStylesheet"] === "dark",
  },
};

export default theme;
