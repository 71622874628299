import { canUpdateInPlace, updateItem, updateList } from "@/utils/storeHelper";
import { createKey, get } from "../../utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
  UPDATE_IN_PLACE,
} from "@/store/commonMutations";
import { clearListCache } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const partnerLinks = {
  namespaced: true,
  state: {
    bannerStatus: new Status(),
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
    SET_BANNER_STATUS(state, payload) {
      state.bannerStatus.value = payload;
    },
    UPDATE_IN_PLACE,
  },
  actions: {
    async get({ commit }, id) {
      commit("SET_BANNER_STATUS", Status.LOADING);
      const res = await get(createKey("partner-links", { id }), request.makeParse, [
        "getPartnerLink",
        { id },
      ]);
      commit("SET_ITEM", res);
      commit("SET_BANNER_STATUS", Status.LOADED);
      return res;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const res = await get(createKey("partner-links-list", params), request.makeParse, [
        "getPartnerLinks",
        params,
      ]);
      commit("SET_LIST", res.list);
      commit("SET_PAGINATION", res.pagination);
      commit("SET_STATUS", Status.LOADED);
      return res;
    },
    create({ dispatch, state }, { data }) {
      return request.makeParse("addPartnerLink", null, data).finally(() => {
        clearListCache("partner-links-list");
        updateList(dispatch, state);
      });
    },
    delete({ dispatch, state }, id) {
      return request.makeParse("deletePartnerLink", { id }).finally(() => {
        clearListCache("partner-links-list");
        updateList(dispatch, state);
      });
    },
    update({ commit, dispatch, state }, { id, data }) {
      return request.makeParse("updatePartnerLink", { id }, data).then((res) => {
        if (canUpdateInPlace(state, { id })) {
          commit("UPDATE_IN_PLACE", data);
        } else {
          clearListCache("partner-links-list");
          updateItem(commit, state, data);
          updateList(dispatch, state);
        }
        return Promise.resolve(res);
      });
    },
  },
};

export default partnerLinks;
