import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "api-task:add":
    case "api-task:update":
      if (data.identity.includes("set-plugin-update-settings")) {
        if (data.identity.includes("(pending)") || data.identity.includes("(processing)")) {
          store.dispatch("wpWoo/addPluginSettingsUpdate", data.resource_id);
          store.dispatch("wpWoo/refreshPluginSettings", data.resource_id);
        }
        if (data.identity.includes("(success)")) {
          store.dispatch("toasts/addToast", {
            variant: "success",
            message: "You have successfully updated your Plugin Upgrades!",
          });
          store.dispatch("wpWoo/removePluginSettingsUpdate", data.resource_id);
        }
        break;
      }
      if (data.identity.includes("set-core-update-settings")) {
        if (data.identity.includes("(pending)") || data.identity.includes("(processing)")) {
          store.dispatch("wpWoo/addCoreSettingsUpdate", data.resource_id);
          store.dispatch("wpWoo/refreshCoreSettings", data.resource_id);
        }
        if (data.identity.includes("(success)")) {
          store.dispatch("toasts/addToast", {
            variant: "success",
            message: "You have successfully updated your Core Upgrades!",
          });
          store.dispatch("wpWoo/removeCoreSettingsUpdate", data.resource_id);
        }
        break;
      }
  }
};

export default handler;
