import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_ALLOWED_KEY = "safe-harbor-allowed";
const CACHE_BLACKLIST_KEY = "safe-harbor-blacklist";
const CACHE_MALWARE_SCANS_KEY = "safe-harbor-malware-scans";
const CACHE_PACKAGE_KEY = "safe-harbor-package";
const CACHE_PATCHES_KEY = "safe-harbor-patches";
const CACHE_SITE_BLACKLIST_KEY = "safe-harbor-site-blacklist";
const CACHE_SITE_MALWARE_SCANS_KEY = "safe-harbor-site-malware-scans";
const CACHE_SITE_PATCHES_KEY = "safe-harbor-site-patches";
const CACHE_SITE_STAGING_ENVIRONMENTS_KEY = "safe-harbor-site-staging-environments";
const CACHE_STAGING_ENVIRONMENTS_KEY = "safe-harbor-staging-environments";

const store = {
  namespaced: true,
  state: {
    allowed: false,
    allowedStatus: new Status(),
    blacklist: null,
    blacklistStatus: new Status(),
    malwareScans: null,
    malwareScansStatus: new Status(),
    package: null,
    packageStatus: new Status(),
    patches: null,
    patchesStatus: new Status(),
    siteBlacklist: null,
    siteBlacklistStatus: new Status(),
    siteMalwareScans: null,
    siteMalwareScansStatus: new Status(),
    sitePatches: null,
    sitePatchesStatus: new Status(),
    siteStagingEnvironments: null,
    siteStagingEnvironmentsStatus: new Status(),
    siteStagingEnvironmentUpdates: [],
    stagingEnvironments: null,
    stagingEnvironmentsStatus: new Status(),
    stagingEnvironmentUpdates: [],
    updates: [],
  },
  mutations: {
    ADD_SITE_STAGING_ENVIRONMENT_UPDATE(state, id) {
      const index = state.siteStagingEnvironmentUpdates.findIndex((item) => item === Number(id));
      if (index < 0) {
        state.siteStagingEnvironmentUpdates.push(Number(id));
      }
    },
    ADD_STAGING_ENVIRONMENT_UPDATE(state, id) {
      const index = state.stagingEnvironmentUpdates.findIndex((item) => item === Number(id));
      if (index < 0) {
        state.stagingEnvironmentUpdates.push(Number(id));
      }
    },
    ADD_UPDATE(state, id) {
      const index = state.updates.findIndex((item) => item === Number(id));
      if (index < 0) {
        state.updates.push(Number(id));
      }
    },
    REMOVE_SITE_STAGING_ENVIRONMENT_UPDATE(state, id) {
      const index = state.siteStagingEnvironmentUpdates.findIndex((item) => item === Number(id));
      if (index >= 0) {
        state.siteStagingEnvironmentUpdates.splice(index, 1);
      }
    },
    REMOVE_STAGING_ENVIRONMENT_UPDATE(state, id) {
      const index = state.stagingEnvironmentUpdates.findIndex((item) => item === Number(id));
      if (index >= 0) {
        state.stagingEnvironmentUpdates.splice(index, 1);
      }
    },
    REMOVE_UPDATE(state, id) {
      const index = state.updates.findIndex((item) => item === Number(id));
      if (index >= 0) {
        state.updates.splice(index, 1);
      }
    },
    SET_ALLOWED(state, payload) {
      state.allowed = payload;
    },
    SET_ALLOWED_STATUS(state, status) {
      state.allowedStatus.value = status;
    },
    SET_BLACKLIST(state, payload) {
      state.blacklist = payload;
    },
    SET_BLACKLIST_STATUS(state, status) {
      state.blacklistStatus.value = status;
    },
    SET_PACKAGE(state, payload) {
      state.package = payload;
    },
    SET_PACKAGE_STATUS(state, status) {
      state.packageStatus.value = status;
    },
    SET_MALWARE_SCANS(state, payload) {
      state.malwareScans = payload;
    },
    SET_MALWARE_SCANS_STATUS(state, status) {
      state.malwareScansStatus.value = status;
    },
    SET_PATCHES(state, payload) {
      state.patches = payload;
    },
    SET_PATCHES_STATUS(state, status) {
      state.patchesStatus.value = status;
    },
    SET_SITE_BLACKLIST(state, payload) {
      state.siteBlacklist = payload;
    },
    SET_SITE_BLACKLIST_STATUS(state, status) {
      state.siteBlacklistStatus.value = status;
    },
    SET_SITE_MALWARE_SCANS(state, payload) {
      state.siteMalwareScans = payload;
    },
    SET_SITE_MALWARE_SCANS_STATUS(state, status) {
      state.siteMalwareScansStatus.value = status;
    },
    SET_SITE_PATCHES(state, payload) {
      state.sitePatches = payload;
    },
    SET_SITE_PATCHES_STATUS(state, status) {
      state.sitePatchesStatus.value = status;
    },
    SET_SITE_STAGING_ENVIRONMENT_LIST(state, payload) {
      state.siteStagingEnvironments = payload;
    },
    SET_SITE_STAGING_ENVIRONMENT_LIST_STATUS(state, status) {
      state.siteStagingEnvironmentsStatus.value = status;
    },
    SET_STAGING_ENVIRONMENT_LIST(state, payload) {
      state.stagingEnvironments = payload;
    },
    SET_STAGING_ENVIRONMENT_LIST_STATUS(state, status) {
      state.stagingEnvironmentsStatus.value = status;
    },
  },
  actions: {
    addSiteStagingEnvironmentUpdate({ commit }, id) {
      commit("ADD_SITE_STAGING_ENVIRONMENT_UPDATE", id);
    },
    addStagingEnvironmentUpdate({ commit }, id) {
      commit("ADD_STAGING_ENVIRONMENT_UPDATE", id);
    },
    addUpdate({ commit }, id) {
      commit("ADD_UPDATE", id);
    },
    createSharedHostSafeHarborDevelopmentLead(context, { id, data }) {
      return request.makeParse("createSharedHostSafeHarborDevelopmentLead", { id }, data);
    },
    createSharedHostSafeHarborMigrationLead(context, { id, data }) {
      return request.makeParse("createSharedHostSafeHarborMigrationLead", { id }, data);
    },
    createSiteSafeHarborDevelopmentLead(context, { id, data }) {
      return request.makeParse("createSiteSafeHarborDevelopmentLead", { id }, data);
    },
    createSiteSafeHarborMigrationLead(context, { id, data }) {
      return request.makeParse("createSiteSafeHarborMigrationLead", { id }, data);
    },
    async getAllowed({ commit, state }, id) {
      const newStatus =
        (state.allowedStatus.isLoaded || state.allowedStatus.isUpdating) &&
        id === state.allowed?.serviceId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_ALLOWED_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_ALLOWED_KEY, { serviceId: id }),
          request.makeParse,
          ["isSafeHarborAllowed", { id }]
        );
        commit("SET_ALLOWED", response);
        commit("SET_ALLOWED_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ALLOWED_STATUS", Status.ERROR);
      }
    },
    async getBlacklist({ commit, state }, id) {
      const newStatus =
        (state.blacklistStatus.isLoaded || state.blacklistStatus.isUpdating) &&
        id === state.blacklist?.serviceId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_BLACKLIST_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_BLACKLIST_KEY, { id }), request.makeParse, [
          "getSharedHostSafeHarborThreatProtection",
          { id },
        ]);
        commit("SET_BLACKLIST", response);
        commit("SET_BLACKLIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_BLACKLIST_STATUS", Status.ERROR);
      }
    },
    async getMalwareScans({ commit, state }, id) {
      const newStatus =
        (state.malwareScansStatus.isLoaded || state.malwareScansStatus.isUpdating) &&
        id === state.malwareScans?.serviceId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_MALWARE_SCANS_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_MALWARE_SCANS_KEY, { id }), request.makeParse, [
          "getSharedHostSafeHarborMalwareScans",
          { id },
        ]);
        commit("SET_MALWARE_SCANS", response);
        commit("SET_MALWARE_SCANS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_MALWARE_SCANS_STATUS", Status.ERROR);
      }
    },
    async getPlanAllowed({ commit, state }, id) {
      const newStatus =
        (state.allowedStatus.isLoaded || state.allowedStatus.isUpdating) &&
        id === state.allowed?.serviceId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_ALLOWED_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_ALLOWED_KEY, { siteId: id }),
          request.makeParse,
          ["getSiteSafeHarborInfo", { siteId: id }]
        );
        commit("SET_ALLOWED", response);
        commit("SET_ALLOWED_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ALLOWED_STATUS", Status.ERROR);
      }
    },
    async getPackage({ commit, state }, label) {
      const newStatus =
        (state.packageStatus.isLoaded || state.packageStatus.isUpdating) &&
        label === state.package?.label
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_PACKAGE_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_PACKAGE_KEY, { label }), request.makeParse, [
          "getSafeHarborPackageByLabel",
          { label },
        ]);
        commit("SET_PACKAGE", response);
        commit("SET_PACKAGE_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_PACKAGE_STATUS", Status.ERROR);
      }
    },
    async getPatches({ commit, state }, id) {
      const newStatus =
        (state.patchesStatus.isLoaded || state.patchesStatus.isUpdating) &&
        id === state.patches?.serviceId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_PATCHES_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_PATCHES_KEY, { id }), request.makeParse, [
          "getSharedHostSafeHarborMagentoPatches",
          { id },
        ]);
        commit("SET_PATCHES", response);
        commit("SET_PATCHES_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_PATCHES_STATUS", Status.ERROR);
      }
    },
    async getSiteBlacklist({ commit, state }, id) {
      const newStatus =
        (state.siteBlacklistStatus.isLoaded || state.siteBlacklistStatus.isUpdating) &&
        id === state.siteBlacklist?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_SITE_BLACKLIST_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_SITE_BLACKLIST_KEY, { siteId: id }),
          request.makeParse,
          ["getSiteSafeHarborThreatProtection", { siteId: id }]
        );
        commit("SET_SITE_BLACKLIST", response);
        commit("SET_SITE_BLACKLIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_SITE_BLACKLIST_STATUS", Status.ERROR);
      }
    },
    async getSiteMalwareScans({ commit, state }, id) {
      const newStatus =
        (state.siteMalwareScansStatus.isLoaded || state.siteMalwareScansStatus.isUpdating) &&
        id === state.siteMalwareScans?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_SITE_MALWARE_SCANS_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_SITE_MALWARE_SCANS_KEY, { siteId: id }),
          request.makeParse,
          ["getSiteSafeHarborMalwareScans", { siteId: id }]
        );
        commit("SET_SITE_MALWARE_SCANS", response);
        commit("SET_SITE_MALWARE_SCANS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_SITE_MALWARE_SCANS_STATUS", Status.ERROR);
      }
    },
    async getSitePatches({ commit, state }, id) {
      const newStatus =
        (state.sitePatchesStatus.isLoaded || state.sitePatchesStatus.isUpdating) &&
        id === state.sitePatches?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_SITE_PATCHES_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_SITE_PATCHES_KEY, { siteId: id }),
          request.makeParse,
          ["getSiteSafeHarborMagentoPatches", { siteId: id }]
        );
        commit("SET_SITE_PATCHES", response);
        commit("SET_SITE_PATCHES_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_SITE_PATCHES_STATUS", Status.ERROR);
      }
    },
    async getStagingEnvironments({ commit, state }, id) {
      const newStatus =
        (state.stagingEnvironmentsStatus.isLoaded || state.stagingEnvironmentsStatus.isUpdating) &&
        id === state.stagingEnvironments?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_STAGING_ENVIRONMENT_LIST_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_STAGING_ENVIRONMENTS_KEY, { id }),
          request.makeParse,
          ["getSharedHostSafeHarborStagingServices", { id }]
        );
        commit("SET_STAGING_ENVIRONMENT_LIST", response);
        commit("SET_STAGING_ENVIRONMENT_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STAGING_ENVIRONMENT_LIST_STATUS", Status.ERROR);
      }
    },
    async getSiteStagingEnvironments({ commit, state }, id) {
      const newStatus =
        (state.siteStagingEnvironmentsStatus.isLoaded ||
          state.siteStagingEnvironmentsStatus.isUpdating) &&
        id === state.siteStagingEnvironments?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_SITE_STAGING_ENVIRONMENT_LIST_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_SITE_STAGING_ENVIRONMENTS_KEY, { id }),
          request.makeParse,
          ["getSiteSafeHarborStagingServices", { id }]
        );
        commit("SET_SITE_STAGING_ENVIRONMENT_LIST", response);
        commit("SET_SITE_STAGING_ENVIRONMENT_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_SITE_STAGING_ENVIRONMENT_LIST_STATUS", Status.ERROR);
      }
    },
    purchase(context, { serviceId, data }) {
      return request.makeParse("purchaseSafeHarbor", { serviceId }, data);
    },
    refreshStagingEnvironments({ dispatch, state }, serviceId) {
      const cacheKey = createKey(CACHE_STAGING_ENVIRONMENTS_KEY, { id: serviceId });
      clear(cacheKey);
      const list = state.stagingEnvironments?.list || [];
      if (serviceId === state.stagingEnvironments?.serviceId) {
        dispatch("getStagingEnvironments", serviceId);
      } else if (list.find((stagingEnvironment) => stagingEnvironment.id === serviceId)) {
        const id = state.stagingEnvironments.serviceId;
        const cacheKey = createKey(CACHE_STAGING_ENVIRONMENTS_KEY, { id });
        clear(cacheKey);
        dispatch("getStagingEnvironments", id);
      }
    },
    refreshSiteStagingEnvironments({ dispatch, state }, siteId) {
      const cacheKey = createKey(CACHE_SITE_STAGING_ENVIRONMENTS_KEY, { id: siteId });
      clear(cacheKey);
      if (siteId === state.siteStagingEnvironments?.siteId) {
        dispatch("getSiteStagingEnvironments", siteId);
      }
    },
    refreshSiteStagingEnvironmentsByServiceId({ dispatch, state }, serviceId) {
      const list = state.siteStagingEnvironments?.list || [];
      const stagingEnvironment = list.find(
        (stagingEnvironment) => stagingEnvironment.id === serviceId
      );
      if (stagingEnvironment) {
        const id = stagingEnvironment?.cloudAccount?.reference_account_id;
        const cacheKey = createKey(CACHE_SITE_STAGING_ENVIRONMENTS_KEY, { id });
        clear(cacheKey);
        dispatch("getSiteStagingEnvironments", id);
      }
    },
    refreshSiteStagingEnvironmentsByServiceSiteId({ dispatch, state }, siteId) {
      const list = state.siteStagingEnvironments?.list || [];
      const stagingEnvironment = list.find(
        (stagingEnvironment) => stagingEnvironment?.cloudAccount?.id === siteId
      );
      if (stagingEnvironment) {
        const id = stagingEnvironment?.cloudAccount?.reference_account_id;
        const cacheKey = createKey(CACHE_SITE_STAGING_ENVIRONMENTS_KEY, { id });
        clear(cacheKey);
        dispatch("getSiteStagingEnvironments", id);
      }
    },
    remove(context, { serviceId, addonId, data }) {
      return request.makeParse("removeSafeHarbor", { serviceId, addonId }, data);
    },
    removeSiteStagingEnvironmentUpdate({ commit }, id) {
      commit("REMOVE_SITE_STAGING_ENVIRONMENT_UPDATE", id);
    },
    removeStagingEnvironmentUpdate({ commit }, id) {
      commit("REMOVE_STAGING_ENVIRONMENT_UPDATE", id);
    },
    removeUpdate({ commit }, id) {
      commit("REMOVE_UPDATE", id);
    },
  },
  getters: {
    getAllowedStatus: (state) => state.allowedStatus,
    getBlacklist: (state) => state.blacklist?.blacklist || [],
    getBlacklistStatus: (state) => state.blacklistStatus,
    getMalwareScans: (state) => state.malwareScans,
    getMalwareScansStatus: (state) => state.malwareScansStatus,
    getPackage: (state) => state.package,
    getPackageStatus: (state) => state.packageStatus,
    getPatches: (state) => state.patches,
    getPatchesStatus: (state) => state.patchesStatus,
    getSiteBlacklist: (state) => state.siteBlacklist?.blacklist || [],
    getSiteBlacklistStatus: (state) => state.siteBlacklistStatus,
    getSiteMalwareScans: (state) => state.siteMalwareScans,
    getSiteMalwareScansStatus: (state) => state.siteMalwareScansStatus,
    getSitePatches: (state) => state.sitePatches,
    getSitePatchesStatus: (state) => state.sitePatchesStatus,
    getSiteStagingEnvironments: (state) => state.siteStagingEnvironments?.list || [],
    getSiteStagingEnvironmentsStatus: (state) => state.siteStagingEnvironmentsStatus,
    getStagingEnvironments: (state) => state.stagingEnvironments?.list || [],
    getStagingEnvironmentsStatus: (state) => state.stagingEnvironmentsStatus,
    isAllowed: (state) => state.allowed?.isAllowed,
    isSiteStagingEnvironmentUpdating: (state) => (id) =>
      state.siteStagingEnvironmentUpdates.findIndex((item) => item === Number(id)) >= 0,
    isStagingEnvironmentUpdating: (state) => (id) =>
      state.stagingEnvironmentUpdates.findIndex((item) => item === Number(id)) >= 0,
    isUpdating: (state) => (id) => state.updates.findIndex((item) => item === Number(id)) >= 0,
  },
};

export default store;
