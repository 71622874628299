import { models } from "leatherman-js";
import store from "@/store";

const handlers = (event, data) => {
  switch (event) {
    case "virt-guest-cloudaccount-stencil:add":
      // eslint-disable-next-line no-case-declarations
      const newStencil = models.Stencil.formatLegacy(data);
      if (newStencil.site.id === store.getters["sites/getItem"]?.id) {
        store.dispatch("stencils/refreshList", { siteId: newStencil.site.id });
      }
      store.dispatch("allStencils/refreshList");
      break;
    case "virt-guest-cloudaccount-stencil:update":
      // eslint-disable-next-line no-case-declarations
      const updatedStencil = models.Stencil.formatLegacy(data);
      if (updatedStencil.site.id === store.getters["sites/getItem"]?.id) {
        store.dispatch("stencils/refreshList", { siteId: updatedStencil.site.id });
      }
      store.dispatch("allStencils/refreshList");
      break;
    case "virt-guest-cloudaccount-stencil:delete":
      store.dispatch("stencils/refreshList");
      store.dispatch("allStencils/refreshList");
      break;
    case "api-task:update":
      switch (data.action) {
        case "stencil:add":
          store.dispatch("allStencils/setStencilStatus", data.status);
          if (data.status === "failure") {
            store.dispatch("toasts/addToast", {
              variant: "error",
              message: "Something went wrong trying to add your stencil.",
            });
          } else if (data.status === "rejected") {
            store.dispatch("toasts/addToast", {
              variant: "error",
              message:
                "Sorry, at this time, we're only able to create stencils for sites less than 4GB in size.",
            });
          }
          break;
      }
      break;
  }
};

export default handlers;
