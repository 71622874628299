import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_HOME_DIR_OPTIONS_KEY = "site-ftp-home-dir-options";
const CACHE_USERS_KEY = "site-ftp-users";

const store = {
  namespaced: true,
  state: {
    homeDirOptions: null,
    homeDirOptionsStatus: new Status(),
    users: null,
    usersStatus: new Status(),
  },
  mutations: {
    SET_HOME_DIR_OPTIONS(state, payload) {
      state.homeDirOptions = payload;
    },
    SET_HOME_DIR_OPTIONS_STATUS(state, status) {
      state.homeDirOptionsStatus.value = status;
    },
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_USERS_STATUS(state, status) {
      state.usersStatus.value = status;
    },
  },
  actions: {
    createUser(context, { id, data }) {
      return request.makeParse("createSiteFtpUser", { id }, data);
    },
    async getHomeDirOptions({ commit, state }, id) {
      const newStatus =
        (state.homeDirOptionsStatus.isLoaded || state.homeDirOptionsStatus.isUpdating) &&
        id === state.users?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_HOME_DIR_OPTIONS_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_HOME_DIR_OPTIONS_KEY, { id }),
          request.makeParse,
          ["getSiteFtpHomeDirOptions", { id }]
        );
        commit("SET_HOME_DIR_OPTIONS", response);
        commit("SET_HOME_DIR_OPTIONS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_HOME_DIR_OPTIONS_STATUS", Status.ERROR);
      }
    },
    async getUsers({ commit, state }, id) {
      const newStatus =
        (state.usersStatus.isLoaded || state.usersStatus.isUpdating) && id === state.users?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_USERS_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_USERS_KEY, { id }), request.makeParse, [
          "getSiteFtpUsers",
          { id },
        ]);
        commit("SET_USERS", response);
        commit("SET_USERS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_USERS_STATUS", Status.ERROR);
      }
    },
    refreshHomeDirOptions({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_HOME_DIR_OPTIONS_KEY, { id });
      clear(cacheKey);
      if (id === state.homeDirOptions.siteId) {
        dispatch("getHomeDirOptions", id);
      }
    },
    refreshUsers({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_USERS_KEY, { id });
      clear(cacheKey);
      if (id === state.users?.siteId) {
        dispatch("getUsers", id);
      }
    },
    removeUser(context, { id, username }) {
      return request.makeParse("removeSiteFtpUser", { id, username });
    },
    updateUser(context, { id, data }) {
      return request.makeParse("updateSiteFtpUser", { id }, data);
    },
    updateUserHomeDir(context, { id, data }) {
      return request.makeParse("updateSiteFtpUserHomeDir", { id }, data);
    },
    updateUserPassword(context, { id, data }) {
      return request.makeParse("updateSiteFtpUserPassword", { id }, data);
    },
  },
  getters: {
    getHomeDirOptions: (state) => state.homeDirOptions?.options,
    getHomeDirOptionsStatus: (state) => state.homeDirOptionsStatus,
    getUsers: (state) => state.users?.list,
    getUsersStatus: (state) => state.usersStatus,
  },
};

export default store;
