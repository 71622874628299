const useClickOutside = (component, callback) => {
  if (!component) return;
  return (event) => {
    if (event.target !== component && event.composedPath().includes(component)) {
      return;
    }
    if (typeof callback === "function") {
      callback();
    }
  };
};

export default useClickOutside;
