const Cancellation = [
  {
    path: ":type/:id",
    name: "request-cancellation",
    component: () =>
      import(
        /* webpackChunkName: "cancellation" */ "@/pages/Dashboard/Cancellation/CancellationDetail.vue"
      ),
    props: (route) => ({
      serviceId: Number.parseInt(route.params.id),
      serviceType: route.params.type,
    }),
  },
];

export default Cancellation;
