import ConfirmationModal from "@/components/display/ConfirmationModal";
import router from "@/router";
import store from "@/store";

let versionChange = false;

const openUpgradeModal = (version) => {
  versionChange = true;
  store.dispatch("modals/open", {
    template: ConfirmationModal,
    data: {
      confirm: () => {
        window.location.reload();
        return Promise.resolve();
      },
      message:
        "There's a new version of our portal! Click upgrade to get the latest. If you choose to wait, the portal will upgrade the next time your browser is refreshed.",
      title: `New Portal Version: ${version}`,
      confirmBtnText: "Upgrade",
    },
  });
};

export default () => {
  if (!versionChange) {
    fetch(`/version.json?date=${new Date().toISOString()}`)
      .then((response) => response.json())
      .then(({ version }) => {
        if (version !== process.env.VUE_APP_PACKAGE_VERSION) {
          const matchedRoutes = [...router.currentRoute.value.matched];
          const isCheckout = matchedRoutes.reverse().find((route) => !!route.meta.isCheckout);

          // don't show update modal in the checkout section of the site
          if (!isCheckout) {
            openUpgradeModal(version);
          }
        }
      })
      .catch(() => null);
  }
};
