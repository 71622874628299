import { createKey, get } from "@/utils/networkCache";
import { SET_ITEM, SET_STATUS } from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";
import { updateItem } from "@/utils/storeHelper";

const CACHE_ALLOCATION_KEY = "allocation";

const allocation = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_ITEM,
    SET_STATUS,
  },
  actions: {
    async get({ commit }, id) {
      commit("SET_STATUS", Status.LOADING);
      const response = await get(createKey(CACHE_ALLOCATION_KEY, { id }), request.makeParse, [
        "getAllocation",
        { id },
      ]);
      commit("SET_ITEM", response);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    async reboot(context, id) {
      return request.makeParse("rebootAllocation", { id });
    },
    async update({ commit, state }, payload) {
      if (state.item.id === payload.id) {
        commit("SET_ITEM", payload);
        updateItem(commit, state, payload);
      }
    },
  },
};

export default allocation;
