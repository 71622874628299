import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "datashare:add":
    case "datashare:delete":
    case "datashare:update":
      if (data.owner === "virt-guest-cloudaccount" && data.type === "ftp-password") {
        store.dispatch("ftp/refreshUsers", data.owner_id);
      }
      break;
  }
};

export default handler;
