import designServices from "@/store/modules/packages/addons/designServices";
import siteKeep from "@/store/modules/packages/addons/siteKeep";

const module = {
  namespaced: true,
  modules: {
    designServices,
    siteKeep,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
};

export default module;
