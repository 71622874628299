import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "api-task:update":
      if (data.action === "site:remove-ssl-cert" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: `The SSL Certificate for ${data?.resource?.identity} has been uninstalled.`,
        });
        store.dispatch("certificates/refreshInfo");
      }
      if (data.action === "site:add-addon") {
        if (store.getters["packages/addons/designServices/isAddon"](data.input?.addon_id)) {
          if (data.status === "processing") {
            store.dispatch("sites/designServices/setProcessing", data.input?.id);
          }
          if (data.status === "success") {
            store.dispatch("sites/refreshItem", data.input?.id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "Design Services was added to your site.",
            });
            store.dispatch("sites/designServices/removeProcessing", data.input?.id);
          }
          if (["failure", "rejected"].includes(data.status)) {
            store.dispatch("sites/refreshItem", data.input?.id);
            store.dispatch("toasts/addToast", {
              variant: "error",
              message: "Something went wrong trying to add Design Services to your site.",
            });
            store.dispatch("sites/designServices/removeProcessing", data.input?.id);
          }
        }
        if (store.getters["packages/addons/siteKeep/isAddon"](data.input?.addon_id)) {
          if (data.status === "processing") {
            store.dispatch("sites/siteKeep/setProcessing", data.input?.id);
          }
          if (data.status === "success") {
            store.dispatch("sites/refreshItem", data.input?.id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "SiteKeep was added to your site.",
            });
            store.dispatch("sites/siteKeep/removeProcessing", data.input?.id);
          }
          if (["failure", "rejected"].includes(data.status)) {
            store.dispatch("sites/refreshItem", data.input?.id);
            store.dispatch("toasts/addToast", {
              variant: "error",
              message: "Something went wrong trying to add SiteKeep to your site.",
            });
            store.dispatch("sites/siteKeep/removeProcessing", data.input?.id);
          }
        }
      }

      break;
  }
};

export default handler;
