import notActivated from "@/store/modules/sites/designServices/notActivated";
import { request } from "leatherman-js";

const module = {
  namespaced: true,
  modules: {
    notActivated,
  },
  state: {
    processing: [],
  },
  mutations: {
    REMOVE_SITE_PROCESSING: (state, siteId) => {
      if (!siteId) return;
      const index = state.processing.findIndex((item) => siteId === item);
      if (index >= 0) {
        const processing = [...state.processing];
        processing.splice(index);
        state.processing = processing;
      }
    },
    SET_SITE_PROCESSING: (state, siteId) => {
      if (!siteId) return;
      const index = state.processing.findIndex((item) => siteId === item);
      if (index < 0) state.processing = [...state.processing, siteId];
    },
  },
  actions: {
    add: (_, { siteId, addonId }) =>
      request.makeParse("addSiteDesignServices", { siteId, addonId }),
    remove: (_, siteId) => request.makeParse("removeSiteDesignServices", { siteId }),
    removeProcessing: ({ commit }, siteId) => commit("REMOVE_SITE_PROCESSING", siteId),
    setProcessing: ({ commit }, siteId) => commit("SET_SITE_PROCESSING", siteId),
  },
  getters: {
    isProcessing: (state) => (siteId) => Boolean(state.processing.find((item) => siteId === item)),
  },
};

export default module;
