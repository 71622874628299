import {
  cancelService,
  getCancellationFormOptions,
  restoreService,
} from "@/utils/serviceActionsHelper";
import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_CERT_KEY = "certificate";
const CACHE_PLURAL_KEY = "certificates";
const CACHE_CERT_INFO_KEY = "certInfo";

const store = {
  namespaced: true,
  state: {
    chunk: null,
    csrStatus: new Status(),
    item: null,
    itemStatus: new Status(),
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
    certInfo: null,
    certInfoStatus: new Status(),
    siteId: null,
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
    SET_ITEM_STATUS(state, payload) {
      state.itemStatus.value = payload;
    },
    SET_LIST_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_CERT_INFO(state, payload) {
      state.certInfo = payload;
    },
    SET_CERT_INFO_STATUS(state, payload) {
      state.certInfoStatus.value = payload;
    },
    SET_CSR_STATUS(state, status) {
      state.csrStatus.value = status;
    },
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async get({ commit }, id, params) {
      commit("SET_ITEM_STATUS", Status.LOADING);
      const response = await get(createKey(CACHE_CERT_KEY, { id }), request.makeParse, [
        "getSslCert",
        { id },
        params,
      ]);
      commit("SET_ITEM", response);
      commit("SET_ITEM_STATUS", Status.LOADED);
      return response;
    },
    async getCertInfo({ commit }, siteId) {
      commit("SET_CERT_INFO_STATUS", Status.LOADING);
      commit("SET_SITE_ID", siteId);
      try {
        const response = await get(createKey(CACHE_CERT_INFO_KEY, { siteId }), request.makeParse, [
          "getSiteSSLInfo",
          { siteId },
        ]);
        commit("SET_CERT_INFO", response);
        commit("SET_CERT_INFO_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_CERT_INFO_STATUS", Status.ERROR);
      }
    },
    async getList({ commit }, params) {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        const response = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
          "getSiteSslCerts",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    async delete(context, id) {
      return await request.makeParse("deleteSslCert", { id });
    },
    async decodeCsr({ commit }, params) {
      commit("SET_CSR_STATUS", Status.LOADING);
      return request.makeParse("decodeCsr", null, params).then((res) => {
        commit("SET_CSR_STATUS", Status.LOADED);
        return res;
      });
    },
    async addCert({ dispatch }, params) {
      const response = await request.makeParse("addCert", null, params);
      dispatch("refreshList");
      return response;
    },
    async getCsrDetails(context, params) {
      return request.makeParse("getCsrDetails", null, params);
    },
    async getCsrDetailsWithCert(context, id, data) {
      return request.makeParse("getCsrDetailsWithCert", { id }, data);
    },
    async order(context, params) {
      return request.makeParse("addCert", null, params);
    },
    installCertificate(context, { siteId, certificateId }) {
      return request.makeParse("installSiteSSLCert", { siteId }, { certificateId });
    },
    uploadCertificate(context, { siteId, key, certificate, chain }) {
      return request.makeParse("uploadSiteSSLCert", { siteId }, { key, certificate, chain });
    },
    refreshInfo({ dispatch, state }) {
      clear(createKey(CACHE_CERT_INFO_KEY, { siteId: state.siteId }));
      if (state.siteId) {
        dispatch("getCertInfo", state.siteId);
      }
    },
    refreshList({ dispatch, state }, pageIndex = undefined) {
      clearListCache(CACHE_PLURAL_KEY);
      dispatch("getList", {
        pageIndex: pageIndex !== undefined ? pageIndex : state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    getCancellationFormOptions,
    cancelService,
    restoreService,
  },
  getters: {
    getItem: (state) => state.item,
    getItemStatus: (state) => state.itemStatus,
    getList: (state) => state.list,
    getStatus: (state) => state.status,
    getCertInfo: (state) => {
      return state.certInfo;
    },
    getCertInfoStatus: (state) => {
      return state.certInfoStatus;
    },
  },
};

export default store;
