import { createKey, get } from "@/utils/networkCache";
import { constants } from "@/utils";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_KEY = "locations";

const locations = {
  namespaced: true,
  state: {
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
  },
  actions: {
    async getList({ commit, state }) {
      const newStatus =
        state.status.isLoaded || state.status.isUpdating ? Status.UPDATING : Status.LOADING;
      commit("SET_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_KEY), request.makeParse, ["getLocations"]);
        commit("SET_LIST", response);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    getList: (state) => {
      return state.list.sort((a, b) => {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      });
    },
    getAllowedList: (_, getters) => {
      const forbiddenIds = Object.keys(constants.LOCATION_OVERRIDES).map((id) => parseInt(id));
      return getters["getList"].filter(({ id }) => !forbiddenIds.includes(id));
    },
    getListByApp: (state) => (app) => {
      return state.list.filter(
        (location) => !constants.APP_LOCATION_TYPE_EXCLUSIONS[app.type]?.includes(location.type)
      );
    },
    getLocationById: (_, getters) => (locationId) => {
      return (
        getters["getAllowedList"].find(({ id }) => id === parseInt(locationId)) ||
        getters["getDefault"]
      );
    },
    getStatus: (state) => {
      return state.status;
    },
    getDefault: (_, getters) => {
      const list = getters["getAllowedList"];
      return (
        list.find(({ id }) => id === parseInt(constants.DEFAULT_LOCATION_ID)) ||
        list.find(({ code }) => code.includes("us-midwest")) ||
        list.find(({ code }) => code.includes("us")) ||
        list[0]
      );
    },
  },
};

export default locations;
