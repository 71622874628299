import store from "@/store";

const handler = (event, data) => {
  if (data?.status === "rejected") {
    try {
      switch (event) {
        case "api-task:update":
          if (data.identity.includes("cloud-account:change-php-version")) {
            store.dispatch("toasts/addToast", {
              variant: "warning",
              message: "We detected incompatibilities with changing your PHP version!",
            });
            store.dispatch("php/removeVersionUpdate", data.resource_id);
          }
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          let message = JSON.parse(data?.errors_json)?.errors[0]?.message;
          if (data.identity === "client:set-tax-exempt (rejected)") {
            message = "Invalid Tax Exemption ID/VAT/GST. Your Tax Exemption could not updated.";
          }
          store.dispatch("toasts/addToast", {
            variant: "error",
            message: message,
          });
      }
    } catch (e) {
      // ignore
    }
  }
};

export default handler;
