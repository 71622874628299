import RendezvousJS from "rendezvous-js";

const disableLog = () => {
  try {
    return JSON.parse(process.env.VUE_APP_RENDEZVOUS_DISABLE_LOGS);
  } catch (err) {
    console.log("You should define VUE_APP_RENDEZVOUS_DISABLE_LOGS", err.message);
    return true;
  }
};

const rendezvous = RendezvousJS({ company: "nx", disableLog: disableLog() });

const redactQueryParams = (fullPath, paramKeys) => {
  if (!Array.isArray(paramKeys)) {
    return fullPath;
  }
  if (paramKeys.length === 0) {
    return fullPath;
  }

  const [path, query] = fullPath.split("?");
  if (!query) {
    return fullPath;
  }
  const params = query.split("&").reduce((params, param) => {
    const [key, value] = param.split("=");
    return { ...params, [key]: value };
  }, {});
  paramKeys.forEach((item) => {
    if (params[item]) {
      params[item] = "REDACTED";
    }
  });
  const filteredQuery = Object.entries(params).reduce((params, param, index, values) => {
    const [key, value] = param;
    return params.concat(`${key}=${value}${index < values.length - 1 ? "&" : ""}`);
  }, "?");

  return path.concat(filteredQuery);
};

const redactPathParams = (fullPath, paramKeys, pathParams) => {
  if (!Array.isArray(paramKeys)) {
    return fullPath;
  }
  if (paramKeys.length === 0) {
    return fullPath;
  }

  return paramKeys.reduce((path, param) => path.replace(pathParams[param], "REDACTED"), fullPath);
};

const redactPathValues = (data) => {
  let path = redactQueryParams(data.fullPath, data.meta.redactedQueryParams);
  path = redactPathParams(path, data.meta.redactedPathParams, data.params);
  return path;
};

// list of actions that can be taken in the application
const definitions = {
  structured: ({ category, action, label, value }, mergeData) => {
    rendezvous.event("structured", category, action, label, value, null, mergeData);
  },
  login: () => {
    rendezvous.dataLayer({
      event: "userLogin",
      userType: "customer",
    });
  },
  loginAsAdmin: () => {
    rendezvous.dataLayer({
      event: "userLogin",
      userType: "admin",
    });
  },
  navigation: (data) => {
    // pageview();
    rendezvous.event("pageviewVirtual", redactPathValues(data), data.meta.title);
  },
  triggerSampleAlert: () => {
    rendezvous.dataLayer({
      event: "triggerSampleAlert",
    });
  },
  addToCart: (cartData) => {
    rendezvous.event("addToCartUA", cartData); // UA Event - Can be deleted once UA is deprecated
    rendezvous.event("clearEcommerce");
    rendezvous.event("addToCart", cartData);
  },
  checkoutStarted: (cartData) => {
    rendezvous.event("checkout", cartData, rendezvous.constants.NEXCESS.CHECKOUT_STEPS.STARTED); // UA Event - Can be deleted once UA is deprecated
    rendezvous.event("clearEcommerce");
    rendezvous.event("beginCheckout", cartData);
  },
  checkoutReviewOrder: (cartData) => {
    // UA Event - Can be deleted once UA is deprecated
    // Code currently runs this when a user changes the plan's nickname
    // There is no comparable GA4 event for this
    // Unclear on the use of this anyway
    rendezvous.event(
      "checkout",
      cartData,
      rendezvous.constants.NEXCESS.CHECKOUT_STEPS.PLAN_DETAILS
    );
  },
  checkoutBillingInfo: (cartData) => {
    // UA Event - Can be deleted once UA is deprecated
    rendezvous.event(
      "checkout",
      cartData,
      rendezvous.constants.NEXCESS.CHECKOUT_STEPS.BILLING_INFO
    );
    rendezvous.event("clearEcommerce");
    rendezvous.event("addPaymentInfo", cartData);
  },
  checkoutPurchaseInitiated: (cartData) => {
    // UA Event - Can be deleted once UA is deprecated
    // Code currently run when user clicks on Purchase button
    // There is no comparable GA4 event for this
    // Unclear on the use of this anyway since it mimics the purchase event
    rendezvous.event(
      "checkout",
      cartData,
      rendezvous.constants.NEXCESS.CHECKOUT_STEPS.PURCHASE_INITIATED
    );
  },
  checkoutPurchaseCompleted: ({ orderData, cartData, userData }) => {
    rendezvous.event("purchaseCompleted", orderData, cartData, userData); // UA Event - Can be deleted once UA is deprecated
    rendezvous.event("clearEcommerce");
    rendezvous.event("purchase", orderData, cartData, userData);
  },
  setUserCookie: (data = {}) => {
    rendezvous.cookie("setUser", data);
  },
  getUserCookie: () => {
    return rendezvous.cookie("getUser");
  },
  reportVitals: () => {
    rendezvous.vitals.report();
  },
  reportAPIResponseTiming: (data) => {
    rendezvous.event("timing", data);
  },
  performanceMarker: ({ event }) => {
    performance.mark(event);
    rendezvous.event("timing", {
      category: "Performance Marker",
      value: performance.now(), // now() returns time since performance.timeOrigin
      variable: event,
    });
  },
  nxCustomer: (data) => {
    rendezvous.dataLayer({ nxCustomer: data });
  },
  wpQuickStart: ({ label }) => {
    const data = {
      category: "wpQuickStartSurvey",
      action: "surveyStep_completed",
    };
    if (label) {
      data.label = label;
    }
    rendezvous.dataLayer(data);
  },
  experimentVariant: ({ experiment, variant }) => {
    rendezvous.event("experimentVariant", experiment, variant);
  },
  dataLayerPush: (data) => {
    rendezvous.dataLayer(data);
  },
  customerLongevity: (longevity) => {
    rendezvous.event("customerLongevity", longevity);
  },
  cloudflareIneligibleNotice: (domainName) => {
    rendezvous.event("cloudflareIneligibleNotice", domainName);
  },
};

const analytics = {
  namespaced: true,
  state: {
    rendezvous,
  },
  mutations: {},
  actions: {
    send(context, payload) {
      try {
        const definition = definitions[payload.name];

        if (definition) {
          return definition(payload.data, payload.mergeData);
        }
      } catch (e) {
        console.log({ e });
      }
    },
  },
  getters: {},
};

export default analytics;
