const overlay = {
  namespaced: true,
  state: {
    message: null,
  },
  mutations: {
    ENABLE(state, payload) {
      state.message = payload;
    },
    DISABLE(state) {
      state.message = null;
    },
  },
  actions: {
    enableOverlay({ commit }, payload) {
      commit("ENABLE", payload);
    },
    disableOverlay({ commit }) {
      commit("DISABLE");
    },
  },
  getters: {
    getMessage: (state) => state.message,
  },
};

export default overlay;
