import { clearListCache, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "stencils";

const stencils = {
  namespaced: true,
  state: {
    status: new Status(),
    list: [],
    sort: null,
    planId: null,
    stencilStatus: "",
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_PLAN_ID(state, payload) {
      state.planId = payload;
    },
    SET_STENCIL_STATUS(state, payload) {
      state.stencilStatus = payload;
    },
  },
  actions: {
    async getList({ commit, state }, { planId }) {
      const newStatus =
        (state.status.isLoaded || state.status.isUpdating) && planId === state.planId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_STATUS", newStatus);
      commit("SET_PLAN_ID", planId);

      try {
        const stencils = await get(createKey(CACHE_PLURAL_KEY, { planId }), request.makeParse, [
          "getStencilsByPlanId",
          { planId },
        ]);

        commit("SET_LIST", stencils);
        commit("SET_STATUS", Status.LOADED);
        return stencils;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    setStencilStatus({ commit }, status) {
      commit("SET_STENCIL_STATUS", status);
    },
    create(context, { siteId, name }) {
      return request.makeParse("createStencil", { siteId }, { name });
    },
    delete(context, { stencilId, siteId }) {
      return request.makeParse("deleteStencil", { siteId, stencilId });
    },
    async refreshList({ dispatch, state }) {
      const planId = state.planId;
      clearListCache(createKey(CACHE_PLURAL_KEY, { planId }));
      if (planId) {
        dispatch("getList", { planId });
      }
    },
  },
  getters: {
    get: (state) => (id) => state.list.find((item) => item.id === id),
    getList: (state) => state.list,
    getListStatus: (state) => state.status,
    getStencilStatus: (state) => state.stencilStatus,
  },
};

export default stencils;
