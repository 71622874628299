import { clearListCache, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "stencils";

const stencils = {
  namespaced: true,
  state: {
    status: new Status(),
    list: [],
    sort: null,
    siteId: null,
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async getList({ commit, state }, { siteId }) {
      const newStatus =
        (state.status.isLoaded || state.status.isUpdating) && siteId === state.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_STATUS", newStatus);
      commit("SET_SITE_ID", siteId);
      try {
        const stencils = await get(createKey(CACHE_PLURAL_KEY, { siteId }), request.makeParse, [
          "getStencils",
          { siteId },
        ]);

        commit("SET_LIST", stencils);
        commit("SET_STATUS", Status.LOADED);
        return stencils;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    create(context, { siteId, name }) {
      return request.makeParse("createStencil", { siteId }, { siteId, name });
    },
    delete(context, { stencilId, siteId }) {
      return request.makeParse("deleteStencil", { siteId, stencilId });
    },
    refreshList({ dispatch, state }, params) {
      params = { ...params, siteId: state.siteId };
      clearListCache(createKey(CACHE_PLURAL_KEY, params));
      if (state.siteId) {
        dispatch("getList", params);
      }
    },
  },
  getters: {
    get: (state) => (id) => state.list.find((item) => item.id === id),
    getList: (state) => state.list,
    getListStatus: (state) => state.status,
  },
};

export default stencils;
