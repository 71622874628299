<template>
  <div>
    <Modal close-name="Cancel" :title="title" :hide-footer="true" @close="cancel">
      <template #body>
        <p style="white-space: pre-wrap">{{ message }}</p>
        <ul class="tw-flex tw-flex-col tw-gap-2 tw-p-0 tw-mb-6" v-if="messageList.length > 0">
          <li v-for="message in messageList" :key="message">{{ message }}</li>
        </ul>
        <div class="modal-footer">
          <Btn
            class="me-2"
            variant="secondary"
            type="button"
            :disabled="loading"
            @click="cancel"
            :testId="`${
              title ? `${title.toLowerCase().replaceAll(' ', '-')}-` : 'confirmation-'
            }modal-cancel-btn`"
          >
            Cancel
          </Btn>
          <Btn
            :testId="`${
              title ? `${title.toLowerCase().replaceAll(' ', '-')}-` : 'confirmation-'
            }modal-confirm-btn`"
            :variant="variant"
            type="button"
            :disabled="loading"
            :loading="loading"
            @click="confirmWrapper"
          >
            {{ confirmBtnText }}
          </Btn>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Btn from "@/components/display/Btn";
import Modal from "@/components/display/Modal";
import { ref } from "vue";
import store from "@/store";

export default {
  name: "ConfirmationModal",
  props: {
    testId: String,
    confirm: {
      type: Function,
      required: true,
    },
    confirmBtnText: {
      type: String,
      default: "Confirm",
    },
    errorMessage: {
      type: String,
      default: "There was an issue completing your request.",
    },
    message: String,
    messageList: {
      type: Array,
      default: () => [],
    },
    onSuccess: {
      type: Function,
      default: () => () => null,
    },
    title: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
  components: {
    Btn,
    Modal,
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const cancel = () => {
      emit("close");
    };
    const confirmWrapper = () => {
      loading.value = true;
      props
        .confirm()
        .then(() => {
          props.onSuccess();
          emit("close");
        })
        .catch(() => {
          store.dispatch("toasts/addToast", {
            variant: "error",
            message: props.errorMessage,
            timeout: true,
          });
          emit("close");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      cancel,
      confirmWrapper,
      loading,
    };
  },
};
</script>
