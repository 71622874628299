import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import { get as lsGet, set as lsSet } from "local-storage";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "orders";
const CACHE_SINGULAR_KEY = "order";
const DISMISSED_FAILED_PLANS_KEY = "dismissedFailedPlans";

const orders = {
  namespaced: true,
  state: {
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
    dismissedFailedPlans: lsGet(DISMISSED_FAILED_PLANS_KEY) || [],
  },
  mutations: {
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_SORT(state, payload) {
      const sort = {};
      if (payload?.sortProperty) sort.property = payload?.sortProperty;
      if (payload?.sortDirection) sort.direction = payload?.sortDirection;
      state.sort = payload?.sortProperty || payload?.sortDirection ? sort : null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_DISMISSED_FAILED_PLANS(state, payload) {
      const allIds = [...new Set([...state.dismissedFailedPlans, ...payload])];
      lsSet(DISMISSED_FAILED_PLANS_KEY, allIds);
      state.dismissedFailedPlans = allIds;
    },
  },
  actions: {
    async get({ commit }, id) {
      const response = await get(createKey(CACHE_SINGULAR_KEY, { id }), request.makeParse, [
        "getOrder",
        { id },
      ]);
      commit("SET_ITEM", response);
      return response;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_SORT", params);
      const response = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
        "getOrders",
        params,
      ]);
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    refreshList({ dispatch, state }) {
      clearListCache(CACHE_PLURAL_KEY);
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    clearCache() {
      clearListCache(CACHE_PLURAL_KEY);
    },
    refreshItem({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_SINGULAR_KEY, { id });
      clear(cacheKey);
      if (id === state.item?.id) {
        dispatch("get", id);
      }
    },
    dismissFailedPlan({ commit, getters }) {
      commit("SET_DISMISSED_FAILED_PLANS", getters.getFraudOrderIds);
    },
  },
  getters: {
    getFraudOrderIds: (state, getters) => getters.getFraudOrders.map((o) => o.id),
    getFraudOrders: (state) => {
      return state.list.filter(
        (order) =>
          ["wait_ccid", "wait_payment", "blocked"].includes(order.status) &&
          order.serviceId === 0 &&
          order.type === "virt-guest-cloud"
      );
    },
    getList: (state) => state.list || [],
    getPendingOrders: (state) => {
      return state.list.filter(
        (order) =>
          ["pending"].includes(order.status) &&
          order.serviceId === 0 &&
          order.type === "virt-guest-cloud"
      );
    },
    getStatus: (state) => {
      return state.status;
    },
    hasNonDismissedFailedPlans: (state, getters) => {
      return getters.getFraudOrderIds.some((r) => state.dismissedFailedPlans.indexOf(r) === -1);
    },
  },
};

export default orders;
