import { constants } from "@/utils";

const ROUTES = constants.ROUTES;

const settings = [
  {
    path: "address",
    name: "address",
    redirect: () => ({
      name: "tax",
    }),
  },
  {
    path: "account-settings",
    name: ROUTES.ACCOUNT_SETTINGS,
    meta: {
      breadcrumb: "ACCOUNT_SETTINGS",
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/pages/Dashboard/Settings/AccountSettings.vue"),
  },
  {
    path: "api-tokens",
    name: "api-tokens",
    meta: {
      breadcrumb: "API_TOKENS",
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/pages/Dashboard/Settings/APITokens.vue"),
  },
  {
    path: "ssh-keys",
    name: "ssh-keys",
    meta: {
      breadcrumb: "SSH_KEYS",
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/pages/Dashboard/Settings/SSHKeys.vue"),
  },
  {
    path: "team",
    name: "team",
    meta: {
      breadcrumb: "TEAM_MEMBERS",
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/pages/Dashboard/Settings/Team.vue"),
  },
  {
    path: "permissions",
    name: "permissions",
    meta: {
      breadcrumb: "TEAM_PERMISSIONS",
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/pages/Dashboard/Settings/Permissions.vue"),
  },
  {
    path: "refer-a-friend",
    name: ROUTES.REFER_A_FRIEND,
    meta: {
      breadcrumb: "REFER_A_FRIEND",
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/pages/Dashboard/Settings/ReferAFriend.vue"),
  },
];

export default settings;
