import * as FullStory from "@fullstory/browser";
import Cookies from "js-cookie";

const COOKIE_NAME = "nxFullStoryRecording";
const COOKIE_EXPIRATION_IN_MINUTES = 30;
const COOKIE_SETTER_INTERVAL_IN_MINUTES = 5;

const RECORDING_STATE = {
  NONE: -1,
  NOT_RECORDING: 0,
  RECORDING: 1,
};

const randomizer = (ratio) => Math.random() <= ratio;

const getCookie = () => Cookies.get(COOKIE_NAME);

const setCookie = (value) =>
  Cookies.set(COOKIE_NAME, value, {
    expires: new Date(new Date().getTime() + COOKIE_EXPIRATION_IN_MINUTES * 60 * 1000),
  });

const extendCookieExpiration = () => {
  const cookie = getCookie();
  if (cookie !== undefined) {
    setCookie(cookie);
  }
};

const getPreviousRecordingState = () => {
  switch (getCookie()) {
    case "true":
      return RECORDING_STATE.RECORDING;
    case "false":
      return RECORDING_STATE.NOT_RECORDING;
    default:
      return RECORDING_STATE.NONE;
  }
};

const shouldInitialize = (previousState, activationRatio) => {
  switch (previousState) {
    case RECORDING_STATE.RECORDING:
      return true;
    case RECORDING_STATE.NOT_RECORDING:
      return false;
    default:
      return randomizer(activationRatio);
  }
};

export const init = () => {
  if (process.env.VUE_APP_FULLSTORY_ID && !FullStory.isInitialized()) {
    FullStory.init({
      orgId: process.env.VUE_APP_FULLSTORY_ID,
      debug: !!process.env.VUE_APP_FULLSTORY_DEBUG,
    });
    setCookie(true);
  }
};

export const identify = (user, client = {}) => {
  try {
    if (process.env.VUE_APP_FULLSTORY_ID && FullStory.isInitialized()) {
      const payload = {
        email: user?.email,
        clientId: client?.id,
        clientName: client?.name,
        cloudServerCount: client?.cloudServerCount,
        colocationCount: client?.colocationCount,
        dedicatedServerCount: client?.dedicatedServerCount,
        isPartner: client?.isPartner,
        planCount: client?.planCount,
        resellerHostingCount: client?.resellerHostingCount,
        sharedHostingCount: client?.sharedHostingCount,
      };
      FullStory.identify(user?.id, payload);
    }
  } catch (e) {
    console.log("FullStory error", e);
  }
};

export default {
  install: (app, { activationRatio }) => {
    if (shouldInitialize(getPreviousRecordingState(), activationRatio)) {
      init();
    } else {
      setCookie(false);
    }
    setInterval(extendCookieExpiration, COOKIE_SETTER_INTERVAL_IN_MINUTES * 60 * 1000);
  },
};
