import { request } from "leatherman-js";
import { SET_STATUS } from "@/store/commonMutations";
import Status from "@/classes/Status";

const cdnControlPanel = {
  namespaced: true,
  state: {
    status: new Status(),
  },
  mutations: {
    SET_STATUS,
  },
  actions: {
    async getCdnControlPanel({ commit }, id) {
      commit("SET_STATUS", Status.LOADING);
      return request
        .makeParse("getCdnControlPanel", { id })
        .finally(() => commit("SET_STATUS", Status.LOADED));
    },
  },
};

export default cdnControlPanel;
