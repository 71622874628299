const EnterpriseCloud = [
  {
    path: "",
    name: "enterprise-cloud",
    meta: {
      breadcrumb: "ENTERPRISE_CLOUD",
    },
    component: () =>
      import(
        /* webpackChunkName: "enterprise-cloud" */ "@/pages/Dashboard/EnterpriseCloud/EnterpriseCloudList.vue"
      ),
  },
  {
    path: ":id",
    name: "enterprise-cloud-detail",
    meta: {
      breadcrumb: "ENTERPRISE_CLOUD_DETAIL",
    },
    component: () =>
      import(
        /* webpackChunkName: "enterprise-cloud" */ "@/pages/Dashboard/EnterpriseCloud/EnterpriseCloud.vue"
      ),
    props: (route) => ({
      enterpriseCloudId: route.params.id,
    }),
  },
];

export default EnterpriseCloud;
