import store from "@/store";

const handler = (event) => {
  switch (event) {
    case "service-cancellation:add":
    case "service-cancellation:delete":
      store.dispatch("sharedHosts/refreshLastItem");
      break;
  }
};

export default handler;
