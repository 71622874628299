import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_LIST, SET_STATUS } from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_UPGRADE_PLURAL_KEY = "siteUpgradeGroups";
// const CACHE_UPGRADE_KEY = "siteUpgrade";
// const CACHE_UPGRADE_REGRESSIONS_KEY = "siteUpgradeRegressions";

const store = {
  namespaced: true,
  state: {
    list: [],
    status: new Status(),
  },
  mutations: {
    SET_LIST,
    SET_STATUS,
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async getUpgradeGroupList({ commit }, siteId) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_SITE_ID", siteId);
      try {
        const upgradeGroups = await get(
          createKey(CACHE_UPGRADE_PLURAL_KEY, { siteId }),
          request.makeParse,
          ["getSiteVisCompUpgradeGroups", { siteId }]
        );

        commit("SET_LIST", upgradeGroups);
        commit("SET_STATUS", Status.LOADED);
        return upgradeGroups;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    async getUpgrade({ commit }, { siteId, upgradeGroupId }) {
      commit("SET_STATUS", Status.LOADING);
      const upgrades = await request.makeParse("getSiteVisCompUpgrades", {
        siteId,
        upgradeGroupId,
      });
      commit("SET_STATUS", Status.LOADED);

      return [...upgrades];
    },
    getUpgradeRegressions(context, { siteId, upgradeGroupId, upgradeId }) {
      return request.makeParse("getSiteVisCompUpgradeRegressions", {
        siteId,
        upgradeGroupId,
        upgradeId,
      });
    },
    refreshList({ state, dispatch }, siteId) {
      if (state.siteId === siteId) {
        clearListCache(createKey(CACHE_UPGRADE_PLURAL_KEY, { siteId }));
        dispatch("getUpgradeGroupList", siteId);
      }
    },
  },
  getters: {
    getList: (state) => state.list,
    getStatus: (state) => state.status,
  },
};

export default store;
