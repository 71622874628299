import store from "@/store";

const handlers = (event, data) => {
  switch (event) {
    case "email-box:change":
      store.dispatch("emailBoxes/refreshList");
      break;
    case "api-task:update":
      if (data.action === "cloud-account:add-email-box" && data.status === "success") {
        store.dispatch("emailBoxes/refreshList");
      }
      if (data.resource_id === store.getters["emailBoxes/getSiteId"]) {
        if (
          data.action === "local-email-delivery:local-email-delivery-enable" &&
          data.identity === "local-email-delivery:local-email-delivery-enable (success)"
        ) {
          // local mail delivery was just enabled
          store.dispatch("emailBoxes/setLocalMailDelivery", true);
        } else if (
          data.action === "local-email-delivery:local-email-delivery-disable" &&
          data.identity === "local-email-delivery:local-email-delivery-disable (success)"
        ) {
          // local mail delivery was just disabled
          store.dispatch("emailBoxes/setLocalMailDelivery", false);
        }
      }
      if (data.action === "site:create-autoresponder" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Your email responder has been successfully updated.",
        });
        store.dispatch("emailResponders/refreshList");
      }
      if (data.action === "site:autoresponder-delete" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Your email responder has been successfully deleted.",
        });
        store.dispatch("emailResponders/refreshList");
      }
  }
};

export default handlers;
