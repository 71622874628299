<template>
  <div class="toast-container" aria-live="polite" aria-atomic="true">
    <Toast v-for="toast in toasts" :key="toast.id" :toast="toast" />
  </div>
</template>

<script>
import Toast from "@/components/smart/Toast";
import { useToasts } from "@/utils/index";

export default {
  name: "ToastService",
  components: {
    Toast,
  },
  setup() {
    const { toasts } = useToasts();

    return {
      toasts,
    };
  },
};
</script>

<style lang="scss" scoped>
.toast-container {
  position: fixed;
  height: 0;
  overflow: visible;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 1060;
}
</style>
