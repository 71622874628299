<template>
  <div
    ref="el"
    :id="idCalc"
    class="tw-inline-block"
    data-bs-toggle="tooltip"
    :data-bs-placement="positionType"
    :title="content"
    :style="{ 'max-width': maxWidth }"
    :data-bs-original-title="content"
    :aria-label="content"
    :data-test="testId"
  >
    <slot />
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { Tooltip } from "bootstrap";
import uniqueId from "lodash-es/uniqueId";

export default {
  name: "Tooltip",
  components: {},
  props: {
    content: {
      type: String,
      default: null,
    },
    position: {
      type: String,
      default: "right",
    },
    maxWidth: {
      type: String,
      default: "none",
    },
    id: String,
    testId: String,
  },
  setup(props) {
    const el = ref(null);
    const tooltip = ref(null);

    const POSITION_TYPES = {
      left: "left",
      right: "right",
      top: "top",
      bottom: "bottom",
    };

    const idCalc = computed(() => props.id || uniqueId("dropdown-"));
    const positionType = computed(() => POSITION_TYPES[props.position]);

    onMounted(() => {
      tooltip.value = new Tooltip(el.value);
    });

    onUnmounted(() => {
      tooltip.value.hide();
    });

    return {
      el,
      idCalc,
      positionType,
    };
  },
};
</script>
