<template>
  <div class="d-flex flex-column align-items-center" :class="classes" :data-test="testId">
    <div class="center-middle">
      <font-awesome-icon
        :icon="icon"
        :spin="spin"
        :style="{
          'font-size': `${size}px`,
          '--fa-primary-color': 'var(--bs-highlightIcon)',
          '--fa-secondary-color': 'var(--bs-highlightBackgroundWeak)',
          '--fa-secondary-opacity': 1,
        }"
        :class="iconClass"
      />
    </div>
    <div v-if="message" class="loading-state-message mt-3 font-italic">{{ message }}</div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";

export default {
  name: "LoadingState",
  props: {
    size: {
      type: Number,
      default: 30,
    },
    messages: {
      type: Array,
      validator: (items) => {
        let valid = true;
        items.forEach((item) => {
          if (!(typeof item === "object" && item !== null)) valid = false;
        });
        return valid;
      },
    },
    classes: {
      type: [Object, String],
      default: "h-100",
    },
    icon: {
      type: String,
      default: "fa-duotone fa-spinner-third",
    },
    iconClass: {
      type: String,
    },
    spin: {
      type: Boolean,
      default: true,
    },
    testId: String,
  },
  setup(props) {
    const message = ref(null);

    onMounted(() => {
      props.messages?.forEach((item) =>
        setTimeout(() => (message.value = item.message), item.delay)
      );
    });

    return {
      message,
    };
  },
};
</script>
