import store from "@/store";

const handlers = (event, data) => {
  const siteId = store.getters["letsencrypt/getSiteId"];
  if (siteId !== data.resource_id) {
    return;
  }

  switch (event) {
    case "api-task:update":
      if (data.identity.includes("disable-lets-encrypt (success)")) {
        store.dispatch("letsencrypt/setLetsEncryptEnabled", false);
      } else if (data.identity.includes("disable-lets-encrypt (failure)")) {
        store.dispatch("letsencrypt/setLetsEncryptEnabled", false);
      } else if (data.identity.includes("enable-lets-encrypt (success)")) {
        store.dispatch("letsencrypt/setLetsEncryptEnabled", true);
      } else if (data.identity === "cloud-account:enable-lets-encrypt (failed)") {
        store.dispatch("letsencrypt/setLetsEncryptEnabled", true);
      } else if (
        data.identity === "cloud-account:issue-lets-encrypt (processing)" ||
        data.identity === "cloud-account:issue-lets-encrypt (success)"
      ) {
        store.dispatch("letsencrypt/refreshDomains");
        store.dispatch("certificates/refreshInfo");
      }
      break;
  }
};

export default handlers;
