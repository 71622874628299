import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";

const CACHE_DATASHARE_KEY = "site-datashare";

const store = {
  namespaced: true,
  actions: {
    async getDataShare(context, uuid) {
      return await get(createKey(CACHE_DATASHARE_KEY, { uuid }), request.makeParse, [
        "getDataShare",
        { uuid },
      ]);
    },
    clearDataShareCache(context, uuid) {
      clear(createKey(CACHE_DATASHARE_KEY, { uuid }));
    },
  },
};

export default store;
