const DesignServices = [
  {
    path: "",
    name: "design-services",
    component: () =>
      import(
        /* webpackChunkName: "design-services" */ "@/pages/Dashboard/DesignServices/DesignServices.vue"
      ),
  },
];

export default DesignServices;
