import { actions, getters, mutations, state } from "@/store/siteStore";
import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import { getAllPaginationParams, getPaginationParams, getSearchParams } from "@/utils/storeHelper";
import cloudflare from "@/store/modules/sites/cloudflare";
import designServices from "@/store/modules/sites/designServices";
import domains from "@/store/modules/sites/domains";
import { request } from "leatherman-js";
import siteKeep from "@/store/modules/sites/siteKeep";
import sslCerts from "@/store/modules/sites/sslCerts";
import Status from "@/classes/Status";
import vanityDomains from "@/store/modules/sites/vanityDomains";

const CACHE_ALL_KEY = "all-sites";
const CACHE_PLURAL_KEY = "sites";
const CACHE_PLURAL_SEARCH_KEY = "sites-search";
const CACHE_SINGULAR_KEY = "site";
const CACHE_WIDGET_KEY = "widget-site";

const store = {
  namespaced: true,
  modules: {
    cloudflare,
    designServices,
    domains,
    siteKeep,
    sslCerts,
    vanityDomains,
  },
  state,
  mutations: {
    ...mutations,
    SET_SEARCH: (state, payload) => {
      const value = payload?.searchValue;
      const type = payload?.searchType;
      state.search = value || type ? { value, type } : null;
    },
    SET_SEARCH_STATUS(state, payload) {
      state.searchStatus.value = payload;
    },
  },
  actions: {
    ...actions,
    create(context, { domain, installApp, name, planId }) {
      return request.makeParse("createSite", {}, { domain, installApp, name, planId });
    },
    createFromStencil(context, { planId, stencilId, name }) {
      return request.makeParse("createSiteFromStencil", {}, { planId, stencilId, name });
    },
    createSecondary(context, { id, data }) {
      return request.makeParse("createSecondarySite", { id }, data);
    },
    async get({ commit, state }, id) {
      const newStatus =
        (state.itemStatus.isLoaded || state.itemStatus.isUpdating) && id === state.item?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_ITEM_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_SINGULAR_KEY, { id }), request.makeParse, [
          "getSite",
          { id },
        ]);
        if (id === state.itemId) {
          commit("SET_ITEM", response);
          commit("SET_ITEM_STATUS", Status.LOADED);
        }
        return response;
      } catch (e) {
        commit("SET_ITEM_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    async getAll({ commit, state }, params) {
      const newStatus =
        state.status.isLoaded || state.status.isUpdating ? Status.UPDATING : Status.LOADING;
      commit("SET_ALL_STATUS", newStatus);
      commit("SET_ALL_CHUNK", params);
      commit("SET_ALL_SORT", params);
      commit("SET_ALL_PARAMS", params);

      params = { ...getAllPaginationParams(state), ...params };
      try {
        const response = await get(createKey(CACHE_ALL_KEY, params), request.makeParse, [
          "getSites",
          params,
        ]);
        commit("SET_ALL_LIST", response.list);
        commit("SET_ALL_PAGINATION", response.pagination);
        commit("SET_ALL_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ALL_STATUS", Status.ERROR);
      }
    },
    async getList({ commit, state }, params) {
      const newStatus =
        (state.status.isLoaded || state.status.isUpdating) && params?.planId === state.list?.planId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_STATUS", newStatus);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      commit("SET_SEARCH", params);

      params = { ...getPaginationParams(state), ...params };
      try {
        const response = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
          "getSitesByPlanId",
          params,
        ]);
        if (params?.planId === state.planId) {
          commit("SET_LIST", { list: response.list, planId: response.planId });
          commit("SET_PAGINATION", response.pagination);
          commit("SET_STATUS", Status.LOADED);
        }
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    async getListSearch({ commit, state }, params) {
      const newStatus =
        (state.status.isLoaded || state.status.isUpdating) && params?.planId === state.list?.planId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_STATUS", newStatus);
      commit("SET_SEARCH_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      commit("SET_SEARCH", params);

      params = { ...getPaginationParams(state), ...getSearchParams(state), ...params };
      try {
        const response = await get(createKey(CACHE_PLURAL_SEARCH_KEY, params), request.makeParse, [
          "searchSitesByPlanId",
          params,
        ]);
        if (params?.planId === state.planId) {
          commit("SET_LIST", { list: response.list, planId: response.planId });
          commit("SET_PAGINATION", response.pagination);
          commit("SET_STATUS", Status.LOADED);
        }
        commit("SET_SEARCH_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
        commit("SET_SEARCH_STATUS", Status.ERROR);
      }
    },
    async getWidgetItem({ commit, state }, id) {
      const newStatus =
        (state.itemStatus.isLoaded || state.itemStatus.isUpdating) && id === state.widget?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_WIDGET_ITEM_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_WIDGET_KEY, { id }), request.makeParse, [
          "getSite",
          { id },
        ]);
        commit("SET_WIDGET_ITEM", response);
        commit("SET_WIDGET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_WIDGET_ITEM_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    async updateName(context, { id, data }) {
      return request.makeParse("updateSiteName", { id }, data);
    },
    async delete({ dispatch }, { id, planId }) {
      const response = await request.makeParse("deleteSite", { id });
      dispatch("refreshList", { planId });
      return response;
    },
    refreshItem({ dispatch, state }, id) {
      let cacheKey = createKey(CACHE_SINGULAR_KEY, { id });
      clear(cacheKey);
      if (id === state.item?.id && id === state.itemId) {
        dispatch("get", id);
      }
      cacheKey = createKey(CACHE_WIDGET_KEY, { id });
      clear(cacheKey);
      if (id === state.widget?.id) {
        dispatch("getWidgetItem", id);
      }
    },
    refreshAll({ dispatch, state }) {
      clearListCache(CACHE_ALL_KEY);
      dispatch("getAll", state.allParams);
    },
    refreshAllBySiteId({ dispatch, state }, siteId) {
      const site = state.allList?.find((item) => item.id === siteId);
      if (site) {
        clearListCache(CACHE_ALL_KEY);
        return dispatch("getList", getAllPaginationParams(state));
      }
    },
    refreshList({ dispatch, state }, { planId }) {
      clearListCache(CACHE_PLURAL_KEY, { planId });
      clearListCache(CACHE_PLURAL_SEARCH_KEY, { planId });
      if (planId === state.list?.planId && planId === state.planId && state.status.hasLoaded) {
        if (state.search) {
          return dispatch("getListSearch", {
            ...{ planId },
            ...getPaginationParams(state),
            ...getSearchParams(state),
          });
        }
        return dispatch("getList", {
          ...{ planId },
          ...getPaginationParams(state),
        });
      }
    },
    refreshListBySiteId({ dispatch, state }, siteId) {
      const site = state.list?.list?.find((item) => item.id === siteId);
      if (site) {
        const planId = state.list?.planId;
        clearListCache(CACHE_PLURAL_KEY, { planId });
        clearListCache(CACHE_PLURAL_SEARCH_KEY, { planId });
        if (state.search) {
          return dispatch("getListSearch", {
            ...{ planId },
            ...getPaginationParams(state),
            ...getSearchParams(state),
          });
        }
        return dispatch("getList", {
          ...{ planId },
          ...getPaginationParams(state),
        });
      }
    },
    setItemId({ commit }, siteId) {
      commit("SET_ITEM_ID", siteId);
      commit("SET_ITEM_STATUS", Status.UNINITIALIZED);
    },
    setPlanId({ commit }, planId) {
      commit("SET_PLAN_ID", planId);
    },
  },
  getters,
};

export default store;
