import { get as lsGet, set as lsSet } from "local-storage";
// import constants from "@/utils/constants";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const UI_FLOW_DATA = "ui-flow-data";

const store = {
  namespaced: true,
  state: {
    data: lsGet(UI_FLOW_DATA) || {},
    status: new Status(),
  },
  mutations: {
    SET_FLOW(state, payload) {
      state.data[payload.id] = payload.data;
      lsSet(UI_FLOW_DATA, state.data);
    },
    REMOVE_FLOW(state, id) {
      delete state.data[id];
      lsSet(UI_FLOW_DATA, state.data);
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async getFlow({ commit }, id) {
      try {
        commit("SET_STATUS", Status.LOADING);
        const response = await request.makeParse("getUIFlow", { id });
        commit("SET_FLOW", { data: response, id });
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        console.log(e);
        commit("SET_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    removeFlow({ commit }, id) {
      commit("REMOVE_FLOW", id);
    },
  },
};

export default store;
