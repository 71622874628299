const ResellerHosting = [
  {
    path: "",
    name: "reseller-hosting",
    meta: {
      breadcrumb: "RESELLER_LIST",
    },
    component: () =>
      import(
        /* webpackChunkName: "reseller-hosting" */ "@/pages/Dashboard/ResellerHosting/ResellerHostList.vue"
      ),
  },
  {
    path: ":id",
    name: "reseller-hosting-detail",
    component: () =>
      import(
        /* webpackChunkName: "reseller-hosting" */ "@/pages/Dashboard/ResellerHosting/ResellerHost.vue"
      ),
    props: (route) => ({ resellerHostId: route.params.id }),
  },
];

export default ResellerHosting;
