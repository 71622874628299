import { clearAllExpired, createKey, getUserStorage, setKey } from "@/utils/hudStorage";
import { get, set } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_LIST_KEY = "hud";

const hud = {
  namespaced: true,
  state: {
    status: new Status(),
    storage: null,
    list: null,
  },
  mutations: {
    SET_LIST: (state, payload) => (state.list = payload),
    SET_LIST_STATUS: (state, payload) => (state.status.value = payload),
    SET_STORAGE: (state) => (state.storage = getUserStorage()),
  },
  actions: {
    clearAllExpiredStorage: ({ commit }) => {
      clearAllExpired();
      commit("SET_STORAGE");
    },
    getList: async ({ commit }) => {
      commit("SET_LIST_STATUS", Status.LOADING);
      try {
        const response = await get(CACHE_LIST_KEY, request.makeParse, ["getHud"]);
        commit("SET_LIST", response);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    refreshList({ commit }, list) {
      commit("SET_LIST", list);
      set(CACHE_LIST_KEY, list);
    },
    setStorageKey: ({ commit }, { type, id }) => {
      setKey(createKey(type, id));
      commit("SET_STORAGE");
    },
    updateStorage: ({ commit }) => commit("SET_STORAGE"),
  },
  getters: {
    getList: (state) => state.list,
    getListStatus: (state) => state.status,
    getStorageKey: (state) => (type, id) => {
      if (!state.storage) return false;

      const now = new Date().getTime();

      if (now > state.storage.expires) return false;

      const key = state.storage?.keys?.[createKey(type, id)];
      if (!key) return false;
      if (now > key.expires) return false;

      return !!key.data;
    },
  },
};

export default hud;
