import store from "@/store";

const handler = (event) => {
  switch (event) {
    case "user-role:add":
    case "user-role:update":
    case "user-role:delete":
      store.dispatch("team/getList", { forceUpdate: true });
      store.dispatch("team/getList", { forceUpdate: true });
      break;
    case "client-client-user-map:delete":
      store.dispatch("teamMembers/refreshList");
  }
};

export default handler;
