import { request } from "leatherman-js";

const maintenance = {
  namespaced: true,
  state: {
    mode: null,
    previousRoutePath: null,
  },
  mutations: {
    SET_MODE(state, mode) {
      state.mode = mode;
    },
    SET_PREVIOUS_ROUTE_PATH(state, previousRoutePath) {
      state.previousRoutePath = previousRoutePath;
    },
  },
  actions: {
    get: () =>
      request.direct({
        method: "GET",
        url: process.env.VUE_APP_API_STATUS_URL,
      }),
    setMode: ({ commit }, mode) => commit("SET_MODE", mode),
    setPreviousRoutePath: ({ commit }, previousRoutePath) =>
      commit("SET_PREVIOUS_ROUTE_PATH", previousRoutePath),
  },
  getters: {
    getMode: (state) => state.mode,
    getPreviousRoutePath: (state) => state.previousRoutePath || "/",
  },
};

export default maintenance;
