import { request } from "leatherman-js";
import Status from "@/classes/Status";

const settings = {
  namespaced: true,
  state: {
    status: new Status(),
    loginRecaptchaEnabled: false,
    signUpRecaptchaEnabled: false,
    cannySettings: null,
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LOGIN_RECAPTCHA_ENABLED(state, payload) {
      state.loginRecaptchaEnabled = payload;
    },
    SET_SIGN_UP_RECAPTCHA_ENABLED(state, payload) {
      state.signUpRecaptchaEnabled = payload;
    },
    SET_CANNY_SETTINGS(state, payload) {
      state.cannySettings = payload;
    },
  },
  actions: {
    async fetch({ commit, state }) {
      if (state.status === Status.LOADED) {
        return state;
      }

      commit("SET_STATUS", Status.LOADING);
      const settings = await request.makeParse("getSettings");
      commit("SET_LOGIN_RECAPTCHA_ENABLED", settings.loginCaptchaEnabled);
      commit("SET_SIGN_UP_RECAPTCHA_ENABLED", settings.signUpCaptchaEnabled);
      commit("SET_STATUS", Status.LOADED);

      return settings;
    },
    async fetchCanny({ commit }) {
      const settings = await request.makeParse("getCannySettings");
      commit("SET_CANNY_SETTINGS", settings);

      return settings;
    },
  },
  getters: {
    isLoginRecaptchaEnabled: (state) => {
      return state.loginRecaptchaEnabled;
    },
    isSignUpRecaptchaEnabled: (state) => {
      return state.signUpRecaptchaEnabled;
    },
  },
};

export default settings;
