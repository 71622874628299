import { models } from "leatherman-js";
import store from "@/store";

export default (event, data) => {
  switch (event) {
    case "hud-notifications":
      store.dispatch(
        "hud/refreshList",
        data.map((item) =>
          models.HUD.getType(item) === models.HUD.SITE
            ? new models.HUDSite(item)
            : new models.HUDFraud(item)
        )
      );
      break;
  }
};
