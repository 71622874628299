import Status from "@/classes/Status";

const blogPosts = {
  namespaced: true,
  state: {
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
  },
  actions: {
    async getList({ commit }, searchQuery = "*") {
      commit("SET_STATUS", Status.LOADING);

      try {
        const body = await fetch(`https://nexcess.helpjuice.com/search?query=${searchQuery}`).then(
          (response) => response.json()
        );
        const list = body.map(({ question }) => question);

        commit("SET_LIST", list);
        commit("SET_STATUS", Status.LOADED);

        return list;
      } catch (error) {
        console.log(error);
        commit("SET_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    byCategory: (state) => {
      const list = {};
      state.list.forEach((post) => {
        list[post.categories_display_for_views] = list[post.categories_display_for_views] || [];
        list[post.categories_display_for_views].push(post);
      });
      return Object.entries(list);
    },
  },
};

export default blogPosts;
