import { createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_ITEM_STATUS,
  SET_LIST,
  SET_LIST_STATUS,
  SET_PAGINATION,
  SET_SORT,
  UPDATE_IN_PLACE,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_KEY = "enterprise-cloud";
const CACHE_LIST_KEY = "enterprise-cloud-list";

const enterpriseCloud = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    itemStatus: new Status(),
    list: [],
    listStatus: new Status(),
    pagination: null,
    sort: null,
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_ITEM_STATUS,
    SET_LIST,
    SET_LIST_STATUS,
    SET_PAGINATION,
    SET_SORT,
    UPDATE_IN_PLACE,
  },
  actions: {
    get: async ({ commit }, id) => {
      commit("SET_ITEM_STATUS", Status.LOADING);
      const response = await get(createKey(CACHE_KEY, { id }), request.makeParse, [
        "getEnterpriseCloud",
        { id },
      ]);
      commit("SET_ITEM", response);
      commit("SET_ITEM_STATUS", Status.LOADED);
      return response;
    },
    getList: async ({ commit }, params) => {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const response = await get(createKey(CACHE_LIST_KEY, params), request.makeParse, [
        "getEnterpriseCloudList",
        params,
      ]);
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_LIST_STATUS", Status.LOADED);
      return response;
    },
  },
  getters: {
    getItem: (state) => state.item,
    getItemStatus: (state) => state.itemStatus,
    getList: (state) => state.list,
    getListStatus: (state) => state.listStatus,
    getPagination: (state) => state.pagination,
    getSort: (state) => state.sort,
  },
};

export default enterpriseCloud;
