import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_SITE_APP_KEY = "password-views-site-app";

const password = {
  namespaced: true,
  state: {
    siteApp: null,
    siteAppStatus: new Status(),
  },
  mutations: {
    SET_SITE_APP: (state, payload) => (state.siteApp = payload),
    SET_SITE_APP_STATUS: (state, status) => (state.siteAppStatus.value = status),
  },
  actions: {
    generate: () => request.makeParse("generatePassword"),
    get: (context, id) => request.makeParse("getPassword", { id }),
    getSiteApp: async ({ commit, state }, id) => {
      commit(
        "SET_SITE_APP_STATUS",
        (state.siteAppStatus.isLoaded || state.siteAppStatus.isUpdating) &&
          id === state.siteApp?.siteId
          ? Status.UPDATING
          : Status.LOADING
      );
      try {
        const response = await get(createKey(CACHE_SITE_APP_KEY, { id }), request.makeParse, [
          "getSiteAppPasswordInfo",
          { id },
        ]);
        commit("SET_SITE_APP", response);
        commit("SET_SITE_APP_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_SITE_APP_STATUS", Status.ERROR);
      }
    },
    getSiteAppDirect: async (context, id) => request.makeParse("getSiteAppPasswordInfo", { id }),
    refreshSiteApp: ({ dispatch, state }, id) => {
      const cacheKey = createKey(CACHE_SITE_APP_KEY, { id });
      clear(cacheKey);
      if (id === state.siteApp?.siteId) {
        dispatch("getSiteApp", id);
      }
    },
  },
  getters: {
    getSiteApp: (state) => state.siteApp,
    getSiteAppStatus: (state) => state.siteAppStatus,
  },
};

export default password;
