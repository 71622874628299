import {
  certificateList,
  cloudServers,
  coloList,
  creditList,
  dedicatedList,
  dnsZoneList,
  domainList,
  enterpriseCloud,
  home,
  invoiceList,
  orderList,
  otherList,
  partner,
  partnerLinkList,
  partnerReferralList,
  paymentList,
  planList,
  profileInfo,
  rdnsRecordList,
  resellerList,
  sharedHostDetail,
  supportTicketList,
} from "@/utils/navLinks";
import Breadcrumbs from "@/classes/Breadcrumbs";
import devSite from "@/utils/breadcrumbs/devSite";
import NavLink from "@/classes/NavLink";
import plan from "@/utils/breadcrumbs/plan";
import planSafeHarbor from "@/utils/breadcrumbs/planSafeHarbor";
import sharedHostSafeHarbor from "@/utils/breadcrumbs/sharedHostSafeHarbor";
import site from "@/utils/breadcrumbs/site";

export default {
  ANNOUNCEMENTS: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Announcements", null, "announcements")],
    });
  },
  ANNOUNCEMENT: ({ announcement }) => {
    if (!announcement) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        new NavLink("Announcements", null, "announcements"),
        new NavLink(announcement.title, null, "announcement"),
      ],
    });
  },
  DOMAINS: () => {
    return new Breadcrumbs({
      links: [home(), domainList()],
    });
  },
  DOMAIN: ({ domain }) => {
    if (!domain) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        domainList(),
        new NavLink(domain.domain, null, {
          name: "other-detail",
          params: { domainId: domain.id },
        }),
      ],
    });
  },
  FEEDBACK: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Feedback", null, "feedback")],
    });
  },
  OTHER: () => {
    return new Breadcrumbs({
      links: [home(), otherList()],
    });
  },
  OTHER_DETAIL: ({ other }) => {
    if (!other) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        otherList(),
        new NavLink(other.description, null, {
          name: "other-detail",
          params: { otherId: other.id },
        }),
      ],
    });
  },
  PLANS: () => {
    return new Breadcrumbs({
      links: [home(), planList()],
    });
  },
  ...plan,
  ...planSafeHarbor,
  ...site,
  ...devSite,
  DNS_ZONES: () => {
    return new Breadcrumbs({
      links: [home(), dnsZoneList()],
    });
  },
  DNS_ZONE: ({ dnsZone }) => {
    if (!dnsZone) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [home(), dnsZoneList(), new NavLink(dnsZone.domain, null, "dns-zone")],
    });
  },
  RDNS_RECORDS: () => {
    return new Breadcrumbs({
      links: [home(), rdnsRecordList()],
    });
  },
  RDNS_RECORD: ({ rdnsRecord }) => {
    if (!rdnsRecord) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [home(), rdnsRecordList(), new NavLink(rdnsRecord.target, null, "rdns-record")],
    });
  },
  PARTNER_LINKS: () => {
    return new Breadcrumbs({
      links: [home(), partner(), partnerLinkList()],
    });
  },
  PARTNER_LINK: ({ partnerLink }) => {
    if (!partnerLink) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        partner(),
        partnerLinkList(),
        new NavLink(partnerLink.name, null, "partner-link-detail"),
      ],
    });
  },
  PARTNER_REFERRALS: () => {
    return new Breadcrumbs({
      links: [home(), partner(), new NavLink("Referrals", null, "partner-referrals")],
    });
  },
  PARTNER_REFERRAL: ({ partnerReferral }) => {
    if (!partnerReferral) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        partner(),
        partnerReferralList(),
        new NavLink(partnerReferral.id, null, "partner-referral-detail"),
      ],
    });
  },
  PARTNER_CREDITS: () => {
    return new Breadcrumbs({
      links: [home(), partner(), new NavLink("Credits", null, "partner-credits")],
    });
  },
  PARTNER_PAYMENTS: () => {
    return new Breadcrumbs({
      links: [home(), partner(), new NavLink("Partner Payments", null, "partner-payments")],
    });
  },
  BILLING_INVOICES: () => {
    return new Breadcrumbs({
      links: [home(), invoiceList()],
    });
  },
  BILLING_INVOICE: ({ invoice }) => {
    if (!invoice) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [home(), invoiceList(), new NavLink(invoice.description, null, "invoice-detail")],
    });
  },
  BILLING_CREDITS: () => {
    return new Breadcrumbs({
      links: [home(), creditList()],
    });
  },
  BILLING_CREDIT: ({ credit }) => {
    if (!credit) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [home(), creditList(), new NavLink(credit.description, null, "credit-detail")],
    });
  },
  BILLING_PAYMENTS: () => {
    return new Breadcrumbs({
      links: [home(), paymentList()],
    });
  },
  BILLING_PAYMENT: ({ invoice }) => {
    if (!invoice) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        paymentList(),
        new NavLink(`${invoice.id} - ${invoice.description}`, null, "invoice-detail"),
      ],
    });
  },
  BILLING_ORDERS: () => {
    return new Breadcrumbs({
      links: [home(), orderList()],
    });
  },
  BILLING_ORDER: ({ order }) => {
    if (!order) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [home(), orderList(), new NavLink(order.identity, null, "order-detail")],
    });
  },
  BILLING_PAYMENT_INFO: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Payment Info", null, "tax")],
    });
  },
  SHARED_HOSTING: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Shared Hosting", null, "shared-hosting")],
    });
  },
  SHARED_HOST: ({ sharedHost }) => {
    if (!sharedHost) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        new NavLink("Shared Hosting", null, "shared-hosting"),
        sharedHostDetail(sharedHost.id, sharedHost.domain),
      ],
    });
  },
  ...sharedHostSafeHarbor,
  SSL_CERTIFICATES: () => {
    return new Breadcrumbs({
      links: [home(), certificateList()],
    });
  },
  SSL_CERTIFICATE_ADD: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Order SSL Certifcate", null, "certificate-add")],
    });
  },
  SSL_CERTIFICATE_ADD_WITH_CERT: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Order SSL Certifcate", null, "certificate-add")],
    });
  },
  SUPPORT_TICKETS: () => {
    return new Breadcrumbs({
      links: [home(), supportTicketList()],
    });
  },
  SUPPORT_MIGRATION: () => {
    return new Breadcrumbs({
      links: [
        home(),
        supportTicketList(),
        new NavLink("Request Migration", null, "request-migration"),
      ],
    });
  },
  SUPPORT_TICKET: ({ ticket }) => {
    if (!ticket) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [home(), supportTicketList(), new NavLink(ticket.id, null, "ticket")],
    });
  },
  PROFILE_INFO: () => {
    return new Breadcrumbs({
      links: [home(), profileInfo()],
    });
  },
  PROFILE_2FA: () => {
    return new Breadcrumbs({
      links: [home(), profileInfo(), new NavLink("2-Factor Auth", null, "2fa")],
    });
  },
  CLOUD_SERVERS: () => {
    return new Breadcrumbs({
      links: [home(), cloudServers()],
    });
  },
  CLOUD_SERVER: ({ cloudServer }) => {
    if (!cloudServer) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        cloudServers(),
        new NavLink(cloudServer.host, null, {
          name: "cloud-server-detail",
          params: { id: cloudServer.id },
        }),
      ],
    });
  },
  ENTERPRISE_CLOUD: () => {
    return new Breadcrumbs({
      links: [home(), enterpriseCloud()],
    });
  },
  ENTERPRISE_CLOUD_DETAIL: ({ enterpriseCloud: item }) => {
    if (!item) {
      return new Breadcrumbs({ loading: true });
    }
    return new Breadcrumbs({
      links: [
        home(),
        enterpriseCloud(),
        new NavLink(item.nickname || item.host, null, {
          name: "enterprise-cloud-detail",
          params: { id: item.id },
        }),
      ],
    });
  },
  CLOUD_SERVER_ADD: () => {
    return new Breadcrumbs({
      links: [home(), cloudServers(), new NavLink("Add")],
    });
  },
  CLOUD_SERVER_RESIZE: () => {
    return new Breadcrumbs({
      links: [home(), cloudServers(), new NavLink("Resize")],
    });
  },
  DEDICATED_LIST: () => {
    return new Breadcrumbs({
      links: [home(), dedicatedList()],
    });
  },
  COLO_LIST: () => {
    return new Breadcrumbs({
      links: [home(), coloList()],
    });
  },
  RESELLER_LIST: () => {
    return new Breadcrumbs({
      links: [home(), resellerList()],
    });
  },
  TEAM_MEMBERS: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Team Members")],
    });
  },
  TEAM_PERMISSIONS: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Team Permissions")],
    });
  },
  API_TOKENS: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("API Tokens")],
    });
  },
  ACCOUNT_SETTINGS: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Account Settings")],
    });
  },
  SSH_KEYS: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("SSH Keys")],
    });
  },
  REFER_A_FRIEND: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Refer a Friend")],
    });
  },
  SITES: () => {
    return new Breadcrumbs({
      links: [home(), new NavLink("Sites")],
    });
  },
};
