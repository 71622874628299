import store from "@/store";

const handler = (event) => {
  switch (event) {
    case "mysql-db:change":
      store.dispatch("databases/refreshList");
      break;
  }
};

export default handler;
