import { request } from "leatherman-js";

const module = {
  namespaced: true,
  actions: {
    add: (context, { dnsZoneId, nameServer, subdomain, ttl }) =>
      request.makeParse("addDnsZoneNsRecord", null, {
        dnsZoneId,
        nameServer,
        subdomain,
        ttl,
      }),
    update: (context, { dnsZoneId, id, nameServer, subdomain, ttl, force }) =>
      request.makeParse(
        "updateDnsZoneNsRecord",
        { id },
        {
          dnsZoneId,
          nameServer,
          subdomain,
          ttl,
          force,
        }
      ),
  },
};

export default module;
