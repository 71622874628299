import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
} from "@/store/commonMutations";
import { updateItem, updateList } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const store = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
  },
  actions: {
    async get({ commit }, id) {
      const response = await get(createKey("invoices", { id }), request.makeParse, [
        "getInvoice",
        { id },
      ]);
      commit("SET_ITEM", response);
      return response;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const response = await get(createKey("invoices-list", params), request.makeParse, [
        "getInvoices",
        params,
      ]);
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    async getItems({ commit }, id, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const response = await get(createKey("invoices-items", { id }, params), request.makeParse, [
        "getLineItems",
        { id },
        params,
      ]);
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    update({ commit, dispatch, state }, payload) {
      clearListCache("invoices-list");
      updateItem(commit, state, payload);
      updateList(dispatch, state);
    },
    refreshList({ dispatch, state }) {
      clearListCache("invoices-list");
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    refreshItem({ dispatch, state }, id) {
      const cacheKey = createKey("invoices", { id });
      clear(cacheKey);
      if (id === state.item?.id) {
        dispatch("get", id);
      }
    },
  },
  getters: {
    getLineItems(state) {
      return state;
    },
  },
};

export default store;
