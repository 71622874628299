const Domains = [
  {
    path: "",
    name: "domains",
    meta: {
      breadcrumb: "DOMAINS",
    },
    component: () =>
      import(/* webpackChunkName: "domains" */ "@/pages/Dashboard/Domains/DomainList.vue"),
  },
  {
    path: ":id",
    name: "domain",
    meta: {
      breadcrumb: "DOMAIN",
    },
    component: () =>
      import(/* webpackChunkName: "domains" */ "@/pages/Dashboard/Domains/Domain.vue"),
    props: (route) => ({ domainId: route.params.id }),
  },
];

export default Domains;
