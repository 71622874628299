import { computed } from "vue";
import store from "@/store";

export default () => {
  const modals = computed(() => {
    return store.getters["modals/getStack"];
  });

  const topModal = computed(() => {
    return store.getters["modals/topModal"];
  });

  return { modals, topModal };
};
