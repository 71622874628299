import { request } from "leatherman-js";

const handleRequest = async (call, context, id) => {
  return request.makeParse(call, { id }).then((data) => {
    context.dispatch("update", context, data);
    context.dispatch("refreshList");
    return data;
  });
};

const disableEdgecastApac = async (context, id) => {
  return handleRequest("disableEdgecastApac", context, id);
};

const enableEdgecastApac = async (context, id) => {
  return handleRequest("enableEdgecastApac", context, id);
};

const disableEdgecastSharedSsl = async (context, id) => {
  return handleRequest("disableEdgecastSharedSsl", context, id);
};

const enableEdgecastSharedSsl = async (context, id) => {
  return handleRequest("enableEdgecastSharedSsl", context, id);
};

const disableNetworking = async (context, id) => {
  return handleRequest("disableNetworking", context, id);
};

const enableNetworking = async (context, id) => {
  return handleRequest("enableNetworking", context, id);
};

const purgeCache = async (context, id) => {
  return handleRequest("purgeCache", context, id);
};

const getCancellationFormOptions = async (context, payload) => {
  return request.makeParse("getCancellationFormOptions", payload);
};

const cancelService = async (context, data) => {
  return request.makeParse("cancelService", {}, data);
};

const restoreService = async (context, id) => {
  return handleRequest("restoreService", context, id);
};

const softReboot = async (context, id) => {
  return handleRequest("softReboot", context, id);
};

const hardReboot = async (context, id) => {
  return handleRequest("hardReboot", context, id);
};

const startServer = async (context, id) => {
  return handleRequest("startServer", context, id);
};

const stopServer = async (context, id) => {
  return handleRequest("stopServer", context, id);
};

const getReverseDns = async (context, ip) => {
  return request
    .makeParse("getReverseDnsByIp", null, { ip })
    .catch(() => request.makeParse("getReverseDnsDefault", null));
};

const updateReverseDns = async (context, { id, data }) => {
  return request.makeParse("updateReverseDns", { id: id || "" }, data);
};

export {
  disableEdgecastApac,
  disableEdgecastSharedSsl,
  disableNetworking,
  enableEdgecastApac,
  enableEdgecastSharedSsl,
  enableNetworking,
  getReverseDns,
  hardReboot,
  purgeCache,
  getCancellationFormOptions,
  cancelService,
  restoreService,
  softReboot,
  startServer,
  stopServer,
  updateReverseDns,
};
