import store from "@/store";

const onDeleteSuccess = (data) => {
  store.dispatch("toasts/addToast", {
    variant: "success",
    message: `You have successfully deleted your SSH Key: ${data.identity.split(" - ")[0]}`,
  });
  const sshKeys = store.getters["sshKeys/getList"];
  const pagination = store.getters["sshKeys/getPagination"];
  const remainingTokens = sshKeys.length - 1;
  const totalPages = Math.ceil(pagination.total / pagination.size);
  const pageIndex =
    remainingTokens === 0 && pagination.index !== 0 && pagination.index === totalPages - 1
      ? pagination.index - 1
      : pagination.index;
  store.dispatch("sshKeys/refreshList", pageIndex);
};

const handler = (event, data) => {
  switch (event) {
    case "user-ssh-key:add":
      store.dispatch("toasts/addToast", {
        variant: "success",
        message: `You have successfully created your SSH Key: ${data.identity.split(" - ")[0]}`,
      });
      store.dispatch("sshKeys/refreshList");
      break;
    case "user-ssh-key:delete":
      onDeleteSuccess(data);
      break;
  }
};

export default handler;
