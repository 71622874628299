import store from "@/store";

const handlers = (event) => {
  switch (event) {
    case "email-responder:change":
      store.dispatch("emailResponders/refreshList");
      break;
  }
};

export default handlers;
