import store from "@/store";

const handler = (event) => {
  switch (event) {
    case "mysql-user:change":
      store.dispatch("databaseUsers/refreshList");
      break;
  }
};

export default handler;
