import { request } from "leatherman-js";

const tasks = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async get(context, uuid) {
      return await request.makeParse("getTask", { uuid });
    },
    async getCompleted({ dispatch }, uuid) {
      const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await timeout(1000);
      const response = await dispatch("get", uuid);
      if (response.status === "success") return response;
      return await dispatch("getCompleted", uuid);
    },
  },
};

export default tasks;
