import checkout from "@/router/routes/checkout";
import configure from "@/router/routes/configure";
import { constants } from "@/utils";
import dashboard from "@/router/routes/dashboard";
import external from "@/router/routes/external";
import redirects from "@/router/routes/redirects";
import resources from "@/router/routes/resources";
import store from "@/store";

const routes = [
  {
    path: "/appInfo",
    name: "appInfo",
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "info" */ "@/pages/AppInfo.vue"),
  },
  {
    path: "/changelog",
    name: "changelog",
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "info" */ "@/pages/Changelog.vue"),
  },
  ...redirects,
  resources,
  {
    path: "/login",
    name: constants.ROUTES.LOGIN,
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/Login.vue"),
    meta: {
      authGuard: true,
      public: true,
    },
    props: (route) => ({
      redirect: route.query.redirect,
      query: route.query.query,
      reason: route.query.reason,
    }),
  },
  {
    path: "/sign-up:signupInfo(.*)*",
    name: "signup",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/Signup.vue"),
    meta: {
      authGuard: true,
      public: true,
    },
    props: (route) => {
      const redirect = route.query.redirect || route.params.pathMatch;

      return {
        redirect,
        reason: route.query.reason,
      };
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/ForgotPassword.vue"),
    meta: {
      authGuard: true,
      public: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: {
      authGuard: true,
      public: true,
      redactedQueryParams: ["authToken"],
    },
    beforeEnter: (to, from, next) => {
      store.dispatch("auth/setPasswordResetInfo", {
        token: to.query.authToken,
        requestId: to.query.requestId,
      });
      next({ name: "reset-password-form", query: { userId: to.query.userId } });
    },
  },
  {
    path: "/reset-password-form",
    name: "reset-password-form",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/ResetPassword.vue"),
    meta: {
      authGuard: true,
      public: true,
      redactedQueryParams: ["authToken"],
    },
    props: (route) => ({
      userId: route.query.userId,
    }),
  },
  {
    path: "/join",
    name: "join",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/Join.vue"),
    props: (route) => ({
      userId: route.query.userId,
      authToken: route.query.authToken,
      clientId: route.query.clientId,
    }),
    meta: {
      sidebar: "JOIN",
      public: true,
      redactedQueryParams: ["authToken"],
    },
  },
  {
    path: "/login-as/:token",
    name: "login-as",
    meta: {
      public: true,
      redactedPathParams: ["token"],
    },
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/LoginAs.vue"),
    props: (route) => ({ token: route.params.token }),
  },
  {
    path: "/domain-search",
    name: "domain-search",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/DomainSearch.vue"),
    meta: {
      hideSidebar: true,
      public: true,
      domainSearch: true,
    },
    props: (route) => {
      const domain = route.query.domain;
      const param1 = route.query.Param1 || route.query.param1;
      const irpid = route.query.irpid;
      return {
        search: domain || param1,
        irpid,
      };
    },
  },
  ...external,
  ...checkout,
  ...configure,
  {
    path: "/",
    component: () => import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue"),
    meta: {
      requiresAuth: true,
      sidebar: "MAIN",
      // public: true,
    },
    children: dashboard,
  },
  {
    path: "/maintenance",
    name: "maintenance",
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "maintenance" */ "@/pages/Maintenance.vue"),
  },
  {
    path: "/:catchAll(.*)",
    meta: {
      public: true,
    },
    beforeEnter: (to, from, next) => {
      console.error("404 NOT FOUND", { to, from });
      next({
        name: constants.ROUTES.HOME,
      });
    },
  },
];

export default routes;
