import { clearListCache, createKey, get } from "@/utils/networkCache";
import { getPaginationParams, getSearchParams } from "@/utils/storeHelper";
import { mutations, state } from "@/store/siteStore";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "all-sites";
const CACHE_PLURAL_SEARCH_KEY = "all-sites-search";

const store = {
  namespaced: true,
  state,
  mutations: {
    ...mutations,
    SET_SEARCH(state, payload) {
      state.search = state.search ? state.search : {};
      if (payload?.planId) {
        state.search.planId = payload.planId;
      }
      if (payload?.search) {
        state.search.searchItems = payload.searchItems;
      }
    },
    SET_SEARCH_STATUS(state, payload) {
      state.searchStatus.value = payload;
    },
  },
  actions: {
    async getList({ commit }, params) {
      // planId required in params
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        // get as many sites as possible (500 should be above the max number for a plan)
        const response = await get(
          createKey(CACHE_PLURAL_KEY, { ...params, pageSize: 500 }),
          request.makeParse,
          ["getSitesByPlanId", { ...params, pageSize: 500 }]
        );
        commit("SET_LIST", response.list);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    async getListSearch({ commit }, params = {}) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_SEARCH_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      commit("SET_SEARCH", params);
      try {
        const response = await get(createKey(CACHE_PLURAL_SEARCH_KEY, params), request.makeParse, [
          "searchSites",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_STATUS", Status.LOADED);
        commit("SET_SEARCH_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
        commit("SET_SEARCH_STATUS", Status.ERROR);
      }
    },
    refreshList: ({ dispatch, state }) => {
      clearListCache(CACHE_PLURAL_KEY);
      clearListCache(CACHE_PLURAL_SEARCH_KEY);
      if (state.status.hasLoaded) {
        if (state.search) {
          return dispatch("getListSearch", {
            ...getPaginationParams(state),
            ...getSearchParams(state),
          });
        }
        return dispatch("getList", {
          ...getPaginationParams(state),
        });
      }
    },
    async refreshListSearch({ dispatch, state }, params = {}) {
      clearListCache(createKey(CACHE_PLURAL_SEARCH_KEY, params));
      dispatch("getListSearch", {
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
  },
  getters: {
    getList: (state) => state.list,
    getListStatus: (state) => state.status,
  },
};

export default store;
