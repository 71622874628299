import { request } from "leatherman-js";

const module = {
  namespaced: true,
  actions: {
    add: (context, { dnsZoneId, mailServer, preference, subdomain, ttl }) =>
      request.makeParse("addDnsZoneMxRecord", null, {
        dnsZoneId,
        mailServer,
        preference,
        subdomain,
        ttl,
      }),
    update: (context, { dnsZoneId, id, mailServer, preference, subdomain, ttl, force }) =>
      request.makeParse(
        "updateDnsZoneMxRecord",
        { id },
        {
          dnsZoneId,
          mailServer,
          preference,
          subdomain,
          ttl,
          force,
        }
      ),
  },
};

export default module;
