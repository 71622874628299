import store from "@/store";

const handlers = (event) => {
  switch (event) {
    case "email-alias:change":
      store.dispatch("emailAliases/refreshList");
      break;
  }
};

export default handlers;
