import { request } from "leatherman-js";

const module = {
  namespaced: true,
  actions: {
    add: (context, { dnsZoneId, hostname, ttl }) =>
      request.makeParse("addDnsZoneAliasRecord", null, {
        dnsZoneId,
        hostname,
        ttl,
      }),
    update: (context, { dnsZoneId, hostname, id, ttl, force }) =>
      request.makeParse(
        "updateDnsZoneAliasRecord",
        { id },
        {
          dnsZoneId,
          hostname,
          ttl,
          force,
        }
      ),
  },
};

export default module;
