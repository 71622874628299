import constants from "@/utils/constants";
import store from "@/store";

export class CartItem {
  id;
  orderType;
  packageId;
  package;
  term;
  discounts;
  addons;

  constructor(data) {
    if (!data.packageId || !data.term) {
      throw new Error("packageId and term are required.");
    }
    this.id = `cart-item-${Math.floor(Math.random() * 1000)}`;
    this.orderType = data.orderType;
    this.packageId = data.packageId;
    this.package = data.package;
    this.term = data.term; // translateTerm(data.term);
    this.discounts = data.discounts || [];
    this.addons = data.addons || [];
  }

  addAddon(addonId) {
    const addonIndex = this.addons.findIndex(({ id }) => id === addonId);
    if (addonIndex === -1) {
      this.addons.push(new CartAddon({ id: addonId, quantity: 1 }));
    } else {
      this.addons[addonIndex].quantity += 1;
    }
  }

  removeAddon(addonId) {
    const addonIndex = this.addons.findIndex(({ id }) => id === addonId);
    if (addonIndex !== -1) {
      this.addons[addonIndex].quantity -= 1;
      if (this.addons[addonIndex].quantity === 0) {
        this.addons = this.addons.filter(({ id }) => id !== addonId);
      }
    }
  }
}

export class CartPlanItem extends CartItem {
  appId;
  locationId;
  nickname;
  affiliateId;
  installApp;

  constructor(data) {
    super(data);
    if (!data.appId || !data.locationId) {
      throw new Error("appId and locationId are required.");
    }
    this.orderType = "virt-guest-cloud";
    this.appId = data.appId;
    this.locationId = data.locationId;
    this.nickname = data.nickname;
    this.affiliateId = data.affiliateId;
    this.installApp = data.installApp;
  }
}

export class CartDomainItem extends CartItem {
  domainName;
  tld;
  contact;
  UIFlowUUID;

  constructor(data) {
    super(data);
    this.orderType = "domain";
    this.domainName = data.domainName;
    this.tld = data.tld;
    this.contact = data.contact;
    this.UIFlowUUID = data.UIFlowUUID;
  }
}

export class CartAddon {
  id;
  quantity;

  constructor(data) {
    this.id = data.id;
    this.quantity = data.quantity;
  }
}

export const canInstallApp = (installApp) => {
  const app = store.state.apps.item;

  if ("generic" === app.type) {
    return true;
  }

  if ("magento" === app.type) {
    return installApp;
  }

  return true;
};

export const getDefaultAddons = (installApp) => {
  const app = store.state.apps.item;

  if (app.type === "magento" && installApp) {
    return [new CartAddon({ id: constants.ELASTIC_SEARCH_ADDON_ID, quantity: 1 })];
  }

  return [];
};
