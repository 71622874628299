<template>
  <button
    v-if="hasAccess"
    :type="type"
    class="tw-inline-flex tw-items-center tw-justify-center tw-text-center !tw-rounded-full disabled:tw-opacity-50 tw-relative"
    :class="[
      colorClass,
      sizeClass,
      {
        'tw-cursor-not-allowed': disabledComp,
        'pointer-events-unset': disabledComp,
        'btn-loading': loading,
      },
    ]"
    :disabled="disabledComp"
    @click="click"
    :data-test="testId"
    @mouseover="focus"
    @mouseleave="blur"
  >
    <font-awesome-icon
      v-if="loading"
      icon="fa-duotone fa-spinner-third"
      :spin="true"
      :class="spinnerClass"
    />
    <font-awesome-icon
      v-else-if="faIcon && iconPosition === 'left'"
      :icon="faIcon?.icon"
      :spin="faIcon?.spin"
      :flip="faIcon?.flip"
      :class="computedIconClass"
      class="tw-text-[18px]"
    />
    <slot> {{ text }}</slot>
    <font-awesome-icon
      v-if="faIcon && iconPosition === 'right'"
      :icon="faIcon?.icon"
      :spin="faIcon?.spin"
      :flip="faIcon?.flip"
      :class="computedIconClass"
      class="tw-text-[18px]"
    />
    <div
      v-if="isAdminRestricted && isAdminUser"
      class="tw-absolute -tw-top-[9px]"
      :class="[restrictedClass, restrictedColorClass]"
    >
      Admin
    </div>
    <div
      v-if="isBetaRestricted && isBetaTester"
      class="tw-absolute -tw-bottom-[9px]"
      :class="[restrictedClass, restrictedColorClass]"
    >
      Beta
    </div>
  </button>
</template>

<script>
import { computed, ref } from "vue";
import store from "@/store";

export default {
  name: "Btn",
  props: {
    type: {
      type: String,
      default: "button",
    },
    text: String,
    variant: {
      type: String,
      default: "primary",
    },
    selected: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    testId: String,
    icon: Object,
    disableHover: {
      type: Boolean,
      default: false,
    },
    restricted: {
      type: Object,
      default: () => ({
        admin: false,
        beta: false,
        globalBeta: false,
      }),
    },
  },
  emits: ["click"],
  setup(props, { emit, slots }) {
    const COLOR_MAP = {
      primary:
        "tw-bg-highlight-background tw-text-highlight-text disabled:tw-bg-highlight-background",
      secondary:
        "tw-bg-highlight-background-weak tw-text-highlight-text-strong disabled:tw-bg-highlight-background-weak disabled:tw-text-highlight-text-strong",
      success: "tw-bg-success-background tw-text-success-text disabled:tw-bg-success-background",
      danger: "tw-bg-danger-background tw-text-danger-text disabled:tw-bg-danger-background",
      warning: "tw-bg-warning-background tw-text-warning-text disabled:tw-bg-warning-background",
      link: "tw-text-text tw-underline disabled:tw-text-text-disabled",
      transparent: "",
    };
    const HOVER_MAP = {
      primary: "hover:tw-bg-highlight-background-hover",
      secondary: "hover:tw-bg-highlight-background-hover hover:tw-text-highlight-text",
      success: "hover:tw-bg-success-background-hover",
      danger: "hover:tw-bg-danger-background-hover",
      warning: "hover:tw-bg-warning-background-hover",
      link: "hover:tw-text-highlight-text-medium",
      transparent: "",
    };
    const SIZE_MAP = {
      default: "nx-text-body2 tw-py-2 tw-px-4",
      small: "nx-text-body2 tw-py-1 tw-px-3",
      large: "nx-text-h4 tw-py-2.5 tw-px-5",
    };
    const SELECTED_COLOR_MAP = {
      primary:
        "tw-bg-highlight-background-hover tw-text-highlight-text disabled:tw-bg-highlight-background-hover",
      secondary:
        "tw-bg-highlight-background-hover tw-text-highlight-text disabled:tw-bg-highlight-background-weak disabled:tw-text-highlight-text-strong hover:tw-bg-highlight-background-hover hover:tw-text-highlight-text",
      success:
        "tw-bg-success-background-hover tw-text-success-text disabled:tw-bg-success-background-hover",
      danger:
        "tw-bg-danger-background-hover tw-text-danger-text disabled:tw-bg-danger-background-hover",
      warning:
        "tw-bg-warning-background-hover tw-text-warning-text disabled:tw-bg-warning-background-hover",
      link: "tw-text-highlight-text-medium tw-underline disabled:tw-text-text-disabled",
      transparent: "",
    };
    const SPINNER_MAP = {
      default: "tw-text-[21px] tw-mr-2.5",
      small: "tw-text-[12px] tw-mr-1.5",
      large: "tw-text-[27px] tw-mr-3",
    };
    const RESTRICTED_COLOR_MAP = {
      primary:
        "tw-bg-highlight-background tw-text-highlight-text tw-border-border-strong disabled:tw-bg-highlight-background",
      secondary:
        "tw-bg-highlight-background-weak tw-text-highlight-text-strong tw-border-border-strong disabled:tw-bg-highlight-background-weak disabled:tw-text-highlight-text-strong",
      success:
        "tw-bg-success-background tw-text-success-text disabled:tw-bg-success-background tw-border-border-strong",
      danger:
        "tw-bg-danger-background tw-text-danger-text tw-border-border-strong disabled:tw-bg-danger-background",
      warning:
        "tw-bg-warning-background tw-text-warning-text tw-border-border-strong disabled:tw-bg-warning-background",
      link: "tw-text-text tw-underline tw-border-border-strong disabled:tw-text-text-disabled",
      transparent: "",
    };

    const active = ref(false);

    const disabledComp = computed(() => props.loading || props.disabled);
    const colorClass = computed(() =>
      props.selected
        ? SELECTED_COLOR_MAP[props.variant] || SELECTED_COLOR_MAP.primary
        : props.disableHover
        ? COLOR_MAP[props.variant] || COLOR_MAP.primary
        : `${COLOR_MAP[props.variant]} ${HOVER_MAP[props.variant]}` ||
          `${COLOR_MAP.primary} ${HOVER_MAP.primary}`
    );
    const computedIconClass = computed(() => {
      if (slots.default) {
        if (faIcon.value?.class) {
          return `${faIcon.value?.class} ${iconPosition.value === "right" ? "tw-ml-2" : "tw-mr-2"}`;
        }
        return iconPosition.value === "right" ? "tw-ml-2" : "tw-mr-2";
      }
      return faIcon.value?.class;
    });
    const restrictedClass =
      "nx-text-body4 tw-rounded-full tw-border-dashed tw-border-[1px] tw-px-1";
    const restrictedColorClass = computed(() => RESTRICTED_COLOR_MAP[props.variant]);

    const isBetaTester = computed(() => store.getters["auth/isBetaTester"]);
    const isBetaRestricted = computed(() => props.restricted?.beta || props.restricted?.globalBeta);
    const isAdminUser = computed(() => store.getters["auth/isAdmin"]);
    const hasAdminAccess = computed(() => props.restricted?.admin && isAdminUser.value);
    const isAdminRestricted = computed(() => props.restricted?.admin);
    const hasBetaAccess = computed(
      () => (props.restricted?.beta && isBetaTester.value) || props.restricted?.globalBeta
    );
    const hasAccess = computed(
      () =>
        !hasRestrictions.value ||
        (isAdminRestricted.value && hasAdminAccess.value) ||
        (isBetaRestricted.value && hasBetaAccess.value)
    );
    const hasRestrictions = computed(() => isBetaRestricted.value || isAdminRestricted.value);

    const sizeClass = computed(() => SIZE_MAP[props.size] || SIZE_MAP.default);
    const spinnerClass = computed(() => SPINNER_MAP[props.size] || SPINNER_MAP.default);

    const blur = () => (active.value = false);
    const click = (obj) => emit("click", obj);
    const faIcon = computed(() =>
      props.loading
        ? props.icon?.loading || props.icon?.default
        : !(props.disabled || props.loading) && active.value
        ? props.icon?.hover || props.icon?.default
        : props.icon?.default
    );
    const focus = () => (active.value = true);
    const iconPosition = computed(() => faIcon.value?.position || "left");

    return {
      blur,
      colorClass,
      click,
      computedIconClass,
      disabledComp,
      faIcon,
      focus,
      hasAccess,
      iconPosition,
      isAdminUser,
      isAdminRestricted,
      isBetaTester,
      isBetaRestricted,
      restrictedClass,
      restrictedColorClass,
      sizeClass,
      spinnerClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.pointer-events-unset {
  pointer-events: unset;
}
</style>
