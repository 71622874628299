import { request } from "leatherman-js";

const module = {
  namespaced: true,
  actions: {
    add: (
      context,
      { dnsZoneId, dnsZoneDomain, service, protocol, priority, weight, port, target, ttl }
    ) =>
      request.makeParse("addDnsZoneSrvRecord", null, {
        dnsZoneId,
        dnsZoneDomain,
        service,
        protocol,
        priority,
        weight,
        port,
        target,
        ttl,
      }),
    update: (
      context,
      {
        dnsZoneDomain,
        dnsZoneId,
        id,
        port,
        priority,
        protocol,
        service,
        target,
        ttl,
        weight,
        force,
      }
    ) =>
      request.makeParse(
        "updateDnsZoneSrvRecord",
        { id },
        {
          dnsZoneDomain,
          dnsZoneId,
          port,
          priority,
          protocol,
          service,
          target,
          ttl,
          weight,
          force,
        }
      ),
  },
};

export default module;
