import safeHarborDevSite from "@/router/routes/dashboard/plans/safeHarborDevSite";
import site from "@/router/routes/dashboard/plans/site";

export default [
  {
    path: "",
    name: "plan-list",
    meta: {
      breadcrumb: "PLANS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/PlansList.vue"),
  },
  {
    path: ":planId",
    name: "plan-detail",
    component: () => import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan.vue"),
    meta: {
      sidebar: "PLAN",
    },
    redirect: {
      name: "plan-sites",
    },
    props: (route) => ({ planId: Number.parseInt(route.params.planId) }),
    children: [
      {
        path: "sites",
        name: "plan-sites",
        meta: {
          breadcrumb: "PLAN_SITES",
          sidebar: "PLAN",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Sites.vue"),
      },
      {
        path: "sites/:siteId",
        name: "plan-site-detail",
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site.vue"),
        redirect: {
          name: "plan-site-credentials",
        },
        meta: {
          sidebar: "PLAN_SITE",
        },
        props: (route) => ({
          planId: Number.parseInt(route.params.planId),
          siteId: Number.parseInt(route.params.siteId),
        }),
        children: site,
      },
      {
        path: "stencils",
        name: "plan-stencils",
        meta: {
          breadcrumb: "PLAN_STENCILS",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Stencils.vue"),
      },
      {
        path: "management",
        name: "plan-management",
        meta: {
          breadcrumb: "PLAN_MANAGEMENT",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Management.vue"),
      },
      {
        path: "content-delivery",
        name: "plan-content-delivery",
        meta: {
          breadcrumb: "PLAN_CONTENT_DELIVARY",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/NexcessCDN.vue"),
      },
      {
        path: "analytics",
        name: "plan-analytics",
        meta: {
          breadcrumb: "PLAN_ANALYTICS",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Analytics.vue"),
      },
      {
        path: "integrations",
        name: "plan-integrations",
        meta: {
          breadcrumb: "PLAN_INTEGRATIONS",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Integrations.vue"),
      },
      {
        path: "credentials",
        name: "plan-credentials",
        meta: {
          breadcrumb: "PLAN_CREDENTIALS",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Credentials.vue"),
      },
      {
        path: "databases",
        name: "plan-databases",
        meta: {
          breadcrumb: "PLAN_DATABASES",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Databases.vue"),
      },
      {
        path: "containers",
        name: "plan-containers",
        meta: {
          breadcrumb: "PLAN_CONTAINERS",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Containers.vue"),
      },
      {
        path: "control-panel",
        name: "plan-control-panel",
        meta: {
          breadcrumb: "PLAN_CONTROL_PANEL",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/ControlPanel.vue"),
      },
      {
        path: "scheduled-tasks",
        name: "plan-scheduled-tasks",
        meta: {
          breadcrumb: "PLAN_SCHEDULED_TASKS",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/ScheduledTasks.vue"),
      },
      {
        path: "email",
        name: "plan-email",
        meta: {
          breadcrumb: "PLAN_EMAIL",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Email.vue"),
      },
      {
        path: "safe-harbor",
        name: "plan-safe-harbor",
        meta: {
          breadcrumb: "PLAN_SAFE_HARBOR",
          sidebar: "PLAN_SAFE_HARBOR",
        },
        component: () =>
          import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/SafeHarbor.vue"),
        redirect: {
          name: "plan-safe-harbor-malware-scans",
        },
        children: [
          {
            path: "malware-scans",
            name: "plan-safe-harbor-malware-scans",
            meta: {
              breadcrumb: "PLAN_SAFE_HARBOR_MALWARE_SCANS",
            },
            component: () =>
              import(
                /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/SafeHarbor/MalwareScans.vue"
              ),
          },
          {
            path: "protection",
            name: "plan-safe-harbor-threat-protection",
            meta: {
              breadcrumb: "PLAN_SAFE_HARBOR_THREAT_PROTECTION",
            },
            component: () =>
              import(
                /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/SafeHarbor/ThreatProtection.vue"
              ),
          },
          {
            path: "staging-environments",
            name: "plan-safe-harbor-staging-environments",
            meta: {
              breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENTS",
            },
            component: () =>
              import(
                /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/SafeHarbor/StagingEnvironments.vue"
              ),
          },
          {
            path: "patches",
            name: "plan-safe-harbor-magento-patches",
            meta: {
              breadcrumb: "PLAN_SAFE_HARBOR_MAGENTO_PATCHES",
            },
            component: () =>
              import(
                /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/SafeHarbor/MagentoPatches.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: ":planId/safe-harbor/staging-environments/:stagingEnvironmentId",
    name: "plan-safe-harbor-staging-environment",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT",
      sidebar: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/SafeHarbor/StagingEnvironment"
      ),
    props: (route) => ({
      serviceId: Number(route.params.planId),
      stagingEnvironmentId: Number(route.params.stagingEnvironmentId),
    }),
    redirect: {
      name: "plan-safe-harbor-staging-environment-credentials",
    },
    children: safeHarborDevSite,
  },
];
