import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_KEY = "container-password";

const store = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
  },
  mutations: {
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_STATUS(state, status) {
      state.status.value = status;
    },
  },
  actions: {
    async get({ commit, state }, containerId) {
      commit(
        "SET_STATUS",
        (state.status.isLoaded || state.status.isUpdating) &&
          containerId === state.item?.containerId
          ? Status.UPDATING
          : Status.LOADING
      );

      try {
        const params = { containerId };
        const response = await get(createKey(CACHE_KEY, params), request.makeParse, [
          "getContainerPassword",
          params,
        ]);
        commit("SET_ITEM", response);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    refresh({ dispatch, state }, containerId) {
      const cacheKey = createKey(CACHE_KEY, { containerId });
      clear(cacheKey);
      if (containerId === state.item.containerId) {
        dispatch("get", containerId);
      }
    },
  },
  getters: {
    get: (state) => state.item,
    getStatus: (state) => state.status,
  },
};

export default store;
