import { home, planDetail, planDevSiteDetail, planList, planSiteDevEnvs } from "@/utils/navLinks";
import Breadcrumbs from "@/classes/Breadcrumbs";
import NavLink from "@/classes/NavLink";

export default {
  PLAN_SITE_DEV_ANALYTICS: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Analytics", null, "plan-site-dev-analytics"),
          ],
        }),
  PLAN_SITE_DEV_BACKUPS: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Backups"),
          ],
        }),
  PLAN_SITE_DEV_CONTAINERS: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Containers"),
          ],
        }),
  PLAN_SITE_DEV_CONTROL_PANEL: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Control Panel"),
          ],
        }),
  PLAN_SITE_DEV_CREDENTIALS: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Credentials"),
          ],
        }),
  PLAN_SITE_DEV_DATABASES: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Databases"),
          ],
        }),
  PLAN_SITE_DEV_DOMAINS: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Domains"),
          ],
        }),
  PLAN_SITE_DEV_EMAIL: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Email"),
          ],
        }),
  PLAN_SITE_DEV_LOGS: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Logs"),
          ],
        }),
  PLAN_SITE_DEV_MANAGEMENT: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Management"),
          ],
        }),
  PLAN_SITE_DEV_CONTENT_DELIVARY: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Content Delivery"),
          ],
        }),
  PLAN_SITE_DEV_SCHEDULED_TASKS: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Scheduled Tasks"),
          ],
        }),
  PLAN_SITE_DEV_SSL: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("SSL"),
          ],
        }),
  PLAN_SITE_DEV_VISUAL_COMPARISON: ({ plan, site, devSite }) =>
    !plan || !site || !devSite
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDevEnvs(plan, site),
            planDevSiteDetail(devSite.domain),
            new NavLink("Visual Comparison"),
          ],
        }),
};
