import {
  cancelService,
  getCancellationFormOptions,
  restoreService,
} from "@/utils/serviceActionsHelper";
import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_ITEM, SET_LIST, SET_PAGINATION, SET_SORT } from "@/store/commonMutations";
import { request } from "leatherman-js";
import { requireSurvey } from "@/utils/surveys";
import Status from "@/classes/Status";

const CACHE_ITEM_KEY = "plan";
const CACHE_COMPLETE_LIST_KEY = "all-plans";
const CACHE_DISK_SPACE_KEY = "planDiskSpace";
const CACHE_LIST_KEY = "plans";
const CACHE_WIDGET_KEY = "widget-plan";

const CACHE_DISK_SPACE_EXPIRY = 60 * 60 * 1000; // 1 hour

const store = {
  namespaced: true,
  state: {
    chunk: null,
    completeList: [],
    completeListStatus: new Status(),
    diskSpace: null,
    diskSpaceStatus: new Status(),
    item: null,
    itemId: null,
    itemStatus: new Status(),
    primarySite: null,
    primarySiteStatus: new Status(),
    list: [],
    upgradingList: [],
    pagination: null,
    sort: null,
    status: new Status(),
    widget: null,
    widgetStatus: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_COMPLETE_LIST: (state, payload) => {
      state.completeList = payload;
    },
    SET_COMPLETE_LIST_STATUS: (state, payload) => {
      state.completeListStatus.value = payload;
    },
    SET_DISK_SPACE: (state, payload) => {
      state.diskSpace = payload;
    },
    SET_DISK_SPACE_STATUS: (state, payload) => {
      state.diskSpaceStatus.value = payload;
    },
    SET_ITEM,
    SET_ITEM_ID: (state, id) => {
      state.itemId = id;
    },
    SET_ITEM_STATUS: (state, payload) => {
      state.itemStatus.value = payload;
    },
    SET_LIST,
    SET_LIST_STATUS: (state, payload) => {
      state.status.value = payload;
    },
    SET_PAGINATION,
    SET_PRIMARY_SITE: (state, payload) => {
      state.primarySite = payload;
    },
    SET_PRIMARY_SITE_STATUS: (state, payload) => {
      state.primarySiteStatus.value = payload;
    },
    SET_SORT,
    SET_WIDGET_ITEM: (state, payload) => (state.widget = payload),
    SET_WIDGET_ITEM_STATUS: (state, payload) => (state.widgetStatus.value = payload),
    SET_UPGRADING_LIST: (state, payload) => (state.upgradingList = payload),
  },
  actions: {
    getAll: async ({ commit }) => {
      commit("SET_COMPLETE_LIST_STATUS", Status.LOADING);
      try {
        const response = await get(createKey(CACHE_COMPLETE_LIST_KEY), request.makeParse, [
          "getPlans",
          undefined,
          undefined,
          { getAll: true },
        ]);
        commit("SET_COMPLETE_LIST", response.list);
        commit("SET_COMPLETE_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_COMPLETE_LIST_STATUS", Status.ERROR);
      }
    },
    getDiskSpace: async ({ commit, state }, id) => {
      const newStatus =
        (state.diskSpaceStatus.isLoaded || state.diskSpaceStatus.isUpdating) &&
        id === state.diskSpace?.planId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_DISK_SPACE_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_DISK_SPACE_KEY, { id: Number(id) }),
          request.makeParse,
          ["getPlanDiskSpace", { id }],
          CACHE_DISK_SPACE_EXPIRY
        );
        commit("SET_DISK_SPACE", response);
        commit("SET_DISK_SPACE_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_DISK_SPACE_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    getItem: async ({ commit, state }, id) => {
      const newStatus =
        (state.itemStatus.isLoaded || state.itemStatus.isUpdating) && id === state.item?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_ITEM_STATUS", newStatus);
      // set primary site status too
      commit("SET_PRIMARY_SITE_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_ITEM_KEY, { id: Number(id) }),
          request.makeParse,
          ["getPlan", { id }]
        );
        if (state.itemId === id) {
          commit("SET_ITEM", response);
          commit("SET_ITEM_STATUS", Status.LOADED);
          // set primary site and status too
          commit("SET_PRIMARY_SITE", response.primarySite);
          commit("SET_PRIMARY_SITE_STATUS", Status.LOADED);
        }
        return response;
      } catch (e) {
        commit("SET_ITEM_STATUS", Status.ERROR);
        commit("SET_PRIMARY_SITE_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    getList: async ({ commit }, params) => {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        const response = await get(createKey(CACHE_LIST_KEY, params), request.makeParse, [
          "getPlans",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    getWidgetItem: async ({ commit, state }, id) => {
      const newStatus =
        state.itemStatus.isLoaded || state.itemStatus.isUpdating ? Status.UPDATING : Status.LOADING;
      commit("SET_WIDGET_ITEM_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_WIDGET_KEY, { id: Number(id) }),
          request.makeParse,
          ["getPlan", { id }]
        );
        commit("SET_WIDGET_ITEM", response);
        commit("SET_WIDGET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_WIDGET_ITEM_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    // TODO: calculateUpgradeTax needs to be consolidated with the similar action in the checkout store
    // thinking of adding a tax store
    // tax may or may not be calculated by different methods for different products (Cloud Servers vs Plans vs Sites vs Upgrades) in the future.
    calculateUpgradeTax: (context, { planId, packageId, term, addonIds }) =>
      request.makeParse(
        "getSalesTax",
        {},
        {
          packageId,
          term,
          addonIds,
          planId,
        }
      ),
    getUpgradePrices: async (context, { planId, appId, isTShirt }) => {
      const packages = await request.makeParse("getSiteUpgradePrices", {
        planId,
        appId,
        notTShirt: !isTShirt,
      });

      return packages.filter((pkg) => !requireSurvey(pkg));
    },
    updateName: async (context, { id, data }) => request.makeParse("updatePlanName", { id }, data),
    updatePackage: async (context, { id, data }) =>
      request.makeParse("updatePlanPackage", { id }, data),
    addToUpgradingList: ({ commit, state }, planId) => {
      const upgradingList = state.upgradingList;
      upgradingList.push(planId);
      commit("SET_UPGRADING_LIST", upgradingList);
    },
    removeFromUpgradingList: ({ commit, state }, planId) => {
      const upgradingList = state.upgradingList.filter((id) => id !== planId);
      commit("SET_UPGRADING_LIST", upgradingList);
    },
    updateTerm: async (context, { id, data }) => request.makeParse("updatePlanTerm", { id }, data),
    refreshAll: ({ dispatch, state }) => {
      if (state.completeListStatus.isInitialized) {
        clearListCache(CACHE_COMPLETE_LIST_KEY);
        dispatch("getAll");
      }
    },
    refreshAllByPlanId: ({ dispatch, state }, planId) => {
      if (state.completeList?.find((item) => item.id === planId)) {
        clearListCache(CACHE_COMPLETE_LIST_KEY);
        dispatch("getAll");
      }
    },
    refreshItem: ({ dispatch, state }, id) => {
      let cacheKey = createKey(CACHE_ITEM_KEY, { id });
      clear(cacheKey);
      if (id === state.item?.id && id === state.itemId) {
        dispatch("getItem", id);
      }
      cacheKey = createKey(CACHE_WIDGET_KEY, { id });
      clear(cacheKey);
      if (id === state.widget?.id) {
        dispatch("getWidgetItem", id);
      }
    },
    refreshList: ({ dispatch, state }) => {
      clearListCache(CACHE_LIST_KEY);
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    refreshDiskSpace: ({ dispatch, state }, planId) => {
      const cacheKey = createKey(CACHE_DISK_SPACE_KEY, { id: Number(planId) });
      clear(cacheKey);
      if (planId === state.diskSpace?.planId) {
        dispatch("getDiskSpace", planId);
      }
    },
    refreshListByPlanId: ({ dispatch, state }, planId) => {
      const plan = state.list?.find((item) => item.id === planId);
      if (plan) {
        clearListCache(CACHE_LIST_KEY);
        dispatch("getList", {
          pageIndex: state.chunk?.pagination?.index,
          pageSize: state.chunk?.pagination?.size,
          sortProperty: state.chunk?.sort?.property,
          sortDirection: state.chunk?.sort?.direction,
        });
      }
    },
    setItemId: ({ commit }, planId) => {
      commit("SET_ITEM_ID", planId);
      commit("SET_ITEM_STATUS", Status.UNINITIALIZED);
    },
    getCancellationFormOptions,
    cancelService,
    restoreService,
  },
  getters: {
    get: (state) => (id) => {
      if (state.item?.id === id) return state.item;
      return state.list.find((item) => item.id === id);
    },
    getAll: (state) => state.completeList,
    getAllStatus: (state) => state.completeListStatus,
    getDiskSpace: (state) => state.diskSpace,
    getDiskSpaceStatus: (state) => state.diskSpaceStatus,
    getItem: (state) => state.item,
    getItemStatus: (state) => state.itemStatus,
    getPrimarySite: (state) => state.primarySite,
    getPrimarySiteStatus: (state) => state.primarySiteStatus,
    getList: (state) => state.list,
    getListStatus: (state) => state.status,
    getPagination: (state) => state.pagination,
    getSort: (state) => state.sort,
    getWidgetItem: (state) => state.widget,
    getWidgetItemStatus: (state) => state.widgetStatus,
    getUpgradingList: (state) => state.upgradingList,
  },
};

export default store;
