const CloudServers = [
  {
    path: "",
    name: "cloud-servers",
    meta: {
      breadcrumb: "CLOUD_SERVERS",
    },
    component: () =>
      import(
        /* webpackChunkName: "cloud-servers" */ "@/pages/Dashboard/CloudServers/CloudServersList.vue"
      ),
  },
  {
    path: "add",
    name: "cloud-server-add",
    meta: {
      breadcrumb: "CLOUD_SERVER_ADD",
    },
    component: () =>
      import(
        /* webpackChunkName: "cloud-servers" */ "@/pages/Dashboard/CloudServers/CloudServerAdd.vue"
      ),
  },
  {
    path: ":id",
    name: "cloud-server-detail",
    meta: {
      breadcrumb: "CLOUD_SERVER",
    },
    component: () =>
      import(
        /* webpackChunkName: "cloud-servers" */ "@/pages/Dashboard/CloudServers/CloudServer.vue"
      ),
    props: (route) => ({
      cloudServerId: route.params.id,
    }),
  },
  {
    path: ":id/resize",
    name: "cloud-server-resize",
    meta: {
      breadcrumb: "CLOUD_SERVER_RESIZE",
    },
    component: () =>
      import(
        /* webpackChunkName: "cloud-servers" */ "@/pages/Dashboard/CloudServers/CloudServerResize.vue"
      ),
    props: (route) => ({
      cloudServerId: route.params.id,
    }),
  },
];

export default CloudServers;
