import { request } from "leatherman-js";

const store = {
  namespaced: true,
  state: {
    suggestions: [],
    siteId: null,
  },
  actions: {
    getSuggestions: async (context, siteId) =>
      request.makeParse("getSiteVanityDomainSuggestions", { siteId }),
    update: (context, { siteId, uuid }) =>
      request.makeParse("updateSiteVanityDomain", { siteId }, { uuid }),
  },
};

export default store;
