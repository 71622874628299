import NavLink from "@/classes/NavLink";

class NavMenu {
  links; // NavLink[]

  back; // NavLink

  title; // String

  helpLinks; // NavLink[]

  loading; // Boolean

  constructor({ links, back, title, helpLinks, loading } = {}) {
    this.loading = loading;
    this.links = links;
    this.back = back;
    this.title = title;

    const defaultHelpItems = [
      new NavLink("Chat", {
        default: { icon: "fa-regular fa-message-dots" },
        hover: { icon: "fa-solid fa-message-dots" },
      }),
      new NavLink("Email", {
        default: { icon: "fa-regular fa-envelope" },
        hover: { icon: "fa-solid fa-envelope" },
      }),
      new NavLink("Call", {
        default: { icon: "fa-regular fa-phone-volume" },
        hover: { icon: "fa-solid fa-phone-volume" },
      }),
    ];

    this.helpLinks = helpLinks || defaultHelpItems;
  }
}

export default NavMenu;
