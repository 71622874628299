import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_KEY = "site-dns";

const store = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
  },
  mutations: {
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_SSL_STATUS(state, status) {
      state.status.value = status;
    },
  },
  actions: {
    async get({ commit, state }, id) {
      const newStatus =
        (state.status.isLoaded || state.status.isUpdating) && id === state.item?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_SSL_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_KEY, { id }), request.makeParse, [
          "getSiteDns",
          { id },
        ]);
        commit("SET_ITEM", response);
        commit("SET_SSL_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_SSL_STATUS", Status.ERROR);
      }
    },
    refresh({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_KEY, { id });
      clear(cacheKey);
      if (id === state.item.siteId) {
        dispatch("get", id);
      }
    },
  },
  getters: {
    get: (state) => state.item?.dns,
    getStatus: (state) => state.status,
  },
};

export default store;
