import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_ITEM, SET_PAGINATION, SET_STATUS } from "@/store/commonMutations";
import { updateItem, updateList } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_SINGULAR_KEY = "user";
const CACHE_PLURAL_ROLES_KEY = "userRoles";

const team = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    rolesStatus: new Status(),
    resourceRoles: null,
    notificationRoles: null,
    list: [],
    pagination: null,
    status: new Status(),
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_CHUNK,
    SET_ITEM,
    SET_PAGINATION,
    SET_STATUS,
    SET_ROLES_STATUS(state, payload) {
      state.rolesStatus.value = payload;
    },
    SET_RESOURCE_ROLE_TYPES(state, payload) {
      state.resourceRoles = payload;
    },
    SET_NOTIFICATION_ROLE_TYPES(state, payload) {
      state.notificationRoles = payload;
    },
  },
  actions: {
    async getList({ commit }, params) {
      try {
        commit("SET_STATUS", Status.LOADING);
        commit("SET_CHUNK", params);
        const forceUpdate = params?.forceUpdate;
        if (params && params.forceUpdate !== undefined) {
          delete params.forceUpdate;
        }
        const res = await get(
          createKey("teams", params),
          request.makeParse,
          ["getTeams", params],
          null,
          forceUpdate
        );
        commit("SET_LIST", res.list);
        commit("SET_PAGINATION", res.pagination);
        commit("SET_STATUS", Status.LOADED);
        return res;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    async getRoles({ commit }) {
      commit("SET_ROLES_STATUS", Status.LOADING);
      try {
        const { resourceRoles, notificationRoles } = await get(
          CACHE_PLURAL_ROLES_KEY,
          request.makeParse,
          ["getRoles", {}, {}, { headers: { "Accept-Format": "nocworx/json-bundle" } }]
        );
        commit("SET_RESOURCE_ROLE_TYPES", resourceRoles);
        commit("SET_NOTIFICATION_ROLE_TYPES", notificationRoles);
        commit("SET_ROLES_STATUS", Status.LOADED);
        return { resourceRoles, notificationRoles };
      } catch (e) {
        commit("SET_ROLES_STATUS", Status.ERROR);
      }
    },
    create(context, { name, permissions, notifications }) {
      return request.makeParse("createTeam", {}, { name, permissions, notifications });
    },
    edit(context, { teamId, name, permissions, notifications }) {
      return request.makeParse("editTeam", { teamId }, { name, permissions, notifications });
    },
    delete(context, { teamId }) {
      return request.makeParse("deleteTeam", { teamId });
    },
    update({ commit, dispatch, state }, payload) {
      clearListCache(CACHE_SINGULAR_KEY);
      updateItem(commit, state, payload);
      updateList(dispatch, state);
    },
    refreshList({ dispatch, state }) {
      clearListCache("teams");
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
      });
    },
  },
  getters: {
    getList(state) {
      return state.list;
    },
    getListStatus(state) {
      return state.status;
    },
    rolesStatus(state) {
      return state.rolesStatus;
    },
    resourceRoles(state) {
      return state.resourceRoles;
    },
    notificationRoles(state) {
      return state.notificationRoles;
    },
  },
};

export default team;
