import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_PAGINATION, SET_SORT } from "@/store/commonMutations";
import a from "@/store/modules/dnsZones/records/a";
import aaaa from "@/store/modules/dnsZones/records/aaaa";
import alias from "@/store/modules/dnsZones/records/alias";
import caa from "@/store/modules/dnsZones/records/caa";
import cname from "@/store/modules/dnsZones/records/cname";
import mx from "@/store/modules/dnsZones/records/mx";
import ns from "@/store/modules/dnsZones/records/ns";
import { request } from "leatherman-js";
import srv from "@/store/modules/dnsZones/records/srv";
import Status from "@/classes/Status";
import txt from "@/store/modules/dnsZones/records/txt";

const CACHE_KEY = "dns-zone-records";

const module = {
  namespaced: true,
  modules: {
    a,
    alias,
    aaaa,
    caa,
    cname,
    mx,
    ns,
    srv,
    txt,
  },
  state: {
    list: [],
    listStatus: new Status(),
    pagination: null,
    sort: null,
    chunk: null,
    dnsZoneId: null,
  },
  mutations: {
    SET_LIST: (state, payload) => (state.list = payload),
    SET_LIST_STATUS: (state, status) => (state.listStatus.value = status),
    SET_PAGINATION,
    SET_SORT,
    SET_CHUNK,
    SET_ZONE_ID: (state, payload) => (state.dnsZoneId = payload),
  },
  actions: {
    delete: (context, { id, force }) => request.makeParse("deleteDnsZoneRecord", { id }, { force }),
    getList: async ({ commit }, params) => {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      commit("SET_ZONE_ID", params.id);
      try {
        const response = await get(createKey(CACHE_KEY, params), request.makeParse, [
          "getDnsZoneRecords",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    refreshList: async ({ dispatch, state }, id) => {
      const params = {
        id,
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
        ...state.chunk?.filters,
      };
      clearListCache(CACHE_KEY, params);
      if (id === state.dnsZoneId) {
        dispatch("getList", params);
      }
    },
    refreshListByRecordId: async ({ dispatch, state }, id) => {
      const record = state.list.find((item) => item.id === id);
      if (record) {
        const params = {
          id: state.dnsZoneId,
          pageIndex: state.chunk?.pagination?.index,
          pageSize: state.chunk?.pagination?.size,
          sortProperty: state.chunk?.sort?.property,
          sortDirection: state.chunk?.sort?.direction,
          ...state.chunk?.filters,
        };
        clearListCache(CACHE_KEY, params);
        dispatch("getList", params);
      }
    },
  },
  getters: {
    getList: (state) => state.list,
    getListStatus: (state) => state.listStatus,
    getPagination: (state) => state.pagination,
    getSort: (state) => state.sort,
  },
};

export default module;
