const Colocations = [
  {
    path: "",
    name: "colocations",
    meta: {
      breadcrumb: "COLO_LIST",
    },
    component: () =>
      import(
        /* webpackChunkName: "colocations" */ "@/pages/Dashboard/Colocations/ColocationsList.vue"
      ),
    props: (route) => ({ colocationId: route.params.id }),
  },
  {
    path: ":id",
    name: "colocation-detail",
    component: () =>
      import(
        /* webpackChunkName: "colocations" */ "@/pages/Dashboard/Colocations/ColocationDetail.vue"
      ),
    props: (route) => ({ colocationId: route.params.id }),
  },
];

export default Colocations;
