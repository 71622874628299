import { request } from "leatherman-js";

const partnerReferrals = {
  namespaced: true,
  actions: {
    set(context, { method, clientId, data }) {
      return Promise.all([
        request.makeParse("setPartnerPaymentInformation", null, { method, data }),
        request.makeParse("setPartnerPaymentMethod", null, { payoutType: method, clientId }),
      ]);
    },
  },
};

export default partnerReferrals;
