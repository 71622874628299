import Cookies from "js-cookie";
import { requireSurvey } from "@/utils/surveys";
import { validations as validationRules } from "leatherman-js";

const CARTSTACK_TARGET = process.env.VUE_APP_CARTSTACK_TARGET;
const CARTSTACK_SITEID = process.env.VUE_APP_CARTSTACK_SITE_ID;

const cartstack = {
  namespaced: true,
  state: {
    email: Cookies.get("LW_CARTSTACK_EMAIL"),
    packageId: null,
    appId: null,
    locationId: null,
    couponCode: null,
    term: "monthly",
  },
  mutations: {
    SET_EMAIL(state, payload) {
      state.email = payload;
    },
    SET_PACKAGE(state, payload) {
      state.packageId = payload;
    },
    SET_APP(state, payload) {
      state.appId = payload;
    },
    SET_LOCATION(state, payload) {
      state.locationId = payload;
    },
    SET_COUPON(state, payload) {
      state.couponCode = payload;
    },
    SET_TERM(state, payload) {
      state.term = payload;
    },
  },
  actions: {
    create({ state, getters, rootGetters }) {
      const pack = rootGetters["packages/site/getPackageById"](state.packageId);

      // cartstack email cannot proceed without any of these values
      // cartstack should not proceed for packages that require a survey
      if (
        !state.email ||
        !state.appId ||
        !window.cartstack_updatecart ||
        !pack ||
        requireSurvey(pack)
      ) {
        return;
      }

      try {
        const price = getters["price"](pack);
        const cartLink = getters["cartLink"];
        const cartItems = [
          ["setSiteID", CARTSTACK_SITEID],
          ["setCartTotal", price],
          ["setDataItem", { cartLink }],
          ["setEmail", state.email],
          [
            "setCartItem",
            {
              productID: pack.id,
              productName: pack.name || pack.label,
              productDescription: pack.fullDescription,
              quantity: 1,
              productPrice: price,
              productURL: cartLink,
              productImageURL: "",
            },
          ],
        ];

        // send cart data to the cartlink api
        window.cartstack_updatecart(cartItems);
      } catch (e) {
        console.log("cartstack/create", { e });
      }
    },
    setEmail({ commit, dispatch }, email) {
      if (validationRules.rules.email(email)) {
        // if the email passes the regex, store it in a cookie
        Cookies.set("LW_CARTSTACK_EMAIL", email);
        commit("SET_EMAIL", email);
      }

      dispatch("create");
    },
    setApp({ commit, dispatch }, appId) {
      commit("SET_APP", appId);
      dispatch("create");
    },
    setPackage({ commit, dispatch }, packageId) {
      commit("SET_PACKAGE", packageId);
      dispatch("create");
    },
    setLocation({ commit, dispatch }, locationId) {
      commit("SET_LOCATION", locationId);
      dispatch("create");
    },
    setTerm({ commit, dispatch }, term) {
      commit("SET_TERM", term);
      dispatch("create");
    },
    setCoupon({ commit, dispatch }, coupon) {
      commit("SET_COUPON", coupon);
      dispatch("create");
    },
    confirm({ dispatch }) {
      try {
        // if cartstack script hasn't been loaded, don't try to confirm an order
        if (!window.cartstack_updatecart) {
          return;
        }
        window.cartstack_updatecart([
          ["setSiteID", CARTSTACK_SITEID],
          ["setAPI", "confirmation"],
        ]);
        // remove cartstack email after confirmation to prevent reminders being sent for completed orders
        dispatch("reset");
      } catch (e) {
        console.log("cartstack/confirm", { e });
      }
    },
    clear({ commit }) {
      commit("SET_EMAIL", null);
      commit("SET_PACKAGE", null);
      commit("SET_APP", null);
      commit("SET_LOCATION", null);
      commit("SET_COUPON", null);
      commit("SET_TERM", "monthly");
    },
    reset({ dispatch }) {
      Cookies.remove("LW_CARTSTACK_EMAIL");

      dispatch("clear");
    },
  },
  getters: {
    cartLink: (state) => {
      const params = {
        packageId: state.packageId,
        appId: state.appId,
      };

      if (state.locationId) {
        params.locationId = state.locationId;
      }

      if (state.term) {
        params.term = state.term;
      }

      if (state.couponCode) {
        params.couponCode = state.couponCode;
      }

      const qeryString = new URLSearchParams(params);
      const cartLink = `${CARTSTACK_TARGET}/checkout?${qeryString.toString()}`;

      return cartLink;
    },
    price: (state) => (pack) => {
      return state.term === "monthly" ? pack.monthlyFee : pack.annualFee;
    },
  },
};

export default cartstack;
