const announcements = [
  {
    path: "",
    name: "announcements",
    meta: {
      breadcrumb: "ANNOUNCEMENTS",
    },
    component: () =>
      import(
        /* webpackChunkName: "announcements" */ "@/pages/Dashboard/Announcements/AnnouncementsList.vue"
      ),
  },
  {
    path: ":id",
    name: "announcement-detail",
    meta: {
      breadcrumb: "ANNOUNCEMENT",
      sidebar: "ANNOUNCEMENT",
    },
    component: () =>
      import(
        /* webpackChunkName: "announcements" */ "@/pages/Dashboard/Announcements/AnnouncementDetail.vue"
      ),
    props: (route) => ({ announcementId: route.params.id }),
  },
];

export default announcements;
