import { get as lsGet, set as lsSet } from "local-storage";

const alerts = {
  namespaced: true,
  state: {
    list: {
      newPortal: {
        dismissed: lsGet("alert_newPortal_dismissed"),
      },
      successfulFirstOrder: {
        dismissed: lsGet("alert_successfulFirstOrder_dismissed"),
      },
    },
  },
  mutations: {
    DISMISS_ALERT(state, payload) {
      lsSet(`alert_${payload}_dismissed`, true);
      state.list[payload].dismissed = true;
    },
  },
  actions: {
    dismiss({ commit }, alert) {
      commit("DISMISS_ALERT", alert);
    },
  },
  getters: {},
};

export default alerts;
