import { request } from "leatherman-js";

const tax = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    setTaxId(context, { taxId, taxFileIds = [] }) {
      const data = { taxId };
      // if no tax documents are passed, don't send them in the request
      if (taxFileIds.length) {
        data.taxFileIds = taxFileIds;
      }
      return request.makeParse("setTaxId", {}, data);
    },
    deleteTaxId() {
      return request.makeParse("deleteTaxId", {}, {});
    },
    validateTaxId(_, { taxId, countryCode }) {
      return request.makeParse("validateTaxId", { taxId, countryCode });
    },
  },
  getters: {
    getTaxId: (state, getters, rootState, rootGetters) => {
      const client = rootGetters["auth/getClient"];

      return client?.taxExemptNumber;
    },
    getIsTaxExempt: (state, getters, rootState, rootGetters) => {
      const client = rootGetters["auth/getClient"];

      return !!client?.isTaxExempt;
    },
  },
};

export default tax;
