import addresses from "@/store/modules/addresses";
import affiliates from "@/store/modules/affiliates";
import alerts from "@/store/modules/alerts";
import allocations from "@/store/modules/allocations";
import allSites from "@/store/modules/allSites";
import allStencils from "@/store/modules/allStencils";
import analytics from "@/store/modules/analytics";
import announcements from "@/store/modules/announcements";
import apps from "@/store/modules/apps";
import auth from "@/store/modules/auth";
import backups from "@/store/modules/sites/backups";
import blogPosts from "@/store/modules/blogPosts";
import cart from "@/store/modules/cart";
import cartstack from "@/store/modules/cartstack";
import cdnControlPanel from "@/store/modules/cdnControlPanel";
import certificates from "@/store/modules/sites/certificates";
import chart from "@/store/modules/chart";
import cloudServers from "@/store/modules/services/cloudServers";
import colocations from "@/store/modules/services/colocations";
import company from "@/store/modules/company";
import containerPassword from "@/store/modules/containerPassword";
import containers from "@/store/modules/containers";
import credits from "@/store/modules/credits";
import databases from "@/store/modules/databases";
import databaseUsers from "@/store/modules/databaseUsers";
import datashares from "@/store/modules/sites/datashares";
import dedicatedServers from "@/store/modules/services/dedicatedServers";
import devBackups from "@/store/modules/sites/devSites/backups";
import devSites from "@/store/modules/devSites";
import dns from "@/store/modules/sites/dns";
import dnsZones from "@/store/modules/dnsZones";
import domainPackages from "@/store/modules/domainPackages";
import domains from "@/store/modules/services/domains";
import emailAliases from "@/store/modules/emailAliases";
import emailBoxes from "@/store/modules/emailBoxes";
import emailResponders from "@/store/modules/emailResponders";
import enterpriseCloud from "@/store/modules/services/enterpriseCloud";
import ftp from "@/store/modules/sites/ftp";
import httpPassword from "@/store/modules/sites/httpPassword";
import hud from "@/store/modules/hud";
import invoices from "@/store/modules/invoices";
import knowledgeBase from "@/store/modules/knowledgeBase";
import letsencrypt from "@/store/modules/sites/letsencrypt";
import locations from "@/store/modules/locations";
import logs from "@/store/modules/sites/logs";
import maintenance from "@/store/modules/maintenance";
import modals from "@/store/modules/modals";
import nav from "@/store/modules/nav";
import newRelic from "@/store/modules/newRelic";
import nxToLw from "@/store/modules/nxToLw";
import orders from "@/store/modules/orders";
import os from "@/store/modules/os";
import others from "@/store/modules/services/others";
import overlay from "@/store/modules/overlay";
import packages from "@/store/modules/packages";
import partnerCredits from "@/store/modules/partnerCredits";
import partnerLinks from "@/store/modules/partnerLinks";
import partnerPayments from "@/store/modules/partnerPayments";
import partnerReferrals from "@/store/modules/partnerReferrals";
import password from "@/store/modules/password";
import passwordProtection from "@/store/modules/sites/passwordProtection";
import paymentMethods from "@/store/modules/paymentMethods";
import payments from "@/store/modules/payments";
import permissions from "@/store/modules/permissions";
import php from "@/store/modules/php";
import plans from "@/store/modules/plans";
import pointerDomains from "@/store/modules/sites/pointerDomains";
import rdnsRecords from "@/store/modules/rdnsRecords";
import remoteUser from "@/store/modules/sites/remoteUser";
import resellerHosts from "@/store/modules/services/resellerHosts";
import safeHarbor from "@/store/modules/safeHarbor";
import settings from "@/store/modules/settings";
import sharedHosts from "@/store/modules/sharedHosts";
import siteCron from "@/store/modules/siteCron";
import sites from "@/store/modules/sites";
import sshKeys from "@/store/modules/keys";
import stencils from "@/store/modules/stencils";
import surveys from "@/store/modules/surveys";
import tasks from "@/store/modules/tasks";
import tax from "@/store/modules/tax";
import team from "@/store/modules/team";
import teamMembers from "@/store/modules/teamMembers";
import theme from "@/store/modules/theme";
import tickets from "@/store/modules/tickets";
import toasts from "@/store/modules/toasts";
import tokens from "@/store/modules/tokens";
import uiFlows from "@/store/modules/uiFlows";
import visualComparison from "@/store/modules/sites/visualComparison";
import wizard from "@/store/modules/wizard";
import wpWoo from "@/store/modules/sites/wpWoo";

export default {
  addresses,
  affiliates,
  alerts,
  allocations,
  allSites,
  allStencils,
  analytics,
  announcements,
  apps,
  auth,
  backups,
  blogPosts,
  cart,
  cartstack,
  cdnControlPanel,
  certificates,
  chart,
  colocations,
  cloudServers,
  company,
  containerPassword,
  containers,
  credits,
  databases,
  databaseUsers,
  datashares,
  dedicatedServers,
  devBackups,
  devSites,
  dns,
  dnsZones,
  domainPackages,
  domains,
  emailAliases,
  emailBoxes,
  emailResponders,
  enterpriseCloud,
  ftp,
  httpPassword,
  hud,
  invoices,
  knowledgeBase,
  letsencrypt,
  locations,
  logs,
  maintenance,
  modals,
  nav,
  newRelic,
  nxToLw,
  orders,
  os,
  others,
  overlay,
  packages,
  partnerCredits,
  partnerLinks,
  partnerPayments,
  partnerReferrals,
  password,
  passwordProtection,
  paymentMethods,
  payments,
  permissions,
  php,
  plans,
  pointerDomains,
  rdnsRecords,
  remoteUser,
  resellerHosts,
  safeHarbor,
  settings,
  sharedHosts,
  siteCron,
  sites,
  sshKeys,
  stencils,
  surveys,
  tasks,
  tax,
  team,
  teamMembers,
  theme,
  tickets,
  toasts,
  tokens,
  uiFlows,
  visualComparison,
  wizard,
  wpWoo,
};
