import store from "@/store";

const handler = (event, data) => {
  const site = store.getters["sites/getItem"];
  const devSite = store.getters["devSites/getItem"];

  switch (event) {
    case "backup-restore:in-progress":
      if (site?.id === data.account.id) {
        store.dispatch("sites/getRestoreStatus", site.id);
        store.dispatch("backups/refreshList");
        store.dispatch("backups/refreshDailyList");
      } else if (devSite?.id === data.account.id) {
        store.dispatch("devSites/getRestoreStatus", devSite.id);
        store.dispatch("backups/refreshList");
        store.dispatch("backups/refreshDailyList");
      }
      break;
    case "backup-restore:complete":
      if (site?.id === data.account.id) {
        store.dispatch("sites/getRestoreStatus", site.id);
        store.dispatch("backups/refreshList");
        store.dispatch("backups/refreshDailyList");
      } else if (devSite?.id === data.account.id) {
        store.dispatch("devSites/getRestoreStatus", devSite.id);
        store.dispatch("backups/refreshList");
        store.dispatch("backups/refreshDailyList");
      }

      break;
    case "backup-restore:error":
      if (site?.id === data.account.id) {
        store.dispatch("sites/getRestoreStatus", site.id);
        store.dispatch("backups/refreshList");
        store.dispatch("backups/refreshDailyList");
      } else if (devSite?.id === data.account.id) {
        store.dispatch("devSites/getRestoreStatus", devSite.id);
        store.dispatch("backups/refreshList");
        store.dispatch("backups/refreshDailyList");
      }

      break;
    case "virt-guest-cloudaccount-backup-restore:update":
      if (site?.id === data.cloud_account_id) {
        store.dispatch("backups/getBackupRestore", site.id);
        if (data.status === "completed") {
          store.dispatch("toasts/addToast", {
            variant: "success",
            message: "Your site successfully restored!",
          });
        }
      } else if (devSite?.id === data.cloud_account_id) {
        store.dispatch("backups/getBackupRestore", devSite.id);
        if (data.status === "completed") {
          store.dispatch("toasts/addToast", {
            variant: "success",
            message: "Your site successfully restored!",
          });
        }
      }

      break;
    case "virt-guest-cloudaccount:backups-changed":
      if (site?.id === data.id) {
        store.dispatch("backups/refreshList");
        store.dispatch("backups/refreshDailyList");
      } else if (devSite?.id === data.id) {
        store.dispatch("devBackups/refreshList");
        store.dispatch("devBackups/refreshDailyList");
      }

      break;
  }
};

export default handler;
