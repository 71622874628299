import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "api-task:add":
      switch (data.action) {
        case "ticket:add":
          store.dispatch("tickets/refreshList");
          store.dispatch("toasts/addToast", {
            variant: "info",
            message: "Your ticket will be created soon.",
          });
          break;
      }
      break;
    case "api-task:update":
      switch (data.action) {
        case "ticket:add":
        case "ticket:update":
          store.dispatch("tickets/refreshList");
          break;
        case "ticket:close":
          store.dispatch("tickets/refreshList");
          if (data.status === "success") {
            store.dispatch("tickets/refreshItemByNocworxId", data.resource_id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "Successfully closed your ticket.",
            });
          }
          break;
        case "ticket:open":
          store.dispatch("tickets/refreshList");
          if (data.status === "success") {
            store.dispatch("tickets/refreshItemByNocworxId", data.resource_id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "Successfully opened your ticket.",
            });
          }
          break;
        case "ticket:add-watcher":
          if (data.status === "success") {
            store.dispatch("tickets/refreshCurrentItem");
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "You have successfully added a new watcher.",
            });
          }
          break;
        case "ticket:remove-watcher":
          if (data.status === "success") {
            store.dispatch("tickets/refreshCurrentItem");
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "You have successfully removed a ticket watcher.",
            });
          }
          break;
        case "ticket:authenticate":
          if (data.status === "success") {
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "Your ticket was verified successfully.",
              timeout: true,
            });
          }
          if (data.status === "failure") {
            store.dispatch("toasts/addToast", {
              variant: "error",
              message: "Something went wrong trying to verify your ticket.",
              timeout: true,
            });
          }
          store.dispatch("tickets/refreshList");
          break;
        case "ticket:comment":
          if (data.status === "success") {
            store.dispatch("tickets/refreshItemByNocworxId", data.resource_id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "Successfully added comment.",
            });
          }
          break;
        case "ticket:rate":
          if (data.status === "success") {
            store.dispatch("tickets/refreshItemByNocworxId", data.resource_id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "Successfully added feedback.",
            });
          }
          break;
        case "ticket:add-datashare":
          if (data.status === "success") {
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "Successfully created Ticket Password Share.",
              timeout: true,
            });
          }
          store.dispatch("tickets/refreshPasswordsInPlace");
          break;
        case "ticket:add-attachment":
          if (data.status === "success") {
            store.dispatch("tickets/refreshItemByNocworxId", data.resource_id);
            store.dispatch("toasts/addToast", {
              variant: "success",
              message: "Successfully added attachment.",
            });
          }
          break;
      }
      break;
  }
};

export default handler;
