export default {
  path: "components",
  component: () => import(/* webpackChunkName: "components" */ "@/pages/Resources/Components.vue"),
  name: "resources-components",
  children: [
    {
      path: "alerts",
      name: "components-alerts",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Alerts.vue"),
    },
    {
      path: "buttons",
      name: "components-buttons",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Buttons.vue"),
    },
    {
      path: "cards",
      name: "components-cards",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Cards.vue"),
    },
    {
      path: "dropdowns",
      name: "components-dropdowns",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/DropDowns.vue"),
    },
    {
      path: "forms",
      name: "components-forms",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Forms.vue"),
    },
    {
      path: "icons",
      name: "components-icons",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Icons.vue"),
    },
    {
      path: "logos",
      name: "components-logos",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Logos.vue"),
    },
    {
      path: "modals",
      name: "components-modals",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Modals.vue"),
    },
    {
      path: "pills",
      name: "components-pills",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Pills.vue"),
    },
    {
      path: "popovers",
      name: "components-popovers",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Popovers.vue"),
    },
    {
      path: "restricted",
      name: "components-restricted",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Restricted.vue"),
    },
    {
      path: "tables",
      name: "components-tables",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Tables.vue"),
    },
    {
      path: "tabs",
      name: "components-tabs",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Tabs.vue"),
    },
    {
      path: "tags",
      name: "components-tags",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Tags.vue"),
    },
    {
      path: "threedotmenus",
      name: "components-three-dots menus",
      meta: {
        public: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "components" */ "@/pages/Resources/Components/ThreeDotsMenus.vue"
        ),
    },
    {
      path: "toasts",
      name: "components-toasts",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Toasts.vue"),
    },
    {
      path: "toggles",
      name: "components-toggles",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Toggles.vue"),
    },
    {
      path: "tooltips",
      name: "components-tooltips",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/Tooltips.vue"),
    },
    {
      path: "linecharts",
      name: "components-line-charts",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/LineCharts.vue"),
    },
    {
      path: "multiselect",
      name: "components-multiselect",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "components" */ "@/pages/Resources/Components/MultiSelect.vue"),
    },
    {
      path: "autocompleteselect",
      name: "components-auto-complete select",
      meta: {
        public: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "components" */ "@/pages/Resources/Components/AutoCompleteSelect.vue"
        ),
    },
  ],
};
