import Cookies from "js-cookie";

const BANNER_DISMISSED_COOKIE_NAME = "nxToLwBannerDismissed";

const hasDismissedCookie = () => {
  const cookie = Cookies.get(BANNER_DISMISSED_COOKIE_NAME);
  if (!cookie) return false;
  return cookie === "true";
};

const nxToLw = {
  namespaced: true,
  state: {
    bannerDismissed: hasDismissedCookie(),
  },
  mutations: {
    SET_BANNER_DISMISSED: (state, payload) => (state.bannerDismissed = payload),
  },
  actions: {
    set: ({ commit }) => {
      commit("SET_BANNER_DISMISSED", true);
      Cookies.set(BANNER_DISMISSED_COOKIE_NAME, "true", {
        expires: 365 * 100,
      });
    },
  },
  getters: {
    isBannerDismissed: (state) => state.bannerDismissed,
  },
};

export default nxToLw;
