import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_CORE_SETTINGS_KEY = "site-wp-woo-core-settings";
const CACHE_PLUGIN_SETTINGS_KEY = "site-wp-woo-plugin-settings";

const store = {
  namespaced: true,
  state: {
    coreSettings: null,
    coreSettingsStatus: new Status(),
    coreSettingsUpdates: [],
    pluginSettings: null,
    pluginSettingsStatus: new Status(),
    pluginSettingsUpdates: [],
  },
  mutations: {
    ADD_CORE_SETTINGS_UPDATE(state, id) {
      const index = state.coreSettingsUpdates.findIndex((item) => item === Number(id));
      if (index < 0) {
        state.coreSettingsUpdates.push(Number(id));
      }
    },
    ADD_PLUGIN_SETTINGS_UPDATE(state, id) {
      const index = state.pluginSettingsUpdates.findIndex((item) => item === Number(id));
      if (index < 0) {
        state.pluginSettingsUpdates.push(Number(id));
      }
    },
    SET_CORE_SETTINGS(state, payload) {
      state.coreSettings = payload;
    },
    SET_CORE_SETTINGS_STATUS(state, status) {
      state.coreSettingsStatus.value = status;
    },
    SET_PLUGIN_SETTINGS(state, payload) {
      state.pluginSettings = payload;
    },
    SET_PLUGIN_SETTINGS_STATUS(state, status) {
      state.pluginSettingsStatus.value = status;
    },
    REMOVE_CORE_SETTINGS_UPDATE(state, id) {
      const index = state.coreSettingsUpdates.findIndex((item) => item === Number(id));
      if (index >= 0) {
        state.coreSettingsUpdates.splice(index, 1);
      }
    },
    REMOVE_PLUGIN_SETTINGS_UPDATE(state, id) {
      const index = state.pluginSettingsUpdates.findIndex((item) => item === Number(id));
      if (index >= 0) {
        state.pluginSettingsUpdates.splice(index, 1);
      }
    },
  },
  actions: {
    addCoreSettingsUpdate({ commit }, id) {
      commit("ADD_CORE_SETTINGS_UPDATE", id);
    },
    addPluginSettingsUpdate({ commit }, id) {
      commit("ADD_PLUGIN_SETTINGS_UPDATE", id);
    },
    async getCoreSettings({ commit, state }, id) {
      const newStatus =
        (state.coreSettingsStatus.isLoaded || state.coreSettingsStatus.isUpdating) &&
        id === state.coreSettings?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_CORE_SETTINGS_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_CORE_SETTINGS_KEY, { id }), request.makeParse, [
          "getSiteWpWooCoreSettings",
          { id },
        ]);
        commit("SET_CORE_SETTINGS", response);
        commit("SET_CORE_SETTINGS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_CORE_SETTINGS_STATUS", Status.ERROR);
      }
    },
    async getPluginSettings({ commit, state }, id) {
      const newStatus =
        (state.pluginSettingsStatus.isLoaded || state.pluginSettingsStatus.isUpdating) &&
        id === state.pluginSettings?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_PLUGIN_SETTINGS_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_PLUGIN_SETTINGS_KEY, { id }),
          request.makeParse,
          ["getSiteWpWooPluginSettings", { id }]
        );
        commit("SET_PLUGIN_SETTINGS", response);
        commit("SET_PLUGIN_SETTINGS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_PLUGIN_SETTINGS_STATUS", Status.ERROR);
      }
    },
    getSsoUrl: (_, id) => request.makeParse("getSiteWpWooSso", { id }),
    refreshCoreSettings({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_CORE_SETTINGS_KEY, { id });
      clear(cacheKey);
      if (id === state.coreSettings.siteId) {
        dispatch("getCoreSettings", id);
      }
    },
    refreshPluginSettings({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_PLUGIN_SETTINGS_KEY, { id });
      clear(cacheKey);
      if (id === state.pluginSettings.siteId) {
        dispatch("getPluginSettings", id);
      }
    },
    removeCoreSettingsUpdate({ commit }, id) {
      commit("REMOVE_CORE_SETTINGS_UPDATE", id);
    },
    removePluginSettingsUpdate({ commit }, id) {
      commit("REMOVE_PLUGIN_SETTINGS_UPDATE", id);
    },
    async updateCoreSettings(context, { id, data }) {
      return request.makeParse("updateSiteWpWooCoreSettings", { id }, data);
    },
    async updatePluginSettings(context, { id, data }) {
      return request.makeParse("updateSiteWpWooPluginSettings", { id }, data);
    },
  },
  getters: {
    getCoreSettings: (state) => state.coreSettings,
    getCoreSettingsStatus: (state) => state.coreSettingsStatus,
    getPluginSettings: (state) => state.pluginSettings,
    getPluginSettingsStatus: (state) => state.pluginSettingsStatus,
    areCoreSettingsUpdating: (state) => (id) =>
      state.coreSettingsUpdates.findIndex((item) => item === Number(id)) >= 0,
    arePluginSettingsUpdating: (state) => (id) =>
      state.pluginSettingsUpdates.findIndex((item) => item === Number(id)) >= 0,
  },
};

export default store;
