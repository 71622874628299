import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "datashare:add":
      if (data.owner === "virt-guest-cloudaccount" && data.type === "ssh-password") {
        store.dispatch("remoteUser/refreshUser", data.owner_id);
      }
      break;
    case "datashare:update":
      if (data.owner === "virt-guest-cloudaccount" && data.type === "ssh-password") {
        store.dispatch("remoteUser/refreshUser", data.owner_id);
      }
      break;
  }
};

export default handler;
