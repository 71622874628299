import store from "@/store";

const handler = (event) => {
  switch (event) {
    case "client:update":
      store.dispatch("auth/clientInfo");
      break;
  }
};

export default handler;
