<template>
  <transition name="fade">
    <div class="overlay-container" v-if="message">
      <div>
        <LoadingState />
        <div class="mt-2">{{ message }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from "vue";
import LoadingState from "@/components/display/LoadingState";
import store from "@/store";

export default {
  name: "OverlayService",
  components: {
    LoadingState,
  },
  setup() {
    const message = computed(() => {
      return store.getters["overlay/getMessage"];
    });

    return {
      message,
    };
  },
};
</script>

<style lang="scss" scoped>
.overlay-container {
  position: fixed;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1460;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
