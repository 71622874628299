import {
  home,
  planDetail,
  planList,
  planSafeHarbor,
  planSafeHarborStagingEnvironment,
  planSafeHarborStagingEnvironments,
} from "@/utils/navLinks";
import Breadcrumbs from "@/classes/Breadcrumbs";
import NavLink from "@/classes/NavLink";

export default {
  PLAN_SAFE_HARBOR: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [home(), planList(), planDetail(plan.id, plan.nickname), planSafeHarbor(plan.id)],
        }),
  PLAN_SAFE_HARBOR_MAGENTO_PATCHES: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            new NavLink("Magento Patches", null, "plan-safe-harbor-magento-patches"),
          ],
        }),
  PLAN_SAFE_HARBOR_MALWARE_SCANS: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            new NavLink("Malware Scans", null, "plan-safe-harbor-malware-scans"),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_ANALYTICS: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Analytics", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_BACKUPS: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Backups", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_CONTAINERS: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Containers", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_CONTROL_PANEL: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Control Panel", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_CREDENTIALS: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Credentials", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_DATABASES: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Databases", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_DOMAINS: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Domains", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_EMAIL: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Email", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_LOGS: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Logs", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_MANAGEMENT: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Management", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_SCHEDULED_TASKS: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("Scheduled Tasks", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_SSL: ({ plan, devSite }) =>
    !devSite || !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            planSafeHarborStagingEnvironments(plan.id),
            planSafeHarborStagingEnvironment(plan.id, devSite),
            new NavLink("SSL", null, () => null),
          ],
        }),
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENTS: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            new NavLink("Staging Environments", null, "plan-safe-harbor-staging-environments"),
          ],
        }),
  PLAN_SAFE_HARBOR_THREAT_PROTECTION: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSafeHarbor(plan.id),
            new NavLink("Threat Protection", null, "plan-safe-harbor-threat-protection"),
          ],
        }),
};
