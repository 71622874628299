import { request } from "leatherman-js";

const store = {
  namespaced: true,
  actions: {
    getListByServiceId: (context, serviceId) =>
      request.makeParse("getSitesByServiceId", { serviceId }, undefined, { getAll: true }),
  },
};

export default store;
