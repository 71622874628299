import { actions, getters, mutations, state } from "@/store/modules/sites/backupUtils";

const store = {
  namespaced: true,
  state: {
    ...state,
  },
  mutations: mutations,
  actions: actions("devSiteBackups", "devSiteDailyBackups", "devSiteBackupDatabases"),
  getters: getters,
};

export default store;
