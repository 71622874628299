import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_ITEM, SET_PAGINATION, SET_STATUS } from "@/store/commonMutations";
import { updateItem, updateList } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "api-token";

const tokens = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    status: new Status(),
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_CHUNK,
    SET_ITEM,
    SET_PAGINATION,
    SET_STATUS,
  },
  actions: {
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      const res = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
        "getTokens",
        params,
      ]);
      commit("SET_LIST", res.list);
      commit("SET_PAGINATION", res.pagination);
      commit("SET_STATUS", Status.LOADED);
      return res;
    },

    generateToken(context, name) {
      return request.makeParse("createToken", null, { name: name });
    },

    editToken({ dispatch, state }, { id, data }) {
      return request.makeParse("renameToken", { id }, { name: data.name }).finally(() => {
        clearListCache(CACHE_PLURAL_KEY);
        updateList(dispatch, state);
      });
    },

    deleteToken(context, id) {
      return request.makeParse("deleteToken", { id });
    },

    update({ commit, dispatch, state }, payload) {
      clearListCache(CACHE_PLURAL_KEY);
      updateItem(commit, state, payload);
      updateList(dispatch, state);
    },
    refreshList({ dispatch, state }, pageIndex = undefined) {
      clearListCache(CACHE_PLURAL_KEY);
      dispatch("getList", {
        pageIndex: pageIndex !== undefined ? pageIndex : state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
  },
  getters: {
    getTokens(state) {
      return state;
    },
  },
};

export default tokens;
