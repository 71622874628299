import nexcess from "@/store/company-config/nexcess";

const company = {
  namespaced: true,
  state: {
    current: process.env.VUE_APP_COMPANY || "default",
    list: {
      nexcess,
    },
  },
  mutations: {
    SET_COMPANY: (state, payload) => (state.current = payload),
  },
  actions: {
    setCompany: ({ commit }, payload) => commit("SET_COMPANY", payload),
  },
  getters: {
    get: (state) => ({
      name: state.current,
      ...state.list[state.current],
    }),
  },
};

export default company;
