import { computed } from "vue";
import store from "@/store";

export default () => {
  const toasts = computed(() => {
    return store.getters["toasts/getStack"];
  });

  return { toasts };
};
