import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "api-task:update":
      if (data.identity === "service-addon:set (success)") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Safe Harbor was enabled successfully.",
        });
        store.dispatch("safeHarbor/removeUpdate", data.resource_id);
        store.dispatch("sharedHosts/refreshItem", data.resource_id);
        store.dispatch("plans/refreshItem", data.resource_id);
      }
      if (data.identity === "service-addon:set (failure)") {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "Something went wrong trying to enable Safe Harbor.",
        });
        store.dispatch("safeHarbor/removeUpdate", data.resource_id);
        store.dispatch("sharedHosts/refreshItem", data.resource_id);
        store.dispatch("plans/refreshItem", data.resource_id);
      }
      if (data.identity === "service-addon:remove (success)") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Safe Harbor was removed successfully.",
        });
        store.dispatch("safeHarbor/removeUpdate", data.resource_id);
        store.dispatch("sharedHosts/refreshItem", data.resource_id);
        store.dispatch("plans/refreshItem", data.resource_id);
      }
      if (data.identity === "service-addon:remove (failure)") {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "Something went wrong trying to remove Safe Harbor.",
        });
        store.dispatch("safeHarbor/removeUpdate", data.resource_id);
        store.dispatch("sharedHosts/refreshItem", data.resource_id);
        store.dispatch("plans/refreshItem", data.resource_id);
      }
      break;
  }
};

export default handler;
