import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_COMPATIBILITY_CHECK_KEY = "site-php-compatibility-check";
const CACHE_VERSIONS_KEY = "site-php-versions";

const store = {
  namespaced: true,
  state: {
    compatibilityCheck: null,
    compatibilityCheckStatus: new Status(),
    versions: null,
    versionsStatus: new Status(),
    versionUpdates: [],
  },
  mutations: {
    ADD_VERSION_UPDATE(state, id) {
      const index = state.versionUpdates.findIndex((item) => item === Number(id));
      if (index < 0) {
        state.versionUpdates.push(Number(id));
      }
    },
    REMOVE_VERSION_UPDATE(state, id) {
      const index = state.versionUpdates.findIndex((item) => item === Number(id));
      if (index >= 0) {
        state.versionUpdates.splice(index, 1);
      }
    },
    SET_COMPATIBILITY_CHECK(state, payload) {
      state.compatibilityCheck = payload;
    },
    SET_COMPATIBILITY_CHECK_STATUS(state, status) {
      state.compatibilityCheckStatus.value = status;
    },
    SET_VERSIONS(state, payload) {
      state.versions = payload;
    },
    SET_VERSIONS_STATUS(state, status) {
      state.versionsStatus.value = status;
    },
  },
  actions: {
    addVersionUpdate({ commit }, id) {
      commit("ADD_VERSION_UPDATE", id);
    },
    async getCompatibilityCheck({ commit, state }, id) {
      const newStatus =
        (state.compatibilityCheckStatus.isLoaded || state.compatibilityCheckStatus.isUpdating) &&
        id === state.compatibilityCheck?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_COMPATIBILITY_CHECK_STATUS", newStatus);
      try {
        const response = await get(
          createKey(CACHE_COMPATIBILITY_CHECK_KEY, { id }),
          request.makeParse,
          ["getSitePhpCompatibilityCheck", { id }]
        );
        commit("SET_COMPATIBILITY_CHECK", response);
        commit("SET_COMPATIBILITY_CHECK_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        if (e.message === "Request failed with status code 404") {
          commit("SET_COMPATIBILITY_CHECK", { siteId: id });
          commit("SET_COMPATIBILITY_CHECK_STATUS", Status.LOADED);
        } else {
          commit("SET_COMPATIBILITY_CHECK_STATUS", Status.ERROR);
        }
      }
    },
    async getVersions({ commit, state }, id) {
      commit(
        "SET_VERSIONS_STATUS",
        state.versionsStatus.isLoaded && id === state.versions?.siteId
          ? Status.UPDATING
          : Status.LOADING
      );
      try {
        const response = await get(createKey(CACHE_VERSIONS_KEY, { id }), request.makeParse, [
          "getSitePhpVersions",
          { id },
        ]);
        commit("SET_VERSIONS", response);
        commit("SET_VERSIONS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_VERSIONS_STATUS", Status.ERROR);
      }
    },
    refreshCompatibilityCheck({ dispatch, state }, compatibilityCheck) {
      const id = compatibilityCheck.siteId;
      const cacheKey = createKey(CACHE_COMPATIBILITY_CHECK_KEY, { id });
      clear(cacheKey);
      if (id === state.compatibilityCheck?.siteId) {
        dispatch("getCompatibilityCheck", id);
      }
    },
    removeVersionUpdate({ commit }, id) {
      commit("REMOVE_VERSION_UPDATE", id);
    },
    async updateVersion(context, { id, data }) {
      return request.makeParse("updateSitePhpVersion", { id }, data);
    },
    async cancelVersionChange(context, id) {
      return request.makeParse("cancelPhpVersionChange", { id });
    },
  },
  getters: {
    getCompatibilityCheck: (state) => state.compatibilityCheck,
    getCompatibilityCheckStatus: (state) => state.compatibilityCheckStatus,
    getVersions: (state) => state.versions,
    getVersionsStatus: (state) => state.versionsStatus,
    isVersionUpdating: (state) => (id) =>
      state.versionUpdates.findIndex((item) => item === Number(id)) >= 0,
  },
};

export default store;
