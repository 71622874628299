import { clearListCache, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_KEY = "revers-dns-records";

const reverseDns = {
  namespaced: true,
  state: {
    list: null,
    listStatus: new Status(),
    ipAddresses: null,
    ipAddressesStatus: new Status(),
  },
  mutations: {
    SET_IP_ADDRESSES(state, payload) {
      state.ipAddresses = payload;
    },
    SET_IP_ADDRESSES_STATUS(state, status) {
      state.ipAddressesStatus.value = status;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_LIST_STATUS(state, status) {
      state.listStatus.value = status;
    },
  },
  actions: {
    add(context, { hostname, ipAddress, ttl }) {
      console.log("ipAddress", ipAddress);
      return request.makeParse("addRdnsRecord", null, {
        hostname,
        ipAddress,
        ttl,
      });
    },
    delete(context, id) {
      return request.makeParse("deleteDnsZoneRecord", { id });
    },
    async getList({ commit, state }, id) {
      const newStatus =
        (state.listStatus.isLoaded || state.listStatus.isUpdating) && id === state.list?.dnsZoneId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_LIST_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_KEY), request.makeParse, ["getRdnsRecords"]);
        commit("SET_LIST", response);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    async getIpAddresses({ commit, state }) {
      const newStatus =
        state.listStatus.isLoaded || state.listStatus.isUpdating ? Status.UPDATING : Status.LOADING;
      commit("SET_IP_ADDRESSES_STATUS", newStatus);
      try {
        const response = await request.makeParse("getReverseDnsIps");
        commit("SET_IP_ADDRESSES", response);
        commit("SET_IP_ADDRESSES_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        console.log("e", e);
        commit("SET_IP_ADDRESSES_STATUS", Status.ERROR);
      }
    },
    update(context, { hostname, ipAddress, recordId, ttl }) {
      return request.makeParse(
        "updateRdnsRecord",
        { recordId },
        {
          hostname,
          ipAddress,
          ttl,
        }
      );
    },
    async refreshList({ dispatch }) {
      clearListCache(CACHE_KEY);
      dispatch("getList");
    },
    async refreshListByRecordId({ dispatch, state }, id) {
      const record = state.list?.list?.find((item) => item.id === id);
      if (record) {
        const dnsZoneId = state.list?.dnsZoneId;
        clearListCache(CACHE_KEY, { id: dnsZoneId });
        dispatch("getList", dnsZoneId);
      }
    },
  },
  getters: {
    getIpAddresses: (state) => state.ipAddresses || [],
    getIpAddressesStatus: (state) => state.ipAddressesStatus,
    getList: (state) => state.list || [],
    getListStatus: (state) => state.listStatus,
  },
};

export default reverseDns;
