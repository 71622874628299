import { canUpdateInPlace, updateItem, updateList } from "@/utils/storeHelper";
import { clearListCache, createKey, get } from "@/utils/networkCache";
import {
  disableEdgecastApac,
  disableEdgecastSharedSsl,
  disableNetworking,
  enableEdgecastApac,
  enableEdgecastSharedSsl,
  enableNetworking,
  getReverseDns,
  hardReboot,
  purgeCache,
  restoreService,
  softReboot,
  startServer,
  stopServer,
  updateReverseDns,
} from "@/utils/serviceActionsHelper";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
  UPDATE_IN_PLACE,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_CLOUD_SERVER_KEY = "cloud-server";
const CACHE_CLOUD_SERVER_LIST_KEY = "cloud-server-list";
const CACHE_CLOUD_SERVER_CONSOLE_LOG_KEY = "cloud-server-console-log";

const cloudServers = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    serverHistory: [],
    serverLog: "",
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SERVER_HISTORY(state, payload) {
      state.serverHistory = payload;
    },
    SET_SERVER_LOG(state, payload) {
      state.serverLog = payload;
    },
    SET_SORT,
    SET_STATUS,
    UPDATE_IN_PLACE,
  },
  actions: {
    async get({ commit }, id) {
      commit("SET_STATUS", Status.LOADING);
      const response = await get(createKey(CACHE_CLOUD_SERVER_KEY, { id }), request.makeParse, [
        "getCloudServer",
        { id },
      ]);
      commit("SET_ITEM", response);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const response = await get(
        createKey(CACHE_CLOUD_SERVER_LIST_KEY, params),
        request.makeParse,
        ["getCloudServers", params]
      );
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    create(context, params) {
      const { locationId, hostname, osId, packageId, sshKeyIds, usePassword, addonIds } = params;
      return request.makeParse(
        "createCloudServer",
        {},
        { locationId, hostname, osId, packageId, sshKeyIds, usePassword, addonIds }
      );
    },
    // TODO: calculateTax needs to be consolidated with the similar action in the checkout store
    // thinking of adding a tax store
    // tax may or may not be calculated by different methods for different products (Cloud Servers vs Sites) in the future.
    async calculateTax(context, { packageId, term, addonIds }) {
      const salesTax = await request.makeParse(
        "getSalesTax",
        {},
        {
          packageId,
          term,
          addonIds,
        }
      );

      return salesTax;
    },
    async delete({ dispatch }, id) {
      const response = await request.makeParse("deleteCloudServer", { id });
      dispatch("refreshList");
      return response;
    },
    refreshList({ dispatch, state }) {
      clearListCache(CACHE_CLOUD_SERVER_LIST_KEY);
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    updateService({ commit, dispatch, state }, payload) {
      if (state.item.id === payload.id) {
        commit("SET_ITEM", payload);
      }
      if (canUpdateInPlace(state, payload)) {
        commit("UPDATE_IN_PLACE", payload);
      } else {
        dispatch("update", payload);
      }
    },
    async getConsoleLog({ commit }, id) {
      return await get(createKey(CACHE_CLOUD_SERVER_CONSOLE_LOG_KEY, { id }), request.makeParse, [
        "getConsoleLog",
        { id },
      ]).then((res) => {
        commit("SET_SERVER_LOG", res);
        return res;
      });
    },
    async getHistory(context, id) {
      return request
        .makeParse("getHistory", { id })
        .then((history) => context.commit("SET_SERVER_HISTORY", history));
    },
    async resize({ dispatch }, { id, data }) {
      return request.makeParse("resizeCloudServer", { id }, data).then((res) => {
        dispatch("update", res);
        return res;
      });
    },
    update({ commit, dispatch, state }, payload) {
      clearListCache(CACHE_CLOUD_SERVER_LIST_KEY);
      updateItem(commit, state, payload);
      updateList(dispatch, state);
    },
    disableEdgecastApac,
    disableEdgecastSharedSsl,
    disableNetworking,
    enableEdgecastApac,
    enableEdgecastSharedSsl,
    enableNetworking,
    getReverseDns,
    hardReboot,
    purgeCache,
    restoreService,
    softReboot,
    startServer,
    stopServer,
    updateReverseDns,
  },
};

export default cloudServers;
