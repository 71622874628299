import { shallowRef } from "vue";

const defaultClose = () => null;
const defaultConfirm = () => null;

class Modal {
  template;

  order = 1;

  id = null;

  data;

  promise;

  confirm;

  close;

  size;

  constructor(config = {}) {
    this.data = config.data;
    this.template = shallowRef(config.template);
    this.order = config.order || 1;
    this.id = config.id;
    this.confirm = config.confirm || defaultConfirm;
    this.close = config.close || defaultClose;
    this.size = config.size;
  }
}

export default Modal;
