import { constants } from "@/utils";
import { requiresStoreBuilder as requiresStoreBuilderSurvey } from "@/utils/surveys";
import store from "@/store";

const { ROUTES } = constants;
const termMap = {
  monthly: 1,
  annually: 12,
};
const propsHandler = (route) => {
  let {
    packageId,
    appId,
    locationId,
    addonId,
    couponCode,
    nickname,
    installApp,
    term,
    hasBC,
    affiliateId,
    firstName,
    lastName,
    email,
  } = route.query;

  // convert from 1/0 to true false
  if (installApp === undefined) {
    installApp = true;
  } else if (Number(installApp) === 1) {
    installApp = true;
  } else {
    installApp = installApp === "true" ? true : false;
  }
  hasBC = !(hasBC === undefined || Number(hasBC) === 0);
  term = ["monthly", "annually"].includes(term) ? term : "monthly";

  // handle location replacements
  if (constants.LOCATION_OVERRIDES[locationId]) {
    locationId = constants.LOCATION_OVERRIDES[locationId];
  }

  if (!locationId) {
    locationId = constants.DEFAULT_LOCATION_ID;
  }

  return {
    packageId: packageId ? Number(packageId) : null,
    appId: appId ? Number(appId) : null,
    locationId: locationId ? Number(locationId) : null,
    addonId: addonId ? Number(addonId) : null,
    couponCode,
    nickname,
    installApp,
    term: termMap[term],
    hasBC,
    affiliateId: affiliateId ? Number(affiliateId) : null,
    firstName,
    lastName,
    email,
  };
};

const checkoutContinue = (next) => {
  // performance marker
  store.dispatch("analytics/send", {
    name: "performanceMarker",
    data: {
      event: "CheckoutRouteBeforeEnter",
    },
  });
  next();
};

export default [
  {
    path: "/checkout",
    name: "checkout",
    component: () => import(/* webpackChunkName: "checkout" */ "@/pages/Checkout.vue"),
    meta: {
      hideSidebar: true,
      public: true,
      cart: true,
    },
    beforeEnter: (to, from, next) => {
      const packagedId = to?.query?.packageId;
      if (packagedId) {
        store
          .dispatch("packages/site/get", packagedId)
          .then((pkg) => {
            if (requiresStoreBuilderSurvey(pkg)) {
              store
                .dispatch("auth/userInfo")
                .catch(() => null)
                .finally(() => {
                  if (store.getters["auth/isLoggedIn"]) {
                    checkoutContinue(next);
                  } else {
                    if (store.getters["wizard/getCompletedSurvey"](packagedId)) {
                      checkoutContinue(next);
                    } else {
                      next({
                        name: ROUTES.WIZARD.WPQUICKSTART.STOREBUILDER.NAME,
                        query: { target: to?.fullPath },
                      });
                    }
                  }
                });
            } else {
              checkoutContinue(next);
            }
          })
          .catch(() => checkoutContinue(next));
      } else {
        checkoutContinue(next);
      }
    },
    props: propsHandler,
  },
  {
    path: "/checkout/package",
    name: "checkout-package-redirect",
    redirect: {
      name: "checkout-package",
    },
    props: propsHandler,
  },
  {
    path: "/checkout-domains",
    name: "checkout-domains",
    component: () => import(/* webpackChunkName: "checkout" */ "@/pages/CheckoutDomains.vue"),
    meta: {
      hideSidebar: true,
      public: true,
      cart: true,
    },
    beforeEnter: (to, from, next) => {
      checkoutContinue(next);
    },
  },
  {
    path: "/checkout/profile",
    name: "checkout-profile-redirect",
    redirect: {
      name: "checkout",
    },
    props: propsHandler,
  },
  {
    path: "/checkout/survey",
    name: "checkout-survey-redirect",
    redirect: {
      name: "checkout",
    },
    props: propsHandler,
  },
  {
    path: "/checkout/review",
    name: "checkout-review-redirect",
    redirect: {
      name: "checkout",
    },
    props: propsHandler,
  },
  {
    path: "/checkout-order/package",
    name: "checkout-package-redirect",
    redirect: {
      name: "checkout-package",
    },
    props: propsHandler,
  },
  {
    path: "/checkout-package",
    name: "checkout-package",
    meta: {
      sidebar: "CHECKOUT_PACKAGE",
      public: true,
      allowPurchase: false,
    },
    props: (route) => {
      const { packageId, appId, term } = route.query;

      return {
        packageId: packageId ? Number(packageId) : null,
        appId: appId ? Number(appId) : null,
        term: term ? Number(term) : undefined,
      };
    },
    component: () => import(/* webpackChunkName: "checkout" */ "@/pages/CheckoutPackage.vue"),
  },
  {
    path: "/checkout-error",
    name: "checkout-error",
    component: () => import(/* webpackChunkName: "checkout" */ "@/pages/CheckoutError.vue"),
    meta: {
      hideSidebar: true,
      public: true,
      cart: true,
    },
  },
];
