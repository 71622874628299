import store from "@/store";

const handler = (event) => {
  switch (event) {
    case "invoice:add":
      store.dispatch("invoices/refreshList");
      break;
    case "invoice:update":
      store.dispatch("invoices/refreshList");
      break;
  }
};

export default handler;
