import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_ITEM, SET_PAGINATION, SET_SORT, SET_STATUS } from "@/store/commonMutations";
import { updateItem, updateList } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "announcements";
const CACHE_SINGULAR_KEY = "announcement";

const announcements = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_CHUNK,
    SET_ITEM,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
  },
  actions: {
    async get({ commit }, id) {
      const res = await get(createKey(CACHE_SINGULAR_KEY, { id }), request.makeParse, [
        "getAnnouncement",
        { id },
      ]);
      commit("SET_ITEM", res);
      return res;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const res = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
        "getAnnouncements",
        params,
      ]);
      commit("SET_LIST", res.list);
      commit("SET_PAGINATION", res.pagination);
      commit("SET_STATUS", Status.LOADED);
      return res;
    },

    update({ commit, dispatch, state }, payload) {
      clearListCache("announcements");
      updateItem(commit, state, payload);
      updateList(dispatch, state);
    },
    refreshList({ dispatch, state }) {
      clearListCache("announcements");
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
  },
};

export default announcements;
