class Toast {
  variant = null;

  color = null;

  message = null;

  template = null;

  dismissible = true;

  id = null;

  constructor(config) {
    this.message = config?.message;
    this.template = config?.template;
    this.variant = config?.variant;
    switch (this.variant) {
      case "success":
        this.color = "successBorder";
        break;
      case "info":
        this.color = "highlightBorder";
        break;
      case "error":
        this.color = "dangerBorder";
        break;
      case "warning":
        this.color = "warningBorder";
        break;
      default:
        this.color = null;
    }
    this.id = config?.id;
    this.timeout =
      config.variant === "error"
        ? false
        : typeof config?.timeout !== "undefined"
        ? config?.timeout
        : 10000;
    this.dismissible = !!config.dismissible;
  }
}

export default Toast;
