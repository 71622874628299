import { addDays, parse } from "date-fns";
import { clearListCache, createKey, get } from "@/utils/networkCache";
import { constants } from "@/utils";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

// get the expiration date in seconds since epoch
// the legacy app sets the expiration to the 15th of the month noted on the card; continuing that convetion here but may change in future.
const formatExpiration = (exp) =>
  addDays(parse(exp, "MM/yyyy", new Date(2020, 15, 1)), 15).getTime() / 1000;

const CACHE_PLURAL_KEY = "payment-methods";
const CACHE_PAYPAY_PLURAL_KEY = "paypal-list";

export default {
  namespaced: true,
  state: {
    creditCard: null,
    payPal: null,
    list: [],
    pagination: null,
    status: new Status(),
    sort: null,
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_CREDIT_CARD(state, payload) {
      state.creditcard = payload;
    },
    SET_PAYPAL(state, payload) {
      state.payPal = payload;
    },
  },
  actions: {
    createCreditCard: async ({ commit }, { name, number, expiration, csv, primary }) => {
      // new card to be created
      const card = {
        name: name,
        number: number,
        cvv: csv,
        primary: primary,
      };

      if (expiration) {
        card["expiration"] = formatExpiration(expiration);
      }

      const creditCard = await request.makeParse("createCreditCard", {}, card);
      commit("SET_CREDIT_CARD", creditCard);
      return creditCard;
    },
    createPayPal: (context, nonce) => request.makeParse("createPayPalAccount", {}, { nonce }),
    clear({ commit }) {
      clearListCache(CACHE_PLURAL_KEY);
      commit("SET_LIST", []);
      commit("SET_STATUS", Status.UNINITIALIZED);
    },
    deleteCreditCard: (context, id) => request.makeParse("deleteCreditCard", { id }),
    deletePayPal: (context, id) => request.makeParse("deletePayPalAccount", { id }),
    get: async ({ commit }, id) => {
      const res = await get(createKey(CACHE_PLURAL_KEY, { id }), request.makeParse, [
        "getCreditCard",
        { id },
      ]);
      commit("SET_CREDIT_CARD", res);
      return res;
    },
    getCreditCardList: (context, params) =>
      get(CACHE_PLURAL_KEY, request.makeParse, ["getCreditCards", params]),
    getList: async ({ commit, dispatch, getters }, params) => {
      commit("SET_STATUS", Status.LOADING);
      try {
        const cards = await dispatch("getCreditCardList", params);
        try {
          const payPalAccounts = await dispatch("getPayPalList");
          const paymentMethods = [
            ...cards.list,
            ...payPalAccounts.list.map((item, index) => {
              item.primary = index === 0;
              return item;
            }),
          ];
          paymentMethods.sort((a, b) =>
            getters["isPrimary"](a) ? -1 : getters["isPrimary"](b) ? 1 : 0
          );
          commit("SET_LIST", paymentMethods);
          return paymentMethods;
        } catch (e) {
          cards.list.sort((a, b) =>
            getters["isPrimary"](a) ? -1 : getters["isPrimary"](b) ? 1 : 0
          );
          commit("SET_LIST", cards.list);
          return cards;
        } finally {
          commit("SET_PAGINATION", cards.pagination);
          commit("SET_STATUS", Status.LOADED);
        }
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    getPayPalClientAuthorization: () => request.makeParse("getPayPalToken"),
    getPayPalList: () => get(CACHE_PAYPAY_PLURAL_KEY, request.makeParse, ["getPayPalAccounts"]),
    refreshList: async ({ dispatch }) => {
      clearListCache(CACHE_PLURAL_KEY);
      clearListCache(CACHE_PAYPAY_PLURAL_KEY);
      await dispatch("getList");
    },
    setPrimaryCreditCard: (context, { id }) => request.makeParse("setPrimary", { id }),
    setPrimaryPaymentType: ({ rootGetters }, paymentType) => {
      const clientId = rootGetters["auth/getClient"]?.id;
      return request.makeParse("updateDefaultPayment", { id: clientId }, { paymentType });
    },
    updateCreditCard: async ({ commit }, { id, name, expiration, primary }) => {
      const card = {
        name: name,
        primary: primary,
      };

      if (expiration) {
        card["expiration"] = formatExpiration(expiration);
      }

      const response = await request.makeParse("updateCreditCard", { id }, card);
      commit("SET_CREDIT_CARD", response);
      return response;
    },
  },
  getters: {
    getList: (state) => state.list,
    getListStatus: (state) => state.status,
    getPrimary: (state, getters, rootState, rootGetters) => {
      const paymentType = rootGetters["auth/getPrimaryPaymentType"];
      return state.list.find((item) => item.primary && paymentType === item.paymentType);
    },
    hasPayPal: (state) =>
      state.list.find((item) => item.paymentType === constants.PAYMENT_METHODS.PAYPAL),
    hasPrimaryPaymentMethod: (state, getters, rootState, rootGetters) =>
      rootGetters["auth/getClient"]?.requiresCreditCard ? !!getters["getPrimary"] : true,
    isPrimary: (state, getters, rootState, rootGetters) => (paymentMethod) =>
      paymentMethod.primary &&
      paymentMethod.paymentType === rootGetters["auth/getPrimaryPaymentType"],
  },
};
