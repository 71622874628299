import constants from "@/utils/constants";

const support = [
  {
    path: "migration",
    name: "request-migration",
    meta: {
      breadcrumb: "SUPPORT_MIGRATION",
    },
    component: () =>
      import(/* webpackChunkName: "support" */ "@/pages/Dashboard/Support/RequestMigration.vue"),
  },
  {
    path: "migration-large",
    name: "migration-large",
    meta: {
      breadcrumb: "SUPPORT_MIGRATION",
    },
    component: () =>
      import(
        /* webpackChunkName: "support" */ "@/pages/Dashboard/Support/RequestLargeMigration.vue"
      ),
  },
  {
    path: "tickets",
    name: constants.ROUTES.TICKETS,
    meta: {
      breadcrumb: "SUPPORT_TICKETS",
    },
    component: () =>
      import(/* webpackChunkName: "support" */ "@/pages/Dashboard/Support/TicketsList.vue"),
  },
  {
    path: "tickets/:id",
    name: constants.ROUTES.TICKET,
    meta: {
      breadcrumb: "SUPPORT_TICKET",
      sidebar: "SUPPORT_TICKET",
    },
    component: () =>
      import(/* webpackChunkName: "support" */ "@/pages/Dashboard/Support/TicketDetail.vue"),
    props: (route) => ({
      ticketId: route.params.id,
    }),
  },
  {
    path: "system-status",
    name: "system-status",
    component: () =>
      import(/* webpackChunkName: "support" */ "@/pages/Dashboard/Support/SystemStatus.vue"),
  },
];

export default support;
