import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import { SET_ITEM } from "@/store/commonMutations";
import Status from "@/classes/Status";

const CACHE_CLOUDFLARE_KEY = "cloudflare";
const CACHE_CLOUDFLARE_ADDONS_KEY = "cloudflareAddons";
const CACHE_CLOUDFLARE_NAMESERVERS_KEY = "cloudflareNameServers";

const store = {
  namespaced: true,
  state: {
    addons: [],
    addonsStatus: new Status(),
    item: null,
    itemStatus: new Status(),
    siteId: null,
    nameServers: [],
    nameServersStatus: new Status(),
  },
  mutations: {
    SET_ADDONS: (state, payload) => (state.addons = payload),
    SET_ADDONS_STATUS: (state, payload) => (state.addonsStatus.value = payload),
    SET_NAMESERVERS: (state, payload) => (state.nameServers = payload),
    SET_NAMESERVERS_STATUS: (state, payload) => (state.nameServersStatus.value = payload),
    SET_ITEM,
    SET_ITEM_STATUS: (state, payload) => (state.itemStatus.value = payload),
    SET_SITE_ID: (state, payload) => (state.siteId = payload),
  },
  actions: {
    getEligible: (context, siteId) => request.makeParse("getSiteCloudflareEligible", { siteId }),
    getAddons: async ({ commit }) => {
      commit("SET_ADDONS_STATUS", Status.LOADING);
      try {
        const response = await get(CACHE_CLOUDFLARE_ADDONS_KEY, request.makeParse, [
          "getSiteCloudflareAddons",
        ]);
        commit("SET_ADDONS", response);
        commit("SET_ADDONS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ADDONS_STATUS", Status.ERROR);
      }
    },

    getNameServers: async ({ commit }) => {
      commit("SET_NAMESERVERS_STATUS", Status.LOADING);
      try {
        const response = await get(CACHE_CLOUDFLARE_NAMESERVERS_KEY, request.makeParse, [
          "getNameServers",
        ]);
        commit("SET_NAMESERVERS", response);
        commit("SET_NAMESERVERS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_NAMESERVERS_STATUS", Status.ERROR);
      }
    },

    get: async ({ commit, state }, siteId) => {
      const newStatus =
        (state.itemStatus.isLoaded || state.itemStatus.isUpdating) && siteId === state.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_ITEM_STATUS", newStatus);
      commit("SET_SITE_ID", siteId);
      try {
        const response = await get(createKey(CACHE_CLOUDFLARE_KEY, { siteId }), request.makeParse, [
          "getSiteCloudflareDetails",
          { siteId },
        ]);
        commit("SET_ITEM", response);
        commit("SET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        if (e?.response?.status === 404) {
          commit("SET_ITEM", null);
          commit("SET_ITEM_STATUS", Status.LOADED);
        } else {
          commit("SET_ITEM_STATUS", Status.ERROR);
        }
      }
    },
    add: (context, { siteId, addonId }) =>
      request.makeParse("addSiteCloudflare", { siteId, addonId }),
    remove: (context, siteId) => request.makeParse("removeSiteCloudflare", { siteId }),
    updateSecurityLevel: (context, { siteId, level }) =>
      request.makeParse("updateSiteCloudflareSecurityLevel", { siteId }, { level }),
    refreshItem: ({ dispatch }, siteId) => {
      clear(createKey(CACHE_CLOUDFLARE_KEY, { siteId }));
      if (siteId) {
        dispatch("get", siteId);
      }
    },
    setCloudflareStatus: ({ commit }, status) => {
      commit("SET_ITEM_STATUS", status);
    },
  },
  getters: {
    getAddons: ({ addons }) => addons,
    getAddonsStatus: ({ addonsStatus }) => addonsStatus,
    getNameServers: ({ nameServers }) => nameServers,
    getNameServersStatus: ({ nameServersStatus }) => nameServersStatus,
    get: (state) => state.item,
    getStatus: (state) => state.itemStatus,
  },
};

export default store;
