// import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";
import { request } from "leatherman-js";
import router from "@/router";
import Status from "@/classes/Status";

const updateSearchParam = (searchValue) => {
  // update relevant query parameter
  if (router.currentRoute.value.meta.domainSearch) {
    const query = router.currentRoute.value.query;
    if (query["Param1"]) {
      router.replace({ query: { ...query, Param1: searchValue } });
    } else {
      router.replace({ query: { ...query, domain: searchValue } });
    }
  }
};

const domainPackages = {
  namespaced: true,
  state: {
    item: null,
    list: [],
    searchValue: null,
    itemStatus: new Status(),
    status: new Status(),
    addons: [],
    addonStatus: new Status(),
    searchStatus: new Status(),
  },
  mutations: {
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_ITEM_STATUS(state, payload) {
      state.itemStatus.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_SEARCH_STATUS(state, payload) {
      state.searchStatus = payload;
    },
    SET_ADDON_LIST(state, payload) {
      state.addons = payload;
    },
    SET_ADDON_STATUS(state, payload) {
      state.addonStatus.value = payload;
    },
    SET_SEARCH_VALUE(state, payload) {
      state.searchValue = payload;
    },
  },
  actions: {
    async getList({ commit }) {
      commit("SET_STATUS", Status.LOADING);
      try {
        const response = await request.makeParse("getDomainPackages");
        commit("SET_ITEM", response.list);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    async get({ commit }, id) {
      commit("SET_ITEM_STATUS", Status.LOADING);
      try {
        const response = await request.makeParse("getDomainPackage", { id });
        commit("SET_ITEM", response);
        commit("SET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        console.log(e);
        commit("SET_ITEM_STATUS", Status.ERROR);
      }
    },
    async getAddons({ commit }) {
      commit("SET_ADDON_STATUS", Status.LOADING);
      try {
        const response = await request.makeParse("getDomainAddons");
        commit("SET_ADDON_LIST", response.list);
        commit("SET_ADDON_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ADDON_STATUS", Status.ERROR);
      }
    },
    async search({ commit }, payload) {
      commit("SET_SEARCH_STATUS", Status.LOADING);
      commit("SET_STATUS", Status.LOADING);
      commit("SET_SEARCH_VALUE", payload);
      try {
        const response = await request.makeParse("searchDomains", { domain: payload });
        const list = response.alternatives
          .filter((item) => item.is_available)
          .map((item) => {
            return {
              domain: item.domain,
              ...item.package,
            };
          });
        if (response.domain?.package?.tld) {
          list.unshift(response.domain.package);
        }
        commit("SET_LIST", list);
        commit("SET_STATUS", Status.LOADED);
        commit("SET_SEARCH_STATUS", Status.LOADED);

        updateSearchParam(payload);

        return response;
      } catch (e) {
        commit("SET_LIST", []);
        commit("SET_SEARCH_STATUS", Status.ERROR);
        commit("SET_STATUS", Status.ERROR);
      }
    },
    reset({ commit }) {
      commit("SET_LIST", []);
      commit("SET_SEARCH_STATUS", Status.UNINITIALIZED);
      commit("SET_STATUS", Status.UNINITIALIZED);
    },
  },
  getters: {
    // state, getters, rootState, rootGetters
    getById: (state) => (id) => state.list.find((item) => item.id === id),
  },
};

export default domainPackages;
