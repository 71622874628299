export const canUpdateInPlace = (state, payload) => {
  const index = state.list.findIndex((item) => item.id === payload.id);
  return index >= 0 && !state.chunk?.sort?.property;
};

export const updateItem = (commit, state, payload) => {
  if (payload.id === state.item?.id) {
    commit("SET_ITEM", payload);
  }
};

export const updateList = (dispatch, state) => {
  if (state.status.isInitialized) {
    dispatch("getList", {
      pageIndex: state.chunk?.pagination?.index,
      pageSize: state.chunk?.pagination?.size,
      sortProperty: state.chunk?.sort?.property,
      sortDirection: state.chunk?.sort?.direction,
    });
  }
};

export const getPaginationParams = (state) => {
  const { pagination, sort } = state.chunk || {};
  return {
    pageIndex: pagination?.index,
    pageSize: pagination?.size,
    sortProperty: sort?.property,
    sortDirection: sort?.direction,
  };
};

export const getAllPaginationParams = (state) => {
  const { pagination, sort } = state.allChunk || {};
  return {
    pageIndex: pagination?.index,
    pageSize: pagination?.size,
    sortProperty: sort?.property,
    sortDirection: sort?.direction,
  };
};

export const getSearchParams = (state) => {
  const { value, type } = state.search || {};
  return {
    searchValue: value,
    searchType: type,
  };
};
