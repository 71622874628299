class Status {
  static ERROR = -1;
  static UNINITIALIZED = 0;
  static LOADING = 1;
  static UPDATING = 2;
  static PARTIALLY_LOADED = 3;
  static LOADED = 4;

  constructor(value = Status.UNINITIALIZED) {
    Status.validate(value);
    this._value = value;
  }

  set value(value) {
    Status.validate(value);
    this._value = value;
  }

  get value() {
    return this._value;
  }

  get isInitialized() {
    return this._value !== Status.UNINITIALIZED;
  }

  get isUninitialized() {
    return this._value === Status.UNINITIALIZED;
  }

  get isLoading() {
    return this._value === Status.LOADING;
  }

  get isUpdating() {
    return this._value === Status.UPDATING;
  }

  get isLoaded() {
    return this._value === Status.LOADED;
  }

  get isErrored() {
    return this._value === Status.ERROR;
  }

  get hasLoaded() {
    return this._value === Status.LOADED || this._value === Status.ERROR;
  }

  toString() {
    switch (this._value) {
      case Status.ERROR:
        return "ERROR";
      case Status.UNINITIALIZED:
        return "UNINITIALIZED";
      case Status.LOADING:
        return "LOADING";
      case Status.UPDATING:
        return "UPDATING";
      case Status.PARTIALLY_LOADED:
        return "PARTIALLY_LOADED";
      case Status.LOADED:
        return "LOADED";
    }

    return "UNINITIALIZED";
  }

  static validate(value) {
    switch (value) {
      case Status.ERROR:
      case Status.UNINITIALIZED:
      case Status.LOADING:
      case Status.UPDATING:
      case Status.PARTIALLY_LOADED:
      case Status.LOADED:
        return;
      default:
        throw new Error(`Invalid Status: ${value}`);
    }
  }
}

export default Status;
