import { constants } from "@/utils";
import { parseParams } from "@/utils/routeParsing";

let locationRedirects = {};
if (process.env.VUE_APP_LOCATION_OVERRIDES) {
  process.env.VUE_APP_LOCATION_OVERRIDES.split(",").forEach((pair) => {
    const [key, value] = pair.split(":");
    locationRedirects[key] = Number(value);
  });
}

export default [
  /*
    CHECKOUT
    /cloud-account/add

    REQUEST CANCELLATION
    /service/158877/cancel

    CLOUD ACCOUNT
    /service/type/cloud-account ~> plans list
    /service/92000/plan ~> plan detail
    /service/92000/plan/stencils ~> plan stencils
    /cloud-account/11289 ~> site detail
    /service/92003 ~> dev/staging site
    /service/196166/plan/safe-harbor ~> plan safe harbor
    /service/196166/plan/safe-harbor/malware-scans
    /service/196166/plan/safe-harbor/threat-protection
    /service/196166/plan/safe-harbor/staging-environments
    /service/196166/plan/safe-harbor/magento-patches

    SHARED HOSTING
    /service/type/shared ~> shared hosting list
    /service/158877/shared ~> shared hosting detail
    /service/158877/shared/safe-harbor ~> shared hosting safe harbor
    /service/158877/shared/safe-harbor/malware-scans
    /service/158877/shared/safe-harbor/threat-protection
    /service/158877/shared/safe-harbor/staging-environments
    /service/158877/shared/safe-harbor/magento-patches

    DEDICATED
    /service/type/dedicated ~> dedicated list
    /service/72694 ~> dedicatec detail

    COLO
    /service/type/colo ~> colo list
    /service/72694 ~> colo detail

    DOMAIN
    /service/type/domain ~> domain list
    /service/37013 ~> domain detail

    RESELLER HOSTING
    /service/type/reseller-hosting ~> reseller hostig list

    OTHER HOSTING
    /service/type/other ~> other list
    /service/37013 ~> other detail

    AFFILIATE
    /affiliate-link ~> affiliate links list
    /affiliate-link/98 ~> affiliate link detail
    /affiliate-referral ~> affiliate referral
    /affiliate-credit ~> affiliate credit list

    SSL LIST
    /ssl ~> ssl list

    SUPPORT
    /ticket ~> tickets list
    /ticket/1529258 ~> ticket detail
    /support/migration ~> request migration

    BILLINGS
    /billing/invoice ~> invoice list
    /invoice/1259976 ~> invoice detail
    /billing/credit ~> credits list
    /billing/creditcard ~> credit card list
    /billing/payment ~> payments list
    /billing/order ~> orders list
    /order/91338 ~. order detail
    /tax ~> tax exemption information

    DNS
    /dns/dns-zone ~> dns zone list
    /dns-zone/27285 ~> dns zone detail

    SETTINGS
    /ssh-key ~> ssh keys list
    /api-token ~> api token list
    /my-refer-a-friend ~> refer a friend detail
    /refer-a-friend/48830 ~> refer a friend redirect
    /address ~> address list
    /team ~> team list and permissions

    PROFILE
    /user/2fa ~> 2fa enable

    ANNOUNCEMENTS
    /announcement ~> announcement list
    /announcement/1234 ~> announcement detail
  */
  {
    path: "/cloud-account/add:packageInfo(.*)",
    component: () => import("@/pages/Redirect.vue"),
    redirect: (to) => {
      // params no longer included: type, domain, install, redirect, stencil_id,
      let {
        package_id,
        app_id,
        cloud_id,
        addon,
        discount_code,
        term,
        nickname,
        installApp,
        hasBC,
        affiliate,
      } = parseParams(to.path, to.query);

      term = term === 12 ? "annually" : "monthly";

      // handle location replacements
      if (locationRedirects[cloud_id]) {
        cloud_id = locationRedirects[cloud_id];
      }

      return {
        name: "checkout",
        query: {
          packageId: package_id,
          appId: app_id,
          locationId: cloud_id,
          addonId: addon,
          couponCode: discount_code,
          term,
          nickname,
          installApp,
          hasBC,
          affiliateId: affiliate,
        },
      };
    },
  },
  {
    path: "/user/:userId/join/:authToken/:clientId",
    name: "join-proxy",
    redirect: (to) => {
      const { userId, authToken, clientId } = to.params;
      return {
        name: "join",
        query: {
          userId,
          authToken,
          clientId,
        },
      };
    },
  },
  {
    path: "/user/:userId/reset-password/:authToken/:requestId",
    name: "reset-password-proxy",
    redirect: (to) => {
      const { userId, authToken, requestId } = to.params;
      return {
        name: "reset-password",
        query: {
          userId,
          authToken,
          requestId,
        },
      };
    },
  },
  {
    path: "/refer-a-friend/:clientId",
    redirect: (to) => {
      const { clientId } = to.params;
      if (process.env.VUE_APP_REFERAFRIEND_URL) {
        window.location.href = `${process.env.VUE_APP_REFERAFRIEND_URL}${clientId}`;
      } else {
        return {
          name: constants.ROUTES.HOME,
        };
      }
    },
  },
  {
    path: "/ticket/:ticketId",
    redirect: (to) => {
      const { ticketId } = to.params;
      return {
        name: constants.ROUTES.TICKET,
        params: {
          id: ticketId,
        },
      };
    },
  },
  {
    path: "/ticket",
    redirect: { name: "tickets" },
  },
];
