import { clearListCache, createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "siteDbs";

const store = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
    siteId: null,
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      commit("SET_SITE_ID", params.siteId);
      try {
        const response = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
          "getSiteDbs",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    quickAdd(context, { siteId, dbName, username, readOnly }) {
      return request.makeParse(
        "quickAddSiteDbWithUser",
        { siteId },
        { dbName, username, readOnly }
      );
    },
    create(context, { siteId, dbName }) {
      return request.makeParse("createSiteDb", { siteId }, { dbName });
    },
    delete(context, { siteId, dbName }) {
      return request.makeParse("deleteSiteDb", { siteId, dbName });
    },
    refreshList({ dispatch, state }, params = {}) {
      params = { ...params, siteId: state.siteId };
      clearListCache(createKey(CACHE_PLURAL_KEY, params));
      if (state.siteId) {
        dispatch("getList", params);
      }
    },
  },
  getters: {
    getList: (state) => {
      return state.list;
    },
    getStatus: (state) => {
      return state.status;
    },
  },
};

export default store;
