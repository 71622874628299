import constants from "@/utils/constants";

export const NAMES = {
  STOREBUILDER: "StoreBuilder by Nexcess",
};

export const TYPES = {
  STOREBUILDER: "STOREBUILDER",
  MEMBERSHIP: "MEMBERSHIP",
};

export const getSurveyType = (pkg) => {
  if (!pkg) return null;

  if (constants.PACKAGE_SURVEYS.WPQUICKSTART.STOREBUILDER.LABELS.includes(pkg.label)) {
    return TYPES.STOREBUILDER;
  }

  if (constants.PACKAGE_SURVEYS.WPQUICKSTART.MEMBERSHIP.LABELS.includes(pkg.label)) {
    return TYPES.MEMBERSHIP;
  }

  return null;
};

export const requiresStoreBuilder = (pkg) => getSurveyType(pkg) === TYPES.STOREBUILDER;

export const requireSurvey = (pkg) => {
  if (!pkg) return false;

  switch (getSurveyType(pkg)) {
    case TYPES.STOREBUILDER:
      return constants.PACKAGE_SURVEYS.WPQUICKSTART.STOREBUILDER.UI_OVERRIDE_LABELS.includes(
        pkg.label
      );
    case TYPES.MEMBERSHIP:
      return constants.PACKAGE_SURVEYS.WPQUICKSTART.MEMBERSHIP.UI_OVERRIDE_LABELS.includes(
        pkg.label
      );
    default:
      return false;
  }
};
