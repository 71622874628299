import { request } from "leatherman-js";

const store = {
  namespaced: true,
  actions: {
    uninstall: (context, id) => request.makeParse("deleteSiteSslCert", { siteId: id }),
  },
};

export default store;
