import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "dns-zone:update":
    case "dns-zone:delete":
      store.dispatch("dnsZones/refreshList");
      break;
    case "dns-record:add":
    case "dns-record:update":
      store.dispatch("dnsZones/records/refreshList", data.zone.id);
      if (data.type === "PTR") {
        store.dispatch("rdnsRecords/refreshList");
      }
      break;
    case "dns-record:delete":
      store.dispatch("dnsZones/records/refreshListByRecordId", data.id);
      if (data.type === "PTR") {
        store.dispatch("rdnsRecords/refreshList");
      }
      break;
    case "api-task:update":
      if (data.action === "dns-record:add" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Your DNS Zone Record was added successfully!",
        });
      }
      if (data.action === "dns-record:edit" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Your DNS Zone Record was edited successfully!",
        });
      }
      if (data.action === "dns-record:delete" && data.status === "success") {
        store.dispatch("toasts/addToast", {
          variant: "success",
          message: "Your DNS Zone Record was deleted successfully!",
        });
      }
      if (
        data.action === "dns-record:add" &&
        (data.status === "rejected" || data.status === "failure")
      ) {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "Something went wrong while adding your DNS Zone Record.",
        });
      }
      if (
        data.action === "dns-record:edit" &&
        (data.status === "rejected" || data.status === "failure")
      ) {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "Something went wrong while editing your DNS Zone Record.",
        });
      }
      if (
        data.action === "dns-record:delete" &&
        (data.status === "rejected" || data.status === "failure")
      ) {
        store.dispatch("toasts/addToast", {
          variant: "error",
          message: "Something went wrong while deleting your DNS Zone Record.",
        });
      }
      break;
  }
};

export default handler;
