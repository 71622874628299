import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const DOMAINS_CACHE_KEY = "getSiteLetsEncryptDomains";

const store = {
  namespaced: true,
  state: {
    info: false,
    infoStatus: new Status(),
    enabled: false,
    enabledStatus: new Status(),
    domains: [],
    domainsStatus: new Status(),
    siteId: null,
  },
  mutations: {
    SET_LETSENCRYPT_INFO(state, payload) {
      state.info = payload;
    },
    SET_LETSENCRYPT_INFO_STATUS(state, payload) {
      state.infoStatus.value = payload;
    },
    SET_LETSENCRYPT_ENABLED(state, payload) {
      state.enabled = payload;
    },
    SET_LETSENCRYPT_ENABLED_STATUS(state, payload) {
      state.enabledStatus.value = payload;
    },
    SET_LETSENCRYPT_DOMAINS(state, payload) {
      state.domains = payload;
    },
    SET_LETSENCRYPT_DOMAINS_STATUS(state, payload) {
      state.domainsStatus.value = payload;
    },
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async getLetsEncryptStatus({ commit }, siteId) {
      commit("SET_LETSENCRYPT_ENABLED_STATUS", Status.LOADING);
      commit("SET_SITE_ID", siteId);
      try {
        const enabled = await request.makeParse("getSiteLetsEncryptStatus", { siteId });
        commit("SET_LETSENCRYPT_ENABLED", enabled);
        commit("SET_LETSENCRYPT_ENABLED_STATUS", Status.LOADED);
        return enabled;
      } catch (e) {
        commit("SET_LETSENCRYPT_ENABLED_STATUS", Status.ERROR);
      }
    },
    async getInfo({ commit }, siteId) {
      commit("SET_LETSENCRYPT_INFO_STATUS", Status.LOADING);
      commit("SET_SITE_ID", siteId);
      try {
        const response = await get(createKey("info", { siteId }), request.makeParse, [
          "getSiteLetsEncryptInfo",
          { siteId },
        ]);
        commit("SET_LETSENCRYPT_INFO", response);
        commit("SET_LETSENCRYPT_INFO_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LETSENCRYPT_INFO_STATUS", Status.ERROR);
      }
    },
    issueCertificate(context, { siteId, domains }) {
      return request.makeParse("issueSiteLetsEncryptCertificate", { siteId }, { domains });
    },
    enableLetsEncrypt({ commit }, siteId) {
      commit("SET_LETSENCRYPT_ENABLED_STATUS", Status.LOADING);
      try {
        return request.makeParse("enableSiteLetsEncrypt", { siteId });
      } catch (e) {
        commit("SET_LETSENCRYPT_ENABLED_STATUS", Status.ERROR);
      }
    },
    disableLetsEncrypt({ commit }, siteId) {
      commit("SET_LETSENCRYPT_ENABLED_STATUS", Status.LOADING);
      try {
        return request.makeParse("disableSiteLetsEncrypt", { siteId });
      } catch (e) {
        commit("SET_LETSENCRYPT_ENABLED_STATUS", Status.ERROR);
      }
    },
    async getDomains({ commit }, siteId) {
      commit("SET_LETSENCRYPT_DOMAINS_STATUS", Status.LOADING);
      commit("SET_SITE_ID", siteId);
      try {
        const response = await get(createKey(DOMAINS_CACHE_KEY, { siteId }), request.makeParse, [
          "getSiteLetsEncryptDomains",
          { siteId },
        ]);
        commit("SET_LETSENCRYPT_DOMAINS", response);
        commit("SET_LETSENCRYPT_DOMAINS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LETSENCRYPT_DOMAINS_STATUS", Status.ERROR);
      }
    },
    refreshDomains({ state, dispatch }) {
      clear(createKey(DOMAINS_CACHE_KEY, { siteId: state.siteId }));
      if (state.siteId) {
        dispatch("getDomains", state.siteId);
      }
    },
    setLetsEncryptEnabled({ commit }, enabled) {
      commit("SET_LETSENCRYPT_ENABLED_STATUS", Status.LOADED);
      commit("SET_LETSENCRYPT_ENABLED", enabled);
    },
  },
  getters: {
    getInfo: (state) => {
      return state.info;
    },
    getInfoStatus: (state) => {
      return state.infoStatus;
    },
    getEnabled: (state) => {
      return state.enabled;
    },
    getEnabledStatus: (state) => {
      return state.enabledStatus;
    },
    getDomains: (state) => {
      return state.domains;
    },
    getDomainsStatus: (state) => {
      return state.domainsStatus;
    },
    getSiteId: (state) => {
      return state.siteId;
    },
  },
};

export default store;
