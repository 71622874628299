import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "api-task:update":
      if (data.status === "success") {
        switch (data.action) {
          case "paypal-account:add":
          case "paypal-account:delete":
            store.dispatch("paymentMethods/refreshList");
            break;
        }
      }
      if (data.status === "failure") {
        switch (data.action) {
          case "paypal-account:add":
            store.dispatch("paymentMethods/refreshList");
            store.dispatch("toasts/addToast", {
              variant: "error",
              message: "Something went wrong trying to add your PayPal payment method.",
            });
            break;
        }
      }
      break;
    case "creditcard:add":
    case "creditcard:delete":
    case "creditcard:update":
      store.dispatch("paymentMethods/refreshList");
      break;
  }
};

export default handler;
