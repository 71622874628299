import { createKey, get } from "../../utils/networkCache";
import { SET_CHUNK, SET_LIST, SET_PAGINATION, SET_SORT, SET_STATUS } from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const partnerCredits = {
  namespaced: true,
  state: {
    chunk: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
  },
  actions: {
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const res = await get(createKey("partner-credits-list", params), request.makeParse, [
        "getPartnerCredits",
        params,
      ]);
      commit("SET_LIST", res.list);
      commit("SET_PAGINATION", res.pagination);
      commit("SET_STATUS", Status.LOADED);
      return res;
    },
  },
};

export default partnerCredits;
