import { request } from "leatherman-js";
import Status from "@/classes/Status";

const os = {
  namespaced: true,
  state: {
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
  },
  actions: {
    async getList({ commit, state }) {
      const newStatus =
        state.status.isLoaded || state.status.isUpdating ? Status.UPDATING : Status.LOADING;
      commit("SET_STATUS", newStatus);
      try {
        const osList = await request.makeParse("getOses");
        commit("SET_LIST", osList);
        commit("SET_STATUS", Status.LOADED);
        return osList;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    getList: (state) => {
      return state.list;
    },
    getStatus: (state) => {
      return state.status;
    },
    getByDistribution: (state) => (distribution) => {
      return state.list.filter((osTemplate) => osTemplate.os.distribution === distribution);
    },
  },
};

export default os;
