import { createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_KEY = "sitePermissions";

const module = {
  namespaced: true,
  state: {
    data: null,
    status: new Status(),
  },
  mutations: {
    SET_DATA: (state, payload) => (state.data = payload),
    SET_STATUS: (state, payload) => (state.status.value = payload),
  },
  actions: {
    get: async ({ commit, state }, id) => {
      const newStatus =
        (state.status.isLoaded || state.status.isUpdating) && id === state.item?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_KEY, { id: Number(id) }), request.makeParse, [
          "getSitePermissions",
          { id },
        ]);
        commit("SET_DATA", response);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
  },
  getters: {
    get: (state) => state.data,
    isVisualComparisonAllowed: (state) => state.data?.visualComparison?.allowed,
    getStatus: (state) => state.status,
  },
};

export default module;
