import { faAddressCard as faAddressCardRegular } from "@fortawesome/pro-regular-svg-icons/faAddressCard";
import { faAddressCard as faAddressCardSolid } from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import { faArrowDownToLine as faArrowDownToLineRegular } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { faArrowDownToLine as faArrowDownToLineSolid } from "@fortawesome/pro-solid-svg-icons/faArrowDownToLine";
import { faArrowRightArrowLeft as faArrowRightArrowLeftRegular } from "@fortawesome/pro-regular-svg-icons/faArrowRightArrowLeft";
import { faArrowRightArrowLeft as faArrowRightArrowLeftSolid } from "@fortawesome/pro-solid-svg-icons/faArrowRightArrowLeft";
import { faArrowsRotate as faArrowsRotateRegular } from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import { faArrowsRotate as faArrowsRotateSolid } from "@fortawesome/pro-solid-svg-icons/faArrowsRotate";
import { faArrowUpArrowDown as faArrowUpArrowDownDuotone } from "@fortawesome/pro-duotone-svg-icons/faArrowUpArrowDown";
import { faArrowUpArrowDown as faArrowUpArrowDownSolid } from "@fortawesome/pro-solid-svg-icons/faArrowUpArrowDown";
import { faArrowUpRight as faArrowUpRightRegular } from "@fortawesome/pro-regular-svg-icons/faArrowUpRight";
import { faArrowUpRight as faArrowUpRightSolid } from "@fortawesome/pro-solid-svg-icons/faArrowUpRight";
import { faBarsSort as faBarsSortRegular } from "@fortawesome/pro-regular-svg-icons/faBarsSort";
import { faBarsSort as faBarsSortSolid } from "@fortawesome/pro-solid-svg-icons/faBarsSort";
import { faBell as faBellRegular } from "@fortawesome/pro-regular-svg-icons/faBell";
import { faBell as faBellSolid } from "@fortawesome/pro-solid-svg-icons/faBell";
import { faBooks as faBooksRegular } from "@fortawesome/pro-regular-svg-icons/faBooks";
import { faBooks as faBooksSolid } from "@fortawesome/pro-solid-svg-icons/faBooks";
import { faBox as faBoxRegular } from "@fortawesome/pro-regular-svg-icons/faBox";
import { faBox as faBoxSolid } from "@fortawesome/pro-solid-svg-icons/faBox";
import { faBuildings as faBuildingsRegular } from "@fortawesome/pro-regular-svg-icons/faBuildings";
import { faBuildings as faBuildingsSolid } from "@fortawesome/pro-solid-svg-icons/faBuildings";
import { faBullhorn as faBullhornRegular } from "@fortawesome/pro-regular-svg-icons/faBullhorn";
import { faBullhorn as faBullhornSolid } from "@fortawesome/pro-solid-svg-icons/faBullhorn";
import { faCabinetFiling as faCabinetFilingRegular } from "@fortawesome/pro-regular-svg-icons/faCabinetFiling";
import { faCabinetFiling as faCabinetFilingSolid } from "@fortawesome/pro-solid-svg-icons/faCabinetFiling";
import { faCaretDown as faCaretDownRegular } from "@fortawesome/pro-regular-svg-icons/faCaretDown";
import { faCaretDown as faCaretDownSolid } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCartMinus as faCartMinusRegular } from "@fortawesome/pro-regular-svg-icons/faCartMinus";
import { faCartMinus as faCartMinusSolid } from "@fortawesome/pro-solid-svg-icons/faCartMinus";
import { faCartPlus as faCartPlusRegular } from "@fortawesome/pro-regular-svg-icons/faCartPlus";
import { faCartPlus as faCartPlusSolid } from "@fortawesome/pro-solid-svg-icons/faCartPlus";
import { faCartShopping as faCartShoppingRegular } from "@fortawesome/pro-regular-svg-icons/faCartShopping";
import { faCartShopping as faCartShoppingSolid } from "@fortawesome/pro-solid-svg-icons/faCartShopping";
import { faChartLine as faChartLineRegular } from "@fortawesome/pro-regular-svg-icons/faChartLine";
import { faChartLine as faChartLineSolid } from "@fortawesome/pro-solid-svg-icons/faChartLine";
import { faChartNetwork as faChartNetworkRegular } from "@fortawesome/pro-regular-svg-icons/faChartNetwork";
import { faChartNetwork as faChartNetworkSolid } from "@fortawesome/pro-solid-svg-icons/faChartNetwork";
import { faCheck as faCheckRegular } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faCheck as faCheckSolid } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faChevronDown as faChevronDownRegular } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faChevronDown as faChevronDownSolid } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faChevronLeft as faChevronLeftSolid } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faCircle1 as faCircle1Light } from "@fortawesome/pro-light-svg-icons/faCircle1";
import { faCircle2 as faCircle2Light } from "@fortawesome/pro-light-svg-icons/faCircle2";
import { faCircle3 as faCircle3Light } from "@fortawesome/pro-light-svg-icons/faCircle3";
import { faCircle4 as faCircle4Light } from "@fortawesome/pro-light-svg-icons/faCircle4";
import { faCircle5 as faCircle5Light } from "@fortawesome/pro-light-svg-icons/faCircle5";
import { faCircle6 as faCircle6Light } from "@fortawesome/pro-light-svg-icons/faCircle6";
import { faCircle7 as faCircle7Light } from "@fortawesome/pro-light-svg-icons/faCircle7";
import { faCircle8 as faCircle8Light } from "@fortawesome/pro-light-svg-icons/faCircle8";
import { faCircleCheck as faCircleCheckRegular } from "@fortawesome/pro-regular-svg-icons/faCircleCheck";
import { faCircleCheck as faCircleCheckSolid } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleDollar as faCircleDollarRegular } from "@fortawesome/pro-regular-svg-icons/faCircleDollar";
import { faCircleDollar as faCircleDollarSolid } from "@fortawesome/pro-solid-svg-icons/faCircleDollar";
import { faCircleInfo as faCircleInfoRegular } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faCircleInfo as faCircleInfoSolid } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { faCircleMinus as faCircleMinusRegular } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import { faCirclePlus as faCirclePlusRegular } from "@fortawesome/pro-regular-svg-icons/faCirclePlus";
import { faCirclePlus as faCirclePlusSolid } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { faCircleQuestion as faCircleQuestionRegular } from "@fortawesome/pro-regular-svg-icons/faCircleQuestion";
import { faCircleQuestion as faCircleQuestionSolid } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleXmark as faCircleXmarkRegular } from "@fortawesome/pro-regular-svg-icons/faCircleXmark";
import { faCircleXmark as faCircleXmarkSolid } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faClock as faClockRegular } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faCloud as faCloudRegular } from "@fortawesome/pro-regular-svg-icons/faCloud";
import { faCloud as faCloudSolid } from "@fortawesome/pro-solid-svg-icons/faCloud";
import { faCodeCompare as faCodeCompareRegular } from "@fortawesome/pro-regular-svg-icons/faCodeCompare";
import { faCodeCompare as faCodeCompareSolid } from "@fortawesome/pro-solid-svg-icons/faCodeCompare";
import { faCode as faCodeRegular } from "@fortawesome/pro-regular-svg-icons/faCode";
import { faCode as faCodeSolid } from "@fortawesome/pro-solid-svg-icons/faCode";
import { faCoinBlank as faCoinBlankRegular } from "@fortawesome/pro-regular-svg-icons/faCoinBlank";
import { faCoinBlank as faCoinBlankSolid } from "@fortawesome/pro-solid-svg-icons/faCoinBlank";
import { faComment as faCommentRegular } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faComment as faCommentSolid } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faComputer as faComputerRegular } from "@fortawesome/pro-regular-svg-icons/faComputer";
import { faComputer as faComputerSolid } from "@fortawesome/pro-solid-svg-icons/faComputer";
import { faCopy as faCopyRegular } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faCopy as faCopySolid } from "@fortawesome/pro-solid-svg-icons/faCopy";
import { faCreditCard as faCreditCardRegular } from "@fortawesome/pro-regular-svg-icons/faCreditCard";
import { faCreditCard as faCreditCardSolid } from "@fortawesome/pro-solid-svg-icons/faCreditCard";
import { faCrosshairs as faCrosshairsRegular } from "@fortawesome/pro-regular-svg-icons/faCrosshairs";
import { faCrosshairs as faCrosshairsSolid } from "@fortawesome/pro-solid-svg-icons/faCrosshairs";
import { faDatabase as faDatabaseRegular } from "@fortawesome/pro-regular-svg-icons/faDatabase";
import { faDatabase as faDatabaseSolid } from "@fortawesome/pro-solid-svg-icons/faDatabase";
import { faDisplayCode as faDisplayCodeRegular } from "@fortawesome/pro-regular-svg-icons/faDisplayCode";
import { faDisplayCode as faDisplayCodeSolid } from "@fortawesome/pro-solid-svg-icons/faDisplayCode";
import { faDoorOpen as faDoorOpenRegular } from "@fortawesome/pro-regular-svg-icons/faDoorOpen";
import { faDoorOpen as faDoorOpenSolid } from "@fortawesome/pro-solid-svg-icons/faDoorOpen";
import { faEllipsisVertical as faEllipsisVerticalSolid } from "@fortawesome/pro-solid-svg-icons/faEllipsisVertical";
import { faEnvelope as faEnvelopeRegular } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faEnvelope as faEnvelopeSolid } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faEye as faEyeRegular } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faEyeSlash as faEyeSlashRegular } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { faEyeSlash as faEyeSlashSolid } from "@fortawesome/pro-solid-svg-icons/faEyeSlash";
import { faEye as faEyeSolid } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faFaceFrown as faFaceFrownRegular } from "@fortawesome/pro-regular-svg-icons/faFaceFrown";
import { faFaceFrown as faFaceFrownSolid } from "@fortawesome/pro-solid-svg-icons/faFaceFrown";
import { faFaceLaughBeam as faFaceLaughBeamLight } from "@fortawesome/pro-light-svg-icons/faFaceLaughBeam";
import { faFaceLaughBeam as faFaceLaughBeamRegular } from "@fortawesome/pro-regular-svg-icons/faFaceLaughBeam";
import { faFaceLaughBeam as faFaceLaughBeamSolid } from "@fortawesome/pro-solid-svg-icons/faFaceLaughBeam";
import { faFileImport as faFileImportRegular } from "@fortawesome/pro-regular-svg-icons/faFileImport";
import { faFileImport as faFileImportSolid } from "@fortawesome/pro-solid-svg-icons/faFileImport";
import { faFileInvoiceDollar as faFileInvoiceDollarRegular } from "@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar";
import { faFileInvoiceDollar as faFileInvoiceDollarSolid } from "@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar";
import { faGear as faGearRegular } from "@fortawesome/pro-regular-svg-icons/faGear";
import { faGear as faGearSolid } from "@fortawesome/pro-solid-svg-icons/faGear";
import { faGift as faGiftRegular } from "@fortawesome/pro-regular-svg-icons/faGift";
import { faGift as faGiftSolid } from "@fortawesome/pro-solid-svg-icons/faGift";
import { faGlobe as faGlobeRegular } from "@fortawesome/pro-regular-svg-icons/faGlobe";
import { faGlobe as faGlobeSolid } from "@fortawesome/pro-solid-svg-icons/faGlobe";
import { faHandshake as faHandshakeRegular } from "@fortawesome/pro-regular-svg-icons/faHandshake";
import { faHandshake as faHandshakeSolid } from "@fortawesome/pro-solid-svg-icons/faHandshake";
import { faHardDrive as faHardDriveRegular } from "@fortawesome/pro-regular-svg-icons/faHardDrive";
import { faHardDrive as faHardDriveSolid } from "@fortawesome/pro-solid-svg-icons/faHardDrive";
import { faHouse as faHouseRegular } from "@fortawesome/pro-regular-svg-icons/faHouse";
import { faHouse as faHouseSolid } from "@fortawesome/pro-solid-svg-icons/faHouse";
import { faIdBadge as faIdBadgeRegular } from "@fortawesome/pro-regular-svg-icons/faIdBadge";
import { faIdBadge as faIdBadgeSolid } from "@fortawesome/pro-solid-svg-icons/faIdBadge";
import { faKey as faKeyRegular } from "@fortawesome/pro-regular-svg-icons/faKey";
import { faKey as faKeySolid } from "@fortawesome/pro-solid-svg-icons/faKey";
import { faLink as faLinkRegular } from "@fortawesome/pro-regular-svg-icons/faLink";
import { faLink as faLinkSolid } from "@fortawesome/pro-solid-svg-icons/faLink";
import { faListCheck as faListCheckRegular } from "@fortawesome/pro-regular-svg-icons/faListCheck";
import { faListCheck as faListCheckSolid } from "@fortawesome/pro-solid-svg-icons/faListCheck";
import { faLoader as faLoaderDuotone } from "@fortawesome/pro-duotone-svg-icons/faLoader";
import { faLock as faLockRegular } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faLock as faLockSolid } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faMagnifyingGlass as faMagnifyingGlassRegular } from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlass";
import { faMagnifyingGlass as faMagnifyingGlassSolid } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faMessageDots as faMessageDotsRegular } from "@fortawesome/pro-regular-svg-icons/faMessageDots";
import { faMessageDots as faMessageDotsSolid } from "@fortawesome/pro-solid-svg-icons/faMessageDots";
import { faMessagePen as faMessagePenRegular } from "@fortawesome/pro-regular-svg-icons/faMessagePen";
import { faMessagePen as faMessagePenSolid } from "@fortawesome/pro-solid-svg-icons/faMessagePen";
import { faPartyHorn as faPartyHornRegular } from "@fortawesome/pro-regular-svg-icons/faPartyHorn";
import { faPen as faPenRegular } from "@fortawesome/pro-regular-svg-icons/faPen";
import { faPen as faPenSolid } from "@fortawesome/pro-solid-svg-icons/faPen";
import { faPenToSquare as faPenToSquareRegular } from "@fortawesome/pro-regular-svg-icons/faPenToSquare";
import { faPenToSquare as faPenToSquareSolid } from "@fortawesome/pro-solid-svg-icons/faPenToSquare";
import { faPersonShelter as faPersonShelterRegular } from "@fortawesome/pro-regular-svg-icons/faPersonShelter";
import { faPersonShelter as faPersonShelterSolid } from "@fortawesome/pro-solid-svg-icons/faPersonShelter";
import { faPhone as faPhoneRegular } from "@fortawesome/pro-regular-svg-icons/faPhone";
import { faPhone as faPhoneSolid } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faPhoneVolume as faPhoneVolumeRegular } from "@fortawesome/pro-regular-svg-icons/faPhoneVolume";
import { faPhoneVolume as faPhoneVolumeSolid } from "@fortawesome/pro-solid-svg-icons/faPhoneVolume";
import { faPuzzlePiece as faPuzzlePieceRegular } from "@fortawesome/pro-regular-svg-icons/faPuzzlePiece";
import { faPuzzlePiece as faPuzzlePieceSolid } from "@fortawesome/pro-solid-svg-icons/faPuzzlePiece";
import { faRadar as faRadarRegular } from "@fortawesome/pro-regular-svg-icons/faRadar";
import { faRadar as faRadarSolid } from "@fortawesome/pro-solid-svg-icons/faRadar";
import { faReceipt as faReceiptRegular } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import { faReceipt as faReceiptSolid } from "@fortawesome/pro-solid-svg-icons/faReceipt";
import { faRectanglesMixed as faRectanglesMixedRegular } from "@fortawesome/pro-regular-svg-icons/faRectanglesMixed";
import { faRectanglesMixed as faRectanglesMixedSolid } from "@fortawesome/pro-solid-svg-icons/faRectanglesMixed";
import { faScrewdriverWrench as faScrewdriverWrenchRegular } from "@fortawesome/pro-regular-svg-icons/faScrewdriverWrench";
import { faScrewdriverWrench as faScrewdriverWrenchSolid } from "@fortawesome/pro-solid-svg-icons/faScrewdriverWrench";
import { faServer as faServerRegular } from "@fortawesome/pro-regular-svg-icons/faServer";
import { faServer as faServerSolid } from "@fortawesome/pro-solid-svg-icons/faServer";
import { faShare as faShareRegular } from "@fortawesome/pro-regular-svg-icons/faShare";
import { faShare as faShareSolid } from "@fortawesome/pro-solid-svg-icons/faShare";
import { faShieldCheck as faShieldCheckRegular } from "@fortawesome/pro-regular-svg-icons/faShieldCheck";
import { faShieldCheck as faShieldCheckSolid } from "@fortawesome/pro-solid-svg-icons/faShieldCheck";
import { faShield as faShieldRegular } from "@fortawesome/pro-regular-svg-icons/faShield";
import { faShield as faShieldSolid } from "@fortawesome/pro-solid-svg-icons/faShield";
import { faShop as faShopRegular } from "@fortawesome/pro-regular-svg-icons/faShop";
import { faShop as faShopSolid } from "@fortawesome/pro-solid-svg-icons/faShop";
import { faSlider as faSliderRegular } from "@fortawesome/pro-regular-svg-icons/faSlider";
import { faSlider as faSliderSolid } from "@fortawesome/pro-solid-svg-icons/faSlider";
import { faSparkles as faSparklesRegular } from "@fortawesome/pro-regular-svg-icons/faSparkles";
import { faSparkles as faSparklesSolid } from "@fortawesome/pro-solid-svg-icons/faSparkles";
import { faSpinnerThird as faSpinnerThirdDuotone } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import { faSquareCheck as faSquareCheckRegular } from "@fortawesome/pro-regular-svg-icons/faSquareCheck";
import { faSquareCheck as faSquareCheckSolid } from "@fortawesome/pro-solid-svg-icons/faSquareCheck";
import { faSquareEllipsis as faSquareEllipsisRegular } from "@fortawesome/pro-regular-svg-icons/faSquareEllipsis";
import { faSquareEllipsis as faSquareEllipsisSolid } from "@fortawesome/pro-solid-svg-icons/faSquareEllipsis";
import { faSquareList as faSquareListRegular } from "@fortawesome/pro-regular-svg-icons/faSquareList";
import { faSquareList as faSquareListSolid } from "@fortawesome/pro-solid-svg-icons/faSquareList";
import { faSquareMinus as faSquareMinusSharpRegular } from "@fortawesome/sharp-regular-svg-icons/faSquareMinus";
import { faSquarePlus as faSquarePlusSharpRegular } from "@fortawesome/sharp-regular-svg-icons/faSquarePlus";
import { faSquareSlidersVertical as faSquareSlidersVerticalRegular } from "@fortawesome/pro-regular-svg-icons/faSquareSlidersVertical";
import { faSquareSlidersVertical as faSquareSlidersVerticalSolid } from "@fortawesome/pro-solid-svg-icons/faSquareSlidersVertical";
import { faTableList as faTableListRegular } from "@fortawesome/pro-regular-svg-icons/faTableList";
import { faTableList as faTableListSolid } from "@fortawesome/pro-solid-svg-icons/faTableList";
import { faTelescope as faTelescopeRegular } from "@fortawesome/pro-regular-svg-icons/faTelescope";
import { faTrashCan as faTrashCanRegular } from "@fortawesome/pro-regular-svg-icons/faTrashCan";
import { faTrashCan as faTrashCanSolid } from "@fortawesome/pro-solid-svg-icons/faTrashCan";
import { faTriangleExclamation as faTriangleExclamationRegular } from "@fortawesome/pro-regular-svg-icons/faTriangleExclamation";
import { faTriangleExclamation as faTriangleExclamationSolid } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import { faUnlock as faUnlockRegular } from "@fortawesome/pro-regular-svg-icons/faUnlock";
import { faUnlock as faUnlockSolid } from "@fortawesome/pro-solid-svg-icons/faUnlock";
import { faUserHeadset as faUserHeadsetRegular } from "@fortawesome/pro-regular-svg-icons/faUserHeadset";
import { faUserHeadset as faUserHeadsetSolid } from "@fortawesome/pro-solid-svg-icons/faUserHeadset";
import { faUser as faUserRegular } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faUser as faUserSolid } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faUsers as faUsersRegular } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faUsers as faUsersSolid } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faWindow as faWindowRegular } from "@fortawesome/pro-regular-svg-icons/faWindow";
import { faWindow as faWindowSolid } from "@fortawesome/pro-solid-svg-icons/faWindow";
import { faXmark as faXmarkRegular } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { faXmark as faXmarkSolid } from "@fortawesome/pro-solid-svg-icons/faXmark";

import { library } from "@fortawesome/fontawesome-svg-core";

/* add icons to the library */
library.add(
  faAddressCardRegular,
  faAddressCardSolid,
  faArrowDownToLineRegular,
  faArrowDownToLineSolid,
  faArrowRightArrowLeftRegular,
  faArrowRightArrowLeftSolid,
  faArrowsRotateRegular,
  faArrowsRotateSolid,
  faArrowUpArrowDownDuotone,
  faArrowUpArrowDownSolid,
  faArrowUpRightRegular,
  faArrowUpRightSolid,
  faBarsSortRegular,
  faBarsSortSolid,
  faBellRegular,
  faBellSolid,
  faBooksRegular,
  faBooksSolid,
  faBoxRegular,
  faBoxSolid,
  faBuildingsRegular,
  faBuildingsSolid,
  faBullhornRegular,
  faBullhornSolid,
  faCabinetFilingRegular,
  faCabinetFilingSolid,
  faCaretDownRegular,
  faCaretDownSolid,
  faCartMinusRegular,
  faCartMinusSolid,
  faCartPlusRegular,
  faCartPlusSolid,
  faCartShoppingRegular,
  faCartShoppingSolid,
  faChartLineRegular,
  faChartLineSolid,
  faChartNetworkRegular,
  faChevronDownRegular,
  faChartNetworkSolid,
  faCheckRegular,
  faCheckSolid,
  faChevronDownSolid,
  faChevronLeftSolid,
  faCircle1Light,
  faCircle2Light,
  faCircle3Light,
  faCircle4Light,
  faCircle5Light,
  faCircle6Light,
  faCircle7Light,
  faCircle8Light,
  faCircleCheckRegular,
  faCircleCheckSolid,
  faCircleDollarRegular,
  faCircleDollarSolid,
  faCircleInfoRegular,
  faCircleInfoSolid,
  faCircleMinusRegular,
  faCirclePlusRegular,
  faCirclePlusSolid,
  faCircleQuestionRegular,
  faCircleQuestionSolid,
  faCircleXmarkRegular,
  faCircleXmarkSolid,
  faClockRegular,
  faCloudRegular,
  faCloudSolid,
  faCodeCompareRegular,
  faCodeCompareSolid,
  faCodeRegular,
  faCodeSolid,
  faCoinBlankRegular,
  faCoinBlankSolid,
  faCommentRegular,
  faCommentSolid,
  faComputerRegular,
  faComputerSolid,
  faCopyRegular,
  faCopySolid,
  faCreditCardRegular,
  faCreditCardSolid,
  faCrosshairsRegular,
  faCrosshairsSolid,
  faDatabaseRegular,
  faDatabaseSolid,
  faDisplayCodeRegular,
  faDisplayCodeSolid,
  faDoorOpenRegular,
  faDoorOpenSolid,
  faEllipsisVerticalSolid,
  faEnvelopeRegular,
  faEnvelopeSolid,
  faEyeRegular,
  faEyeSlashRegular,
  faEyeSlashSolid,
  faEyeSolid,
  faFaceFrownRegular,
  faFaceFrownSolid,
  faFaceLaughBeamLight,
  faFaceLaughBeamRegular,
  faFaceLaughBeamSolid,
  faFileImportRegular,
  faFileImportSolid,
  faFileInvoiceDollarRegular,
  faFileInvoiceDollarSolid,
  faGearRegular,
  faGearSolid,
  faGiftRegular,
  faGiftSolid,
  faGlobeRegular,
  faGlobeSolid,
  faHandshakeRegular,
  faHandshakeSolid,
  faHardDriveRegular,
  faHardDriveSolid,
  faHouseRegular,
  faHouseSolid,
  faIdBadgeRegular,
  faIdBadgeSolid,
  faKeyRegular,
  faKeySolid,
  faLinkRegular,
  faLinkSolid,
  faListCheckRegular,
  faListCheckSolid,
  faLoaderDuotone,
  faLockRegular,
  faLockSolid,
  faMagnifyingGlassRegular,
  faMagnifyingGlassSolid,
  faMessageDotsRegular,
  faMessageDotsSolid,
  faMessagePenRegular,
  faMessagePenSolid,
  faPartyHornRegular,
  faPenRegular,
  faPenSolid,
  faPenToSquareRegular,
  faPenToSquareSolid,
  faPersonShelterRegular,
  faPersonShelterSolid,
  faPhoneRegular,
  faPhoneSolid,
  faPhoneVolumeRegular,
  faPhoneVolumeSolid,
  faPuzzlePieceRegular,
  faPuzzlePieceSolid,
  faRadarRegular,
  faRadarSolid,
  faReceiptRegular,
  faReceiptSolid,
  faRectanglesMixedRegular,
  faRectanglesMixedSolid,
  faScrewdriverWrenchRegular,
  faScrewdriverWrenchSolid,
  faServerRegular,
  faServerSolid,
  faShareRegular,
  faShareSolid,
  faShieldCheckRegular,
  faShieldCheckSolid,
  faShieldRegular,
  faShieldSolid,
  faShopRegular,
  faShopSolid,
  faSliderRegular,
  faSliderSolid,
  faSparklesRegular,
  faSparklesSolid,
  faSpinnerThirdDuotone,
  faSquareCheckRegular,
  faSquareCheckSolid,
  faSquareEllipsisRegular,
  faSquareEllipsisSolid,
  faSquareListRegular,
  faSquareListSolid,
  faSquareMinusSharpRegular,
  faSquarePlusSharpRegular,
  faSquareSlidersVerticalRegular,
  faSquareSlidersVerticalSolid,
  faTableListRegular,
  faTableListSolid,
  faTelescopeRegular,
  faTrashCanRegular,
  faTrashCanSolid,
  faTriangleExclamationRegular,
  faTriangleExclamationSolid,
  faUnlockRegular,
  faUnlockSolid,
  faUserHeadsetRegular,
  faUserHeadsetSolid,
  faUserRegular,
  faUserSolid,
  faUsersRegular,
  faUsersSolid,
  faWindowRegular,
  faWindowSolid,
  faXmarkRegular,
  faXmarkSolid
);
