<template>
  <button
    @click="click"
    :type="type"
    @mouseover="focus"
    @mouseleave="blur"
    :disabled="disabled || loading"
    :data-test="testId"
  >
    <Tooltip v-if="tooltip" :content="tooltip">
      <slot v-if="contentPosition === 'left'" />
      <font-awesome-icon
        :icon="faIcon?.icon"
        :spin="faIcon?.spin"
        :flip="faIcon?.flip"
        :rotation="faIcon?.rotation"
        :class="computedIconClass"
      />
      <slot v-if="contentPosition === 'right'" />
    </Tooltip>
    <template v-else>
      <slot v-if="contentPosition === 'left'" />
      <font-awesome-icon
        :icon="faIcon?.icon"
        :spin="faIcon?.spin"
        :flip="faIcon?.flip"
        :rotation="faIcon?.rotation"
        :class="computedIconClass"
      />
      <slot v-if="contentPosition === 'right'" />
    </template>
  </button>
</template>

<script>
import { computed, ref } from "vue";
import Tooltip from "@/components/display/Tooltip";

export default {
  name: "IconButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
    type: {
      type: String,
      default: "button",
    },
    contentPosition: {
      type: String,
      default: "left",
      validator: (val) => ["left", "right"].includes(val),
    },
    testId: {
      type: String,
    },
  },
  components: {
    Tooltip,
  },
  emits: ["click"],
  setup: (props, { emit, slots }) => {
    const active = ref(false);

    const blur = () => (active.value = false);
    const click = (e) => emit("click", e);
    const computedIconClass = computed(() => {
      if (slots.default) {
        if (faIcon.value?.class) {
          return `${faIcon.value?.class} ${
            props.contentPosition === "right" ? "tw-mr-2" : "tw-ml-2"
          }`;
        }
        return props.contentPosition === "right" ? "tw-mr-2" : "tw-ml-2";
      }
      return faIcon.value?.class;
    });
    const faIcon = computed(() =>
      props.loading
        ? props.icon?.loading || props.icon?.default
        : !(props.disabled || props.loading) && active.value
        ? props.icon?.hover || props.icon?.default
        : props.icon?.default
    );
    const focus = () => (active.value = true);

    return {
      blur,
      click,
      computedIconClass,
      faIcon,
      focus,
    };
  },
};
</script>

<style lang="scss" scoped>
:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  > * {
    pointer-events: none;
  }
}
</style>
