import planHandler from "@/store/plugins/handlers/plan";
import sharedHandler from "@/store/plugins/handlers/shared";

const handler = (event, data) => {
  switch (event) {
    case "service:add":
    case "service:delete":
    case "service:update":
      switch (data.type) {
        case "virt-guest-cloud":
          planHandler(event, data);
          break;
      }
      break;
    case "api-task:add":
    case "api-task:update":
      planHandler(event, data);
      break;
    case "service-cancellation:add":
    case "service-cancellation:delete":
      sharedHandler(event, data);
      break;
  }
};

export default handler;
