<template>
  <div>
    <component
      v-if="topModal"
      :is="topModal.template"
      v-bind="topModal.data"
      @close="close"
      @confirm="confirm"
      @update:input="updateValue"
    />
    <div class="modal-backdrop fade" :class="{ show: topModal }" v-if="topModal"></div>
  </div>
</template>

<script>
import { ref } from "vue";
import store from "@/store";
import { useModals } from "@/utils";

export default {
  name: "ModalService",
  props: {},
  setup() {
    const value = ref(null);
    const { topModal } = useModals();
    const close = (id) => {
      if (id) {
        store.dispatch("modals/closeById", id);
        return;
      }
      store.dispatch("modals/close", topModal.value);
    };

    const confirm = (id) => {
      if (id) {
        store.dispatch("modals/confirmById", { id, inputValue: value.value });
        return;
      }
      store.dispatch("modals/confirm", { modal: topModal.value, inputValue: value.value });
    };

    const updateValue = (newValue) => (value.value = newValue);

    return {
      close,
      confirm,
      topModal,
      updateValue,
    };
  },
};
</script>
