const parseParams = (params = "", parsedQueryStringItems = {}) => {
  // remove preceding '/' if one is present
  params = params.startsWith("/") ? params.substring(1) : params;
  // get array of all params in the path
  const pathItems = params.split("?")[0].split("/");
  // parse path params into an object
  const parsedPathItems = {};
  for (let i = 0; i < pathItems.length; i += 2) {
    parsedPathItems[pathItems[i]] = pathItems[i + 1];
  }
  // get all query string parameters
  // const parsedQueryStringItems = queryStringParser.parse(window.location.search);
  // combine all parameters
  const parsedParams = { ...parsedPathItems, ...parsedQueryStringItems };
  // validationMap maps url parameters to final field names, and optionally includes a validation function
  const validationMap = {
    app: ["app_id", Number],
    level: ["package_id", Number],
    zone: ["cloud_id", Number],
    stencil: ["stencil_id", Number],
    addon: ["addon", Number],
    coupon: ["discount_code"],
    type: ["type"],
    term: ["term", Number],
    domain: ["domain"],
    nickname: ["nickname", decodeURIComponent],
    step: ["step"],
    display: ["display"],
    install: ["install", (install) => install !== "migration"],
    "install-app": ["installApp", (install) => install !== "false"],
    redirect: ["redirect", decodeURIComponent],
    "has-bc": ["hasBC", (bc) => bc === "true"],
    affiliate: ["affiliate"],
  };

  let selected = {};
  for (const [key, value] of Object.entries(parsedParams)) {
    if (validationMap[key]) {
      // get the final field name and validation function from validationMap
      const [fieldName, typeValidator] = validationMap[key];
      // if validation function is present, use it
      selected[fieldName] = typeValidator ? typeValidator(value) : value;
    }
  }

  return selected;
};

export { parseParams };
