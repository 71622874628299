import Cookies from "js-cookie";
import router from "@/router";

export const disable = () => Cookies.remove(process.env.VUE_APP_PORTAL_MODE_COOKIE_NAME);

export const enable = () =>
  Cookies.set(
    process.env.VUE_APP_PORTAL_MODE_COOKIE_NAME,
    process.env.VUE_APP_PORTAL_MODE_COOKIE_VALUE
  );

export const getExpiration = () =>
  new Date(process.env.VUE_APP_PORTAL_MODE_EXPIRATION).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

export const isAvailable = () =>
  isNaN(Date.parse(process.env.VUE_APP_PORTAL_MODE_EXPIRATION))
    ? false
    : new Date(process.env.VUE_APP_PORTAL_MODE_EXPIRATION) > new Date();

export const isEnabled = () => isAvailable() && isSet();

export const isSet = () =>
  Cookies.get(process.env.VUE_APP_PORTAL_MODE_COOKIE_NAME) ===
  process.env.VUE_APP_PORTAL_MODE_COOKIE_VALUE;

export const redirect = () => {
  const key = "cb";
  const redirectUrl = new URL(`${process.env.VUE_APP_URL}${router.currentRoute?.value?.fullPath}`);
  redirectUrl.pathname = getNearestParentPath(redirectUrl.pathname);
  const currentTime = new Date().getTime();
  if (redirectUrl.searchParams.has(key)) {
    redirectUrl.searchParams.set(key, currentTime);
  } else {
    redirectUrl.searchParams.append(key, currentTime);
  }
  window.location.replace(redirectUrl);
};

const getNearestParentPathMatches = (path) => {
  if (path.includes("plans") && path.includes("sites") && path.includes("dev-environments")) {
    return path?.match(new RegExp("/plans/\\d+/sites/\\d+/dev-environments/\\d+(.*)"));
  }
  if (path.includes("plans") && path.includes("sites")) {
    return path?.match(new RegExp("/plans/\\d+/sites/\\d+(.*)"));
  }
  if (
    path.includes("plans") &&
    path.includes("safe-harbor") &&
    path.includes("staging-environments")
  ) {
    return path?.match(new RegExp("/plans/\\d+/safe-harbor/staging-environments/\\d+(.*)"));
  }
  if (path.includes("plans") && !path.includes("safe-harbor")) {
    return path?.match(new RegExp("/plans/\\d+(.*)"));
  }
  return null;
};

const getNearestParentPath = (path) =>
  path?.replace(getNearestParentPathMatches(path)?.[1], "") || path;
