import { clearListCache, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_OPTIONS_KEY = "site-cron-options";
const CACHE_PRESETS_KEY = "site-cron-presets";
const CACHE_TASKS_KEY = "site-cron-tasks";

const store = {
  namespaced: true,
  state: {
    options: null,
    optionsStatus: new Status(),
    presets: null,
    presetsStatus: new Status(),
    siteId: null,
    tasks: [],
    tasksStatus: new Status(),
  },
  mutations: {
    SET_OPTIONS(state, payload) {
      state.options = payload;
    },
    SET_OPTIONS_STATUS(state, status) {
      state.optionsStatus.value = status;
    },
    SET_PRESETS(state, payload) {
      state.presets = payload;
    },
    SET_PRESETS_STATUS(state, status) {
      state.presetsStatus.value = status;
    },
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
    SET_TASKS(state, payload) {
      state.tasks = payload;
    },
    SET_TASKS_STATUS(state, status) {
      state.tasksStatus.value = status;
    },
  },
  actions: {
    async addTask(context, { id, data }) {
      return request.makeParse("addSiteCron", { id }, data);
    },
    async deleteTask(context, { siteId, cronId }) {
      return request.makeParse("deleteSiteCron", { siteId, cronId });
    },
    async getOptions({ commit, state }, id) {
      commit(
        "SET_OPTIONS_STATUS",
        (state.optionsStatus.isLoaded || state.optionsStatus.isUpdating) &&
          id === state.options.site.id
          ? Status.UPDATING
          : Status.LOADING
      );
      try {
        const response = await get(createKey(CACHE_OPTIONS_KEY, { id }), request.makeParse, [
          "getSiteCronOptions",
          { id },
        ]);
        commit("SET_OPTIONS", response);
        commit("SET_OPTIONS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_OPTIONS_STATUS", Status.ERROR);
      }
    },
    async getPresets({ commit, state }, id) {
      commit(
        "SET_PRESETS_STATUS",
        state.presetsStatus.isLoaded && id === state.presets?.site?.id
          ? Status.UPDATING
          : Status.LOADING
      );
      try {
        const response = await get(createKey(CACHE_PRESETS_KEY, { id }), request.makeParse, [
          "getSiteCronPresets",
          { id },
        ]);
        commit("SET_PRESETS", response);
        commit("SET_PRESETS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_PRESETS_STATUS", Status.ERROR);
      }
    },
    async getTasks({ commit, state }, id) {
      commit(
        "SET_TASKS_STATUS",
        state.tasksStatus.isLoaded && id === state.siteId ? Status.UPDATING : Status.LOADING
      );
      commit("SET_SITE_ID", id);
      try {
        const response = await get(createKey(CACHE_TASKS_KEY, { id }), request.makeParse, [
          "getSiteCronTasks",
          { id },
        ]);
        commit("SET_TASKS", response);
        commit("SET_TASKS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_TASKS_STATUS", Status.ERROR);
      }
    },
    refreshOptions({ dispatch, state }) {
      clearListCache(CACHE_OPTIONS_KEY);
      const id = state.options?.site?.id;
      if (id) {
        dispatch("getOptions", id);
      }
    },
    refreshTasks({ dispatch, state }) {
      clearListCache(CACHE_TASKS_KEY);
      const id = state.siteId;
      if (id) {
        dispatch("getTasks", id);
      }
    },
    async updateOptions(context, { id, data }) {
      return request.makeParse("updateSiteCronOptions", { id }, data);
    },
    async updateTask(context, { siteId, cronId, data }) {
      return request.makeParse("updateSiteCron", { siteId, cronId }, data);
    },
  },
  getters: {
    getOptions: (state) => state.options,
    getOptionsStatus: (state) => state.optionsStatus,
    getPresets: (state) => state.presets,
    getPresetsStatus: (state) => state.presetsStatus,
    getTasks: (state) => state.tasks,
    getTasksStatus: (state) => state.tasksStatus,
  },
};

export default store;
