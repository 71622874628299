import { differenceInDays, fromUnixTime } from "date-fns";

export const calculateLongevity = (startDate) => {
  const difference = differenceInDays(new Date(), fromUnixTime(startDate / 1000));

  if (difference === 0) return "new";
  if (difference >= 365) return "gte365";
  if (difference >= 180) return "gte180";
  if (difference >= 90) return "gte90";
  if (difference >= 60) return "gte60";
  if (difference >= 30) return "gte30";
  if (difference < 30) return "lt30";
};
