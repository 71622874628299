import { clear, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_LIST, SET_PAGINATION, SET_SORT, SET_STATUS } from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_ADVANCED_AUTO_SCALE_KEY = "site-advanced-auto-scale";
const CACHE_ADVANCED_AUTO_SCALE_PACKAGES_KEY = "site-advanced-auto-scale-packages";
const CACHE_AUTO_SCALE_USAGE_KEY = "site-auto-scale-usage";
const CACHE_CDN_KEY = "site-cdn";
const CACHE_CDN_USAGE_KEY = "site-cdn-usage";
const CACHE_EADN_KEY = "site-eadn";
const CACHE_NEW_RELIC_KEY = "site-new-relic";

const state = () => ({
  advancedAutoScale: null,
  advancedAutoScaleStatus: new Status(),
  advancedAutoScalePackages: null,
  advancedAutoScalePackagesStatus: new Status(),
  allChunk: null,
  allList: null,
  allPagination: null,
  allParams: null,
  allSearch: null,
  allSort: null,
  allStatus: new Status(),
  autoScaleUsage: null,
  autoScaleUsageStatus: new Status(),
  cdn: null,
  cdnStatus: new Status(),
  cdnUsage: null,
  cdnUsageStatus: new Status(),
  chunk: null,
  cronOptions: null,
  cronOptionsStatus: new Status(),
  eadn: null,
  eadnStatus: new Status(),
  item: null,
  itemId: null,
  itemStatus: new Status(),
  list: [],
  newRelic: null,
  newRelicStatus: new Status(),
  newRelicUpdates: [],
  pagination: null,
  planId: null,
  search: null,
  searchStatus: new Status(),
  siteId: null,
  sort: null,
  status: new Status(),
  systemTime: null,
  systemTimeStatus: new Status(),
  awstatsUrl: null,
  awstatsUrlStatus: new Status(),
  siteWorxUrl: null,
  siteWorxUrlStatus: new Status(),
  phpMyAdminUrl: null,
  phpMyAdminUrlStatus: new Status(),
  restoreStatus: new Status(),
  widget: null,
  widgetStatus: new Status(),
});

const mutations = {
  SET_CDN(state, payload) {
    state.cdn = payload;
  },
  SET_CDN_STATUS(state, payload) {
    state.cdnStatus.value = payload;
  },
  SET_CDN_USAGE(state, payload) {
    state.cdnUsage = payload;
  },
  SET_CDN_USAGE_STATUS(state, payload) {
    state.cdnUsageStatus.value = payload;
  },
  SET_EADN(state, payload) {
    state.eadn = payload;
  },
  SET_EADN_STATUS(state, payload) {
    state.eadnStatus.value = payload;
  },
  ADD_NEW_RELIC_UPDATE(state, id) {
    const index = state.newRelicUpdates.findIndex((item) => item === Number(id));
    if (index < 0) {
      state.newRelicUpdates.push(Number(id));
    }
  },
  REMOVE_NEW_RELIC_UPDATE(state, id) {
    const index = state.newRelicUpdates.findIndex((item) => item === Number(id));
    if (index >= 0) {
      state.newRelicUpdates.splice(index, 1);
    }
  },
  SET_ADVANCED_AUTO_SCALE(state, payload) {
    state.advancedAutoScale = payload;
  },
  SET_ADVANCED_AUTO_SCALE_STATUS(state, payload) {
    state.advancedAutoScaleStatus.value = payload;
  },
  SET_ADVANCED_AUTO_SCALE_PACKAGES(state, payload) {
    state.advancedAutoScalePackages = payload;
  },
  SET_ADVANCED_AUTO_SCALE_PACKAGES_STATUS(state, payload) {
    state.advancedAutoScalePackagesStatus.value = payload;
  },
  SET_ALL_CHUNK: (state, payload) => {
    const pageIndex = payload?.pageIndex;
    const pageSize = payload?.pageSize;
    const sortDirection = payload?.sortDirection;
    const sortProperty = payload?.sortProperty;
    const pagination = pageIndex || pageSize ? { index: pageIndex, size: pageSize } : null;
    const sort =
      sortDirection || sortProperty ? { direction: sortDirection, property: sortProperty } : null;
    state.allChunk = pagination || sort ? { pagination, sort } : null;
  },
  SET_ALL_LIST: (state, payload) => (state.allList = payload),
  SET_ALL_PAGINATION: (state, payload) => (state.allPagination = payload),
  SET_ALL_SORT: (state, payload) => {
    const sort = {};
    if (payload?.sortProperty) sort.property = payload?.sortProperty;
    if (payload?.sortDirection) sort.direction = payload?.sortDirection;
    state.allSort = payload?.sortProperty || payload?.sortDirection ? sort : null;
  },
  SET_ALL_STATUS: (state, payload) => {
    state.allStatus.value = payload;
  },
  SET_ALL_PARAMS: (state, payload) => (state.allParams = payload),
  SET_AUTO_SCALE_USAGE(state, payload) {
    state.autoScaleUsage = payload;
  },
  SET_AUTO_SCALE_USAGE_STATUS(state, payload) {
    state.autoScaleUsageStatus.value = payload;
  },
  SET_CHUNK,
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  SET_ITEM_ID(state, id) {
    state.itemId = id;
  },
  SET_ITEM_STATUS(state, payload) {
    state.itemStatus.value = payload;
  },
  SET_LIST,
  SET_NEW_RELIC(state, payload) {
    state.newRelic = payload;
  },
  SET_NEW_RELIC_STATUS(state, payload) {
    state.newRelicStatus.value = payload;
  },
  SET_PAGINATION,
  SET_PLAN_ID(state, id) {
    state.planId = id;
  },
  SET_SITE_ID(state, id) {
    state.siteId = id;
  },
  SET_SORT,
  SET_STATUS,
  SET_SYSTEM_TIME(state, payload) {
    state.systemTime = payload;
  },
  SET_SYSTEM_TIME_STATUS(state, status) {
    state.systemTimeStatus.value = status;
  },
  SET_AWSTATS_URL(state, url) {
    state.awstatsUrl = url;
  },
  SET_SITEWORX_URL(state, url) {
    state.siteWorxUrl = url;
  },
  SET_PHPMYADMIN_URL(state, url) {
    state.phpMyAdminUrl = url;
  },
  SET_AWSTATS_URL_STATUS(state, status) {
    state.awstatsUrlStatus.value = status;
  },
  SET_SITEWORX_URL_STATUS(state, status) {
    state.siteWorxUrlStatus.value = status;
  },
  SET_PHPMYADMIN_URL_STATUS(state, status) {
    state.phpMyAdminUrlStatus.value = status;
  },
  SET_RESTORE_STATUS(state, payload) {
    state.restoreStatus.value = payload;
  },
  SET_WIDGET_ITEM: (state, payload) => (state.widget = payload),
  SET_WIDGET_ITEM_STATUS: (state, payload) => (state.widgetStatus.value = payload),
};

const actions = {
  addNewRelicUpdate({ commit }, id) {
    commit("ADD_NEW_RELIC_UPDATE", id);
  },
  disableAdvancedAutoScale(context, { id }) {
    return request.makeParse("disableSiteAdvancedAutoScale", { id });
  },
  disableNewRelic(context, { id }) {
    return request.makeParse("disableSiteNewRelic", { id });
  },
  enableCdn(context, siteId) {
    return request.makeParse("enableSiteCdn", { siteId });
  },
  enableAdvancedAutoScale(context, { id, data }) {
    return request.makeParse("enableSiteAdvancedAutoScale", { id }, data);
  },
  enableNewRelic(context, { id, data }) {
    return request.makeParse("enableSiteNewRelic", { id }, data);
  },
  async getAdvancedAutoScale({ commit, state }, id) {
    commit(
      "SET_ADVANCED_AUTO_SCALE_STATUS",
      (state.advancedAutoScaleStatus.isLoaded || state.advancedAutoScaleStatus.isUpdating) &&
        id === state.advancedAutoScale.site.id
        ? Status.UPDATING
        : Status.LOADING
    );
    try {
      const response = await get(
        createKey(CACHE_ADVANCED_AUTO_SCALE_KEY, { id }),
        request.makeParse,
        ["getSiteAdvancedAutoScale", { id }]
      );
      commit("SET_ADVANCED_AUTO_SCALE", response);
      commit("SET_ADVANCED_AUTO_SCALE_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_ADVANCED_AUTO_SCALE_STATUS", Status.ERROR);
    }
  },
  async getAdvancedAutoScalePackages({ commit, state }, id) {
    commit(
      "SET_ADVANCED_AUTO_SCALE_PACKAGES_STATUS",
      state.advancedAutoScalePackagesStatus.isLoaded &&
        id === state.advancedAutoScalePackages.site?.id
        ? Status.UPDATING
        : Status.LOADING
    );
    try {
      const response = await get(
        createKey(CACHE_ADVANCED_AUTO_SCALE_PACKAGES_KEY, { id }),
        request.makeParse,
        ["getSiteAdvancedAutoScalePackages", { id }]
      );
      commit("SET_ADVANCED_AUTO_SCALE_PACKAGES", response);
      commit("SET_ADVANCED_AUTO_SCALE_PACKAGES_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_ADVANCED_AUTO_SCALE_PACKAGES_STATUS", Status.ERROR);
    }
  },
  async getCdnUsage({ commit, state }, id) {
    commit(
      "SET_CDN_USAGE_STATUS",
      state.cdnUsageStatus.isLoaded && id === state.cdnUsage ? Status.UPDATING : Status.LOADING
    );
    try {
      const response = await get(createKey(CACHE_CDN_USAGE_KEY, { id }), request.makeParse, [
        "getSiteCdnUsage",
        { id },
      ]);
      commit("SET_CDN_USAGE", response);
      commit("SET_CDN_USAGE_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_CDN_USAGE_STATUS", Status.ERROR);
    }
  },
  async getAutoScaleUsage({ commit, state }, id) {
    commit(
      "SET_AUTO_SCALE_USAGE_STATUS",
      state.autoScaleUsageStatus.isLoaded && id === state.autoScaleUsage.id
        ? Status.UPDATING
        : Status.LOADING
    );
    try {
      const response = await get(createKey(CACHE_AUTO_SCALE_USAGE_KEY, { id }), request.makeParse, [
        "getSiteAutoScaleUsage",
        { id },
      ]);
      commit("SET_AUTO_SCALE_USAGE", response);
      commit("SET_AUTO_SCALE_USAGE_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_AUTO_SCALE_USAGE_STATUS", Status.ERROR);
    }
  },
  async getNewRelic({ commit, state }, id) {
    commit(
      "SET_NEW_RELIC_STATUS",
      state.newRelicStatus.isLoaded && id === state.newRelic.site.id
        ? Status.UPDATING
        : Status.LOADING
    );
    try {
      const response = await get(createKey(CACHE_NEW_RELIC_KEY, { id }), request.makeParse, [
        "getSiteNewRelic",
        { id },
      ]);
      commit("SET_NEW_RELIC", response);
      commit("SET_NEW_RELIC_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_NEW_RELIC_STATUS", Status.ERROR);
    }
  },
  async getSystemTime({ commit, state }, id) {
    commit(
      "SET_SYSTEM_TIME_STATUS",
      state.systemTimeStatus.isLoaded && id === state.systemTime?.site?.id
        ? Status.UPDATING
        : Status.LOADING
    );
    try {
      const response = await request.makeParse("getSiteSystemTime", { id });
      commit("SET_SYSTEM_TIME", response);
      commit("SET_SYSTEM_TIME_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_SYSTEM_TIME_STATUS", Status.ERROR);
    }
  },
  removeNewRelicUpdate({ commit }, id) {
    commit("REMOVE_NEW_RELIC_UPDATE", id);
  },
  async getCdn({ commit, state }, id) {
    commit(
      "SET_CDN_STATUS",
      state.cdnStatus.isLoaded && id === state.cdn ? Status.UPDATING : Status.LOADING
    );

    try {
      const response = await get(createKey(CACHE_CDN_KEY, { id }), request.makeParse, [
        "getSiteCdn",
        { id },
      ]);
      commit("SET_CDN", response);
      commit("SET_CDN_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_CDN_STATUS", Status.ERROR);
    }
  },
  async getEadn({ commit, state }, id) {
    const getEadn = async () => {
      const account = await request.makeParse("getSiteEadn", { id });
      const origins = await request.makeParse("getSiteEadnOrigins", { id: account.id });
      const uris = await request.makeParse("getSiteEadnOriginUris", {
        account_id: account.id,
        origin_id: origins[0].id,
      });
      return {
        origin: origins[0].identity,
        points_to: uris[0].uri,
        hostname: account.base_access_uri,
      };
    };

    commit(
      "SET_EADN_STATUS",
      state.eadnStatus.isLoaded && id === state.eadn ? Status.UPDATING : Status.LOADING
    );
    try {
      const response = await get(createKey(CACHE_EADN_KEY, { id }), getEadn, []);
      commit("SET_EADN", response);
      commit("SET_EADN_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_EADN_STATUS", Status.ERROR);
    }
  },
  async purgeCdn(context, siteId) {
    return request.makeParse("purgeSiteCdn", { siteId });
  },
  async updateAutoScale(context, { id, data }) {
    return request.makeParse("updateSiteAutoScale", { id }, data);
  },
  async updateDomain(context, { id, data }) {
    return request.makeParse("updateSiteDomain", { id }, data);
  },
  async updateNginx(context, { id, data }) {
    return request.makeParse("updateSiteNginx", { id }, data);
  },
  async delete({ dispatch }, { id, planId }) {
    const response = await request.makeParse("deleteSite", { id });
    dispatch("refreshList", { planId });
    return response;
  },
  refreshAdvancedAutoScale({ dispatch, state }, payload) {
    const id = payload?.id || state.advancedAutoScale?.site?.id;
    if (id) {
      const cacheKey = createKey(CACHE_ADVANCED_AUTO_SCALE_KEY, { id });
      clear(cacheKey);
      dispatch("getAdvancedAutoScale", id);
    }
  },
  refreshNewRelic({ dispatch, state }, id) {
    if (id) {
      const cacheKey = createKey(CACHE_NEW_RELIC_KEY, { id });
      clear(cacheKey);
      if (id === state.newRelic?.site?.id) {
        dispatch("getNewRelic", id);
      }
    }
  },
  async purgeCache(context, { id }) {
    return request.makeParse("purgeSiteCache", { id });
  },
  getUsageMetrics(context, id) {
    return get(createKey("getUsageMetrics", { id }), request.makeParse, [
      "getUsageMetrics",
      { id },
    ]);
  },
  getAutoscaleMetrics(context, id) {
    return get(createKey("getAutoscaleMetrics", { id }), request.makeParse, [
      "getAutoscaleMetrics",
      { id },
    ]);
  },
  updateAdvancedAutoScale(context, { id, data }) {
    return request.makeParse("updateSiteAdvancedAutoScale", { id }, data);
  },
  async getAwstatsUrl({ commit }, id) {
    commit("SET_AWSTATS_URL_STATUS", Status.LOADING);
    try {
      const url = await request.makeParse("getAwstatsUrl", { id });
      commit("SET_AWSTATS_URL", url);
      commit("SET_AWSTATS_URL_STATUS", Status.LOADED);
      return url;
    } catch (e) {
      commit("SET_AWSTATS_URL_STATUS", Status.ERROR);
    }
  },
  async getSiteWorxUrl({ commit }, id) {
    commit("SET_SITEWORX_URL_STATUS", Status.LOADING);
    try {
      const url = await request.makeParse("getSiteWorxUrl", { id });
      commit("SET_SITEWORX_URL", url);
      commit("SET_SITEWORX_URL_STATUS", Status.LOADED);
      return url;
    } catch (e) {
      commit("SET_SITEWORX_URL_STATUS", Status.ERROR);
    }
  },
  async getPhpMyAdminUrl({ commit }, id) {
    commit("SET_PHPMYADMIN_URL_STATUS", Status.LOADING);
    try {
      const url = await request.makeParse("getPhpMyAdminUrl", { id });
      commit("SET_PHPMYADMIN_URL", url);
      commit("SET_PHPMYADMIN_URL_STATUS", Status.LOADED);
      return url;
    } catch (e) {
      commit("SET_PHPMYADMIN_URL_STATUS", Status.ERROR);
    }
  },
  async getRestoreStatus({ commit }, siteId) {
    const restoring = await request.makeParse("getSiteBackupRestoreStatus", { siteId });
    const status = restoring ? Status.LOADING : Status.LOADED;

    // set current state of restore
    commit("SET_RESTORE_STATUS", status);
  },
};

const getters = {
  getAdvancedAutoScale: (state) => state.advancedAutoScale,
  getAdvancedAutoScaleStatus: (state) => state.advancedAutoScaleStatus,
  getAdvancedAutoScalePackages: (state) => state.advancedAutoScalePackages,
  getAdvancedAutoScalePackagesStatus: (state) => state.advancedAutoScalePackagesStatus,
  getAllList: (state) => state.allList || [],
  getAllListStatus: (state) => state.allStatus,
  getAllPagination: (state) => state.allPagination,
  getAutoScaleUsage: (state) => state.autoScaleUsage,
  getAutoScaleUsageStatus: (state) => state.autoScaleUsageStatus,
  getCdn: (state) => state.cdn,
  getCdnStatus: (state) => state.cdnStatus,
  getCdnUsage: (state) => state.cdnUsage,
  getCdnUsageStatus: (state) => state.cdnUsageStatus,
  getEadn: (state) => state.eadn,
  getEadnStatus: (state) => state.eadnStatus,
  getCronOptions: (state) => state.cronOptions,
  getCronOptionsStatus: (state) => state.cronOptionsStatus,
  getItem: (state) => state.item,
  getItemStatus: (state) => state.itemStatus,
  getList: (state) => state.list?.list || [],
  getListPagination: (state) => state.pagination,
  getListSort: (state) => state.sort,
  getListStatus: (state) => state.status,
  getNewRelic: (state) => state.newRelic,
  getNewRelicStatus: (state) => state.newRelicStatus,
  getSearchStatus: (state) => state.searchStatus,
  getSystemTime: (state) => state.systemTime,
  getSystemTimeStatus: (state) => state.systemTimeStatus,
  isNewRelicUpdating: (state) => (id) =>
    state.newRelicUpdates.findIndex((item) => item === Number(id)) >= 0,
  getAwstatsUrl: (state) => state.awstatsUrl,
  getSiteWorxUrl: (state) => state.siteWorxUrl,
  getPhpMyAdminUrl: (state) => state.phpMyAdminUrl,
  getAwstatsUrlStatus: (state) => state.awstatsUrlStatus,
  getSiteWorxUrlStatus: (state) => state.siteWorxUrlStatus,
  getPhpMyAdminUrlStatus: (state) => state.phpMyAdminUrlStatus,
  getRestoreStatus: (state) => state.restoreStatus,
  getWidgetItem: (state) => state.widget,
  getWidgetItemStatus: (state) => state.widgetStatus,
};

export { state, mutations, actions, getters };
