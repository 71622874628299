import { request } from "leatherman-js";
import Status from "@/classes/Status";

const blogPosts = {
  namespaced: true,
  state: {
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
  },
  actions: {
    async getList({ commit }) {
      commit("SET_STATUS", Status.LOADING);

      try {
        const response = await request.direct({
          method: "GET",
          url: `${process.env.VUE_APP_SPIDEY_URL}${process.env.VUE_APP_SPIDEY_BLOG_POSTS_PATH}`,
        });
        commit("SET_LIST", response.data);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (error) {
        console.log(error);
        commit("SET_STATUS", Status.ERROR);
      }
    },
  },
};

export default blogPosts;
