import store from "@/store";

const onDeleteSuccess = (data) => {
  store.dispatch("toasts/addToast", {
    variant: "success",
    message: `Successfully Deleted API Token: ${data.identity}`,
  });
  const tokens = store.getters["tokens/getTokens"];
  const pagination = tokens.pagination;
  const remainingTokens = tokens.list.length - 1;
  const totalPages = Math.ceil(pagination.total / pagination.size);
  const pageIndex =
    remainingTokens === 0 && pagination.index !== 0 && pagination.index === totalPages - 1
      ? pagination.index - 1
      : pagination.index;
  store.dispatch("tokens/refreshList", pageIndex);
};

const handler = (event, data) => {
  switch (event) {
    case "client-user-api-token:add":
      store.dispatch("tokens/refreshList");
      break;
    case "client-user-api-token:delete":
      onDeleteSuccess(data);
      break;
  }
};

export default handler;
