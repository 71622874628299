import {
  back,
  getBillingLinks,
  getDevSiteLinks,
  getDNSLinks,
  getMainLinks,
  getPartnerLinks,
  getPlanLinks,
  getPlanSafeHarborLinks,
  getPlanSafeHarborStagingEnvironmentLinks,
  getSettingsLinks,
  getSharedHostLinks,
  getSharedHostSafeHarborLinks,
  getSharedHostSafeHarborStagingEnvironmentLinks,
  getSiteLinks,
  profile2fa,
  profileInfo,
} from "@/utils/navLinks";
import NavMenu from "@/classes/NavMenu";

const sidebarModes = {
  CHECKOUT_PURCHASE: () =>
    new NavMenu({
      links: [],
      back: back("Back to Plans", "plans"),
      title: "Plan Details",
    }),
  CHECKOUT_PACKAGE: () =>
    new NavMenu({
      links: [],
      back: back("Back to Plans", "plans"),
      title: "Select Plan",
    }),
  CHECKOUT_PROFILE: () =>
    new NavMenu({
      links: [],
      back: back("Back to Plans", "plans"),
      title: "Create Profile",
    }),
  CHECKOUT_DETAILS: () =>
    new NavMenu({
      links: [],
      back: back("Back to Plans", "plans"),
      title: "Plan Details",
    }),
  CHECKOUT_BILLING: () =>
    new NavMenu({
      links: [],
      back: back("Back to Plans", "plans"),
      title: "Billing",
    }),
  CHECKOUT_SURVEY: () =>
    new NavMenu({
      links: [],
      back: back("Back to Plans", "plans"),
      title: "Payment",
    }),
  CHECKOUT_PAYMENT: () =>
    new NavMenu({
      links: [],
      back: back("Back to Plans", "plans"),
      title: "Payment",
    }),
  // primary links
  MAIN: ({ client, company, user }) => {
    if (!client || !company || !user) {
      return new NavMenu({ loading: true });
    }
    return new NavMenu({
      links: getMainLinks(client, company, user),
    });
  },
  // plan detail
  PLAN: ({ plan, user, client }) => {
    if (!plan || !user || !client) {
      return new NavMenu({ loading: true });
    }
    const { nickname } = plan;
    return new NavMenu({
      links: getPlanLinks(plan, user),
      back: back("Back to Plans", "plans"),
      title: nickname,
    });
  },
  // plan site detail
  PLAN_SITE: ({ user, site, plan, isAdmin, isBeta, sitePermissions }) => {
    if (!user || !site || !plan) {
      return new NavMenu({ loading: true });
    }
    const { nickname } = plan;
    return new NavMenu({
      links: getSiteLinks(user, site, plan, isAdmin, isBeta, sitePermissions),
      back: back(`Back to ${nickname}`, "plan-sites"),
      title: site.domain,
    });
  },
  // plan site dev/stage site
  PLAN_SITE_DEV: ({ user, site, plan, devSite, isAdmin, isBeta }) => {
    if (!user || !site || !devSite || !plan) {
      return new NavMenu({ loading: true });
    }
    return new NavMenu({
      links: getDevSiteLinks(user, devSite, plan, isAdmin, isBeta),
      back: back("Back to Environments", {
        name: plan.isTShirt ? "plan-site-dev" : "plan-site-staging-and-dev",
        params: {
          planId: plan.id,
          siteId: site.id,
        },
      }),
      title: devSite.domain,
    });
  },
  PLAN_SAFE_HARBOR: ({ plan }) => {
    if (!plan) {
      return new NavMenu({ loading: true });
    }
    const { nickname } = plan;
    return new NavMenu({
      links: getPlanSafeHarborLinks(),
      back: back(`Back to ${nickname}`, "plan-detail"),
      title: nickname,
    });
  },
  PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT: ({ devSite, plan }) => {
    if (!devSite || !plan) {
      return new NavMenu({ loading: true });
    }
    const { nickname } = plan;
    return new NavMenu({
      links: getPlanSafeHarborStagingEnvironmentLinks(),
      back: back("Back to Safe Harbor", {
        name: "plan-safe-harbor-staging-environments",
        params: {
          id: plan.id,
        },
      }),
      title: nickname,
    });
  },

  // Shared hosting detail
  SHARED_HOST: ({ sharedHost }) => {
    if (!sharedHost) {
      return new NavMenu({ loading: true });
    }
    const { domain } = sharedHost;
    return new NavMenu({
      links: getSharedHostLinks(sharedHost),
      back: back("Back to Shared Hosting", "shared-hosting"),
      title: domain,
    });
  },
  SHARED_HOST_SAFE_HARBOR: ({ sharedHost }) => {
    if (!sharedHost) {
      return new NavMenu({ loading: true });
    }
    const { domain } = sharedHost;
    return new NavMenu({
      links: getSharedHostSafeHarborLinks(),
      back: back("Back to Overview", "shared-host"),
      title: domain,
    });
  },
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT: ({ devSite, sharedHost }) => {
    if (!devSite || !sharedHost) {
      return new NavMenu({ loading: true });
    }
    const { domain } = sharedHost;
    return new NavMenu({
      links: getSharedHostSafeHarborStagingEnvironmentLinks(),
      back: back("Back to Safe Harbor", {
        name: "shared-host-safe-harbor-staging-environments",
        params: {
          id: sharedHost.id,
        },
      }),
      title: domain,
    });
  },

  // Shared hosting add stage
  SHAREDHOST_DEVADD: () => {},
  // Shared Hosting safe harbor
  SHAREDHOST_SAFEHARBOR: () => {},
  // shared hosting dev/stage site
  SHAREDHOST_DEV: () => {},
  // dedicated server detail
  DEDICATED: () => {},
  // dedicated server allocation detail
  DEDICATED_ALLOCATION: () => {},
  // colocation detail
  COLO: () => {},
  // cloud server detail
  CLOUD_SERVER: () =>
    new NavMenu({
      links: [],
      title: "Cloud Server",
      back: back("Back to Cloud Servers", "cloud-servers"),
    }),
  // cloud server add
  CLOUD_SERVER_ADD: () =>
    new NavMenu({
      links: [],
      title: "Add Cloud Server",
      back: back("Back to Cloud Servers", "cloud-servers"),
    }),
  // cloud server resize
  CLOUD_SERVER_RESIZE: () =>
    new NavMenu({
      links: [],
      title: "Cloud Server",
      back: back("Back to Cloud Server", "cloud-server-detail"),
    }),
  // reseller hosting detail
  RESELLER_HOSTING: () => {},
  // settings section
  SETTINGS: ({ user }) => {
    if (!user) {
      return new NavMenu({ loading: true });
    }

    return new NavMenu({
      links: getSettingsLinks(user),
      title: "Settings",
      back: back("Back To Home"),
    });
  },
  JOIN: () => {
    return new NavMenu({
      back: back("Back To Home"),
    });
  },
  // profile settings
  PROFILE: () =>
    new NavMenu({
      links: [profileInfo(), profile2fa()],
      back: back("Back To Home"),
      title: "Profile",
    }),
  // support ticket detail
  SUPPORT_TICKET: ({ client, company }) => {
    if (!client || !company) {
      return new NavMenu({ loading: true });
    }

    return new NavMenu({
      links: [],
      title: "Ticket Detail",
      back: back("Back to Tickets", "tickets"),
    });
  },
  // DNS section
  DNS: ({ user, client, company }) => {
    if (!user || !client || !company) {
      return new NavMenu({ loading: true });
    }

    return new NavMenu({
      links: getDNSLinks(user, client, company),
      back: back("Back To Home"),
    });
  },
  // dns zone detail page
  DNS_ZONE: ({ dnsZone }) => {
    if (!dnsZone) {
      return new NavMenu({ loading: true });
    }

    return new NavMenu({
      links: [],
      title: dnsZone.domain,
      back: back("Back to DNS Zones", "dns-zones"),
    });
  },
  // rdns record detail page
  RDNS_RECORD: ({ rdnsRecord }) => {
    if (!rdnsRecord) {
      return new NavMenu({ loading: true });
    }

    return new NavMenu({
      links: [],
      title: rdnsRecord.target,
      back: back("Back to RDNS Records", "rdns-records"),
    });
  },
  // partner section
  PARTNER: ({ client }) =>
    new NavMenu({
      links: getPartnerLinks(client),
      back: back("Back To Home"),
    }),
  // partner link detail
  PARTNER_LINK: ({ client }) =>
    new NavMenu({
      links: getPartnerLinks(client),
      title: "Partner Link Detail",
      back: back("Back To Links", "partner-link-list"),
    }),
  PARTNER_REFERRAL: () =>
    new NavMenu({
      links: getPartnerLinks(),
      title: "Partner Referral Detail",
      back: back("Back to Referrals", "partner-referral-list"),
    }),
  BILLING: ({ user, company }) => {
    if (!user || !company) {
      return new NavMenu({ loading: true });
    }

    return new NavMenu({
      links: getBillingLinks(user, company),
      back: back("Back To Home"),
    });
  },
  // billing invoice detail
  BILLING_INVOICE: () =>
    new NavMenu({
      links: [],
      back: back("Back To Invoices", "invoices"),
    }),
  // billing credit detail
  BILLING_CREDIT: () =>
    new NavMenu({
      links: [],
      back: back("Back to Credits", "credits"),
    }),
  // billing payment detail
  BILLING_PAYMENT: () =>
    new NavMenu({
      links: [],
      back: back("Back to Payments", "payments"),
    }),
  // billing order detail
  BILLING_ORDER: () =>
    new NavMenu({
      links: [],
      back: back("Back to Orders", "orders"),
    }),
  // ssl section
  SSL: () =>
    new NavMenu({
      links: [],
      back: back("Back To Home"),
    }),
  // ssl certificate detail
  SSL_CERTIFICATE: () =>
    new NavMenu({
      links: [],
      back: back("Back to SSL Certificates", "certificates"),
    }),
  // ssl add page
  SSL_CERTIFICATE_ADD: () =>
    new NavMenu({
      links: [],
      title: "Order SSL Certificate",
      back: back("Back to SSL Certificates", "certificates"),
    }),
  SSL_CERTIFICATE_ADD_WITH_CERT: () =>
    new NavMenu({
      links: [],
      title: "Order SSL Certificate",
      back: back("Back to SSL Certificates", "certificates"),
    }),
  // announcements section
  ANNOUNCEMENTS: () =>
    new NavMenu({
      links: [],
      back: back("Back To Home"),
    }),
  ANNOUNCEMENT: () =>
    new NavMenu({
      links: [],
      title: "Announcement Detail",
      back: back("Back to Announcements", "announcements"),
    }),
  // feedback section
  FEEDBACK: () => {},
  // Cancellation section
  CANCELLATION: () => {},
};

export { sidebarModes };
