class Breadcrumbs {
  links; // NavLink[]

  loading; // Boolean

  constructor({ links, loading } = {}) {
    this.loading = loading || false;
    this.links = links || [];
  }
}

export default Breadcrumbs;
