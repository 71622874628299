export default [
  {
    path: "analytics",
    name: "plan-site-dev-analytics",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_ANALYTICS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Analytics.vue"
      ),
  },
  {
    path: "backups",
    name: "plan-site-dev-backups",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_BACKUPS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Backups.vue"
      ),
  },
  {
    path: "containers",
    name: "plan-site-dev-containers",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_CONTAINERS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Containers.vue"
      ),
  },
  {
    path: "control-panel",
    name: "plan-site-dev-control-panel",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_CONTROL_PANEL",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/ControlPanel.vue"
      ),
  },
  {
    path: "credentials",
    name: "plan-site-dev-credentials",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_CREDENTIALS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Credentials.vue"
      ),
  },
  {
    path: "databases",
    name: "plan-site-dev-databases",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_DATABASES",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Databases.vue"
      ),
  },
  {
    path: "domains",
    name: "plan-site-dev-domains",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_DOMAINS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Domains.vue"
      ),
  },
  {
    path: "email",
    name: "plan-site-dev-email",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_EMAIL",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Email.vue"
      ),
  },
  {
    path: "logs",
    name: "plan-site-dev-logs",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_LOGS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Logs.vue"
      ),
  },
  {
    path: "management",
    name: "plan-site-dev-management",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_MANAGEMENT",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Management.vue"
      ),
  },
  {
    path: "content-delivery",
    name: "plan-site-dev-content-delivery",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_CONTENT_DELIVARY",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/NexcessCDN.vue"
      ),
  },
  {
    path: "scheduled-tasks",
    name: "plan-site-dev-scheduled-tasks",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_SCHEDULED_TASKS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/ScheduledTasks.vue"
      ),
  },
  {
    path: "ssl",
    name: "plan-site-dev-ssl",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_SSL",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/Ssl.vue"
      ),
  },
  {
    path: "visual-comparison",
    name: "plan-site-dev-visual-comparison",
    meta: {
      breadcrumb: "PLAN_SITE_DEV_VISUAL_COMPARISON",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment/VisualComparison.vue"
      ),
  },
];
