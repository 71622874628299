import router from "@/router";

const defaultFunction = () => {
  console.log("Default Nav Function");
};

class NavLink {
  action = defaultFunction;

  advanced = false;

  admin = false;

  disabled = false;

  icon = "default";

  keywords;

  loading = false;

  name;

  popover = null;

  routeName = null;

  selected = false;

  slot = null;

  to = null;

  constructor(
    name,
    icon,
    action = defaultFunction,
    selected = false,
    variant,
    iconColor,
    disabled = false,
    loading = false,
    admin,
    keywords,
    advanced,
    slot,
    popover
  ) {
    this.name = name;
    this.icon = icon;
    this.selected = selected;
    this.variant = [
      "default",
      "info",
      "warning",
      "danger",
      "success",
      "primary",
      "primary",
      "secondary",
    ].includes(variant)
      ? variant
      : "default";
    this.iconColor = iconColor;

    if (typeof action === "string") {
      this.routeName = action;
      const to = { name: action };
      this.action = () => {
        router.push(to, null, (data) => data);
      };
      this.to = to;
    } else if (typeof action === "object") {
      this.routeName = action.name;
      this.action = () => {
        router.push(action, null, (data) => data);
      };
      this.to = action;
    } else {
      this.action = action;
    }
    this.disabled = disabled;
    this.loading = loading;
    this.admin = admin;
    this.keywords = keywords || [];
    this.advanced = !!advanced;
    this.slot = slot;
    this.popover = popover;
  }
}

export default NavLink;
