import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "api-task:add":
      if (data.identity === "cloud-server:add (pending)") {
        store.dispatch("cloudServers/refreshList");
      }
      break;
    case "service:add":
      if (data.type === "virt-guest") {
        store.dispatch("cloudServers/refreshList");
      }
      break;
    case "api-task:update":
      if (
        data.identity === "cloud-server:add (processing)" ||
        data.identity === "cloud-server:add (success)" ||
        data.identity === "cloud-server:delete (processing)" ||
        data.identity === "cloud-server:delete (success)"
      ) {
        store.dispatch("auth/clientInfo");
        store.dispatch("cloudServers/refreshList");
      }
      break;
  }
};

export default handler;
