import Modal from "@/classes/Modal";

const modals = {
  namespaced: true,
  state: {
    stack: [],
    index: 1,
  },
  mutations: {
    ADD_MODAL(state, payload) {
      state.index += 1;
      state.stack = [...state.stack, payload];
    },
    ADD_MODAL_UNDER(state, payload) {
      state.index += 1;
      const orderGreatest =
        Math.max.apply(
          Math,
          state.stack.map((o) => o.order)
        ) || 1;
      payload.order = orderGreatest + 1;
      state.stack = [...state.stack, payload];
    },
    REMOVE_MODAL(state, payload) {
      let newStack = state.stack.slice();
      const foundIndex = payload.id
        ? newStack.findIndex((val) => val.id === payload.id)
        : newStack.findIndex((val) => val.template === payload.template);
      newStack.splice(foundIndex, 1);
      state.stack = [...newStack];
    },
    CLEAR_ALL(state) {
      state.stack = [];
    },
  },
  actions: {
    open({ commit, state }, payload) {
      let added = payload;
      if (!(payload instanceof Modal)) {
        added = new Modal({ id: state.index, ...payload });
      }
      commit("ADD_MODAL", added);

      return added;
    },
    openUnder({ commit, state }, payload) {
      let added = payload;
      if (!(payload instanceof Modal)) {
        added = new Modal({ id: state.index, ...payload });
      }
      commit("ADD_MODAL_UNDER", added);

      return added;
    },
    close({ commit }, payload) {
      let removed = payload;
      if (!(payload instanceof Modal)) {
        removed = new Modal(payload);
      }

      removed.close();

      commit("REMOVE_MODAL", removed);
    },
    confirm({ commit }, { modal, inputValue }) {
      let removed = modal;
      if (!(modal instanceof Modal)) {
        removed = new Modal(modal);
      }

      removed.confirm(inputValue);

      commit("REMOVE_MODAL", removed);
    },
    closeById({ commit, state }, id) {
      const removed = state.stack.find((s) => s.id === id);

      if (removed) {
        removed.close();
        commit("REMOVE_MODAL", removed);
      }
    },
    confirmById({ commit, state }, { id, inputValue }) {
      const removed = state.stack.find((s) => s.id === id);

      if (removed) {
        removed.confirm(inputValue);
        commit("REMOVE_MODAL", removed);
      }
    },
  },
  getters: {
    getStack: (state) => state.stack,
    topModal: (state) => state.stack.sort((a, b) => (a.order > b.order ? 1 : -1))[0] || false,
  },
};

export default modals;
