import { get as lsGet, set as lsSet } from "local-storage";

const SURVEY_KEY = "wizard-survey";

export const clear = (packageId) => {
  const storage = lsGet(SURVEY_KEY);
  if (storage) {
    if (storage[packageId] !== undefined) {
      delete storage[packageId];
    }
    lsSet(SURVEY_KEY, storage);
  }
};

export const getAll = () => lsGet(SURVEY_KEY);

export const set = (packageId, value) => {
  const storage = lsGet(SURVEY_KEY) || {};
  storage[packageId] = value;
  lsSet(SURVEY_KEY, storage);
};
