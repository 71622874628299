import { request } from "leatherman-js";

export const getLatestTask = async (taskActions, status, resourceId) => {
  const getTasks = taskActions.map((action) =>
    request.makeParse("getTaskByActionAndStatus", {
      action,
      status,
    })
  );

  const pending = await Promise.all(getTasks);
  let flattened = [];
  pending.forEach((list) => {
    flattened = flattened.concat(list);
  });

  const last = flattened
    .filter(({ resource }) => resource.id === resourceId)
    .sort((a, b) => b.request_date - a.request_date)[0];

  return last;
};
