import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_ITEM, SET_PAGINATION, SET_SORT, SET_STATUS } from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";
import { updateList } from "@/utils/storeHelper";

const CACHE_PLURAL_KEY = "teamMembers";

const teamMembers = {
  namespaced: true,
  state: {
    chunk: null,
    list: [],
    sort: null,
    pagination: null,
    status: new Status(),
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_CHUNK,
    SET_ITEM,
    SET_PAGINATION,
    SET_STATUS,
    SET_SORT,
  },
  actions: {
    async getList({ commit }, params) {
      try {
        commit("SET_STATUS", Status.LOADING);
        commit("SET_CHUNK", params);
        commit("SET_SORT", params);
        const res = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
          "getTeamMembers",
          params,
        ]);
        commit("SET_LIST", res.list);
        commit("SET_PAGINATION", res.pagination);
        commit("SET_STATUS", Status.LOADED);
        return res;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    delete({ dispatch, state }, id) {
      return request.makeParse("deleteMember", { id }).finally(() => {
        updateList(dispatch, state);
      });
    },
    refreshList({ dispatch }, params) {
      clearListCache(createKey(CACHE_PLURAL_KEY, params));
      dispatch("getList", params);
    },
  },
  getters: {
    getList(state) {
      return state.list;
    },
    getListStatus(state) {
      return state.status;
    },
  },
};

export default teamMembers;
