import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";
import store from "@/store";

const STORAGE_NAME = "hud-storage";

const TTL = // 5 Days in Milliseconds
  5 * // Days
  24 * // Hours in Days
  60 * // Minutes in Hour
  60 * // Seconds in Minute
  1000; // Milliseconds in Second

const storageName = () => `${STORAGE_NAME}-${store?.getters["auth/getUser"]?.id}`;

export const createKey = (prefix, id) => `${prefix}:${id}`;

const getTypeKeys = (type) =>
  Object.keys((lsGet(storageName()) || {})?.keys).filter((storageKey) =>
    storageKey.startsWith(type)
  );

export const clearType = (type) =>
  getTypeKeys(type).forEach((storageKey) => clearKey(storageName(), storageKey));

export const setKey = (key) => {
  const storage = lsGet(storageName()) || {};
  const expires = new Date().getTime() + TTL;

  if (!("keys" in storage)) {
    storage.keys = {};
  }

  storage.keys[key] = {
    data: true,
    expires,
  };
  storage.expires = expires;

  lsSet(storageName(), storage);
};

export const clearKey = (storageName, key) => {
  const storage = lsGet(storageName);
  if (storage?.keys[key] !== undefined) {
    delete storage.keys[key];
  }
  lsSet(storageName, storage);
};

export const getUserStorage = () => lsGet(storageName());

const getAllStorageNames = () =>
  Object.keys(localStorage).filter((item) => item.startsWith(STORAGE_NAME));

export const clearAllExpired = () =>
  getAllStorageNames()?.forEach((name) => {
    const storage = lsGet(name);
    const now = new Date().getTime();

    if (!storage?.expires || now > storage.expires) {
      lsRemove(name);
    } else {
      Object.entries(storage.keys).forEach(([key, value]) => {
        if (!value?.expires || now > value?.expires) {
          clearKey(name, key);
        }
      });
    }
  });
