import { createKey, get } from "@/utils/networkCache";
import { models, request } from "leatherman-js";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_ITEM_STATUS,
  SET_LIST,
  SET_LIST_STATUS,
  SET_PAGINATION,
  SET_SORT,
} from "@/store/commonMutations";
import { requireSurvey } from "@/utils/surveys";
import Status from "@/classes/Status";

const CACHE_ITEM_KEY = "site-package";
const CACHE_LIST_KEY = "site-packages";

const CACHE_EXPIRY = 60 * 60 * 1000; // 1 hour

const module = {
  namespaced: true,
  state: {
    item: null,
    itemStatus: new Status(),
    list: [],
    listStatus: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_ITEM_STATUS,
    SET_LIST,
    SET_LIST_STATUS,
    SET_PAGINATION,
    SET_SORT,
  },
  actions: {
    get: async ({ commit }, id) => {
      commit("SET_ITEM_STATUS", Status.LOADING);
      try {
        const response = await get(createKey(CACHE_ITEM_KEY, { id }), request.makeParse, [
          "getSitePackage",
          { id },
        ]);
        commit("SET_ITEM", response);
        commit("SET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ITEM_STATUS", Status.ERROR);
      }
    },
    getList: async ({ commit }, params) => {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        const response = await get(
          createKey(CACHE_LIST_KEY, params),
          request.makeParse,
          ["getSitePackages", params],
          CACHE_EXPIRY
        );
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    getItem: (state) => state.item,
    getItemStatus: (state) => state.itemStatus,
    getList: (state) => state.list,
    getListedPackagesByApp: (state, getters) => (app) => {
      if (!app) {
        return [];
      }

      return getters["getOrderableList"].filter(
        (pack) =>
          models.Package.isCompatibleWithApp(pack, app) &&
          models.Package.canAdvertiseWithApp(pack, app) &&
          !requireSurvey(pack) &&
          pack.listed
      );
    },
    getListStatus: (state) => state.listStatus,
    getOrderableList: (state) => state.list.filter((pack) => pack.label !== "mwp.vip"),
    getPackageById: (state) => (packageId) => state.list.find(({ id }) => id === packageId),
  },
};

export default module;
