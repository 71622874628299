import components from "@/router/routes/resources/components";

export default {
  path: "/resources",
  component: () => import(/* webpackChunkName: "resources" */ "@/pages/Resources.vue"),
  children: [
    {
      path: "colors",
      name: "resources-colors",
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "resources" */ "@/pages/Resources/Colors.vue"),
    },
    components,
    {
      path: "typography",
      name: "resources-typography",
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "resources" */ "@/pages/Resources/Typography.vue"),
    },
  ],
};
