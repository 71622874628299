import { constants } from "@/utils";
import { hasPermissions } from "@/utils/usePermissions";

const billing = [
  {
    path: "invoices",
    name: "invoices",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/Invoices.vue"),
    meta: {
      breadcrumb: "BILLING_INVOICES",
    },
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["invoices"],
      redirect: "credits",
    }),
  },
  {
    path: "invoices/:id",
    name: "invoice-detail",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/InvoiceDetail.vue"),
    meta: {
      breadcrumb: "BILLING_INVOICE",
      sidebar: "BILLING_INVOICE",
    },
    props: (route) => ({
      invoiceId: route.params.id,
    }),
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["invoices"],
      redirect: constants.ROUTES.HOME,
    }),
  },
  {
    path: "credits",
    name: "credits",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/Credits.vue"),
    meta: {
      breadcrumb: "BILLING_CREDITS",
    },
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["credits"],
      redirect: "payments",
    }),
  },
  {
    path: "credits/:id",
    name: "credit-detail",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/CreditDetail.vue"),
    meta: {
      breadcrumb: "BILLING_CREDIT",
      sidebar: "BILLING_CREDIT",
    },
    props: (route) => ({
      creditId: route.params.id,
    }),
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["credits"],
      redirect: constants.ROUTES.HOME,
    }),
  },
  {
    path: "payments",
    name: "payments",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/Payments.vue"),
    meta: {
      breadcrumb: "BILLING_PAYMENTS",
    },
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["payments"],
      redirect: "payment-info",
    }),
  },
  {
    path: "payments/:id",
    name: "payment-detail",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/InvoiceDetail.vue"),
    meta: {
      breadcrumb: "BILLING_PAYMENT",
      sidebar: "BILLING_PAYMENT",
    },
    props: (route) => ({
      invoiceId: route.params.id,
    }),
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["payments"],
      redirect: constants.ROUTES.HOME,
    }),
  },
  {
    path: "credit-cards",
    name: "credit-cards",
    redirect: () => ({
      name: "payment-info",
    }),
  },
  {
    path: "payment-info",
    name: "payment-info",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/Taxes.vue"),
    meta: {
      breadcrumb: "BILLING_PAYMENT_INFO",
    },
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["addresses", "creditcards", "account"],
      redirect: "orders",
    }),
  },
  {
    path: "orders",
    name: "orders",
    meta: {
      breadcrumb: "BILLING_ORDERS",
    },
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/Orders.vue"),
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["orders"],
      redirect: "invoices",
    }),
  },
  {
    path: "orders/:id",
    name: "order-detail",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/pages/Dashboard/Billing/OrderDetail.vue"),
    meta: {
      breadcrumb: "BILLING_ORDER",
      sidebar: "BILLING_ORDER",
    },
    props: (route) => ({
      orderId: route.params.id,
    }),
    beforeEnter: hasPermissions.bind(null, {
      permissions: ["orders"],
      redirect: constants.ROUTES.HOME,
    }),
  },
];

export default billing;
