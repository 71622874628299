import { clearAll } from "@/utils/networkCache";
import constants from "@/utils/constants";
import router from "@/router";
import store from "@/store";

const switchClientAction = (id, route = { name: constants.ROUTES.HOME }) => {
  const currentClient = store.getters["auth/getClient"];
  if (currentClient.id !== id) {
    clearAll();
    store.dispatch("overlay/enableOverlay", "Switching clients...");
    store.dispatch("auth/switchClient", { clientId: id }).then(() => {
      store.dispatch("auth/userClientInfo").then(() => {
        router
          .push(route)
          .catch(() => {})
          .then(() => router.go());
      });
    });
  }
};

export { switchClientAction };
