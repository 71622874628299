import * as io from "socket.io-client";
import backupHandler from "@/store/plugins/handlers/backup";
import certificateHandler from "@/store/plugins/handlers/certificate";
import clientHandler from "@/store/plugins/handlers/client";
import cloudServer from "@/store/plugins/handlers/cloudServer";
import databaseHandler from "@/store/plugins/handlers/database";
import databaseUserHandler from "@/store/plugins/handlers/databaseUser";
import dnsZoneHandler from "@/store/plugins/handlers/dnsZone";
import emailAliasHandler from "@/store/plugins/handlers/emailAlias";
import emailBoxHandler from "@/store/plugins/handlers/emailBox";
import emailResponderHandler from "@/store/plugins/handlers/emailResponder";
import ftpHandler from "@/store/plugins/handlers/ftp";
import hudHandler from "@/store/plugins/handlers/hud";
import invoiceHandler from "@/store/plugins/handlers/invoice";
import letsencryptHandler from "@/store/plugins/handlers/letsencrypt";
import orderHandler from "@/store/plugins/handlers/order";
import passwordHandler from "@/store/plugins/handlers/password";
import paymentMethodHandler from "@/store/plugins/handlers/paymentMethod";
import phpHandler from "@/store/plugins/handlers/php";
import pointerDomainHandler from "@/store/plugins/handlers/pointerDomain";
import rejectedHandler from "@/store/plugins/handlers/rejected";
import remoteUserHandler from "@/store/plugins/handlers/remoteUser";
import safeHarborHandler from "@/store/plugins/handlers/safeHarbor";
import serviceHandler from "@/store/plugins/handlers/service";
import siteHandler from "@/store/plugins/handlers/site";
import siteHandlerV1 from "@/store/plugins/handlers/v1/site";
import sshKeyHandler from "@/store/plugins/handlers/sshKey";
import stencilHandler from "@/store/plugins/handlers/stencil";
import taxHandler from "@/store/plugins/handlers/tax";
import teamHandler from "@/store/plugins/handlers/team";
import ticketHandler from "@/store/plugins/handlers/ticket";
import tokensHandler from "@/store/plugins/handlers/tokens";
import visualComparison from "@/store/plugins/handlers/visualComparison";
import wpWooHandler from "@/store/plugins/handlers/wpWoo";

export default function () {
  const config = {
    reconnectionAttempts: 9,
    transports: ["websocket", "polling"],
    path: "/websocket-v2/socket.io",
  };

  return (store) => {
    let socket = null;
    const connect = () => {
      if (!store.getters["auth/getUser"]) return;

      const token = store.getters["auth/getToken"].fullString;
      const user = store.getters["auth/getUser"];

      socket = io.connect(process.env.VUE_APP_WEBSOCKET, config);

      socket.on("connect", () => {
        socket.emit("extranet:jwt", { token, identity: user.identity });
      });

      socket.on(`user:${user.id}`, (message) => {
        const version = message?.version || "0.0.0";
        if (version === "0.0.0") {
          emailBoxHandler(message.event, message.data);
          emailAliasHandler(message.event, message.data);
          emailResponderHandler(message.event, message.data);
          ftpHandler(message.event, message.data);
          hudHandler(message.event, message.data);
          databaseHandler(message.event, message.data);
          databaseUserHandler(message.event, message.data);
          dnsZoneHandler(message.event, message.data);
          passwordHandler(message.event, message.data);
          phpHandler(message.event, message.data);
          remoteUserHandler(message.event, message.data);
          safeHarborHandler(message.event, message.data);
          serviceHandler(message.event, message.data);
          siteHandler(message.event, message.data);
          stencilHandler(message.event, message.data);
          ticketHandler(message.event, message.data);
          tokensHandler(message.event, message.data);
          wpWooHandler(message.event, message.data);
          letsencryptHandler(message.event, message.data);
          pointerDomainHandler(message.event, message.data);
          certificateHandler(message.event, message.data);
          backupHandler(message.event, message.data);
          visualComparison(message.event, message.data);
          teamHandler(message.event, message.data);
          taxHandler(message.event, message.data);
          sshKeyHandler(message.event, message.data);
          cloudServer(message.event, message.data);
          invoiceHandler(message.event, message.data);
          orderHandler(message.event, message.data);
          paymentMethodHandler(message.event, message.data);
          clientHandler(message.event, message.data);
          rejectedHandler(message.event, message.data);
          //TODO MAD-4371 - Add Client Update Event When Available
        }
        if (version === "1.0.0") {
          siteHandlerV1(message.event, message.data);
        }
      });
    };

    store.watch(
      (state) => state.auth.user,
      (newValue, oldValue) => {
        if (oldValue == null && oldValue !== newValue) {
          connect();
        } else if (socket && newValue == null && oldValue !== newValue) {
          socket.close();
        } else if (socket && newValue?.id !== oldValue?.id) {
          socket.close();
          connect();
        }
      }
    );
  };
}
