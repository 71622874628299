import { getSurveyType, requireSurvey } from "@/utils/surveys";
import { request } from "leatherman-js";

const transformStoreBuilderBody = {
  industry: "industry",
  alreadySelling: "already_selling_products",
  currentPlatform: "platform_currently_using",
  howSell: "plan_to_sell",
  salesMobileOrder: "in_person_sales_needs[mobile_ordering]",
  salesMobileFunds: "in_person_sales_needs[mobile_point_of_sale]",
  salesMobileCatalog: "in_person_sales_needs[mobile_catalog]",
  catalogSize: "product_catalog_size",
  // hasVariations: "has_variations[has_variations]",
  contentExplainer: "existing_content_types[explainer_video]",
  contentKeyDifferentiate: "existing_content_types[key_differentiating_features]",
  contentArticles: "existing_content_types[articles_on_other_sites_telling_your_story]",
  contentCaseStudies: "existing_content_types[case_studies_of_happy_customers]",
  contentReturnPolicy: "existing_content_types[amazing_return_policy]",
  contentSocial: "existing_content_types[social_proof]",
  contentOffers: "existing_content_types[special_offers]",
  contentCoupons: "existing_content_types[special_coupons]",
  contentNewsletter: "existing_content_types[email_newsletter_signups]",
  contentReviews: "existing_content_types[customer_reviews]",
  loadProducts: "demo_products[demo_products]",
};

const surveys = {
  namespaced: true,
  state: {
    fields: {},
    id: null,
  },
  mutations: {
    SET_SURVEY_ID(state, payload) {
      state.id = payload;
    },
    SET_FIELDS(state, payload) {
      state.fields = { ...payload };
    },
  },
  actions: {
    setFields({ commit }, payload) {
      commit("SET_FIELDS", payload);
    },
    reset({ commit }) {
      commit("SET_SURVEY_ID", null);
      commit("SET_FIELDS", {});
    },
    setId({ commit }, id) {
      commit("SET_SURVEY_ID", id);
    },
    async getStoreBuilderId({ commit }, fields) {
      const body = new FormData();

      // build url encoded form data body out of store builder fields object
      Object.keys(fields).forEach((key) => {
        let value = fields[key];
        const transformedKey = transformStoreBuilderBody[key];
        if (value) {
          if (typeof value === "boolean") {
            value = value ? "on" : "off";
          }
          body.append(transformedKey, value);
        }
      });

      try {
        const response = await request.direct({
          method: "POST",
          url: process.env.VUE_APP_WPQUICKSTART_STOREBUILDER_API,
          headers: {
            "X-Api-Key": process.env.VUE_APP_WPQUICKSTART_STOREBUILDER_API_KEY,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: body,
        });

        commit("SET_SURVEY_ID", response.data.uuid);

        return response.data.uuid;
      } catch (error) {
        console.log(error);
      }
    },
    async getWpQuickStartId({ commit }, fields) {
      const responses = {
        free_tier: fields.freeTier,
        teams: fields.teams,
        selling_content: fields.sellingContent,
        upsell: fields.upsell,
        events: fields.events,
      };

      const response = await request.direct({
        method: "POST",
        url: process.env.VUE_APP_WPQUICKSTART_MEMBERSHIP_API,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          type: "membership",
          responses,
        },
      });

      commit("SET_SURVEY_ID", response.data.uuid);

      return response.data.uuid;
    },
  },
  getters: {
    getSurveyType: (state, getters, rootState, rootGetters) => {
      const planItem = rootGetters["cart/getPlanItems"][0];
      if (!planItem?.packageId) return null;

      const pkg =
        rootGetters["packages/site/getPackageById"](planItem?.packageId) ||
        rootGetters["packages/site/getItem"];

      return getSurveyType(pkg);
    },
    requireSurvey: (state, getters, rootState, rootGetters) => {
      const planItem = rootGetters["cart/getPlanItems"];
      if (!planItem?.packageId) return null;

      const pkg =
        rootGetters["packages/site/getPackageById"](planItem?.packageId) ||
        rootGetters["packages/site/getItem"];

      return requireSurvey(pkg);
    },
  },
};

export default surveys;
