import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_LIST, SET_STATUS } from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "pointerDomains";

const store = {
  namespaced: true,
  state: {
    list: [],
    types: [],
    status: new Status(),
    siteId: null,
  },
  mutations: {
    SET_LIST,
    SET_TYPES(state, payload) {
      state.types = payload;
    },
    SET_STATUS,
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async getList({ commit }, siteId) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_SITE_ID", siteId);
      try {
        const response = await get(
          createKey(CACHE_PLURAL_KEY, { siteId: siteId }),
          request.makeParse,
          ["getSitePointerDomains", { siteId: siteId }]
        );
        const { domains, types } = response;
        commit("SET_LIST", domains);
        commit("SET_TYPES", types);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    create(context, { siteId, domain, domainType }) {
      return request.makeParse("createSitePointerDomain", { siteId }, { domain, type: domainType });
    },
    delete(context, { siteId, domain }) {
      return request.makeParse("deleteSitePointerDomain", { siteId }, { domain });
    },
    refreshList({ dispatch, state }) {
      clearListCache(createKey(CACHE_PLURAL_KEY, { siteId: state.siteId }));
      if (state.siteId) {
        dispatch("getList", state.siteId);
      }
    },
  },
  getters: {
    getList: (state) => {
      return state.list;
    },
    getStatus: (state) => {
      return state.status;
    },
    getTypeList: (state) => {
      return state.types;
    },
  },
};

export default store;
