import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "api-task:add":
      if (
        data.identity === "client:set-tax-exempt (success)" ||
        data.identity === "client:remove-tax-exempt (success)"
      ) {
        store.dispatch("auth/clientInfo");
      }
      break;
    case "api-task:update":
      if (data.identity === "client:remove-tax-exempt (success)") {
        store.dispatch("auth/clientInfo");
      }
      break;
  }
};

export default handler;
