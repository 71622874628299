<template>
  <Modal title="Logging Out..." :hide-close="true" :hide-footer="true">
    <template #body>
      <LoadingState />
    </template>
  </Modal>
</template>

<script>
import LoadingState from "@/components/display/LoadingState";
import Modal from "@/components/display/Modal";
import { onMounted } from "vue";
import store from "@/store";

export default {
  name: "Logout",
  components: {
    LoadingState,
    Modal,
  },
  emits: ["close"],
  props: {
    reason: String,
    redirect: String,
  },
  setup(props, { emit }) {
    onMounted(() => {
      store
        .dispatch("auth/logout", { redirect: props.redirect, reason: props.reason })
        .then(() => emit("close"));
    });
  },
};
</script>
