<template>
  <div>
    <!-- CartStack stuff -->
  </div>
</template>

<script>
import constants from "@/utils/constants";
import { onBeforeMount } from "vue";

export default {
  name: "CartStack",
  props: {},
  setup() {
    const target = process.env.VUE_APP_CARTSTACK_TARGET;
    const siteId = process.env.VUE_APP_CARTSTACK_SITE_ID;
    const scriptSrc = process.env.VUE_APP_CARTSTACK_SRC;

    onBeforeMount(() => {
      // nothing will work without these, so skip if either are missing
      if (!siteId || !scriptSrc || !target) {
        return;
      }

      fetch("https://api.ipify.org")
        .then((res) => res.text())
        .then((myIp) => fetch(`https://ipapi.co/${myIp}/country`))
        .then((res) => res.text())
        .then((country) => {
          // if country of origin isn't in whitelist, skip cartstack
          if (!constants.CARTSTACK_COUNTRY_WHITELIST.includes(country)) {
            return;
          }

          const s = document.createElement("script");
          s.id = "cartstack-script";
          s.type = "text/javascript";
          s.src = scriptSrc;
          s.setAttribute("data-siteid", siteId);
          s.onload = function () {
            // suppress automatic detection of email fields
            window._cartstack.push(["setEmailAddressInput", ""]);
          };
          const c = document.getElementsByTagName("script")[0];
          c.parentNode.insertBefore(s, c);
        });
    });

    return {};
  },
};
</script>
