import addons from "@/store/modules/packages/addons";
import cloudServer from "@/store/modules/packages/cloudServer";
import devSite from "@/store/modules/packages/devSite";
import site from "@/store/modules/packages/site";
import ssl from "@/store/modules/packages/ssl";
import stagingSite from "@/store/modules/packages/stagingSite";

const module = {
  namespaced: true,
  modules: {
    addons,
    cloudServer,
    devSite,
    site,
    ssl,
    stagingSite,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
};

export default module;
