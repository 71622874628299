import { models } from "leatherman-js";
import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "app-phpcompatibilitycheck:add":
    case "app-phpcompatibilitycheck:update":
      store.dispatch(
        "php/refreshCompatibilityCheck",
        models.PhpCompatibilityCheck.formatWebsocketEvent(data)
      );
      break;
    case "api-task:add":
    case "api-task:update":
      if (data.identity.includes("change-php-version")) {
        if (data.identity.includes("(pending)") || data.identity.includes("(processing)")) {
          store.dispatch("php/addVersionUpdate", data.resource_id);
        }
        if (data.identity.includes("(success)")) {
          store.dispatch("toasts/addToast", {
            variant: "success",
            message: "You have successfully changed your PHP Version!",
          });
          store.dispatch("php/removeVersionUpdate", data.resource_id);
          store.dispatch("siteCron/getOptions", data.resource_id);
        }
        if (data.identity.includes("(rejected)")) {
          store.dispatch("php/removeVersionUpdate", data.resource_id);
        }
        store.dispatch("sites/refreshItem", data.resource_id);
        store.dispatch("devSites/refreshItem", data.resource_id);
        break;
      }
  }
};

export default handler;
