import store from "@/store";

const handlers = (event, data) => {
  switch (event) {
    case "app-environment-upgrade:add":
      store.dispatch("visualComparison/refreshList", data.cloud_account_id);
      break;
    case "app-environment-upgrade:update":
      store.dispatch("visualComparison/refreshList", data.cloud_account_id);
      break;
  }
};

export default handlers;
