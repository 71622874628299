import breadcrumbs from "@/utils/breadcrumbs";
import constants from "@/utils/constants";
import NavLink from "@/classes/NavLink";
import { sidebarModes } from "@/utils/sidebarModes";

const defaultBreadcrumbs = [new NavLink("Home", "home", constants.ROUTES.HOME)];

const nav = {
  namespaced: true,
  state: {
    menuMode: "MAIN",
    homeRoute: constants.ROUTES.HOME,
    userMenuItems: [],
    breadcrumbs: defaultBreadcrumbs,
    currentRoute: null,
  },
  mutations: {
    SET_CURRENT_ROUTE: (state, payload) => (state.currentRoute = { ...payload }),
  },
  actions: {
    setCurrentRoute: ({ commit }, payload) => commit("SET_CURRENT_ROUTE", payload),
  },
  getters: {
    getBreadcrumbs: (state, getters, rootState) => {
      // if currentRoute isn't set, don't bother setting the breadcrumb trail
      if (!state.currentRoute) {
        return {};
      }

      // check if a breadcrumb has been set
      // if not, then don't create one for the current route
      const breadcrumbMode = state.currentRoute?.meta?.breadcrumb;
      if (!breadcrumbMode) {
        return {};
      }

      // get everything that breadcrumbs need to build a trail
      const { client, user } = rootState.auth;
      const announcement = rootState.announcements.item;
      const plan = rootState.plans.item;
      const sharedHost = rootState.sharedHosts.item;
      const site = rootState.sites.item;
      const devSite = rootState.devSites.item;
      const dnsZone = rootState.dnsZones.item;
      const domain = rootState.domains.item;
      const rdnsRecord = rootState.rdnsRecords.item;
      const invoice = rootState.invoices.item;
      const credit = rootState.credits.item;
      const creditcard = rootState.paymentMethods.item;
      const payment = rootState.payments.item;
      const order = rootState.orders.item;
      const other = rootState.others.item;
      const partnerLink = rootState.partnerLinks.item;
      const partnerReferral = rootState.partnerReferrals.item;
      const ticket = rootState.tickets.item;
      const cloudServer = rootState.cloudServers.item;
      const enterpriseCloud = rootState.enterpriseCloud.item;

      const breadcrumbHandler = breadcrumbs[breadcrumbMode];
      // if no handler was found, return
      if (!breadcrumbHandler) {
        return {};
      }

      // find and build the breadcrumb trail
      return breadcrumbHandler({
        announcement,
        client,
        user,
        plan,
        sharedHost,
        site,
        devSite,
        dnsZone,
        domain,
        rdnsRecord,
        invoice,
        credit,
        creditcard,
        payment,
        order,
        other,
        partnerLink,
        partnerReferral,
        ticket,
        cloudServer,
        enterpriseCloud,
      });
    },
    getMenu: (state, getters, rootState, rootGetters) => {
      // if currentRoute isn't set, don't bother setting the sidebar
      if (!state.currentRoute) {
        return {};
      }

      // find the nearest sidebar by searching up the hierarchy of routes
      // if no menu mode is found (which is unlikely) don't set the sidebar
      const matchedRoutes = [...state.currentRoute.matched];
      const menuModeRecord = matchedRoutes.reverse().find((route) => !!route.meta.sidebar);
      const menuMode = menuModeRecord?.meta?.sidebar;
      if (!menuMode) {
        return {};
      }

      // get everything that sidebars will need
      const isAdmin = rootGetters["auth/isAdmin"];
      const isBeta = rootGetters["auth/isBetaTester"];
      const { client, user } = rootState.auth;
      const plan = rootState.plans.item;
      const sharedHost = rootState.sharedHosts.item;
      const site = rootState.sites.item;
      const company = rootState.company.current;
      const devSite = rootState.devSites.item;
      const dnsZone = rootState.dnsZones.item;
      const rdnsRecord = rootState.rdnsRecords.item;
      const sitePermissions = rootGetters["permissions/site/get"];

      const sidebarHandler = sidebarModes[menuMode];
      // if no handler was found, return
      if (!sidebarHandler) {
        return {};
      }

      // find and build the sidebar based on the selected sidebar mode
      return sidebarHandler({
        client,
        company,
        devSite,
        dnsZone,
        isAdmin,
        isBeta,
        plan,
        rdnsRecord,
        sharedHost,
        site,
        sitePermissions,
        user,
      });
    },
    hideSidebar: (state) => state.currentRoute?.meta?.hideSidebar,
  },
};

export default nav;
