import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PROTECTION_KEY = "site-password-protection";

const store = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    updates: [],
  },
  mutations: {
    ADD_UPDATE(state, id) {
      const index = state.updates.findIndex((item) => item === Number(id));
      if (index < 0) {
        state.updates.push(Number(id));
      }
    },
    REMOVE_UPDATE(state, id) {
      const index = state.updates.findIndex((item) => item === Number(id));
      if (index >= 0) {
        state.updates.splice(index, 1);
      }
    },
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_STATUS(state, status) {
      state.status.value = status;
    },
  },
  actions: {
    addUpdate({ commit }, id) {
      commit("ADD_UPDATE", id);
    },
    enable(context, id) {
      return request.makeParse("enableSitePasswordProtection", { id });
    },
    disable(context, id) {
      return request.makeParse("disableSitePasswordProtection", { id });
    },
    async get({ commit, state }, id) {
      const newStatus =
        (state.status.isLoaded || state.status.isUpdating) && id === state.item?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_PROTECTION_KEY, { id }), request.makeParse, [
          "getSitePasswordProtection",
          { id },
        ]);
        commit("SET_ITEM", response);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    refresh({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_PROTECTION_KEY, { id });
      clear(cacheKey);
      if (id === state.item?.siteId) {
        return dispatch("get", id);
      }
    },
    removeUpdate({ commit }, id) {
      commit("REMOVE_UPDATE", id);
    },
  },
  getters: {
    get: (state) => state.item,
    getStatus: (state) => state.status,
    isUpdating: (state) => (id) => state.updates.findIndex((item) => item === Number(id)) >= 0,
  },
};

export default store;
