const SET_CHUNK = (state, payload) => {
  const pageIndex = payload?.pageIndex;
  const pageSize = payload?.pageSize;
  const sortDirection = payload?.sortDirection;
  const sortProperty = payload?.sortProperty;
  const pagination = pageIndex || pageSize ? { index: pageIndex, size: pageSize } : null;
  const filterKeys = Object.keys(payload || {})?.filter((item) => /filter\[(.*)\]/.test(item));
  const filters =
    filterKeys.length > 0
      ? filterKeys.reduce(
          (result, item) => ({
            ...result,
            ...{
              [item]: payload[item],
            },
          }),
          {}
        )
      : null;
  const sort =
    sortDirection || sortProperty ? { direction: sortDirection, property: sortProperty } : null;
  state.chunk = pagination || sort || filters ? { pagination, sort, filters } : null;
};

const SET_ITEM = (state, payload) => {
  state.item = payload;
};

const SET_ITEM_STATUS = (state, status) => {
  state.itemStatus.value = status;
};

const SET_LIST = (state, payload) => {
  state.list = payload;
};

const SET_LIST_STATUS = (state, status) => {
  state.listStatus.value = status;
};

const SET_PAGINATION = (state, payload) => {
  state.pagination = payload;
};

const SET_SORT = (state, payload) => {
  const sort = {};
  if (payload?.sortProperty) sort.property = payload?.sortProperty;
  if (payload?.sortDirection) sort.direction = payload?.sortDirection;
  state.sort = payload?.sortProperty || payload?.sortDirection ? sort : null;
};

const SET_STATUS = (state, payload) => {
  state.status.value = payload;
};

const UPDATE_IN_PLACE = (state, payload) => {
  const index = state.list.findIndex((item) => item.id === payload.id);
  state.list[index] = payload;
};

export {
  SET_CHUNK,
  SET_ITEM,
  SET_ITEM_STATUS,
  SET_LIST,
  SET_LIST_STATUS,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
  UPDATE_IN_PLACE,
};
