import { clear, createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_USER_KEY = "site-remote-user";

const store = {
  namespaced: true,
  state: {
    user: null,
    userStatus: new Status(),
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_USER_STATUS(state, status) {
      state.userStatus.value = status;
    },
  },
  actions: {
    async getUser({ commit, state }, id) {
      const newStatus =
        (state.userStatus.isLoaded || state.userStatus.isUpdating) && id === state.user?.siteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_USER_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_USER_KEY, { id }), request.makeParse, [
          "getSiteRemoteUserInfo",
          { id },
        ]);
        commit("SET_USER", response);
        commit("SET_USER_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_USER_STATUS", Status.ERROR);
      }
    },
    refreshUser({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_USER_KEY, { id });
      clear(cacheKey);
      if (id === state.user?.siteId) {
        dispatch("getUser", id);
      }
    },
    async updatePassword(context, id) {
      return request.makeParse("updateSiteRemotePassword", { id });
    },
  },
  getters: {
    getUser: (state) => state.user,
    getUserStatus: (state) => state.userStatus,
  },
};

export default store;
