export default [
  {
    path: "analytics",
    name: "plan-safe-harbor-staging-environment-analytics",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_ANALYTICS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Analytics"
      ),
  },
  {
    path: "backups",
    name: "plan-safe-harbor-staging-environment-backups",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_BACKUPS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Backups"
      ),
  },
  {
    path: "containers",
    name: "plan-safe-harbor-staging-environment-containers",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_CONTAINERS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Containers"
      ),
  },
  {
    path: "control-panel",
    name: "plan-safe-harbor-staging-environment-control-panel",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_CONTROL_PANEL",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/ControlPanel"
      ),
  },
  {
    path: "credentials",
    name: "plan-safe-harbor-staging-environment-credentials",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_CREDENTIALS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Credentials"
      ),
  },
  {
    path: "databases",
    name: "plan-safe-harbor-staging-environment-databases",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_DATABASES",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Databases"
      ),
  },
  {
    path: "domains",
    name: "plan-safe-harbor-staging-environment-domains",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_DOMAINS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Domains"
      ),
  },
  {
    path: "email",
    name: "plan-safe-harbor-staging-environment-email",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_EMAIL",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Email"
      ),
  },
  {
    path: "logs",
    name: "plan-safe-harbor-staging-environment-logs",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_LOGS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Logs"
      ),
  },
  {
    path: "management",
    name: "plan-safe-harbor-staging-environment-management",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_MANAGEMENT",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Management"
      ),
  },
  {
    path: "scheduled-tasks",
    name: "plan-safe-harbor-staging-environment-scheduled-tasks",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_SCHEDULED_TASKS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/ScheduledTasks"
      ),
  },
  {
    path: "ssl",
    name: "plan-safe-harbor-staging-environment-ssl",
    meta: {
      breadcrumb: "PLAN_SAFE_HARBOR_STAGING_ENVIRONMENT_SSL",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/SharedHosting/SafeHarbor/StagingEnvironment/Ssl"
      ),
  },
];
