import { constants } from "@/utils";

const { ROUTES } = constants;

export default [
  {
    path: ROUTES.WIZARD.WPQUICKSTART.PATH,
    name: ROUTES.WIZARD.WPQUICKSTART.NAME,
    component: () => import(/* webpackChunkName: "configure" */ "@/pages/Configure/WPQuickStart"),
    children: [
      {
        path: ROUTES.WIZARD.WPQUICKSTART.STOREBUILDER.PATH,
        name: ROUTES.WIZARD.WPQUICKSTART.STOREBUILDER.NAME,
        component: () =>
          import(/* webpackChunkName: "configure" */ "@/pages/Configure/StoreBuilder/StoreBuilder"),
        props: (route) => ({
          packageId: parseInt(
            new URL(`https://my.nexcess.net${route.query.target}`).searchParams.get("packageId")
          ),
        }),
      },
    ],
  },
  {
    path: "/storebuilder",
    name: "storebuilder",
    component: () =>
      import(/* webpackChunkName: "cancellation" */ "@/pages/Dashboard/StoreBuilder.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
