const dns = [
  {
    path: "zones",
    name: "dns-zones",
    meta: {
      breadcrumb: "DNS_ZONES",
    },
    component: () => import(/* webpackChunkName: "dns" */ "@/pages/Dashboard/Dns/DnsZones.vue"),
  },
  {
    path: "zones/:id",
    name: "dns-zone",
    meta: {
      breadcrumb: "DNS_ZONE",
      sidebar: "DNS_ZONE",
    },
    component: () =>
      import(/* webpackChunkName: "dns" */ "@/pages/Dashboard/Dns/DnsZoneDetail.vue"),
    props: (route) => ({
      dnsZoneId: Number(route.params.id),
    }),
  },
  {
    path: "reverse",
    name: "rdns-records",
    meta: {
      breadcrumb: "RDNS_RECORDS",
    },
    component: () => import(/* webpackChunkName: "dns" */ "@/pages/Dashboard/Dns/RdnsRecords.vue"),
  },
  {
    path: "reverse/:id",
    name: "rdns-record",
    meta: {
      breadcrumb: "RDNS_RECORD",
      sidebar: "RDNS_RECORD",
    },
    component: () =>
      import(/* webpackChunkName: "dns" */ "@/pages/Dashboard/Dns/RdnsRecordDetail.vue"),
    props: (route) => ({
      rdnsRecordId: route.params.id,
    }),
  },
];

export default dns;
