import { request } from "leatherman-js";

const module = {
  namespaced: true,
  actions: {
    add: (context, { dnsZoneId, hostname, ttl, value }) =>
      request.makeParse("addDnsZoneTxtRecord", null, {
        dnsZoneId,
        hostname,
        ttl,
        value,
      }),
    update: (context, { dnsZoneId, hostname, id, ttl, value, force }) =>
      request.makeParse(
        "updateDnsZoneTxtRecord",
        { id },
        {
          dnsZoneId,
          hostname,
          ttl,
          value,
          force,
        }
      ),
  },
};

export default module;
