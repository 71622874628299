import { actions, getters, mutations, state } from "@/store/siteStore";
import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import { getPaginationParams } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "dev-sites";
const CACHE_SINGULAR_KEY = "dev-site";

const devSites = {
  namespaced: true,
  state,
  mutations,
  actions: {
    ...actions,
    async get({ commit, state }, id) {
      const newStatus =
        (state.itemStatus.isLoaded || state.itemStatus.isUpdating) && id === state.item?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_ITEM_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_SINGULAR_KEY, { id }), request.makeParse, [
          "getSite",
          { id },
        ]);
        if (id === state.itemId) {
          commit("SET_ITEM", response);
          commit("SET_ITEM_STATUS", Status.LOADED);
        }
        return response;
      } catch (e) {
        console.log("devSites/get", { e });
        commit("SET_ITEM_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    create(context, { siteId, data }) {
      clear("getDevSites");
      const {
        copyData,
        copyUserData,
        anonymize,
        packageId,
        planId,
        environmentType,
        locationId,
        addonIds,
        installApp,
      } = data;
      return request.makeParse(
        "createDevSite",
        {},
        {
          copyData,
          copyUserData,
          anonymize,
          packageId,
          siteId,
          planId,
          environmentType,
          locationId,
          addonIds,
          installApp,
        }
      );
    },
    delete(context, planId) {
      clear("getDevSites");
      return request.makeParse("deleteDevSite", { planId });
    },
    async getList({ commit, state }, params) {
      const newStatus =
        (state.status.isLoaded || state.status.isUpdating) &&
        state.list?.siteId === params?.parentSiteId
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_STATUS", newStatus);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);

      params = { ...getPaginationParams(state), ...params };
      try {
        const response = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
          "getDevSites",
          params,
        ]);
        if (params?.parentSiteId === state.siteId) {
          commit("SET_LIST", {
            list: response.list,
            siteId: response.siteId,
          });
          commit("SET_STATUS", Status.LOADED);
        }
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    refreshItem({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_SINGULAR_KEY, { id });
      clear(cacheKey);
      if (id === state.item?.id && id === state.itemId) {
        dispatch("get", id);
      }
    },
    refreshList({ dispatch, state }, { parentSiteId }) {
      clearListCache(CACHE_PLURAL_KEY, { parentSiteId });
      if (state.list?.siteId === parentSiteId && parentSiteId === state.siteId) {
        dispatch("getList", { parentSiteId });
      }
    },
    refreshListByDevSiteId({ dispatch, state }, id) {
      const site = state.list?.list?.find((item) => item.id === id);
      if (site) {
        const siteId = state.list?.siteId;
        clearListCache(CACHE_PLURAL_KEY, { parentSiteId: siteId });
        dispatch("getList", { parentSiteId: siteId });
      }
    },
    setItemId({ commit }, siteId) {
      commit("SET_ITEM_ID", siteId);
      commit("SET_ITEM_STATUS", Status.UNINITIALIZED);
    },
    setSiteId({ commit }, siteId) {
      commit("SET_SITE_ID", siteId);
    },
  },
  getters,
};

export default devSites;
