import { clearListCache, createKey, get } from "@/utils/networkCache";
import { updateItem, updateList } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const store = {
  namespaced: true,
  state: {
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_SORT(state, payload) {
      const sort = {};
      if (payload?.sortProperty) sort.property = payload?.sortProperty;
      if (payload?.sortDirection) sort.direction = payload?.sortDirection;
      state.sort = payload?.sortProperty || payload?.sortDirection ? sort : null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async get({ commit }, id) {
      const response = await get(createKey("credits", { id }), request.makeParse, [
        "getCredit",
        { id },
      ]);
      commit("SET_ITEM", response);
      return response;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_SORT", params);
      const response = await get(createKey("credits", params), request.makeParse, [
        "getCredits",
        params,
      ]);
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    redeemCredit({ dispatch, state }, data) {
      return request.makeParse("redeemCredit", null, data).finally(() => {
        clearListCache("credits");
        updateList(dispatch, state);
      });
    },
    release({ dispatch, state }, id) {
      return request.makeParse("releaseCredit", { id }).finally(() => {
        clearListCache("credits");
        updateList(dispatch, state);
      });
    },
    update({ commit, dispatch, state }, payload) {
      clearListCache("credits");
      updateItem(commit, state, payload);
      updateList(dispatch, state);
    },
    refreshList({ dispatch, state }) {
      clearListCache("credits");
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
      });
    },
  },
};

export default store;
