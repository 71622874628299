import store from "@/store";

const handler = (event, data) => {
  switch (event) {
    case "api-task:add":
      if (data.action.includes("disable-password-protection")) {
        store.dispatch("passwordProtection/addUpdate", data.resource_id);
        store.dispatch("toasts/addToast", {
          variant: "info",
          message: "Password Protection will be disabled soon.",
        });
      }
      if (data.action.includes("enable-password-protection")) {
        store.dispatch("passwordProtection/addUpdate", data.resource_id);
        store.dispatch("toasts/addToast", {
          variant: "info",
          message: "Password Protection will be enabled soon.",
        });
      }
      break;
    case "api-task:update":
      // http password
      if (data.identity.includes("disable-password-protection (success)")) {
        store.dispatch("passwordProtection/refresh", data.resource_id).then((protection) => {
          store.dispatch("passwordProtection/removeUpdate", data.resource_id);
          store.dispatch("toasts/addToast", {
            variant: "success",
            message: "You have successfully disabled Password Protection!",
          });
          const uuid = protection?.dataShare?.uuid;
          if (protection?.enabled && uuid) {
            store.dispatch("httpPassword/get", { id: data.resource_id, uuid });
          }
        });
      }
      if (data.identity.includes("enable-password-protection (success)")) {
        store.dispatch("passwordProtection/refresh", data.resource_id).then((protection) => {
          store.dispatch("passwordProtection/removeUpdate", data.resource_id);
          store.dispatch("toasts/addToast", {
            variant: "success",
            message: "You have successfully enabled Password Protection!",
          });
          const uuid = protection?.dataShare?.uuid;
          if (protection?.enabled && uuid) {
            store.dispatch("httpPassword/get", { id: data.resource_id, uuid });
          }
        });
      }
      break;
    case "datashare:add":
      // http password
      if (data.type === "http-password") {
        store.dispatch("passwordProtection/refresh", data.owner_id).then((protection) => {
          const uuid = protection?.dataShare?.uuid;
          if (protection?.enabled && uuid) {
            store.dispatch("httpPassword/get", { id: data.owner_id, uuid });
          }
        });
      }
      break;
    case "datashare:update":
      // database users
      if (data.type.startsWith("mysqluser-")) {
        store.dispatch("databaseUsers/refreshList");
      }

      // email boxes
      if (data.type === "email-box") {
        store.dispatch("emailBoxes/refreshList");
      }

      // app password
      if (data.type === "app-password") {
        store.dispatch("password/refreshSiteApp", data.owner_id);
      }

      // http password
      if (data.type === "http-password") {
        store.dispatch("httpPassword/refresh", {
          id: data.owner_id,
          uuid: data.uuid,
        });
      }

      // rabbitmq password
      if (data.type === "rabbitmq") {
        store.dispatch("containerPassword/refresh", data.owner_id);
      }

      break;
    case "datashare:delete":
      // deleted database user
      if (data.type.startsWith("mysqluser-")) {
        store.dispatch("databaseUsers/refreshList");
      }

      // deleted email boxe
      if (data.type === "email-box") {
        store.dispatch("emailBoxes/refreshList");
      }
      break;
  }
};

export default handler;
