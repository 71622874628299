const ssl = [
  {
    path: "certificates",
    name: "certificates",
    meta: {
      breadcrumb: "SSL_CERTIFICATES",
    },
    component: () => import(/* webpackChunkName: "ssl" */ "@/pages/Dashboard/Ssl/Certificates.vue"),
  },
  {
    path: "add",
    name: "certificate-add",
    meta: {
      breadcrumb: "SSL_CERTIFICATE_ADD",
      sidebar: "SSL_CERTIFICATE_ADD",
    },
    component: () =>
      import(/* webpackChunkName: "ssl" */ "@/pages/Dashboard/Ssl/CertificateAdd.vue"),
  },
  {
    path: "add/cert/:certId",
    name: "certificate-add-with-cert",
    meta: {
      breadcrumb: "SSL_CERTIFICATE_ADD_WITH_CERT",
      sidebar: "SSL_CERTIFICATE_ADD_WITH_CERT",
    },
    props: (route) => ({ certId: Number.parseInt(route.params.certId) }),
    component: () =>
      import(/* webpackChunkName: "ssl" */ "@/pages/Dashboard/Ssl/CertificateAddWithCert.vue"),
  },
];

export default ssl;
