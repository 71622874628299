import {
  cancelService,
  disableEdgecastApac,
  disableEdgecastSharedSsl,
  disableNetworking,
  enableEdgecastApac,
  enableEdgecastSharedSsl,
  enableNetworking,
  getCancellationFormOptions,
  purgeCache,
  restoreService,
} from "@/utils/serviceActionsHelper";
import { canUpdateInPlace, updateItem, updateList } from "@/utils/storeHelper";
import { clearListCache, createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
  UPDATE_IN_PLACE,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_DEDICATED_SERVER_KEY = "dedicated-server";
const CACHE_DEDICATED_SERVER_LIST_KEY = "dedicated-server-list";

const dedicatedServer = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
    UPDATE_IN_PLACE,
  },
  actions: {
    async get({ commit }, id) {
      commit("SET_STATUS", Status.LOADING);
      const response = await get(createKey(CACHE_DEDICATED_SERVER_KEY, { id }), request.makeParse, [
        "getDedicatedServer",
        { id },
      ]);
      commit("SET_ITEM", response);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const response = await get(
        createKey(CACHE_DEDICATED_SERVER_LIST_KEY, params),
        request.makeParse,
        ["getDedicatedServers", params]
      );
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    async updateService({ commit, dispatch, state }, payload) {
      if (state.item.id === payload.id) {
        commit("SET_ITEM", payload);
      }
      if (canUpdateInPlace(state, payload)) {
        commit("UPDATE_IN_PLACE", payload);
      } else {
        dispatch("update", payload);
      }
    },
    update({ commit, dispatch, state }, payload) {
      clearListCache(CACHE_DEDICATED_SERVER_LIST_KEY);
      updateItem(commit, state, payload);
      updateList(dispatch, state);
    },
    refreshList({ dispatch, state }) {
      clearListCache(CACHE_DEDICATED_SERVER_LIST_KEY);
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    cancelService,
    disableEdgecastApac,
    disableEdgecastSharedSsl,
    disableNetworking,
    enableEdgecastApac,
    enableEdgecastSharedSsl,
    enableNetworking,
    getCancellationFormOptions,
    purgeCache,
    restoreService,
  },
};

export default dedicatedServer;
