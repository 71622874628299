import {
  cancelService,
  getCancellationFormOptions,
  restoreService,
} from "@/utils/serviceActionsHelper";
import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_ITEM_STATUS,
  SET_LIST,
  SET_LIST_STATUS,
  SET_PAGINATION,
  SET_SORT,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_ITEM_KEY = "shared-host";
const CACHE_LIST_KEY = "shared-hosts";

const store = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    itemStatus: new Status(),
    listStatus: new Status(),
    list: [],
    pagination: null,
    sort: null,
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_ITEM_STATUS,
    SET_LIST,
    SET_LIST_STATUS,
    SET_PAGINATION,
    SET_SORT,
  },
  actions: {
    async getItem({ commit }, id) {
      commit("SET_ITEM_STATUS", Status.LOADING);
      try {
        const params = { id: Number(id) };
        const response = await get(createKey(CACHE_ITEM_KEY, params), request.makeParse, [
          "getSharedHost",
          params,
        ]);
        commit("SET_ITEM", response);
        commit("SET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ITEM_STATUS", Status.ERROR);
      }
    },
    async getList({ commit }, params) {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        const response = await get(createKey(CACHE_LIST_KEY, params), request.makeParse, [
          "getSharedHosts",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        console.log(e);
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    refreshItem({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_ITEM_KEY, { id });
      clear(cacheKey);
      if (id === state.item?.id) {
        dispatch("getItem", id);
      }
    },
    refreshLastItem({ dispatch, state }) {
      if (state.item?.id) {
        dispatch("refreshItem", state.item?.id);
      }
    },
    refreshList({ dispatch, state }) {
      clearListCache(CACHE_LIST_KEY);
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    getCancellationFormOptions,
    cancelService,
    restoreService,
    update({ dispatch }) {
      dispatch("refreshList");
      dispatch("refreshLastItem");
    },
  },
  getters: {
    getItem: (state) => state.item,
    getItemStatus: (state) => state.itemStatus,
    getList: (state) => state.list,
    getListStatus: (state) => state.listStatus,
    getPagination: (state) => state.pagination,
    getSort: (state) => state.sort,
  },
};

export default store;
