export default [
  {
    path: "/external",
    name: "external",
    meta: {
      hideSidebar: true,
      public: true,
    },
    redirect: (to) => ({
      name: "external-login",
      query: { id: to.query.id, theme: to.query.theme },
    }),
  },
  {
    path: "/external/login",
    name: "external-login",
    meta: {
      hideSidebar: true,
      public: true,
    },
    props: (route) => ({
      id: route.query.id,
      theme: route.query.theme,
    }),
    component: () => import("@/pages/ExternalPages/ExternalNexcessLogin.vue"),
  },
  {
    path: "/external/switch-client",
    name: "external-switch-client",
    meta: {
      hideSidebar: true,
      public: true,
    },
    props: (route) => ({
      id: route.query.id,
      theme: route.query.theme,
    }),
    component: () => import("@/pages/ExternalPages/ExternalNexcessSwitchClient.vue"),
  },
  {
    path: "/external/billing-address",
    name: "external-billing-address",
    meta: {
      hideSidebar: true,
      public: true,
    },
    props: (route) => ({
      id: route.query.id,
      theme: route.query.theme,
    }),
    component: () => import("@/pages/ExternalPages/ExternalBillingAddress.vue"),
  },
  {
    path: "/external/payment-info",
    name: "external-payment-info",
    meta: {
      hideSidebar: true,
      public: true,
    },
    props: (route) => ({
      id: route.query.id,
      theme: route.query.theme,
    }),
    component: () => import("@/pages/ExternalPages/ExternalPaymentInfo.vue"),
  },
  {
    path: "/external/technical-address",
    name: "external-technical-address",
    meta: {
      hideSidebar: true,
      public: true,
    },
    props: (route) => ({
      id: route.query.id,
      theme: route.query.theme,
    }),
    component: () => import("@/pages/ExternalPages/ExternalTechnicalAddress.vue"),
  },
  {
    path: "/external/:flow/:step",
    name: "external-flow",
    component: () => import(/* webpackChunkName: "auth" */ "@/pages/External.vue"),
    meta: {
      hideSidebar: true,
      public: true,
    },
    props: (route) => ({
      flow: route.params.flow,
      step: route.params.step,
      id: route.query.id,
      theme: route.query.theme,
    }),
  },
];
