import { createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const payments = {
  namespaced: true,
  state: {
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
  },
  mutations: {
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_SORT(state, payload) {
      const sort = {};
      if (payload?.sortProperty) sort.property = payload?.sortProperty;
      if (payload?.sortDirection) sort.direction = payload?.sortDirection;
      state.sort = payload?.sortProperty || payload?.sortDirection ? sort : null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async get({ commit }, id) {
      const response = await get(createKey("payments", { id }), request.makeParse, [
        "getPayment",
        { id },
      ]);
      commit("SET_ITEM", response);
      return response;
    },
    async getInvoicePayments({ commit }, id) {
      commit("SET_STATUS", Status.LOADING);
      const response = await get(createKey("payments", { id }), request.makeParse, [
        "getInvoicePayments",
        { id },
      ]);
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_SORT", params);
      const response = await get(createKey("payments", params), request.makeParse, [
        "getPayments",
        params,
      ]);
      commit("SET_LIST", response.list);
      commit("SET_PAGINATION", response.pagination);
      commit("SET_STATUS", Status.LOADED);
      return response;
    },
    async getPaymentOptions(context, id) {
      return request.makeParse("getPaymentOptions", id);
    },
    async addPayment(context, data) {
      return request.makeParse("addPayment", {}, data);
    },
  },
};

export default payments;
