import store from "@/store";

const handlers = (event, data) => {
  switch (event) {
    case "cloud-account:update-ssl:post":
      store.dispatch("certificates/refreshInfo");
      break;
    case "ssl-cert:delete":
      store.dispatch("toasts/addToast", {
        variant: "success",
        message: `We have successfully deleted your SSL: ${data.identity}`,
      });
      break;
  }
};

export default handlers;
