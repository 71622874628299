import { createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_PAGINATION, SET_SORT } from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "AVAILABLE_DESIGN_SERVICES_ELIGIBLE_SITES";

const store = {
  namespaced: true,
  state: {
    list: [],
    listStatus: new Status(),
    pagination: null,
    sort: null,
    chunk: null,
  },
  mutations: {
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_LIST_STATUS(state, payload) {
      state.listStatus.value = payload;
    },
    SET_CHUNK,
    SET_PAGINATION,
    SET_SORT,
  },
  actions: {
    async getList({ commit }, params) {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        const response = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
          "getDesignServicesEligibleSitesAvailableToActivate",
          params,
          undefined,
          { getAll: true },
        ]);
        commit("SET_LIST", response);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        console.log(e);
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    getList: (state) => {
      return state.list;
    },
    getListStatus: (state) => {
      return state.listStatus;
    },
  },
};

export default store;
