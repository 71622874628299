import { clearListCache, get } from "@/utils/networkCache";
import { models, request } from "leatherman-js";
import Status from "@/classes/Status";

const addresses = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    address: new models.Address(),
    addressTypes: null,
    list: [],
    pagination: null,
    sort: null,
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_LIST(state, payload) {
      state.list = payload;
    },
    SET_ADDRESS(state, payload) {
      state.address = payload;
    },
    SET_ADDRESS_TYPES(state, payload) {
      state.addressTypes = payload;
    },
  },
  actions: {
    async getAddressTypes({ commit }) {
      const response = await request.makeParse("getAddressTypes");
      commit("SET_ADDRESS_TYPES", response);
      return response;
    },
    async getList({ commit }) {
      commit("SET_STATUS", Status.LOADING);
      const addresses = await get("getAddresses", request.makeParse, ["getAddresses"]);
      commit("SET_LIST", addresses);
      commit("SET_STATUS", Status.LOADED);
      return addresses;
    },
    async create({ commit }, data) {
      const address = await request.makeParse("createAddress", {}, data);
      commit("SET_ADDRESS", address);
      return address;
    },
    async update({ commit, getters }, { id, data }) {
      const defaultAddress = getters["getDefaultAddress"];
      const billingAddress = getters["getBillingAddress"];

      // check if the current address being updated is the default address
      if (defaultAddress.id === id) {
        // if the user wishes to make the default address the new billing address,
        // the current billing address must be set to other
        if (data.type === "billing" && billingAddress.id !== defaultAddress.id) {
          await request.makeParse(
            "updateAddress",
            { id: billingAddress.id },
            {
              type: "other",
            }
          );
        }

        // do not allow the type field of the default address to be updated
        // it will always be 'default'
        delete data["type"];
      }

      const address = await request.makeParse("updateAddress", { id }, data);
      commit("SET_ADDRESS", address);
      return address;
    },
    async deleteAddress(context, { id }) {
      return request.makeParse("deleteAddress", { id });
    },
    refreshList({ dispatch }) {
      clearListCache("getAddresses");
      return dispatch("getList");
    },
    clear({ commit }) {
      clearListCache("getAddresses");
      commit("SET_LIST", []);
      commit("SET_STATUS", Status.UNINITIALIZED);
    },
    validatePhone(context, phone) {
      const sanitizedPhone = `${phone}`.replace(/[\s()+-]/g, "");
      return request.makeParse("validatePhone", { phone: sanitizedPhone });
    },
  },
  getters: {
    getDefaultAddress: (state) => {
      return state.list.find((address) => address.type === "default");
    },
    getBillingAddress: (state, getters) => {
      let billing = state.list.find((address) => address.type === "billing");
      if (!billing) {
        return getters["getDefaultAddress"];
      }
      return billing;
    },
    getTechnicalAddress: (state, getters) => {
      const technical = state.list.find((address) => address.type === "technical");
      if (!technical) {
        return getters["getDefaultAddress"];
      }
      return technical;
    },
    getList: (state) => {
      return state.list || [];
    },
    getListStatus: (state) => {
      return state.status;
    },
    addressTypes(state) {
      return state.addressTypes;
    },
  },
};

export default addresses;
