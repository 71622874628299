const Other = [
  {
    path: "",
    name: "other",
    meta: {
      breadcrumb: "OTHER",
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/pages/Dashboard/Other/OtherList.vue"),
  },
  {
    path: ":id",
    name: "other-detail",
    meta: {
      breadcrumb: "OTHER_DETAIL",
    },
    component: () => import(/* webpackChunkName: "other" */ "@/pages/Dashboard/Other/Other.vue"),
    props: (route) => ({ otherId: route.params.id }),
  },
];

export default Other;
