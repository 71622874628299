import site from "@/store/modules/permissions/site";

const module = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    site,
  },
};

export default module;
