import { clearListCache, createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
} from "@/store/commonMutations";
import { updateItem, updateList } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const store = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
    itemStatus: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
    SET_ITEM_STATUS(state, payload) {
      state.itemStatus.value = payload;
    },
  },
  actions: {
    async get({ commit }, id) {
      commit("SET_ITEM_STATUS", Status.LOADING);
      try {
        const response = await get(createKey("app", { id }), request.makeParse, ["getApp", { id }]);
        commit("SET_ITEM", response);
        commit("SET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        console.log(e);
        commit("SET_ITEM_STATUS", Status.ERROR);
      }
    },
    async getList({ commit }, params) {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        const response = await get(createKey("apps", params), request.makeParse, [
          "getApps",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_STATUS", Status.ERROR);
      }
    },
    update({ commit, dispatch, state }, payload) {
      clearListCache("apps");
      updateItem(commit, state, payload);
      updateList(dispatch, state);
    },
  },
  getters: {
    getList: (state) => {
      return state.list || [];
    },
    getListStatus: (state) => {
      return state.status;
    },
    getItem: (state) => {
      return state.item;
    },
    getItemStatus: (state) => {
      return state.itemStatus;
    },
    getAppById: (state) => (appId) => {
      return state.list.find((ap) => parseInt(ap.id) === parseInt(appId));
    },
    getApp: (state) => {
      return state.item;
    },
  },
};

export default store;
