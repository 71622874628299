const DedicatedServers = [
  {
    path: "",
    name: "dedicated-servers",
    meta: {
      breadcrumb: "DEDICATED_LIST",
    },
    component: () =>
      import(
        /* webpackChunkName: "dedicated-servers" */ "@/pages/Dashboard/DedicatedServers/DedicatedServersList.vue"
      ),
  },
  {
    path: ":dedicatedServerId/allocations/:allocationId",
    name: "allocation-detail",
    component: () =>
      import(
        /* webpackChunkName: "dedicated-servers" */ "@/pages/Dashboard/DedicatedServers/DedicatedServer/Allocation.vue"
      ),
    props: (route) => ({
      allocationId: route.params.allocationId,
      dedicatedServerId: route.params.dedicatedServerId,
    }),
  },
  {
    path: ":id",
    name: "dedicated-server-detail",
    component: () =>
      import(
        /* webpackChunkName: "dedicated-servers" */ "@/pages/Dashboard/DedicatedServers/DedicatedServer.vue"
      ),
    props: (route) => ({ dedicatedServerId: route.params.id }),
  },
];

export default DedicatedServers;
