import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_ITEM, SET_LIST, SET_PAGINATION, SET_SORT } from "@/store/commonMutations";
import { getLatestTask } from "@/utils/tasks";
import { getPaginationParams } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";
import { updateList } from "@/utils/storeHelper";

const store = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    itemStatus: new Status(),
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
    localMailDeliveryStatus: new Status(),
    localMailDeliveryEnabled: null,
    siteId: null,
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_ITEM_STATUS(state, payload) {
      state.itemStatus.value = payload;
    },
    SET_LIST_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LOCAL_MAIL_DELIVERY_STATUS(state, payload) {
      state.localMailDeliveryStatus.value = payload;
    },
    SET_LOCAL_MAIL_DELIVERY_ENABLED(state, payload) {
      state.localMailDeliveryEnabled = payload;
    },
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async getList({ commit, state }, params) {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      commit("SET_SITE_ID", params.siteId);

      params = { ...getPaginationParams(state), ...params };
      try {
        const response = await get(createKey("emailBoxes", params), request.makeParse, [
          "getSiteEmailBoxes",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    create(context, { siteId, username, storage }) {
      return request.makeParse("createSiteEmailBox", { siteId }, { username, storage });
    },
    delete(context, { siteId, username }) {
      return request.makeParse("deleteSiteEmailBox", { siteId }, { username });
    },
    regenPassword(context, { siteId, username }) {
      return request.makeParse("regenPasswordSiteEmailBox", { siteId }, { username });
    },
    update({ dispatch, state }) {
      clearListCache("emailBoxes");
      updateList(dispatch, state);
    },
    refreshList({ dispatch, state }, params = {}) {
      params = { ...params, siteId: state.siteId };
      clearListCache(createKey("emailBoxes", params));
      if (state.siteId) {
        dispatch("getList", params);
      }
    },
    getWebmailUrl(context, siteId) {
      return request.makeParse("getWebmailUrl", { siteId });
    },
    async getLocalMailDeliveryInfo({ commit }, { siteId, domain }) {
      commit("SET_LOCAL_MAIL_DELIVERY_STATUS", Status.LOADING);

      const last = await getLatestTask(
        [
          "local-email-delivery:local-email-delivery-enable",
          "local-email-delivery:local-email-delivery-disable",
        ],
        "processing",
        siteId
      );

      if (last?.action.includes("enable")) {
        commit("SET_LOCAL_MAIL_DELIVERY_ENABLED", true);
      } else if (last?.action.includes("disable")) {
        commit("SET_LOCAL_MAIL_DELIVERY_ENABLED", false);
      } else {
        const enabled = await request.makeParse("getLocalMailDeliveryInfo", { siteId, domain });
        commit("SET_LOCAL_MAIL_DELIVERY_ENABLED", enabled);
        commit("SET_LOCAL_MAIL_DELIVERY_STATUS", Status.LOADED);
      }
    },
    setLocalMailDelivery({ commit }, enabled) {
      commit("SET_LOCAL_MAIL_DELIVERY_STATUS", Status.LOADED);
      commit("SET_LOCAL_MAIL_DELIVERY_ENABLED", enabled);
    },
    async enableLocalMailDelivery({ commit }, siteId) {
      commit("SET_LOCAL_MAIL_DELIVERY_STATUS", Status.LOADING);
      const response = await request.makeParse("enableLocalMailDelivery", { siteId });
      return response;
    },
    async disableLocalMailDelivery({ commit }, siteId) {
      commit("SET_LOCAL_MAIL_DELIVERY_STATUS", Status.LOADING);
      const response = await request.makeParse("disableLocalMailDelivery", { siteId });
      return response;
    },
  },
  getters: {
    getSiteId: (state) => {
      return state.siteId;
    },
  },
};

export default store;
