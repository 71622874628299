import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_CHUNK, SET_ITEM, SET_LIST, SET_PAGINATION, SET_SORT } from "@/store/commonMutations";
import { getPaginationParams } from "@/utils/storeHelper";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const store = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    itemStatus: new Status(),
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
    siteId: null,
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_ITEM_STATUS(state, payload) {
      state.itemStatus.value = payload;
    },
    SET_LIST_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_SITE_ID(state, payload) {
      state.siteId = payload;
    },
  },
  actions: {
    async getList({ commit, state }, params) {
      commit("SET_LIST_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      commit("SET_SITE_ID", params.siteId);

      params = { ...getPaginationParams(state), ...params };
      try {
        const response = await get(createKey("emailResponders", params), request.makeParse, [
          "getSiteEmailResponders",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    create(context, { siteId, username, response, copyto }) {
      return request.makeParse(
        "createSiteEmailResponders",
        { siteId },
        { username, response, copyto }
      );
    },
    update(context, { siteId, username, response, copyto }) {
      return request.makeParse(
        "updateSiteEmailResponders",
        { siteId, username },
        { response, copyto }
      );
    },
    delete(context, { siteId, username }) {
      return request.makeParse("deleteSiteEmailResponders", { siteId, username });
    },
    refreshList({ dispatch, state }, params = {}) {
      params = { ...params, siteId: state.siteId };
      clearListCache(createKey("emailResponders", params));
      if (state.siteId) {
        dispatch("getList", params);
      }
    },
  },
  getters: {},
};

export default store;
