import { home, planDetail, planList, planSiteDetail } from "@/utils/navLinks";
import Breadcrumbs from "@/classes/Breadcrumbs";
import NavLink from "@/classes/NavLink";

export default {
  PLAN_SITE_ANALYTICS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Analytics", null, "plan-site-analytics"),
          ],
        }),
  PLAN_SITE_BACKUPS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Backups", null, "plan-site-backup"),
          ],
        }),
  PLAN_SITE_CONTAINERS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Containers", null, "plan-site-containers"),
          ],
        }),
  PLAN_SITE_CONTENT_DELIVERY: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Content Delivery", null, "plan-site-content-delivery"),
          ],
        }),
  PLAN_SITE_CONTROL_PANEL: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Control Panel", null, "plan-site-control-panel"),
          ],
        }),
  PLAN_SITE_CREDENTIALS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Credentials", null, "plan-site-credentials"),
          ],
        }),
  PLAN_SITE_DATABASES: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Databases", null, "plan-site-databases"),
          ],
        }),
  PLAN_SITE_DESIGN_SERVICES: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Design Services", null, "plan-site-design-services"),
          ],
        }),
  PLAN_SITE_DEV: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Dev", null, "plan-site-dev"),
          ],
        }),
  PLAN_SITE_DOMAINS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Domains", null, "plan-site-domains"),
          ],
        }),
  PLAN_SITE_EMAIL: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Email", null, "plan-site-email"),
          ],
        }),
  PLAN_SITE_INTEGRATIONS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Integrations", null, "plan-site-integrations"),
          ],
        }),
  PLAN_SITE_LOGS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Logs", null, "plan-site-logs"),
          ],
        }),
  PLAN_SITE_MANAGEMENT: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Management", null, "plan-site-management"),
          ],
        }),
  PLAN_SITE_SCHEDULED_TASKS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Scheduled Tasks", null, "plan-site-scheduled-tasks"),
          ],
        }),
  PLAN_SITE_SSL: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("SSL", null, "plan-site-ssl"),
          ],
        }),
  PLAN_SITE_STAGING_AND_DEV: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Staging & Dev", null, "plan-site-staging-and-dev"),
          ],
        }),
  PLAN_SITE_STENCILS: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Stencils", null, "plan-site-stencils"),
          ],
        }),
  PLAN_SITE_VISUAL_COMPARISON: ({ plan, site }) =>
    !plan || !site
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            planSiteDetail(site),
            new NavLink("Visual Comparison", null, "plan-site-visual-comparison"),
          ],
        }),
};
