import { clearListCache, createKey, get } from "@/utils/networkCache";
import { SET_LIST, SET_STATUS } from "@/store/commonMutations";
import { constants } from "@/utils";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

export const state = {
  list: [],
  status: new Status(),
  dailyList: [],
  dailyStatus: new Status(),
  databaseList: [],
  databaseListStatus: new Status(),
  restoreStatus: new Status(),
  siteId: null,
  backupRestore: null,
  backupRestoreAcknowledged: true,
};

export const mutations = {
  SET_LIST,
  SET_STATUS,
  SET_DATABASE_LIST(state, payload) {
    state.databaseList = payload;
  },
  SET_DATABASE_LIST_STATUS(state, payload) {
    state.databaseListStatus.value = payload;
  },
  SET_DAILY_LIST(state, payload) {
    state.dailyList = payload;
  },
  SET_DAILY_STATUS(state, payload) {
    state.dailyStatus.value = payload;
  },
  SET_RESTORE_STATUS(state, payload) {
    state.restoreStatus.value = payload;
  },
  SET_SITE_ID(state, payload) {
    state.siteId = payload;
  },
  SET_BACKUP_RESTORE(state, payload) {
    state.backupRestore = payload;
  },
  SET_BACKUP_RESTORE_ACKNOWLEDGED(state, payload) {
    state.backupRestoreAcknowledged = payload;
  },
};

export const actions = (cacheBackupsKey, cacheDailyBackupsKey, cacheDatabaseListKey) => ({
  async getDatabaseList({ commit }, { siteId, backupId }) {
    commit("SET_DATABASE_LIST_STATUS", Status.LOADING);
    try {
      const response = await get(
        createKey(cacheDatabaseListKey, { siteId, backupId }),
        request.makeParse,
        ["getSiteBackupDatabases", { siteId, backupId }]
      );
      commit("SET_DATABASE_LIST", response);
      commit("SET_DATABASE_LIST_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_DATABASE_LIST_STATUS", Status.ERROR);
    }
  },
  async getList({ commit }, siteId) {
    commit("SET_STATUS", Status.LOADING);
    commit("SET_SITE_ID", siteId);
    try {
      const response = await get(createKey(cacheBackupsKey, { siteId }), request.makeParse, [
        "getSiteBackups",
        { siteId },
      ]);
      commit("SET_LIST", response);
      commit("SET_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_STATUS", Status.ERROR);
    }
  },
  async getDailyList({ commit }, siteId) {
    commit("SET_DAILY_STATUS", Status.LOADING);
    commit("SET_SITE_ID", siteId);
    try {
      const response = await get(createKey(cacheDailyBackupsKey, { siteId }), request.makeParse, [
        "getSiteDailyBackups",
        { siteId },
      ]);
      commit("SET_DAILY_LIST", response);
      commit("SET_DAILY_STATUS", Status.LOADED);
      return response;
    } catch (e) {
      commit("SET_DAILY_STATUS", Status.ERROR);
    }
  },
  create(context, { siteId }) {
    return request.makeParse("createSiteBackup", { siteId });
  },
  delete(context, { siteId, filename }) {
    return request.makeParse("deleteSiteBackup", { siteId, filename });
  },
  restore(context, { siteId, filename }) {
    return request.makeParse("restoreSiteBackup", { siteId, filename });
  },
  restoreDaily(context, { siteId, filedateIso }) {
    return request.makeParse("restoreSiteDailyBackup", { siteId }, { filedateIso });
  },
  restoreDailyDatabases(context, { siteId, backupId, databases }) {
    return request.makeParse(
      "restoreSiteDailyBackupDatabases",
      { siteId, backupId },
      { databases }
    );
  },
  restoreDailyFullFiles(context, { siteId, backupId }) {
    return request.makeParse("restoreSiteDailyBackupFullFiles", { siteId, backupId });
  },
  restoreDailyFullFilesAndDatabases(context, { siteId, backupId, databases }) {
    return request.makeParse(
      "restoreSiteDailyBackupFullFilesAndDatabases",
      { siteId, backupId },
      { databases }
    );
  },
  refreshList({ dispatch, state }) {
    clearListCache(createKey(cacheBackupsKey, { siteId: state.siteId }));
    if (state.siteId) {
      dispatch("getList", state.siteId);
    }
  },
  refreshDailyList({ dispatch, state }) {
    clearListCache(createKey(cacheDailyBackupsKey, { siteId: state.siteId }));
    if (state.siteId) {
      dispatch("getDailyList", state.siteId);
    }
  },
  async getRestoreStatus({ commit }, siteId) {
    const restoring = await request.makeParse("getSiteBackupRestoreStatus", { siteId });
    const status = restoring ? Status.LOADING : Status.LOADED;

    // set current state of restore
    commit("SET_RESTORE_STATUS", status);
  },
  setInitialBackupRestore({ commit }) {
    commit("SET_BACKUP_RESTORE", { status: constants.RESTORE_STATUS.PENDING });
  },
  async getBackupRestore({ commit }, siteId) {
    try {
      const backupRestore = await request.makeParse("getLastSiteDailyBackupRestore", { siteId });
      commit("SET_BACKUP_RESTORE", backupRestore);
    } catch (e) {
      commit("SET_BACKUP_RESTORE", null);
    }
  },
  setBackupRestoreAcknowledged({ commit }, acknowledged) {
    commit("SET_BACKUP_RESTORE_ACKNOWLEDGED", acknowledged);
  },
});

export const getters = {
  getList: (state) => {
    return state.list || [];
  },
  getStatus: (state) => {
    return state.status;
  },
  getDatabaseList: (state) => {
    return state.databaseList;
  },
  getDatabaseListStatus: (state) => {
    return state.databaseListStatus;
  },
  getDailyList: (state) => {
    return state.dailyList;
  },
  getDailyStatus: (state) => {
    return state.dailyStatus;
  },
  getRestoreStatus: (state) => {
    return state.restoreStatus;
  },
  getBackupRestore: (state) => {
    return state.backupRestore;
  },
  getBackupRestoreAcknowledged: (state) => {
    return state.backupRestoreAcknowledged;
  },
};
