import { createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "PACKAGE_ADDONS_SITE_KEEP_KEY";

const store = {
  namespaced: true,
  state: {
    list: [],
    listStatus: new Status(),
  },
  mutations: {
    SET_LIST: (state, payload) => (state.list = payload),
    SET_LIST_STATUS: (state, payload) => (state.listStatus.value = payload),
  },
  actions: {
    getList: async ({ commit }) => {
      commit("SET_LIST_STATUS", Status.LOADING);
      try {
        const response = await get(createKey(CACHE_PLURAL_KEY), request.makeParse, [
          "getPackageSiteKeepAddons",
        ]);
        commit("SET_LIST", response);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    getList: (state) => state.list,
    getListStatus: (state) => state.listStatus,
    isAddon: (state) => (addonId) => Boolean(state.list.find((item) => addonId === item.id)),
  },
};

export default store;
