import { clear, getAll, set } from "@/utils/wizardStorage";

const wizard = {
  namespaced: true,
  state: {
    completedSurveys: getAll(),
  },
  mutations: {
    SET_COMPLETED_SURVEYS(state, payload) {
      state.completedSurveys = payload;
    },
  },
  actions: {
    clearCompletedSurvey: ({ commit }, packageId) => {
      if (packageId) {
        clear(packageId);
        commit("SET_COMPLETED_SURVEYS", getAll());
      }
    },
    setCompletedSurvey: ({ commit }, { packageId, data }) => {
      if (packageId) {
        set(packageId, data);
        commit("SET_COMPLETED_SURVEYS", getAll());
      }
      return Promise.resolve("Success");
    },
  },
  getters: {
    getCompletedSurveys: (state) => state.completedSurveys,
    getCompletedSurvey: (state) => (packageId) => state.completedSurveys?.[packageId],
  },
};

export default wizard;
