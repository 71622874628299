const partner = [
  {
    path: "links",
    name: "partner-links",
    meta: {
      breadcrumb: "PARTNER_LINKS",
    },
    redirect: {
      name: "partner-link-list",
    },
    component: () =>
      import(/* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner/Links.vue"),
    children: [
      {
        path: "",
        name: "partner-link-list",
        meta: {
          breadcrumb: "PARTNER_LINKS",
        },
        component: () =>
          import(/* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner/Links/LinkList.vue"),
      },
      {
        path: ":id",
        name: "partner-link-detail",
        meta: {
          breadcrumb: "PARTNER_LINK",
          sidebar: "PARTNER_LINK",
        },
        component: () =>
          import(
            /* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner/Links/LinkDetail.vue"
          ),
        props: (route) => ({
          linkId: route.params.id,
        }),
      },
    ],
  },
  {
    path: "referrals",
    name: "partner-referrals",
    redirect: {
      name: "partner-referral-list",
    },
    component: () =>
      import(/* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner/Referrals.vue"),
    children: [
      {
        path: "",
        name: "partner-referral-list",
        meta: {
          breadcrumb: "PARTNER_REFERRALS",
        },
        component: () =>
          import(
            /* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner/Referrals/ReferralList.vue"
          ),
      },
      {
        path: ":id",
        name: "partner-referral-detail",
        meta: {
          breadcrumb: "PARTNER_REFERRAL",
        },
        component: () =>
          import(
            /* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner/Referrals/ReferralDetail.vue"
          ),
        props: (route) => ({
          referralId: route.params.id,
        }),
      },
    ],
  },
  {
    path: "credits",
    name: "partner-credits",
    meta: {
      breadcrumb: "PARTNER_CREDITS",
    },
    component: () =>
      import(/* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner/Credits.vue"),
  },
  {
    path: "payments",
    name: "partner-payments",
    meta: {
      breadcrumb: "PARTNER_PAYMENTS",
    },
    component: () =>
      import(/* webpackChunkName: "partner" */ "@/pages/Dashboard/Partner/Payments.vue"),
  },
];

export default partner;
