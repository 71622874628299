import { auth } from "leatherman-js";
import store from "@/store";

export const hasPermissions = async ({ permissions, redirect }, to, from, next) => {
  let user = store.getters["auth/getUser"] || (await store.dispatch("auth/userInfo"));
  if (permissions.some((per) => auth.hasPermission(user, "read", per))) {
    next();
  } else {
    next({ name: redirect });
  }
};
