import { request } from "leatherman-js";

const module = {
  namespaced: true,
  actions: {
    add: (context, { dnsZoneId, hostname, flag, tag, ttl, value }) =>
      request.makeParse("addDnsZoneCaaRecord", null, {
        dnsZoneId,
        hostname,
        flag,
        tag,
        ttl,
        value,
      }),
    update: (context, { dnsZoneId, flag, hostname, id, tag, ttl, value, force }) =>
      request.makeParse(
        "updateDnsZoneCaaRecord",
        { id },
        {
          dnsZoneId,
          hostname,
          flag,
          tag,
          ttl,
          value,
          force,
        }
      ),
  },
};

export default module;
