import { createKey, get } from "@/utils/networkCache";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLAN_BANDWIDTH_KEY = "chart-plan-bandwidth";
const CACHE_PLAN_PHP_FPM_KEY = "chart-plan-php-fpm";
const CACHE_SITE_BANDWIDTH_KEY = "chart-site-bandwidth";
const CACHE_SITE_PHP_FPM_KEY = "chart-site-php-fpm";

const store = {
  namespaced: true,
  state: {
    planBandwidth: null,
    planBandwidthStatus: new Status(),
    planPhpFpm: null,
    planPhpFpmStatus: new Status(),
    siteBandwidth: null,
    siteBandwidthStatus: new Status(),
    sitePhpFpm: null,
    sitePhpFpmStatus: new Status(),
  },
  mutations: {
    SET_PLAN_BANDWIDTH(state, payload) {
      state.planBandwidth = payload;
    },
    SET_PLAN_BANDWIDTH_STATUS(state, status) {
      state.planBandwidthStatus.value = status;
    },
    SET_PLAN_PHP_FPM(state, payload) {
      state.planPhpFpm = payload;
    },
    SET_PLAN_PHP_FPM_STATUS(state, status) {
      state.planPhpFpmStatus.value = status;
    },
    SET_SITE_BANDWIDTH(state, payload) {
      state.siteBandwidth = payload;
    },
    SET_SITE_BANDWIDTH_STATUS(state, status) {
      state.siteBandwidthStatus.value = status;
    },
    SET_SITE_PHP_FPM(state, payload) {
      state.sitePhpFpm = payload;
    },
    SET_SITE_PHP_FPM_STATUS(state, status) {
      state.sitePhpFpmStatus.value = status;
    },
  },
  actions: {
    async getPlanBandwidth({ commit, state }, id) {
      const newStatus =
        state.planBandwidthStatus.isLoaded || state.planBandwidthStatus.isUpdating
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_PLAN_BANDWIDTH_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_PLAN_BANDWIDTH_KEY, { id }), request.makeParse, [
          "getPlanBandwidthChart",
          { id },
        ]);
        commit("SET_PLAN_BANDWIDTH", response);
        commit("SET_PLAN_BANDWIDTH_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_PLAN_BANDWIDTH_STATUS", Status.ERROR);
      }
    },
    async getPlanPhpFpm({ commit, state }, id) {
      const newStatus =
        state.planPhpFpmStatus.isLoaded || state.planPhpFpmStatus.isUpdating
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_PLAN_PHP_FPM_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_PLAN_PHP_FPM_KEY, { id }), request.makeParse, [
          "getPlanPhpFpmChart",
          { id },
        ]);
        commit("SET_PLAN_PHP_FPM", response);
        commit("SET_PLAN_PHP_FPM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_PLAN_PHP_FPM_STATUS", Status.ERROR);
      }
    },
    async getSiteBandwidth({ commit, state }, id) {
      const newStatus =
        state.siteBandwidthStatus.isLoaded || state.siteBandwidthStatus.isUpdating
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_SITE_BANDWIDTH_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_SITE_BANDWIDTH_KEY, { id }), request.makeParse, [
          "getSiteBandwidthChart",
          { id },
        ]);
        commit("SET_SITE_BANDWIDTH", response);
        commit("SET_SITE_BANDWIDTH_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_SITE_BANDWIDTH_STATUS", Status.ERROR);
      }
    },
    async getSitePhpFpm({ commit, state }, id) {
      const newStatus =
        state.sitePhpFpmStatus.isLoaded || state.sitePhpFpmStatus.isUpdating
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_SITE_PHP_FPM_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_SITE_PHP_FPM_KEY, { id }), request.makeParse, [
          "getSitePhpFpmChart",
          { id },
        ]);
        commit("SET_SITE_PHP_FPM", response);
        commit("SET_SITE_PHP_FPM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_SITE_PHP_FPM_STATUS", Status.ERROR);
      }
    },
  },
  getters: {
    getPlanBandwidth: (state) => state.planBandwidth,
    getPlanBandwidthStatus: (state) => state.planBandwidthStatus,
    getPlanPhpFpm: (state) => state.planPhpFpm,
    getPlanPhpFpmStatus: (state) => state.planPhpFpmStatus,
    getSiteBandwidth: (state) => state.siteBandwidth,
    getSiteBandwidthStatus: (state) => state.siteBandwidthStatus,
    getSitePhpFpm: (state) => state.sitePhpFpm,
    getSitePhpFpmStatus: (state) => state.sitePhpFpmStatus,
  },
};

export default store;
