import constants from "@/utils/constants";
import { nav } from "leatherman-js";
import NavLink from "@/classes/NavLink";

export function home() {
  return new NavLink("Home", "home", constants.ROUTES.HOME);
}

export function partner() {
  return new NavLink("Partner", null, "partner-links");
}

export function back(text, route = constants.ROUTES.HOME) {
  return new NavLink(text, "back", route);
}

export function domainList() {
  return new NavLink("Domains", null, "domains");
}

export function otherList() {
  return new NavLink("Other", null, "other");
}

export function profileInfo() {
  return new NavLink(
    "My Profile",
    { default: { icon: "fa-regular fa-gear" }, hover: { icon: "fa-solid fa-gear" } },
    "profile"
  );
}

export function profile2fa() {
  return new NavLink(
    "2 Factor Auth",
    { default: { icon: "fa-regular fa-lock" }, hover: { icon: "fa-solid fa-lock" } },
    "2fa"
  );
}

export function planDetail(id, nickname) {
  return new NavLink(nickname, null, { name: "plan-detail", params: { planId: id } });
}

export function planList() {
  return new NavLink("Plans", null, "plan-list");
}

export function planSiteList() {
  return new NavLink("Sites", null, "plan-sites");
}

export function planSiteDetail(site) {
  return new NavLink(site.name || site.domain, null, "plan-site-detail");
}

export function planDevSiteDetail(domain) {
  return new NavLink(domain, null, "plan-site-dev-detail");
}

export const planSiteDevEnvs = (plan, site) =>
  new NavLink(
    site.name || site.domain,
    null,
    plan.isTShirt ? "plan-site-dev" : "plan-site-staging-and-dev"
  );

export function getPlanSafeHarborLinks() {
  return createNavLinks(nav.plan.getPlanSafeHarborLinks());
}

export function getPlanSafeHarborStagingEnvironmentLinks() {
  return createNavLinks(nav.plan.getPlanSafeHarborStagingEnvironmentLinks());
}

export function planSafeHarbor(id) {
  return new NavLink("Safe Harbor", null, {
    name: "plan-safe-harbor",
    params: { id },
  });
}

export function planSafeHarborStagingEnvironments(id) {
  return new NavLink("Staging Environments", null, {
    name: "plan-safe-harbor-staging-environments",
    params: { id },
  });
}

export function planSafeHarborStagingEnvironment(planId, site) {
  return new NavLink(site.domain, null, {
    name: "plan-safe-harbor-staging-environment",
    params: { planId, stagingEnvironmentId: site.id },
  });
}

export function siteList() {
  return new NavLink("Sites", null, "plan-sites");
}

export function dnsZoneList() {
  return new NavLink("DNS Zones", null, "dns-zones");
}

export function rdnsRecordList() {
  return new NavLink("Reverse DNS Records", null, "rdns-records");
}

export function invoiceList() {
  return new NavLink("Invoices", null, "invoices");
}

export function creditList() {
  return new NavLink("Credits", null, "credits");
}

export function paymentList() {
  return new NavLink("Payments", null, "payments");
}

export function orderList() {
  return new NavLink("Orders", null, "orders");
}

export function partnerLinkList() {
  return new NavLink("Links", null, "partner-links");
}

export function partnerReferralList() {
  return new NavLink("Referrals", null, "partner-referrals");
}

export function certificateList() {
  return new NavLink("SSL Certificates", null, "certificates");
}

export function supportTicketList() {
  return new NavLink("Tickets", null, "tickets");
}

export function cloudServers() {
  return new NavLink("Cloud Servers", null, "cloud-servers");
}

export function enterpriseCloud() {
  return new NavLink("Enterprise Cloud", null, "enterprise-cloud");
}

export const createNavLinks = (links) =>
  links.map(
    (link) =>
      new NavLink(
        nameMapper(link.name, link.meta),
        link.icon,
        link.action || link.route,
        null,
        null,
        null,
        null,
        null,
        link.admin,
        link.keywords,
        link.advanced
      )
  );

export function getMainLinks(client, company, user) {
  let links = [];
  if (company === "thermo") {
    links = nav.main.getThermoMainLinks(client, user);
  } else {
    links = nav.main.getNexcessMainLinks(client, user);
  }
  return createNavLinks(links);
}

export function getPlanLinks(plan, user) {
  const links = plan.isTShirt
    ? nav.plan.getTShirtPlanLinks(user, plan)
    : nav.plan.getLiquidwebPlanLinks(plan);
  return createNavLinks(links);
}

export function getSiteLinks(user, site, plan, isAdmin, isBeta, sitePermissions) {
  let links = [];
  if (plan.isTShirt) {
    links = nav.site.getTShirtSiteLinks(user, site, plan, isAdmin, isBeta);
  } else {
    links = nav.site.getLiquidwebSiteLinks(user, site, plan, isAdmin, sitePermissions);
  }
  return createNavLinks(links);
}

export function getDevSiteLinks(user, site, plan, isAdmin, isBeta) {
  let links = [];
  if (plan.isTShirt) {
    links = nav.devSite.getTShirtDevSiteLinks(user, site, isAdmin, isBeta);
  } else {
    links = nav.devSite.getLiquidwebDevSiteLinks(user, site, isAdmin);
  }
  return createNavLinks(links);
}

export function getBillingLinks(user, company) {
  let links = [];
  if (company === "thermo") {
    links = nav.billing.getThermoBillingLinks(user);
  } else {
    links = nav.billing.getNexcessBillingLinks(user);
  }
  return createNavLinks(links);
}

export function getDNSLinks(user, client, company) {
  let links = [];
  if (company === "thermo") {
    links = nav.dns.getThermoDNSLinks(user, client);
  } else {
    links = nav.dns.getNexcessDNSLinks(user, client);
  }
  return createNavLinks(links);
}

export function getPartnerLinks(client) {
  return createNavLinks(nav.partner.getPartnerLinks(client));
}

export function getSettingsLinks(user) {
  const links = nav.settings.getSettingsLinks(user);
  return createNavLinks(links);
}

export function getSharedHostLinks(sharedHost) {
  return createNavLinks(nav.sharedHost.getSharedHostLinks(sharedHost));
}

export function getSharedHostSafeHarborLinks() {
  return createNavLinks(nav.sharedHost.getSharedHostSafeHarborLinks());
}

export function getSharedHostSafeHarborStagingEnvironmentLinks() {
  return createNavLinks(nav.sharedHost.getSharedHostSafeHarborStagingEnvironmentLinks());
}

export function sharedHostDetail(id, domain) {
  return new NavLink(domain, null, { name: "shared-host", params: { id } });
}

export function sharedHostSafeHarbor(id) {
  return new NavLink("Safe Harbor", null, {
    name: "shared-host-safe-harbor",
    params: { id },
  });
}

export function sharedHostSafeHarborStagingEnvironments(id) {
  return new NavLink("Staging Environments", null, {
    name: "shared-host-safe-harbor-staging-environments",
    params: { id },
  });
}

export function sharedHostSafeHarborStagingEnvironment(sharedHostId, site) {
  return new NavLink(site.domain, null, {
    name: "shared-host-safe-harbor-staging-environment",
    params: { sharedHostId, stagingEnvironmentId: site.id },
  });
}

export function dedicatedList() {
  return new NavLink("Dedicated Servers", null, "dedicated-servers");
}

export function coloList() {
  return new NavLink("Colo", null, "colocations");
}

export function resellerList() {
  return new NavLink("Reseller Hosting", null, "reseller-hosting");
}

export const nameMapper = (lookup, meta) => {
  const count = meta?.count;
  const name = meta?.name;
  switch (lookup) {
    case "2fa":
      return "2-Factor Auth";
    case "access":
      return "Access";
    case "account-settings":
      return "Account Settings";
    case "address":
      return "Addresses";
    case "addresses":
      return "Addresses";
    case "analytics":
      return "Analytics";
    case "announcements":
      return "Announcements";
    case "api-tokens":
      return "API Tokens";
    case "architecture":
      return "Architecture";
    case "back-to-announcements":
      return "Back to Announcements";
    case "back-to-certificates":
      return "Back to SSL Certificates";
    case "back-to-cloud-server":
      return "Back to Cloud Server";
    case "back-to-cloud-servers":
      return "Back to Cloud Servers";
    case "back-to-credits":
      return "Back to Credits";
    case "back-to-dev-sites":
      return "Back to Environments";
    case "back-to-dns-zones":
      return "Back to DNS Zones";
    case "back-to-home":
      return "Back To Home";
    case "back-to-invoices":
      return "Back To Invoices";
    case "back-to-links":
      return "Back To Links";
    case "back-to-payments":
      return "Back to Payments";
    case "back-to-orders":
      return "Back to Orders";
    case "back-to-overview":
      return "Back to Overview";
    case "back-to-plan":
      return `Back to ${name}`;
    case "back-to-plans":
      return "Back to Plans";
    case "back-to-rdns-records":
      return "Back to RDNS Records";
    case "back-to-referrals":
      return "Back to Referrals";
    case "back-to-safe-harbor":
      return "Back to Safe Harbor";
    case "back-to-shared-hosting":
      return "Back to Shared Hosting";
    case "back-to-site":
      return `Back to ${name}`;
    case "back-to-sites":
      return "Back to Sites";
    case "back-to-tickets":
      return "Back to Tickets";
    case "backup":
      return "Backups";
    case "backups":
      return "Backups";
    case "billing":
      return "Billing";
    case "cloud-servers":
      return `Cloud Servers (${count})`;
    case "cloud-servers-empty":
      return "Cloud Servers";
    case "colocations":
      return `Colo (${count})`;
    case "colocations-empty":
      return "Colo";
    case "containers":
      return "Containers";
    case "control-panel":
      return "Control Panel";
    case "create-profile":
      return "Create Profile";
    case "credentials":
      return "Credentials";
    case "credits":
      return "Credits";
    case "credit-cards":
      return "Credit Cards";
    case "databases":
    case "db":
      return "Databases";
    case "dedicated-servers":
      return `Dedicated Servers (${count})`;
    case "dedicated-servers-empty":
      return "Dedicated Servers";
    case "design-services":
      return "Design Services";
    case "dev":
      return "Dev";
    case "dev-environments":
      return "Dev Environments";
    case "dev-environment-add":
      return "Add Dev Environment";
    case "dns":
      return "DNS";
    case "dns-zones":
      return "DNS Zones";
    case "domain-options":
      return "Domains";
    case "domains":
      return `Domains (${count})`;
    case "email":
      return "Email";
    case "email-accounts":
      return "Email Accounts";
    case "enterprise-cloud":
      return `Enterprise Cloud (${count})`;
    case "environment":
      return "Environment";
    case "feedback":
      return "Feedback";
    case "home":
      return "Home";
    case "integrations":
      return "Integrations";
    case "invoices":
      return "Invoices";
    case "import-certificate":
      return "Import Certificate";
    case "knowledge-base":
      return "Knowledge Library";
    case "legal":
      return "Legal";
    case "links":
      return "Links";
    case "logs":
      return "Logs";
    case "magento-patches":
      return "Magento Patches";
    case "malware-scans":
      return "Malware Scans";
    case "management":
      return "Management";
    case "content-delivery":
      return "Content Delivery";
    case "other":
      return `Other (${count})`;
    case "other-list":
      return "Other";
    case "partner":
      return "Partner";
    case "partner-links":
      return "Links";
    case "partner-referrals":
      return "Referrals";
    case "partner-credits":
      return "Credits";
    case "partner-payments":
      return "Partner Payments";
    case "payment-info":
      return "Payment Info";
    case "payments":
      return "Payments";
    case "plan-details":
      return "Plan Details";
    case "plan-list":
      return "Plans";
    case "plan-select":
      return "Select Plan";
    case "plans":
      return `Plans (${count})`;
    case "plans-empty":
      return "Plans";
    case "profile":
      return "My Profile";
    case "orders":
      return "Orders";
    case "overview":
      return "Overview";
    case "payment":
      return "Payment";
    case "performance":
      return "Performance";
    case "rdns-records":
      return "Reverse DNS Records";
    case "refer-a-friend":
      return "Refer a Friend";
    case "refer-a-friend-title":
      return "Get your free $50 Credit!";
    case "refer-a-friend-learn-more":
      return "Learn more about the program";
    case "refer-a-friend-message":
      return "Introduce a friend to Nexcess and you will receive $50 of free credits when your friend purchases a Nexcess plan.";
    case "refer-a-friend-share":
      return "Share Your Link";
    case "refer-a-friend-questions":
      return "If you have questions or concerns, please email us at";
    case "referrals":
      return "Referrals";
    case "reseller-hosting":
      return `Reseller Hosting (${count})`;
    case "reseller-hosting-empty":
      return "Reseller Hosting";
    case "safe-harbor":
      return "Safe Harbor";
    case "scheduled-tasks":
      return "Scheduled Tasks";
    case "shared-hosting":
      return `Shared Hosting (${count})`;
    case "shared-hosting-empty":
      return "Shared Hosting";
    case "shared-hosting-list":
      return "Shared Hosting";
    case "set-default-client":
      return "Set Default Client";
    case "settings":
      return "Settings";
    case "sites":
      return "Sites";
    case "sites-count":
      return `Sites (${count})`;
    case "signout":
      return "Sign Out";
    case "ssh-keys":
      return "SSH Keys";
    case "ssl":
      return "SSL";
    case "ssl-certificate-add":
      return "Order SSL Certifcate";
    case "ssl-certificates":
      return "SSL Certificates";
    case "staging-and-dev":
      return "Staging & Dev";
    case "staging-environment":
      return "Staging Environment";
    case "staging-environments":
      return "Staging Environments";
    case "staging-environment-add":
      return "Add Staging Environment";
    case "stencils":
      return "Stencils";
    case "support":
      return "Support";
    case "support-tickets":
      return "Tickets";
    case "switch-client":
      return "Switch Client";
    case "tasks":
      return "Scheduled Tasks";
    case "tax":
      return "Payment Info";
    case "team":
      return "Team Members";
    case "permissions":
      return "Team Permissions";
    case "threat-protection":
      return "Threat Protection";
    case "upgrade-your-plan":
      return "Upgrade Your Plan";
    case "visual-compare":
      return "Visual Comparison";
    case "visual-comparison":
      return "Visual Comparison";
  }
};
