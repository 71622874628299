import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_ITEM_STATUS,
  SET_LIST,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
  UPDATE_IN_PLACE,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_PLURAL_KEY = "v1-tickets";
const CACHE_SINGULAR_KEY = "v1-ticket";
const CACHE_PASSWORDS_KEY = "v1-ticket-passwords";

const tickets = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    itemStatus: new Status(),
    list: [],
    pagination: null,
    sort: null,
    status: new Status(),
    openTicketCount: null,
    openTicketCountStatus: new Status(),
    options: null,
    optionsStatus: new Status(),
    passwords: null,
    passwordsStatus: new Status(),
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_ITEM_STATUS,
    SET_LIST,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
    UPDATE_IN_PLACE,
    SET_OPEN_TICKET_COUNT: (state, payload) => (state.openTicketCount = payload),
    SET_OPEN_TICKET_COUNT_STATUS: (state, payload) => (state.openTicketCountStatus.value = payload),
    SET_OPTIONS: (state, payload) => (state.options = payload),
    SET_OPTIONS_STATUS: (state, payload) => (state.optionsStatus.value = payload),
    SET_PASSWORDS: (state, payload) => (state.passwords = payload),
    SET_PASSWORDS_STATUS: (state, payload) => (state.passwordsStatus.value = payload),
  },
  actions: {
    addComment: async (context, { id, data }) => {
      const comment = request.makeParse("addTicketComment", { id }, data);
      clearListCache(CACHE_PLURAL_KEY);
      return comment;
    },
    addFeedback: async (context, { id, data }) =>
      request.makeParse("addTicketFeedback", { id }, data),
    addPassword: async (context, { id, data }) =>
      request.makeParse("addTicketPassword", { id }, data),
    addWatcher: async (context, { id, data }) =>
      request.makeParse("addTicketWatcher", { id }, data),
    removeWatcher: async (context, { id, data }) =>
      request.makeParse("deleteTicketWatcher", { id }, data),
    claim: async (context, { id, data }) => request.makeParse("claimTicket", { id }, data),
    close: async (context, { id }) => request.makeParse("closeTicket", { id }),
    create: async (context, data) => {
      try {
        return await request.makeParse("addTicket", {}, data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getAttachment: async (context, { id, attachmentId, attachmentName }) =>
      await request.makeDownload(
        "getTicketAttachment",
        { id, attachmentId },
        null,
        null,
        "/",
        attachmentName
      ),
    getCommentAttachmentLink: async (context, id) =>
      request.makeParse("getTicketDownloadAttachmentLink", { id }),
    getCommentAttachmentLinks: async (context, idList) => {
      const list = [];
      idList.forEach((item) =>
        list.push({ namedRoute: "getTicketDownloadAttachmentLink", params: { id: item } })
      );
      return request.makeParseList(list);
    },
    getCommentDataShare: async (context, uuid) => request.makeParse("getDataShare", { uuid }),
    getItem: async ({ commit, state }, id) => {
      const newStatus =
        (state.itemStatus.isLoaded || state.itemStatus.isUpdating) && id === state.item?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_ITEM_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_SINGULAR_KEY, { id }), request.makeParse, [
          "getTicket",
          { id },
        ]);
        commit("SET_ITEM", response);
        commit("SET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ITEM_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    getList: async ({ commit }, params) => {
      commit("SET_STATUS", Status.LOADING);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      const res = await get(createKey(CACHE_PLURAL_KEY, params), request.makeParse, [
        "getTickets",
        params,
      ]);
      commit("SET_LIST", res.list);
      commit("SET_PAGINATION", res.pagination);
      commit("SET_STATUS", Status.LOADED);
      return res;
    },
    getOpenTicketCount: async ({ commit, state }) => {
      const newStatus =
        state.itemStatus.isLoaded || state.itemStatus.isUpdating ? Status.UPDATING : Status.LOADING;
      commit("SET_OPEN_TICKET_COUNT_STATUS", newStatus);
      try {
        const response = await request.makeParse("getOpenTicketCount");
        commit("SET_OPEN_TICKET_COUNT", response);
        commit("SET_OPEN_TICKET_COUNT_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_OPEN_TICKET_COUNT_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    getOptions: async ({ commit }) => {
      try {
        commit("SET_OPTIONS_STATUS", Status.LOADING);
        const options = await request.makeParse(
          "getTicketOptions",
          {},
          {},
          { headers: { "Accept-Format": "nocworx/json-bundle" } }
        );
        commit("SET_OPTIONS", options);
        commit("SET_OPTIONS_STATUS", Status.LOADED);
        return options;
      } catch (e) {
        commit("SET_ITEM_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    getPasswords: async ({ commit, state }, id) => {
      const newStatus =
        (state.passwordsStatus.isLoaded || state.passwordsStatus.isUpdating) &&
        id === state.passwords?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_PASSWORDS_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_PASSWORDS_KEY, { id }), request.makeParse, [
          "getTicketPasswords",
          { id },
        ]);
        commit("SET_PASSWORDS", { id, list: response });
        commit("SET_PASSWORDS_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_PASSWORDS_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    open: async (context, { id }) => request.makeParse("openTicket", { id }),
    refreshItem: async ({ dispatch, state }, id) => {
      const cacheKey = createKey(CACHE_SINGULAR_KEY, { id });
      clear(cacheKey);
      if (id === state.item?.id) {
        return await dispatch("getItem", id);
      }
    },
    refreshCurrentItem: async ({ dispatch, state }) => {
      const id = state.item?.id;
      if (id) {
        const cacheKey = createKey(CACHE_SINGULAR_KEY, { id });
        clear(cacheKey);
        return await dispatch("getItem", id);
      }
    },
    refreshItemByNocworxId: async ({ dispatch, state }, nocworxId) => {
      if (nocworxId === state.item?.nocworxId) {
        const id = state.item?.id;
        const cacheKey = createKey(CACHE_SINGULAR_KEY, { id });
        clear(cacheKey);
        dispatch("getItem", id);
      }
    },
    refreshList: ({ dispatch, state }) => {
      clearListCache(CACHE_PLURAL_KEY);
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    refreshPasswords: ({ dispatch, state }, id) => {
      const passwordKey = createKey(CACHE_PASSWORDS_KEY, { id });
      clear(passwordKey);
      if (id === state.item?.id) {
        dispatch("getPasswords", id);
      }
    },
    refreshPasswordsInPlace: async ({ dispatch, state }) => {
      const id = state.passwords?.id;
      if (id) {
        const cacheKey = createKey(CACHE_PASSWORDS_KEY, { id });
        clear(cacheKey);
        dispatch("getPasswords", id);
      }
    },
    // deleteTicketRecipient: async ({ dispatch }, { id, recipientId }) => {
    //   await request.makeParse("deleteTicketRecipient", { id }, recipientId);
    //
    //   return dispatch("getTicket", id);
    // },
    // deleteTicketPassword: async ({ dispatch }, { id, passwordId }) => {
    //   await request.makeParse("deleteTicketPassword", { id: passwordId });
    //
    //   return dispatch("getTicket", id);
    // },
  },
  getters: {
    getItem: (state) => state.item,
    getItemStatus: (state) => state.itemStatus,
    getList: (state) => state.list || [],
    getListPagination: (state) => state.pagination,
    getListSort: (state) => state.sort,
    getListStatus: (state) => state.status,
    getOpenTicketCount: (state) => state.openTicketCount,
    getOpenTicketCountStatus: (state) => state.openTicketCountStatus,
    getOptions: (state) => state.options,
    getOptionsStatus: (state) => state.optionsStatus,
    getPasswords: (state) => state.passwords?.list || [],
    getPasswordsStatus: (state) => state.passwordsStatus,
  },
};

export default tickets;
