import {
  cancelService,
  getCancellationFormOptions,
  restoreService,
} from "@/utils/serviceActionsHelper";
import { clear, clearListCache, createKey, get } from "@/utils/networkCache";
import {
  SET_CHUNK,
  SET_ITEM,
  SET_ITEM_STATUS,
  SET_LIST,
  SET_LIST_STATUS,
  SET_PAGINATION,
  SET_SORT,
  SET_STATUS,
} from "@/store/commonMutations";
import { request } from "leatherman-js";
import Status from "@/classes/Status";

const CACHE_ITEM_KEY = "domain";
const CACHE_LIST_KEY = "domains";

const domains = {
  namespaced: true,
  state: {
    chunk: null,
    item: null,
    itemStatus: new Status(),
    list: [],
    listStatus: new Status(),
    pagination: null,
    sort: null,
  },
  mutations: {
    SET_CHUNK,
    SET_ITEM,
    SET_ITEM_STATUS,
    SET_LIST,
    SET_LIST_STATUS,
    SET_PAGINATION,
    SET_SORT,
    SET_STATUS,
  },
  actions: {
    async get({ commit, state }, id) {
      const newStatus =
        (state.itemStatus.isLoaded || state.itemStatus.isUpdating) && id === state.item?.id
          ? Status.UPDATING
          : Status.LOADING;
      commit("SET_ITEM_STATUS", newStatus);
      try {
        const response = await get(createKey(CACHE_ITEM_KEY, { id }), request.makeParse, [
          "getDomain",
          { id },
        ]);
        commit("SET_ITEM", response);
        commit("SET_ITEM_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_ITEM_STATUS", Status.ERROR);
      }
    },
    async getList({ commit, state }, params) {
      const newStatus =
        state.listStatus.isLoaded || state.listStatus.isUpdating ? Status.UPDATING : Status.LOADING;
      commit("SET_LIST_STATUS", newStatus);
      commit("SET_CHUNK", params);
      commit("SET_SORT", params);
      try {
        const response = await get(createKey(CACHE_LIST_KEY, params), request.makeParse, [
          "getDomains",
          params,
        ]);
        commit("SET_LIST", response.list);
        commit("SET_PAGINATION", response.pagination);
        commit("SET_LIST_STATUS", Status.LOADED);
        return response;
      } catch (e) {
        commit("SET_LIST_STATUS", Status.ERROR);
      }
    },
    refreshItem({ dispatch, state }, id) {
      const cacheKey = createKey(CACHE_ITEM_KEY, { id });
      clear(cacheKey);
      if (id === state.item?.id) {
        dispatch("get", id);
      }
    },
    refreshList({ dispatch, state }) {
      clearListCache(CACHE_LIST_KEY);
      dispatch("getList", {
        pageIndex: state.chunk?.pagination?.index,
        pageSize: state.chunk?.pagination?.size,
        sortProperty: state.chunk?.sort?.property,
        sortDirection: state.chunk?.sort?.direction,
      });
    },
    getCancellationFormOptions,
    cancelService,
    restoreService,
  },
  getters: {
    getItem: (state) => state.item,
    getItemStatus: (state) => state.itemStatus,
    getList: (state) => state.list,
    getListStatus: (state) => state.listStatus,
    getPagination: (state) => state.pagination,
    getSort: (state) => state.sort,
  },
};

export default domains;
