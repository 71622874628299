import {
  home,
  sharedHostDetail,
  sharedHostSafeHarbor,
  sharedHostSafeHarborStagingEnvironment,
  sharedHostSafeHarborStagingEnvironments,
} from "@/utils/navLinks";
import Breadcrumbs from "@/classes/Breadcrumbs";
import NavLink from "@/classes/NavLink";

export default {
  SHARED_HOST_SAFE_HARBOR: ({ sharedHost }) =>
    !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_MAGENTO_PATCHES: ({ sharedHost }) =>
    !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            new NavLink("Magento Patches", null, "shared-host-safe-harbor-magento-patches"),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_MALWARE_SCANS: ({ sharedHost }) =>
    !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            new NavLink("Malware Scans", null, "shared-host-safe-harbor-malware-scans"),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_ACCESS: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Access", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_BACKUPS: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Backups", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_CONTAINERS: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Containers", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_CONTROL_PANEL: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Control Panel", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_DATABASES: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Databases", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_DOMAINS: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Domains", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_EMAIL: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Email", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_ENVIRONMENT: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Environment", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_LOGS: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Logs", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_PERFORMANCE: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Performance", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_SCHEDULED_TASKS: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Scheduled Tasks", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_SETTINGS: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("Settings", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENT_SSL: ({ sharedHost, devSite }) =>
    !devSite || !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            sharedHostSafeHarborStagingEnvironments(sharedHost.id),
            sharedHostSafeHarborStagingEnvironment(sharedHost.id, devSite),
            new NavLink("SSL", null, () => null),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_STAGING_ENVIRONMENTS: ({ sharedHost }) =>
    !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            new NavLink(
              "Staging Environments",
              null,
              "shared-host-safe-harbor-staging-environments"
            ),
          ],
        }),
  SHARED_HOST_SAFE_HARBOR_THREAT_PROTECTION: ({ sharedHost }) =>
    !sharedHost
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            new NavLink("Shared Hosting", null, "shared-hosting"),
            sharedHostDetail(sharedHost.id, sharedHost.domain),
            sharedHostSafeHarbor(sharedHost.id),
            new NavLink("Threat Protection", null, "shared-host-safe-harbor-threat-protection"),
          ],
        }),
};
