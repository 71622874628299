import { home, planDetail, planList, planSiteList } from "@/utils/navLinks";
import Breadcrumbs from "@/classes/Breadcrumbs";
import NavLink from "@/classes/NavLink";

export default {
  PLAN_ANALYTICS: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Analytics", null, "plan-analytics"),
          ],
        }),
  PLAN_CONTAINERS: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Containers", null, "plan-containers"),
          ],
        }),
  PLAN_CONTROL_PANEL: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Control Panel", null, "plan-control-panel"),
          ],
        }),
  PLAN_CREDENTIALS: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Credentials", null, "plan-credentials"),
          ],
        }),
  PLAN_DATABASES: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Databases", null, "plan-databases"),
          ],
        }),
  PLAN_EMAIL: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Email", null, "plan-email"),
          ],
        }),
  PLAN_INTEGRATIONS: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Integrations", null, "plan-integrations"),
          ],
        }),
  PLAN_MANAGEMENT: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Management", null, "plan-management"),
          ],
        }),
  PLAN_CONTENT_DELIVARY: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Content Delivery", null, "plan-content-delivery"),
          ],
        }),
  PLAN_SCHEDULED_TASKS: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Scheduled Tasks", null, "plan-scheduled-tasks"),
          ],
        }),
  PLAN_SITES: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [home(), planList(), planDetail(plan.id, plan.nickname), planSiteList()],
        }),
  PLAN_STENCILS: ({ plan }) =>
    !plan
      ? new Breadcrumbs({ loading: true })
      : new Breadcrumbs({
          links: [
            home(),
            planList(),
            planDetail(plan.id, plan.nickname),
            new NavLink("Stencils", null, "plan-stencils"),
          ],
        }),
};
