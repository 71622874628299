import { constants } from "@/utils";
import devSite from "@/router/routes/dashboard/plans/devSite";
import store from "@/store";

export default [
  {
    path: "analytics",
    name: "plan-site-analytics",
    meta: {
      breadcrumb: "PLAN_SITE_ANALYTICS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Analytics.vue"),
  },
  {
    path: "backups",
    name: "plan-site-backups",
    meta: {
      breadcrumb: "PLAN_SITE_BACKUPS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Backups.vue"),
  },
  {
    path: "containers",
    name: "plan-site-containers",
    meta: {
      breadcrumb: "PLAN_SITE_CONTAINERS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Containers.vue"),
  },
  {
    path: "control-panel",
    name: "plan-site-control-panel",
    meta: {
      breadcrumb: "PLAN_SITE_CONTROL_PANEL",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/ControlPanel.vue"),
    beforeEnter: async (to, from, next) => {
      const isAdmin = store.getters["auth/isAdmin"];
      if (isAdmin) {
        next();
      } else {
        next({
          name: constants.ROUTES.HOME,
        });
      }
    },
  },
  {
    path: "credentials",
    name: "plan-site-credentials",
    meta: {
      breadcrumb: "PLAN_SITE_CREDENTIALS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Credentials.vue"),
  },
  {
    path: "databases",
    name: "plan-site-databases",
    meta: {
      breadcrumb: "PLAN_SITE_DATABASES",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Databases.vue"),
  },
  {
    path: "dev",
    name: "plan-site-dev",
    meta: {
      breadcrumb: "PLAN_SITE_DEV",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironments.vue"
      ),
    props: (route) => ({
      siteId: Number.parseInt(route.params.siteId),
    }),
  },
  {
    path: "domains",
    name: "plan-site-domains",
    meta: {
      breadcrumb: "PLAN_SITE_DOMAINS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Domains.vue"),
  },
  {
    path: "email",
    name: "plan-site-email",
    meta: {
      breadcrumb: "PLAN_SITE_EMAIL",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Email.vue"),
  },
  {
    path: "integrations",
    name: "plan-site-integrations",
    meta: {
      breadcrumb: "PLAN_SITE_INTEGRATIONS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Integrations.vue"),
  },
  {
    path: "logs",
    name: "plan-site-logs",
    meta: {
      breadcrumb: "PLAN_SITE_LOGS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Logs.vue"),
  },
  {
    path: "management",
    name: "plan-site-management",
    meta: {
      breadcrumb: "PLAN_SITE_MANAGEMENT",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Management.vue"),
    props: (route) => ({
      siteId: Number.parseInt(route.params.siteId),
    }),
  },
  {
    path: "content-delivery",
    name: "plan-site-content-delivery",
    meta: {
      breadcrumb: "PLAN_SITE_CONTENT_DELIVERY",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/NexcessCDN.vue"),
  },
  {
    path: "design-services",
    name: "plan-site-design-services",
    meta: {
      breadcrumb: "PLAN_SITE_DESIGN_SERVICES",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DesignServices.vue"
      ),
    beforeEnter: async (to, from, next) => {
      const plan = await store.dispatch("plans/getItem", to.params?.planId);
      if (plan?.isTShirt) {
        next({
          name: "plan-site-detail",
          params: { planId: to.params?.planId, siteId: to.params?.siteId },
        });
      } else {
        next();
      }
    },
  },
  {
    path: "scheduled-tasks",
    name: "plan-site-scheduled-tasks",
    meta: {
      breadcrumb: "PLAN_SITE_SCHEDULED_TASKS",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/ScheduledTasks.vue"
      ),
  },
  {
    path: "ssl",
    name: "plan-site-ssl",
    meta: {
      breadcrumb: "PLAN_SITE_SSL",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Ssl.vue"),
  },
  {
    path: "staging-&-dev",
    name: "plan-site-staging-and-dev",
    meta: {
      breadcrumb: "PLAN_SITE_STAGING_AND_DEV",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironments.vue"
      ),
    props: (route) => ({
      siteId: Number.parseInt(route.params.siteId),
    }),
  },
  {
    path: "stencils",
    name: "plan-site-stencils",
    meta: {
      breadcrumb: "PLAN_SITE_STENCILS",
    },
    component: () =>
      import(/* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/Stencils.vue"),
  },
  {
    path: "visual-comparison",
    name: "plan-site-visual-comparison",
    meta: {
      breadcrumb: "PLAN_SITE_VISUAL_COMPARISON",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/VisualComparison.vue"
      ),
  },
  {
    path: "dev-environments/:devSiteId",
    name: "plan-site-dev-detail",
    redirect: {
      name: "plan-site-dev-credentials",
    },
    component: () =>
      import(
        /* webpackChunkName: "plans" */ "@/pages/Dashboard/Plans/Plan/Site/DevEnvironment.vue"
      ),
    props: (route) => ({
      devSiteId: Number.parseInt(route.params.devSiteId),
    }),
    meta: {
      sidebar: "PLAN_SITE_DEV",
    },
    children: devSite,
  },
];
