/* eslint-disable no-undef */
import Cookies from "js-cookie";

const getReferralCookie = () => {
  const cookieRaw = Cookies.get("lwReferralData");
  if (!cookieRaw) {
    return;
  }

  let cookieBaked = false;

  try {
    cookieBaked = JSON.parse(cookieRaw);
  } catch (e) {
    console.log("error parsing irCookie", e);
  }

  return cookieBaked;
};

const affiliates = {
  namespaced: true,
  state: {
    lwReferralCookie: getReferralCookie(),
  },
  getters: {
    getReferral: (state) => {
      if (state.lwReferralCookie?.program === "impact_radius") {
        const { clickid, iradid, irpid, sharedid } = state.lwReferralCookie;
        return [
          {
            source: "impact_radius",
            source_data: {
              clickid,
              iradid,
              irpid,
              sharedid,
            },
          },
        ];
      } else if (state.lwReferralCookie?.program === "partnerstack") {
        const { gspk, gsxid } = state.lwReferralCookie;
        return [
          {
            source: "partnerstack",
            source_data: {
              gspk,
              gsxid,
            },
          },
        ];
      }
    },
    getProgram: (state) => state.lwReferralCookie?.program,
  },
};

export default affiliates;
