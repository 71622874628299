import { createStore } from "vuex";
import modules from "@/store/modules";
import websocket from "@/store/plugins/websocket";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [websocket()],
});
